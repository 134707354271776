import _ from 'lodash';
import FormGroup from '@material-ui/core/FormGroup';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { ApiDefaultAlert } from '../../types/default_alert';
import useStyles from '../../assets/styles/Master.css';
import { PrimaryReadOnlyField, UIError, UIReturn, UISubmit } from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
  post: string;
  address: string;
  groups: Group[];
}

type Group = {
  number: number | null;
  name: string;
  emails: string[];
  alertNumber: number | null;
  alertValue: number | null;
};
type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkSiteConfirm: FC<Props> = (props) => {
  const { handleSubmit, values, errors, default_alerts } = props;
  const classes = useStyles();
  const alert = (alertNumber: number | null): any =>
    _.find(default_alerts, { number: alertNumber });

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={classes.errorMessage}>
        <UIError errors={errors} />
      </p>

      <PrimaryReadOnlyField label="現場名" value={values.name} />
      <PrimaryReadOnlyField label="郵便番号" value={values.post} />
      <PrimaryReadOnlyField label="住所" value={values.address} />

      {values.groups.map((group, index: number) => (
        <div className={classes.workSiteEditAlert} key={index}>
          <PrimaryReadOnlyField label="グループ名" value={group.name} />
          {
            [...Array(5)].map((_, i) =>
              <PrimaryReadOnlyField
                label={`アラート時の通知先メールアドレス${i+1}`}
                value={group.emails[i]}
              />
            )
          }
          <PrimaryReadOnlyField
            label="アラート設定"
            value={alert(group.alertNumber)?.name ?? '現場の設定を反映'}
          />
          {group.alertNumber !== null && (
            <PrimaryReadOnlyField
              label="アラート設定値"
              value={
                group.alertNumber === 0
                  ? String(group.alertValue)
                  : String(alert(group.alertNumber)?.value)
              }
            />
          )}
        </div>
      ))}

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確定" />
      </FormGroup>
    </form>
  );
};

export default WorkSiteConfirm;

import { FC, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
interface Props {
  children: ReactNode;
  dialogOpen: boolean;
  handleOpen: () => void;
  logoutItem?: (id: string) => Promise<void> | void;
  logoutId: string;
}

const UILogoutDialog: FC<Props> = (props) => {
  const handleDelete = () => {
    if (props.logoutItem) {
      props.logoutItem(props.logoutId);
    }
    props.handleOpen();
  };
  return (
    <div>
      <Dialog
        open={props.dialogOpen}
        onClose={props.handleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="simple-dialog-title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon />
            <span style={{ marginLeft: '5px' }}>強制ログアウト</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.children}
            <br />
            この操作は取り消しできません。
            <br />
            本当にログアウトしますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={props.handleOpen} variant="outlined">
            戻る
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={handleDelete}
          >
            ログアウト
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UILogoutDialog;

import { ReactNode } from 'react';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
};

/**
 *
 *
 *
 * @method LayoutComponents
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const NoLayout = (props: Props) => {
  return <>{props.children}</>;
};

export default NoLayout;

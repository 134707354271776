import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { csvDownload } from '../../functions/CsvDownload';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import { ApiGetDevice } from '../../types/device';
import { CsvDeviceHeader } from '../../constants/csv';
import useStyles from '../../assets/styles/Header.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CsvDownloadButton = () => {
  const { id } = useParams<Params>();
  const classes = useStyles();
  const { alertWithCode } = useAlert();

  //  ダウンロードさせる
  const handleClick = async () => {
    const result = await fetch.get(`/work_site/${id}/devices`);
    if (result.status === 200) {
      const devices = result.data.map((device: ApiGetDevice) => {
        return [device.name];
      });

      csvDownload({
        filename: 'センサーデバイス一覧',
        header: CsvDeviceHeader,
        body: devices,
      });
    } else if (result.status === 500) {
      alertWithCode(result.status);
    }
  };

  return (
    <span className={classes.subHeaderRightMenu} onClick={() => handleClick()}>
      <GetAppIcon />
      センサーデバイス一覧ダウンロード
    </span>
  );
};

export default CsvDownloadButton;

import { FC } from 'react';
import { Grid, Chip } from '@material-ui/core';
import clsx from 'clsx';
import { LABEL } from '../../constants/worker';
import useStyles from '../../assets/styles/Uikit.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  name: string;
  value: string[];
  setFieldValue?: (name: string, value: any) => void;
  disabled?: boolean;
};

/**
 * トグルラベル
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UIToggleLabel: FC<Props> = (props: Props) => {
  const { disabled = false } = props;
  const classes = useStyles();

  const handleChange = (value: string) => {
    if (props.setFieldValue) {
      const index = props.value.indexOf(value);

      if (index === -1) {
        props.value.push(value);
      } else {
        props.value.splice(index, 1);
      }
      props.setFieldValue(props.name, props.value);
    }
  };

  return (
    <Grid container spacing={1}>
      {LABEL.map((label) => {
        const index = props.value.indexOf(label.value);

        return (
          <Grid item md={4} xs={4} key={label.value}>
            <Chip
              classes={{
                root: clsx(
                  classes.toggleLabelChips,
                  index !== -1 && classes.toggleLabelChipsActive,
                ),
              }}
              label={label.name}
              size="small"
              onClick={() => handleChange(label.value)}
              clickable={!disabled}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default UIToggleLabel;

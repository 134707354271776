import FormGroup from '@material-ui/core/FormGroup';
import { FormikProps } from 'formik';
import { FC } from 'react';
import useStyles from '../../assets/styles/Master.css';
import { PrimaryReadOnlyField, UIError, UIReturn, UISubmit } from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
  password: string;
  passwordConfirm: string;
  belongingName: string;
}

type Props = FormikProps<FormValues> & {
  email: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserConfirm: FC<Props> = (props) => {
  const { handleSubmit, values, errors, email } = props;
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={classes.errorMessage}>
        <UIError errors={errors} />
      </p>

      <PrimaryReadOnlyField label="ユーザー名" value={values.name} />
      <PrimaryReadOnlyField label="メールアドレス" value={email} />
      <PrimaryReadOnlyField label="所属" value={values.belongingName} />

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確定" />
      </FormGroup>
    </form>
  );
};

export default UserConfirm;

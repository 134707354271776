import { FC, ChangeEvent } from 'react';
import { Slider, Mark } from '@material-ui/core';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  name: string;
  value: number;
  step?: number;
  marks?: Mark[];
  color: string;
  setFieldValue?: (name: string, value: any) => void;
  disabled?: boolean;
};

/**
 * アラート
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UIAlert: FC<Props> = (props: Props) => {
  const {
    disabled = false,
    marks = [
      { value: 0, label: '0%' },
      { value: 50, label: '50%' },
      { value: 100, label: '100%' },
    ],
    step = 10,
  } = props;

  const handleChange = (e: ChangeEvent<{}>, value: number | number[]) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, value);
    }
  };

  return (
    <Slider
      name={props.name}
      value={props.value}
      marks={marks}
      step={step}
      onChange={handleChange}
      valueLabelDisplay='on'
      style={{ color: props.color }}
      disabled={disabled}
    />
  );
};

export default UIAlert;

import { FC } from 'react';
import { FormikErrors } from 'formik';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  errors: FormikErrors<any>;
};

/**
 * エラー
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UIError: FC<Props> = (props: Props) => {
  const errors = props.errors as any;

  return (
    <>
      {Object.keys(errors).map((key) => (
        <div key={key}>
          {Array.isArray(errors[key]) ? (
            <>
              {errors[key].map((e: any, index: any) => (
                <div key={index}>
                  {e && (
                    <>
                      {Object.keys(e).map((akey) => (
                        <div key={akey}>
                          {index + 1}番目: {e[akey]}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </>
          ) : (
            errors[key]
          )}
        </div>
      ))}
    </>
  );
};

export default UIError;

import { FC, createContext } from 'react';
import Router from './Router';
import CssBaseline from '@material-ui/core/CssBaseline';
import './assets/style.css';

export const UserContext = createContext<any>(null);
const App: FC = () => {
  return (
    <>
      <CssBaseline />
      <Router />
    </>
  );
};

export default App;

import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import { PeopleAlt, Map } from '@material-ui/icons';
import { fetch } from '../functions/fetch';
import { useAlert } from '../functions';
import { SubHeader } from '../components';
import useStyles from '../assets/styles/Home.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Home: FC = () => {
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState<string>('');
  const classes = useStyles();
  const { alertWithCode } = useAlert();

  //  ページ情報の取得
  useEffect(() => {
    (async () => {
      const result = await fetch.get('/user/organizations');
      if (result.status === 200) {
        setOrganizations(result.data);
        if (result.data.length > 0) {
          setOrganizationId(result.data[0].id);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [alertWithCode]);

  return (
    <>
      <SubHeader
        title="トップページ"
        link=""
        menus={[
          {
            icon: '',
            name: '2段階認証設定',
            link: `/organization/twoFactor/${organizationId}`,
          },
        ]}
      />
      <Container>
        <div className={classes.content}>
          <h2 className={classes.header}>ダッシュボード選択</h2>

          <section className={classes.section}>
            <Box
              className={classes.sectionCaption}
              display="flex"
              alignItems="center"
            >
              <Box className={classes.sectionIcon}>
                <PeopleAlt />
              </Box>
              <Box className={classes.sectionTitle}>組織から選択</Box>
            </Box>

            <Box className={classes.panels} display="flex" flexWrap="wrap">
              {organizations.map((org: any) => (
                <Box className={classes.panel} key={org.id}>
                  <Link
                    className={classes.block}
                    to={`/dashboard/organization/${org.id}`}
                  >
                    <div className="shorten_text">{org.companyName}</div>
                    <div className="shorten_text">{org.name}</div>
                  </Link>
                </Box>
              ))}
            </Box>
          </section>

          <section className={classes.section}>
            <Box
              className={classes.sectionCaption}
              display="flex"
              alignItems="center"
            >
              <Box className={classes.sectionIcon}>
                <Map />
              </Box>
              <Box className={classes.sectionTitle}>現場から選択</Box>
            </Box>

            <Box className={classes.panels} display="flex" flexWrap="wrap">
              {organizations.map((org: any) =>
                org.workSites.map((worksite: any) => (
                  <Box className={classes.panel} key={worksite.id}>
                    <Link
                      className={classes.block}
                      to={`/dashboard/workSite/${worksite.id}`}
                    >
                      <div className="shorten_text">{org.name}</div>
                      <div className="shorten_text">{worksite.name}</div>
                    </Link>
                  </Box>
                )),
              )}
            </Box>
          </section>
        </div>
      </Container>
    </>
  );
};
export default Home;

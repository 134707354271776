import { Box } from '@material-ui/core';
import format from 'date-fns/format';
import { useCommonStyles } from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  title: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportHeader = (props: Props) => {
  const classes = useCommonStyles();

  return (
    <Box className={classes.header} display="flex" alignItems="center">
      <Box className={classes.headerImage}>
        <img src="/images/report/logo.svg" alt={props.title} />
      </Box>
      <Box className={classes.headerTitle} flexGrow={1}>
        {props.title} - eメット&reg;システム
      </Box>
      <Box className={classes.headerDate}>
        作成日: {format(new Date(), 'yyyy/MM/dd')}
      </Box>
    </Box>
  );
};

export default ReportHeader;

import { FC } from 'react';
import Empty from '../../Empty';
import DangerGraph from './DangerGraph';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  alertLevel1: number;
  alertLevel2: number;
  alertLevel3: number;
  alertLevel4: number;
  totalCount: number;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const GroupStatus: FC<Props> = (props: Props) => {
  const classes = commonStyles();

  return (
    <div className={classes.block}>
      <h3 className={classes.blockTitle}>会社・部署全体の状態</h3>

      <Empty isEmpty={props.totalCount === null}>
        <DangerGraph
          alertLevel1={props.alertLevel1}
          alertLevel2={props.alertLevel2}
          alertLevel3={props.alertLevel3}
          alertLevel4={props.alertLevel4}
          totalCount={props.totalCount}
        />
      </Empty>
    </div>
  );
};

export default GroupStatus;

import { FC } from 'react';
import { FormikProps } from 'formik';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { UITextField, UISubmit, UIError } from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  email: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, handleChange, values, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={masterClasses.text}>パスワード再設定メールを送信します</p>
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="email"
          name="email"
          label="メールアドレス"
          value={values.email}
          error={errors.email}
          required
          handleChange={handleChange}
        />
      </div>

      <div className="module-spacer--medium" />

      <div className={masterClasses.submitButton}>
        <UISubmit title="送信" />
      </div>
    </form>
  );
};

export default Form;

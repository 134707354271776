import { FC, useState, ChangeEvent } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Button,
  Dialog,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { FilterList, Close } from '@material-ui/icons';
import { LABEL } from '../../../../constants/worker';
import {
  commonStyles,
  worksiteStyles,
} from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  setFilter: (labels: string[]) => void;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const FilterDialog: FC<Props> = (props: Props) => {
  const { setFilter } = props;
  const classes = commonStyles();
  const wsClasses = worksiteStyles();
  const [value, setValue] = useState<string[]>([]);
  const [selects, setSelects] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  //  ダイアログオープン
  const handleClickOpen = () => {
    setIsOpen(true);
  };

  //  閉じる
  const handleClose = () => {
    setIsOpen(false);
    setValue([]);
    setFilter([]);
  };

  //  フィルター実行
  const handleFilterChange = () => {
    setIsOpen(false);
    setFilter(value);
    setSelects(value);
  };

  //  入力
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const index = value.indexOf(event.target.name);

    if (index === -1) {
      value.push(event.target.name);
    } else {
      value.splice(index, 1);
    }
    setValue([...value]);
  };

  return (
    <>
      <Button
        className={wsClasses.filterButton}
        onClick={handleClickOpen}
        startIcon={<FilterList />}
      >
        絞り込み
      </Button>

      {selects.map((label, index) => (
        <span className={wsClasses.filterChip} key={index}>
          {label}
        </span>
      ))}

      <Dialog
        classes={{ paper: classes.workersDialog }}
        open={isOpen}
        maxWidth="lg"
        onClose={handleClose}
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}></Box>
          <Box>
            <IconButton size="small" onClick={handleClose}>
              <Close className={classes.workersDialogClose} />
            </IconButton>
          </Box>
        </Box>

        <div className={classes.workersDialogForm}>
          <Grid
            className={classes.workersDialogFormLabel}
            container
            spacing={1}
          >
            {LABEL.map((label) => {
              const index = value.indexOf(label.value);

              return (
                <Grid
                  className={classes.workersDialogFormLabelItem}
                  item
                  md={4}
                  xs={6}
                  key={label.value}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={index !== -1}
                        onChange={handleChange}
                        name={label.value}
                        color="primary"
                      />
                    }
                    label={label.value}
                  />
                </Grid>
              );
            })}
          </Grid>

          <div className={classes.workersDialogFormHelp}>
            チェックした項目が含まれる装着者に絞り込まれます
          </div>

          <div className="module-spacer--small" />

          <Button
            className={wsClasses.filterButton}
            onClick={handleFilterChange}
          >
            絞り込む
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default FilterDialog;

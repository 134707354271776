import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from '../../assets/styles/Master.css';
import { SubHeader, WorkerDetailView } from '../../components';
import { fetch, useAlert } from '../../functions';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkerDetail: FC = () => {
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [worker, setWorker] = useState();
  const [workSite, setWorkSite] = useState<any>();
  //  初期値取得
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/worker/${id}`);
      if (result.status === 200) {
        setWorker(result.data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
      //サブヘッダー用の現場情報とその組織情報を取得。
      const workSite = await fetch.get(`/work_site/${result.data.workSiteId}`);
      if (workSite.status === 200) {
        setWorkSite(workSite.data);
      } else if (workSite.status === 500) {
        alertWithCode(workSite.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={workSite?.name}
        subTitle="装着者の詳細"
        link={`/workSite/${id}/workers`}
      />

      <div className={classes.root}>
        {worker && <WorkerDetailView worker={worker} />}
      </div>
    </>
  );
};

export default WorkerDetail;

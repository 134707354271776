import { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import {
  UITextField,
  UIDatePicker,
  UISubmit,
  UIReturn,
  UIError,
} from '../index';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  postDate: string;
  title: string;
  description: string;
  from: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, handleChange, setFieldValue, values, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  useEffect(() => {
    errors && window.scrollTo(0, 0);
  }, [errors]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <UIDatePicker
          name="postDate"
          label="日付"
          value={values.postDate}
          error={errors.postDate}
          setFieldValue={setFieldValue}
        />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="from"
          label="投稿者名"
          value={values.from}
          error={errors.from}
          required
          handleChange={handleChange}
        />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          name="title"
          label="タイトル"
          value={values.title}
          error={errors.title}
          required
          handleChange={handleChange}
        />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          name="description"
          label="本文"
          value={values.description}
          error={errors.description}
          required
          handleChange={handleChange}
          multiline
          rows={10}
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

import GetAppIcon from '@material-ui/icons/GetApp';
import { csvDownload } from '../../functions/CsvDownload';
import { CsvWorkerHeader } from '../../constants/csv';
import useStyles from '../../assets/styles/Header.css';

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CsvSampleDownloadButton = () => {
  const classes = useStyles();

  //  ダウンロードさせる
  const handleClick = () => {
    csvDownload({
      filename: `装着者一括登録用サンプル`,
      header: CsvWorkerHeader,
      body: [
        [
          'ニホン００１',
          '1',
          'EMET-0001',
          '日本',
          '太郎',
          'ニホン',
          'タロウ',
          '1980/01/01',
          'male',
          'sample@emet.jp',
          '00001234567',
          '1638001',
          '東京都新宿区西新宿二丁目８番１号',
          'EMET',
          '',
          '',
          '新人 喫煙者',
        ],
      ],
    });
  };

  return (
    <span className={classes.subHeaderRightMenu} onClick={() => handleClick()}>
      <GetAppIcon />
      CSVサンプルダウンロード
    </span>
  );
};

export default CsvSampleDownloadButton;

import { Grid } from '@material-ui/core';
import { useParamsStyles } from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Param = {
  name: string;
  value: string;
};
type Props = {
  left: Param[];
  right: Param[];
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportParamsTable = (props: Props) => {
  const classes = useParamsStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={6}>
          <table className={classes.table}>
            <tbody>
              {props.left.map((p: Param, index) => (
                <tr key={index}>
                  <td>{p.name}</td>
                  <td>: {p.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <Grid item xs={6}>
          <table className={classes.table}>
            <tbody>
              {props.right.map((p: Param, index) => (
                <tr key={index}>
                  <td>{p.name}</td>
                  <td>: {p.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportParamsTable;

import { FC, ReactNode } from 'react';
import Popover from '@material-ui/core/Popover';


/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface Props {
  anchorEl: any;
  onClose: () => void;
  children: ReactNode;
}

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HeaderMenuContent: FC<Props> = (props) => {
  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {props.children}
    </Popover>
  );
};

export default HeaderMenuContent;

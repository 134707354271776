import { FC } from 'react';
import useStyles from '../../assets/styles/Master.css';
import { PrimaryContainedButton } from '../../components';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  link: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const EmailCommit: FC<Props> = (props: Props) => {
  const { message, link } = props;
  const classes = useStyles();

  return (
    <div>
      <p className={classes.text}>{message}</p>
      <div className="module-spacer--medium" />
      <PrimaryContainedButton label="戻る" linkTo={link} />
    </div>
  );
};

export default EmailCommit;

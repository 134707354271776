import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import { SubHeader, UserEditConfirm, UserEditForm } from '../../components';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { updateUser } from '../../redux/userSlice';
import { push } from 'connected-react-router';
/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  name: yup
    .string()
    .max(255, 'ユーザー名は255文字まで入力できます')
    .required('ユーザー名を入力してください'),
  belongingName: yup.string().nullable().required('所属を入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserEdit: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alertWithCode } = useAlert();
  const [user, setUser] = useState({ name: '', email: '', belongingName: '' });

  //  フォームの定義
  const formik = useFormik({
    initialValues: { name: user.name, belongingName: user.belongingName },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.put(`/user`, values);
        if (result.status === 200) {
          dispatch(
            updateUser({
              name: values.name,
              belongingName: values.belongingName,
            }),
          );
          dispatch(push('/my'));
        } else if (result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  //  データの取得
  //  --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/user`);

      if (result.status === 200) {
        setUser({
          name: result.data.name,
          email: result.data.email,
          belongingName: result.data.belongingName,
        });
      } else if (result.status === 500) {
        alertWithCode(result.status);
      } else {
        alert(result.status);
      }
    })();
  }, [setUser, alertWithCode]);

  return (
    <>
      <SubHeader
        title={`アカウント情報編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link="/my"
      />
      <div className={classes.root}>
        {mode === 'editing' && <UserEditForm {...formik} email={user.email} />}
        {mode === 'confirm' && (
          <UserEditConfirm {...formik} email={user.email} />
        )}
      </div>
    </>
  );
};

export default withFormStatus(UserEdit);

import { makeStyles } from '@material-ui/core/styles';

/**
 *  確認画面のスタイル
 *
 *
 * -------------------------------------------------------------------------- */
export const confirmStyles = makeStyles(
  (theme) => ({
    status: {
      width: '80px',
      minWidth: '80px',
      textAlign: 'center',

      '& span': {
        fontWeight: 'bold',
        color: '#666',
      },
    },

    statusDanger: {
      '& span': {
        color: '#e33',
      },
    },

    cellDelete: {
      backgroundColor: '#d33053',
      fontWeight: 'bold',
      color: '#fff',
    },

    cellUpdate: {
      backgroundColor: '#c7dddf',
      fontWeight: 'bold',
      color: '#295559',
    },
  }),
  { index: 1 },
);

/**
 *  使用方法説明のスタイル
 *
 *
 * -------------------------------------------------------------------------- */
export const usageStyles = makeStyles(
  (theme) => ({
    warning: {
      display: 'block',
      fontWeight: 'bold',
      textAlign: 'left',
      color: '#d33053',
    },

    usage: {
      margin: '16px 0 0',
      width: 'auto',
      minWidth: 600,
      border: '.5px solid rgba(0, 0, 0, .08)',
      tableLayout: 'auto',
    },

    row: {
      color: '#444',

      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },

      '& > th ': {
        whiteSpace: 'nowrap',
        padding: '4px 6px',
        fontWeight: 'normal',
        textAlign: 'left',

        '& > span': {
          margin: '0 0 0 4px',
          padding: '2px 5px',
          backgroundColor: '#ee3053',
          borderRadius: '10px',
          fontWeight: 'bold',
          fontSize: '0.8em',
          color: '#fff',
        },
      },

      '& > td ': {
        whiteSpace: 'nowrap',
        padding: '4x 6px',
      },
    },

    important: {
      fontWeight: 'bold',
      color: '#d33053',
    },
  }),
  { index: 1 },
);

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../assets/styles/Header.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface Props {
  workSites: { id: string; name: string }[];
  handleOpen: () => void;
}

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkSiteMenu: FC<Props> = (props) => {
  const { workSites, handleOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const clickWorkSite = (e: any, id: string) => {
    e.stopPropagation();
    dispatch(push(`/dashboard/workSite/${id}`));
    handleOpen();
  };

  return (
    <List className="full-width">
      {workSites.map((workSite: any) => (
        <ListItem
          button
          key={workSite.id}
          onClick={(e) => clickWorkSite(e, workSite.id)}
          className={classes.subHeaderWorkSiteItem}
        >
          <ListItemText
            primary={workSite.name}
            className={classes.subHeaderWorkSiteName}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default WorkSiteMenu;

import { useOperationStatusMonthlyStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  deviceUniqueCount?: number;
  deviceCount?: number;
  deviceSettingCount?: number;
  workerUniqueCount?: number;
  workerCount?: number;
  workerSettingCount?: number;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyOperationStatus = (props: Props) => {
  const classes = useOperationStatusMonthlyStyles();

  return (
    <div className={classes.container}>
      <div className={classes.operationStatus}>
        <div className={classes.operationStatusImage}>
          <img src="/images/report/status-helmet.svg" alt="ヘルメット" />
        </div>
        <div className={classes.operationStatusTitle}>稼働台数</div>
        <div className={classes.operationStatusCount}>
          <span>{props.deviceUniqueCount}</span>台/{props.deviceSettingCount}台
        </div>
        <div className={classes.operationStatusTitle}>稼働のべ台数</div>
        <div className={classes.operationStatusCount}>
          <span>{props.deviceCount}</span>台
        </div>
      </div>
      <div className={classes.operationStatus}>
        <div className={classes.operationStatusImage}>
          <img src="/images/report/worker.svg" alt="装着者" />
        </div>
        <div className={classes.operationStatusTitle}>装着者人数</div>
        <div className={classes.operationStatusCount}>
          <span>{props.workerUniqueCount}</span>人/{props.workerSettingCount}人
        </div>
        <div className={classes.operationStatusTitle}>装着者のべ人数</div>
        <div className={classes.operationStatusCount}>
          <span>{props.workerCount}</span>人
        </div>
      </div>
    </div>
  );
};

export default ReportMonthlyOperationStatus;

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PieLabel = (props: any) => {
  const { innerRadius, outerRadius, cx, cy, midAngle, percent } = props;
  const radian = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * radian) * 1.2;
  const y = cy + radius * Math.sin(-midAngle * radian) * 1.1;

  return (
    <>
      {percent > 0 && (
        <text
          x={x}
          y={y}
          fill="#7A7A7A"
          fontSize="10px"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )}
    </>
  );
};

export default PieLabel;

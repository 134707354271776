import { FC } from 'react';
import { FormGroup } from '@material-ui/core';
import { FormikProps } from 'formik';
import format from 'date-fns/format';
import useStyles from '../../assets/styles/Master.css';
import { PrimaryReadOnlyField, UIError, UIReturn, UISubmit } from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  postDate: string;
  title: string;
  description: string;
  from: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CreateConfirm: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, values, errors } = props;
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={classes.errorMessage}>
        <UIError errors={errors} />
      </p>

      <PrimaryReadOnlyField
        label="日付"
        value={format(new Date(values.postDate), 'yyyy/MM/dd')}
      />
      <PrimaryReadOnlyField label="投稿者名" value={values.from} />
      <PrimaryReadOnlyField label="タイトル" value={values.title} />
      <PrimaryReadOnlyField label="本文" value={values.description} multiline />

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="作成" />
      </FormGroup>
    </form>
  );
};

export default CreateConfirm;

import { push } from 'connected-react-router';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import logo from '../../assets/images/logo.svg';
import useStyles from '../../assets/styles/Master.css';
import { News, SignInForm, SignInSecondForm } from '../../components';
import { fetch, useAlert } from '../../functions';
import { getOrganizations } from '../../redux/commonSlice';
import { signIn } from '../../redux/loginSlice';
import { getUser } from '../../redux/userSlice';
import { FormMode } from '../../types/forms';
import { AUTH_CODE_EXPIRATION } from '../../constants/common';

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const signInSchema = yup.object({
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('メールアドレスが不正です'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードは8文字以上を入力してください'),
});

export const signInSecondSchema = yup.object({
  oneTimePassword: yup
    .string()
    .required('ワンタイムパスワードを入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const SignIn: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mode, setMode] = useState<FormMode>('signIn');
  const [oneTimePassword, setOneTimePassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [expiration, setExpiration] = useState<number>(0);
  const { alertWithCode, alertWithText } = useAlert();
  const [btnDisabled, setBtnDisabled] = useState(false);

  //  ログインフォームの定義
  const signInFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'signIn') {
        const result = await fetch.post('/login', {
          email: values.email,
          password: values.password,
        });

        if (result.status === 200) {
          if (result.data.authToken) {
            let token = result.data.authToken.replace("{\"authToken\":\"","");
            token = token.slice(0,-1);
            localStorage.setItem(
              'authToken',
              JSON.stringify(token),
            );
            const result2 = await fetch.get('/user');
            const result3 = await fetch.get('/user/organizations');
            dispatch(signIn());
            dispatch(getUser(result2.data));
            dispatch(getOrganizations(result3.data));
            dispatch(push('/'));
          }

          if (result.data.oneTimePassword) {
            // 削除予定
            setOneTimePassword(result.data.oneTimePassword);
          }

          //  認証コード発行時刻を記録
          setExpiration(new Date().getTime());
          setErrorMessage('');
          setMode('signInSecond');
        } else if (result.status === 404 || result.status === 400) {
          setErrorMessage('メールアドレスもしくはパスワードが違います。');
        } else if (result.status === 423) {
          setErrorMessage('アカウントがロックされています');
        } else {
          setErrorMessage('エラーが発生しました');
          alertWithCode(result.status);
        }
      }
    },
  });

  //  二段階認証フォームの定義
  const signInSecondFormik = useFormik({
    initialValues: {
      email: signInFormik.values.email,
      password: signInFormik.values.password,
      oneTimePassword: '',
    },
    validationSchema: signInSecondSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'signInSecond') {
        setBtnDisabled(true);
        const result = await fetch.post('/login/second', {
          email: signInFormik.values.email,
          password: signInFormik.values.password,
          oneTimePassword: values.oneTimePassword,
        });
        if (result.status === 200) {
          if (result.data.authToken) {
            localStorage.setItem(
              'authToken',
              JSON.stringify(result.data.authToken),
            );
            const result2 = await fetch.get('/user');
            const result3 = await fetch.get('/user/organizations');
            dispatch(signIn());
            dispatch(getUser(result2.data));
            dispatch(getOrganizations(result3.data));
            dispatch(push('/'));
          }
        } else if (result.status === 400) {
          //  認証失敗時に一定時間経過していた場合リロードを行う
          if (expiration < new Date().getTime() - AUTH_CODE_EXPIRATION) {
            window.location.reload();
          } else {
            setErrorMessage('2段階認証のコードが間違っています。');
          }
        } else if (result.status === 423) {
          setErrorMessage('アカウントがロックされています');
          alertWithText('アカウントがロックされました','danger');
        } else {
          alertWithCode(result.status);
        }
        setBtnDisabled(false);
      }
    },
  });

  return (
    <>
      <div className={classes.centerRoot}>
        <img src={logo} alt="eメットシステム" />
        <p className={classes.errorMessage}>{errorMessage}</p>
        {mode === 'signIn' && <SignInForm {...signInFormik} />}
        {mode === 'signInSecond' && (
          <SignInSecondForm {...signInSecondFormik} btnDisabled={btnDisabled} />
        )}
      </div>
      {oneTimePassword !== '' && (
        <div className={classes.centerRoot}>
          ワンタイムパスワード: {oneTimePassword}
        </div>
      )}
      <News apiurl={`/login/notifications`} />
    </>
  );
};

export default SignIn;

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Paper } from '@material-ui/core';
import {
  SubHeader,
  HelpFooter,
  HelpSummaryBasic,
  HelpSummaryEditing,
  HelpSummaryNotice,
  HelpSummaryReport,
} from '../../../components';
import useStyles from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSummary: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SubHeader title="ヘルプ（操作方法）" link="" />

      <Container className={classes.containerMain}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={6}>
            <Link to={'/help/usage/basic'} className={classes.summaryHeadLink}>
              <Paper className={classes.summaryHead}>各種設定</Paper>
            </Link>
            <HelpSummaryBasic />
          </Grid>

          <Grid item md={4} xs={6}>
            <Link
              to={'/help/usage/editing'}
              className={classes.summaryHeadLink}
            >
              <Paper className={classes.summaryHead}>登録情報の変更</Paper>
            </Link>

            <HelpSummaryEditing />
          </Grid>

          <Grid item md={4} xs={6}>
            <Link to={'/help/usage/report'} className={classes.summaryHeadLink}>
              <Paper className={classes.summaryHead}>レポート</Paper>
            </Link>

            <HelpSummaryReport />
          </Grid>

          <Grid item md={4} xs={6}>
            <Link to={'/help/usage/notice'} className={classes.summaryHeadLink}>
              <Paper className={classes.summaryHead}>お知らせ</Paper>
            </Link>

            <HelpSummaryNotice />
          </Grid>
        </Grid>
      </Container>

      <HelpFooter
        left={{ title: 'よくある質問を見る', url: '/help' }}
        right={{ title: 'アプリのヘルプを見る', url: '/help/app' }}
      />
    </div>
  );
};

export default HelpSummary;

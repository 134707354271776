import { ReactNode } from 'react';
import { useContentsStyles } from '../../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSubTitle = (props: Props) => {
  const classes = useContentsStyles();

  return (
    <h3 className={classes.subtitle}>
      <span className={classes.subtitleIcon}>-</span>
      <span>{props.children}</span>
    </h3>
  );
};

export default HelpSubTitle;

import GetAppIcon from '@material-ui/icons/GetApp';
import { csvDownload } from '../../functions/CsvDownload';
import { CsvDeviceHeader } from '../../constants/csv';
import useStyles from '../../assets/styles/Header.css';

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CsvSampleDownloadButton = () => {
  const classes = useStyles();

  //  ダウンロードさせる
  const handleClick = () => {
    csvDownload({
      filename: 'センサーデバイス一覧',
      header: CsvDeviceHeader,
      body: [['ABC0001']],
    });
  };

  return (
    <span className={classes.subHeaderRightMenu} onClick={() => handleClick()}>
      <GetAppIcon />
      CSVサンプルダウンロード
    </span>
  );
};

export default CsvSampleDownloadButton;

import { ReactChild } from 'react';
import { Box } from '@material-ui/core';
import { useCommonStyles } from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  title: string;
  subTitle?: string | ReactChild;
  flexGrow?: number;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportSectionHeader = (props: Props) => {
  const { title, subTitle, flexGrow = 0 } = props;
  const classes = useCommonStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={flexGrow}>
        <h3 className={classes.sectionHeader}>{title}</h3>
      </Box>

      <Box>
        {subTitle ? (
          <span className={classes.sectionHeaderSub}>{subTitle}</span>
        ) : null}
      </Box>
    </Box>
  );
};

export default ReportSectionHeader;

import { FC } from 'react';
import useStyles from '../../assets/styles/Master.css';
import logo from '../../assets/images/logo.svg';
import { PrimaryOutlinedButton } from '../../components';
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const NotFound: FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.centerRoot}>
      <div className="module-spacer--medium" />
        <img src={logo} alt="eメットシステム" />
        <p style={{ color: '#666666' }}>ページが見つかりません</p>
        <PrimaryOutlinedButton label="トップへ戻る" linkTo="/" />
      </div>
    </>
  );
};

export default NotFound;

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WarningIcon = () => {
  return <img src="/images/help/warning.svg" alt="warning" />;
};

export default WarningIcon;

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const YAxisValueTick = (props: any) => {
  const { x, y, payload, maxValue } = props;

  return (
    <>
      {(payload.value >= maxValue || payload.value === 0) &&
        !isNaN(x) &&
        !isNaN(y) && (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={-13} dy={16} textAnchor="end" fill="#666" fontSize={9}>
              {payload.value}
            </text>
          </g>
        )}
    </>
  );
};

export default YAxisValueTick;

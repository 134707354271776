/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PieLabel = (props: any) => {
  const { innerRadius, outerRadius, cx, cy, percent, midAngle } = props;
  const radian = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * radian) * 0.85;
  const y = cy + radius * Math.sin(-midAngle * radian) * 0.85;

  return (
    <>
      {props.answer >= 4 && percent > 0 && (
        <>
          <text
            x={160}
            y={10}
            fill="#7A7A7A"
            fontSize="7px"
            fontWeight="bold"
            textAnchor="end"
            dominantBaseline="central"
          >
            {props.label}
          </text>
          <path d={`M ${x} ${y} L 150 15`} stroke="#7A7A7A" strokeWidth={0.7} />
        </>
      )}
    </>
  );
};

export default PieLabel;

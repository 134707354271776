import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import useStyles from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  sub?: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSummaryReport = (props: Props) => {
  const { sub = false } = props;
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(location.hash.replace('#', ''));
    if (element !== null) {
      element.scrollIntoView(true);
    }
  }, [location.hash]);

  return (
    <Paper className={classes.summaryBody}>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/report#workerReport">個人レポートを作成する</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>個人デイリーレポート</li>
            <li>個人マンスリーレポート</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/report#worksiteReport">
          現場レポートを作成する
        </Link>
      </div>
    </Paper>
  );
};

export default HelpSummaryReport;

import AddIcon from '@material-ui/icons/Add';
import { FC, useEffect, useState } from 'react';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { List, SubHeader } from '../../components';
import { Row } from '../../components/list/List';
import { fetch, useAuth } from '../../functions';
import { ApiGetNotification } from '../../types/notifications';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const NotificationsList: FC = () => {
  const { id } = useParams<Params>();
  const [data, setData] = useState<Row[]>([]);
  const [title, setTitle] = useState<string>('');
  const { isAllowOrganization } = useAuth();
  const isAllow = isAllowOrganization(id);

  const subMenus = [
    {
      icon: <AddIcon />,
      name: 'お知らせ追加',
      link: `/organization/${id}/notifications/create`,
      allow: isAllow,
    },
  ];

  //  削除 Callback の定義
  const removeNotification = async (notificationId: string) => {
    await fetch.delete(
      `/organization/${id}/notification?notificationId=${notificationId}`,
    );
    setData(
      data.filter((row: Row) => {
        return row.id !== notificationId;
      }),
    );
  };

  //  表示データ取得
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}/notifications`);

      if (result.status === 200) {
        const data: Row[] = [];
        result.data.forEach(async (notice: ApiGetNotification) => {
          data.push({
            id: String(notice.id),
            cells: [
              format(new Date(notice.postDate), 'yyyy/MM/dd'),
              notice.from,
              notice.title,
              notice.description,
            ],
            name: notice.title,
          });
        });
        setData(data);
      }

      const organizationResult = await fetch.get(`/organization/${id}`);
      if (organizationResult.status === 200) {
        setTitle(organizationResult.data.name);
      }
    })();
  }, [setData, id]);

  return (
    <div>
      <SubHeader
        title={title}
        subTitle="お知らせ管理"
        link={`/dashboard/organization/${id}`}
        menus={subMenus}
      />
      <List
        tableHeadCells={['日付', '投稿者名', 'タイトル', '本文']}
        tableBodyCells={data}
        edit={`/organization/${id}/notifications`}
        deleteItem={removeNotification}
        allow={isAllow}
      />
    </div>
  );
};

export default NotificationsList;

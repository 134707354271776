import { FC } from 'react';
import { FormikProps } from 'formik';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { UITextField, UIPasswordField, UISubmit, UIError } from '../index';
import Button from '@material-ui/core/Button';
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  email: string;
  password: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, handleChange, values, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="email"
          name="email"
          label="メールアドレス"
          value={values.email}
          error={errors.email}
          required
          handleChange={handleChange}
        />
        <UIPasswordField
          name="password"
          label="パスワード"
          value={values.password}
          error={errors.password}
          required
          handleChange={handleChange}
        />
      </div>

      <Button color="primary" href="/passwordReset">
        パスワードを忘れた場合はこちら
      </Button>
      <div className="module-spacer--medium" />

      <div className={masterClasses.submitButton}>
        <UISubmit title="ログイン" />
      </div>
    </form>
  );
};

export default Form;

import { useState, useRef, useEffect } from 'react';
import { LineChart, Line, ReferenceLine, XAxis, YAxis } from 'recharts';
import XAxisDateTick from './XAxisDateTick';
import YAxisValueTick from './YAxisValueTick';
import AlertReferenceLabel from './AlertReferenceLabel';
import Legend from './Legend';
import {
  ReportWorkderDailyBodyHeatIndexData,
  ReportWorkderDailyAlertValueData,
} from '../../../types/report';
import { useLineGraphStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  monthlyName: string; 
  alertValue: number;
  bodyHeatIndexMaxData: ReportWorkderDailyBodyHeatIndexData[];
  lastBodyHeatIndexMaxData: ReportWorkderDailyBodyHeatIndexData[];
  alertValueData: ReportWorkderDailyAlertValueData[];
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyBodyHeatIndex = (props: Props) => {
  const {
    monthlyName = 'worker',
    alertValue,
    bodyHeatIndexMaxData,
    lastBodyHeatIndexMaxData,
    alertValueData,
  } = props;
  const classes = useLineGraphStyles();
  const [points, setPoints] = useState<any>([]);
  const [max, setMax] = useState<number>(0);
  const [minMax, setMinMax] = useState<{min: number, max: number}>({min: 0, max: -1});
  const line = useRef(null);

  // 初期化
  useEffect(() => {
    const _data: any[] = [];
    let _max: number = 0;
    let _alertMax: number = monthlyName === 'worker' ? 0 : alertValue;

    //  データの整形
    bodyHeatIndexMaxData.forEach(
      (bhid: ReportWorkderDailyBodyHeatIndexData, index: number) => {
        const lbhid = lastBodyHeatIndexMaxData[index];
        const avd = alertValueData[index];

        if (bhid.bodyHeatIndex > _max) {
          _max = bhid.bodyHeatIndex;
        }
        if (avd.alertValue > _alertMax) {
          _alertMax = avd.alertValue;
        }

        _data.push({
          timestamp: bhid.timestamp,
          bodyHeatIndex: bhid.bodyHeatIndex,
          lastBodyHeatIndex: lbhid.bodyHeatIndex,
          alertValue: monthlyName === 'worker' ? avd.alertValue : alertValue,
        });
      },
    );

    // 通常データのMax値とアラートデータのMax値を比較
    if(_max < _alertMax) {
      const max = Math.round(_alertMax*1.1);
      setMinMax({min: 0, max: max});
    }
    setPoints(_data);
    setMax(_max);
  }, [bodyHeatIndexMaxData, lastBodyHeatIndexMaxData, alertValueData, alertValue, monthlyName]);

  return (
    <div className={classes.container}>
      <LineChart
        width={700}
        height={180}
        data={points}
        margin={{ top: 5, right: 25, bottom: 8, left: -5 }}
      >
        <YAxis
          dataKey="bodyHeatIndex"
          interval={0}
          yAxisId={0}
          tickLine={false}
          domain={[minMax.min, minMax.max === -1 ? 'auto' : minMax.max]}
          tick={<YAxisValueTick maxValue={max} />}
        />
        <ReferenceLine
          y={alertValue}
          style={{ opacity: '0' }}
          label={<AlertReferenceLabel value={alertValue} />}
        />
        <XAxis
          dataKey="timestamp"
          interval={0}
          tickLine={false}
          height={20}
          padding={{ left: 20, right: 20 }}
          tick={<XAxisDateTick />}
        />
        <Line
          ref={line}
          type="monotone"
          dataKey="alertValue"
          stroke="#7A7A7A"
          strokeWidth={1}
          strokeDasharray="10 4"
          dot={{ stroke: 'rgb(122, 122, 122)', strokeWidth: 2, r: 1, fill: 'rgb(122, 122, 122)'}}
        />
        <Line
          type="monotone"
          dataKey="lastBodyHeatIndex"
          stroke="#686868"
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="bodyHeatIndex"
          stroke="#FE104B"
          dot={{ stroke: 'rgb(254, 16, 75)', strokeWidth: 2, r: 1, fill: 'rgb(254, 16, 75)'}}
          strokeWidth={3}
        />
        <g transform="translate(500, 0)">
          <Legend />
        </g>
      </LineChart>
    </div>
  );
};

export default ReportMonthlyBodyHeatIndex;

import { FC, ReactNode, MouseEvent, HTMLProps } from 'react';
import useStyles from '../../../assets/styles/Header.css';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface Props {
  onClick?: (event: MouseEvent<any>) => void;
  icon: HTMLProps<SvgIconProps>;
  children: ReactNode;
}


/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HeaderButton: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div onClick={props.onClick} className={classes.headerButton}>
      {props.icon}
      {props.children}
    </div>
  );
};

export default HeaderButton;

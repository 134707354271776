import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type WorkSite = {
  id: number;
  name: string;
}

type Organization = {
    id:number;
    name: string;
    workSites: WorkSite[];
}
const initialState:Organization[] = [];

export const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    getOrganizations: (state, action: PayloadAction<any>) =>(state=action.payload),
  },
});

export const { getOrganizations } = commonSlice.actions;
export default commonSlice.reducer;

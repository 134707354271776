import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UserState } from '../redux/store';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type AuthType = 'SYSTEM_ADMIN' | 'WORK_SITE_ADMIN' | 'NORMAL_MONITOR';

/**
 *
 *
 *
 * @method HooksFunction
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useAuth = () => {
  const loginuser = useSelector((state: UserState) => state.user);

  /**
   *  管理者権限か調べる
   *
   *
   * @return Boolean
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  const isAdmin = useCallback(() => loginuser.isAdmin, [loginuser.isAdmin]);

  /**
   *  プランIDはどのような状態か調べる
   * 　現在は 1 でなければ 許可
   *
   *
   * @return Boolean
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  const isAllowPlanId = useCallback((planid: number = 1) => planid !== 1, []);

  /**
   *  許可された組織か調べる
   *
   *
   * @return Boolean
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  const isAllowOrganization = useCallback(
    (id: any) => {
      //  権限情報を取得する
      const auth = _.find(loginuser.authorities, {
        organizationId: Number(id),
      });

      //
      return loginuser.isAdmin || auth !== undefined;
    },
    [loginuser.isAdmin, loginuser.authorities],
  );

  /**
   *  許可された権限か調べる
   *
   *
   * @return Boolean
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  const isAllowOrganizationWithAuth = useCallback(
    (id: any, authorities: AuthType[]) => {
      //  権限情報を取得する
      const auth = _.find(loginuser.authorities, {
        organizationId: Number(id),
      });

      //  ログインユーザーの権限情報を調べる
      let isAuth = false;
      if (auth !== undefined) {
        const type: any = auth.type;
        isAuth = authorities.indexOf(type) !== -1;
      }

      return loginuser.isAdmin || (auth !== undefined && isAuth);
    },
    [loginuser.isAdmin, loginuser.authorities],
  );

  return {
    isAdmin,
    isAllowPlanId,
    isAllowOrganization,
    isAllowOrganizationWithAuth,
  };
};

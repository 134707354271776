import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from '../redux/alertSlice';

/**
 *
 *
 *
 * @method Function
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useAlert = () => {
  const dispatch = useDispatch();

  /**
   *  アラートを表示 （ステータスコードにより規定のメッセージを表示）
   *
   *
   *
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  const alertWithCode = useCallback(
    (error: number) => {
      switch (error) {
        case 200:
          dispatch(showAlert({ text: '成功しました', severity: 'success' }));
          break;

        case 401:
          dispatch(showAlert({ text: '権限がありません', severity: 'danger' }));
          break;

        case 409:
          dispatch(
            showAlert({
              text: '使用中または使用済みのログイン名です。',
              severity: 'danger',
            }),
          );
          break;

        case 500:
          dispatch(showAlert({ text: 'サーバーでエラーが発生しました。運営に問い合わせください。', severity: 'danger' }));
          break;

        default:
          dispatch(
            showAlert({ text: 'エラーが発生しました', severity: 'danger' }),
          );
          break;
      }
    },
    [dispatch],
  );

  /**
   *  アラートを表示 （任意のメッセージを表示）
   *
   *
   *
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  const alertWithText = useCallback(
    (
      text: string,
      severity: 'danger' | 'warning' | 'info' | 'success' = 'success',
    ) => {
      dispatch(showAlert({ text, severity }));
    },
    [dispatch],
  );

  return {
    alertWithCode,
    alertWithText,
  };
};

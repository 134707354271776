import { FC } from 'react';
import TransitionGraph from './TransitionGraph';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: any;
  lastData: any;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const GroupStatus: FC<Props> = (props: Props) => {
  const classes = commonStyles();

  return (
    <div className={classes.block}>
      <h3 className={classes.blockTitle}>会社・部署全体の状態</h3>
      <TransitionGraph data={props.data} lastData={props.lastData} />
    </div>
  );
};

export default GroupStatus;

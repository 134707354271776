import _ from 'lodash';
import { FC } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import { PrimaryReadOnlyField, UISubmit, UIReturn, UIError } from '../index';
import { ApiDefaultAlert } from '../../types/default_alert';
import useStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  organizationId: string;
  alertNumber: number;
  alertValue: number;
}
type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkSiteConfirm: FC<Props> = (props) => {
  const { handleSubmit, values, errors, default_alerts } = props;
  const classes = useStyles();
  const alert = _.find(default_alerts, { number: values.alertNumber });

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete='off'>
      <p className={classes.errorMessage}>
        <UIError errors={errors} />
      </p>

      <PrimaryReadOnlyField label='アラート設定' value={alert?.name ? alert.name : ''} />
      <PrimaryReadOnlyField label='アラート設定値' value={String(values.alertValue)} />
      <div className="module-spacer--medium" />
      <FormGroup row>
        <UIReturn title='戻る' />
        <div className="module-spacer--button" />
        <UISubmit title='確定' />
      </FormGroup>
    </form>
  );
};

export default WorkSiteConfirm;

import { makeStyles } from '@material-ui/core/styles';

export const useHowToUseStyles = makeStyles(
  (theme) => ({
    message: {
      margin: '0 0 16px 0',
      padding: '8px',
      border: '2px solid #777',
      backgroundColor: '#efefef',
      maxWidth: '38rem',
    },
    messageLink: {
      cursor: 'pointer',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },

    popover: {
      padding: '8px',
    },
    popoverMessage: {
      padding: '8px 0',
      fontWeight: 'bold',
      color: '#d33053',
    },
    popoverTable: {
      '& tr td': {
        padding: '8px 0',
        borderBottom: '1px dashed #ccc',
      },
    },
  }),
  { index: 1 },
);

export const FormButtonStyles = makeStyles(
  (theme) => ({
    formUITextField: {
      marginTop: '0',
      marginBottom: '0',
    },

    emailField: {
      display: 'flex',
      marginTop: '8px',
      marginBottom: '4px',
    },

    emailDeleteBtn: {
      position: 'relative',
      top: '11.67px',
      right: '40px',
      minWidth: '30px',
      marginRight: '-40px',
      '&>span': {
        '&>span': {
          margin: '0',
          '&>svg': {
            color: '#666666',
          },
        },
      },
    },
    groupDeleteBtn: {
      display: 'flex',
      margin: '0 0 0 auto',
    },
    groupAddBtn: {
      width: '180px',
      display: 'flex',
      marginLeft: 'auto',
      marginTop: '10px',
    },
    emailAddBtn: {
      display: 'flex',
      margin: '0 0 0 auto',
    },
  }),
  { index: 1 },
);

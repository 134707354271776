import {FC} from 'react';
import { PrimaryReadOnlyField } from '..';
import useMasterStyles from '../../assets/styles/Master.css';

interface Props{
    workSiteName: string;
    alertNumber: string;
    alertValue: string;
}
const WorkSiteAlertData:FC<Props> = (props) => {
  const masterClasses = useMasterStyles();
  const { workSiteName, alertNumber, alertValue} = props;
  return (
    <div className={masterClasses.workSiteAlertDiv}>
      <span>{workSiteName}のアラート情報</span>
        {' '}
        <div>
          <PrimaryReadOnlyField
            label="アラート設定"
            value={String(alertNumber)}
          />
        </div>
        <div>
          <PrimaryReadOnlyField
            label="アラート設定値"
            value={String(alertValue)}
          />
        </div>
    </div>
  );
};

export default WorkSiteAlertData;

import { CSSProperties, FC, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { Location, LocationDescriptor } from "history";
import Button from "@material-ui/core/Button";

interface Props {
  className?: string;
  label: string;
  linkTo?:
    | LocationDescriptor<unknown>
    | ((location: Location<unknown>) => LocationDescriptor<unknown>);
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  startIcon?: any;
}

const PrimaryContainedButton: FC<Props> = (props) => {
  return (
    <>
      {props.linkTo ? (
        <Button
          className={props.className}
          color="primary"
          component={Link}
          variant="contained"
          style={props.style}
          startIcon={props.startIcon}
          to={props.linkTo}
        >
          {props.label}
        </Button>
      ) : (
        <Button
          className={props.className}
          color="primary"
          onClick={props.onClick}
          variant="contained"
          style={props.style}
          startIcon={props.startIcon}
        >
          {props.label}
        </Button>
      )}
    </>
  );
};

export default PrimaryContainedButton;

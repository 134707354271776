/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@material-ui/core';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { ApiDefaultAlert } from '../../types/default_alert';
import {
  PrimaryReadOnlyField,
  UIError,
  UISelect,
  UITextField,
  FormHowToUse,
} from '../index';
import { FormButtonStyles } from '../../assets/styles/Form.css';
import { AddCircleOutline, Clear, HighlightOff } from '@material-ui/icons';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  id: string;
  name: string;
  organizationId: string;
  post: string;
  address: string;
  alertNumber: number | null;
  alertValue: number | null;
  groups: Group[];
}

type Group = {
  id: string;
  workSiteId: string;
  number: number | null;
  name: string;
  emails: string[];
  alertNumber: number | null;
  alertValue: number | null;
  isHidden: boolean;
};

type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
  formEmails: any;
  onAddGroup: (index: number) => void;
  onAddEmail: (index: number) => void;
  onDeleteEmail: (index: number, mailIndex: number) => void;
  onDeleteGroup: (index: number) => void;
};
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<Props> = (props) => {
  const classes = FormButtonStyles();
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    onAddGroup,
    onAddEmail,
    onDeleteEmail,
    onDeleteGroup,
    values,
    errors,
    default_alerts,
    formEmails,
  } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  const [alert_list, setAlertList] = useState([]);
  const alert = (alertNumber: number | null): any => {
    return _.find(default_alerts, {
      number: alertNumber,
    });
  };
  const customAlert: any = alert(values.alertNumber);
  //  Select Box の変化でアラート値を決定
  const uiSelectHandleChange = (
    e: ChangeEvent<HTMLSelectElement>,
    index: number,
  ) => {
    setFieldValue(`groups.${index}.alertValue`, customAlert?.value);
    setFieldValue(
      `groups.${index}.alertNumber`,
      e.target.value === null ? null : e.target.value,
    );
  };
  const hash = window.location.hash.replace('#', '');
  const [element, setElement] = useState<HTMLElement>();

  //  デフォルトアラート情報を取得
  useEffect(() => {
    const alert: any = [];
    default_alerts.forEach((o) => {
      alert.push({
        name: o.name,
        value: o.number,
      });
    });
    setAlertList(alert);
  }, [default_alerts]);

  useEffect(() => {
    errors && window.scrollTo(0, 0);
  }, [errors]);

  useEffect(() => {
    const getElement = document.getElementById(hash.replace('#', ''));
    if (getElement === element) return;

    if (getElement !== null) {
      getElement.scrollIntoView(true);
      window.scrollBy(0, -110);
      setElement(getElement);
    }
  });

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormHowToUse />
      <p>現場情報を編集してください</p>
      <p style={{ color: '#FF0000', fontSize: '16px' }}>
        入力完了後、確定ボタンを押してください！
      </p>

      <p className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </p>

      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="name"
          label="現場名"
          value={values.name}
          error={errors.name}
          required
          handleChange={handleChange}
        />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="post"
          label="郵便番号"
          value={values.post}
          error={errors.post}
          required
          handleChange={handleChange}
          helperText="数字7桁（ハイフンなし）"
        />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="address"
          label="住所"
          value={values.address}
          error={errors.address}
          required
          handleChange={handleChange}
        />
      </div>

      {values.groups?.map((group: Group, index: number) => {
        if (!group.isHidden) {
          return (
            <div className={masterClasses.workSiteEditAlert} key={group.id}>
              <div className={masterClasses.workSiteEditGroupTitle}>
                グループ{index + 1}
              </div>
              <div>
                <UITextField
                  type="text"
                  name={`groups.${index}.name`}
                  label="グループ名"
                  value={group.name}
                  error={
                    errors.groups &&
                    errors.groups[index] &&
                    (errors.groups[index] as any).name
                  }
                  handleChange={handleChange}
                />
                {formEmails[`group${index + 1}`]?.map(
                  (_: number, i: number) => (
                    <div className="emailField">
                      <UITextField
                        type="email"
                        name={`groups.${index}.emails.${i}`}
                        className="formUITextField"
                        label={`アラート時の通知先メールアドレス${i + 1}`}
                        key={index + i}
                        value={group.emails?.[i]}
                        error={
                          errors.groups &&
                          errors.groups[index] &&
                          (errors.groups[index] as any).email[i]
                        }
                        handleChange={handleChange}
                      />
                      <Button
                        type="button"
                        className={classes.emailDeleteBtn}
                        onClick={() => onDeleteEmail(index, i)}
                        startIcon={<Clear />}
                      ></Button>
                    </div>
                  ),
                )}
                <div className="module-spacer--extra-small" />
                <Button
                  className={classes.emailAddBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => onAddEmail(index)}
                  startIcon={<AddCircleOutline />}
                >
                  メールアドレスを追加
                </Button>
                <div className="module-spacer--extra-small" />
                <UISelect
                  name={`groups.${index}.alertNumber`}
                  label="アラート設定"
                  value={group.alertNumber}
                  error={
                    errors.groups &&
                    errors.groups[index] &&
                    (errors.groups[index] as any).alertNumber
                  }
                  handleChange={(e: any) => uiSelectHandleChange(e, index)}
                  menus={alert_list}
                  empty="現場の設定を反映"
                />
                {group.alertNumber === 0 ? (
                  <>
                    <div className="module-spacer--small" />
                    <UITextField
                      type="text"
                      name={`groups.${index}.alertValue`}
                      label="アラート設定値"
                      value={String(group.alertValue)}
                      error={
                        errors.groups &&
                        errors.groups[index] &&
                        (errors.groups[index] as any).alertValue
                      }
                      required
                      handleChange={handleChange}
                    />
                  </>
                ) : (
                  <PrimaryReadOnlyField
                    label="アラート設定値"
                    value={
                      alert(group.alertNumber)?.value
                        ? alert(group.alertNumber)?.value
                        : values.alertValue
                        ? values.alertValue
                        : alert(values.alertNumber)?.value
                        ? alert(values.alertNumber)?.value
                        : '未設定'
                    }
                  />
                )}
              </div>
              <div className="module-spacer--extra-small" />
              <Button
                className={classes.groupDeleteBtn}
                variant="contained"
                color="primary"
                onClick={() => onDeleteGroup(index)}
                startIcon={<HighlightOff />}
              >
                {`グループ${index + 1}を使用しない`}
              </Button>
            </div>
          );
        } else {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classes.groupAddBtn}
              onClick={() => onAddGroup(index)}
            >
              {`グループ${index + 1}を使用する`}
            </Button>
          );
        }
      })}
      <div className="module-spacer--extra-small" />
    </form>
  );
};

export default Form;

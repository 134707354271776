import { Paper } from '@material-ui/core';
import HelpSection from './parts/HelpSection';
import HelpTitle from './parts/HelpTitle';
import HelpSubtitle from './parts/HelpSubtitle';
import HelpParagraph from './parts/HelpParagraph';
import PointIcon from './parts/PointIcon';
import WarningIcon from './parts/WarningIcon';
import useStyles, { useContentsStyles } from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpContentsReport = () => {
  const classes = useStyles();
  const contentClasses = useContentsStyles();

  return (
    <Paper className={classes.contentsPaper}>
      <HelpSection id="workerReport" first={true}>
        <div style={{ backgroundColor: 'white' }}>
          <HelpTitle>個人レポートを作成する</HelpTitle>
          <HelpParagraph>
            <div>
              個人レポートには「デイリーレポート」と「マンスリーレポート」の2種類あります。
            </div>
            <div>
              1日分のデータは翌日の朝5時に集計されるため、今日のデータを反映したレポートは翌日に確認することができます。
            </div>
          </HelpParagraph>

          <HelpSubtitle>個人デイリーレポート</HelpSubtitle>
          <HelpParagraph icon={'①'}>
            <div>
              レポートを作成したい装着者が登録された現場ダッシュボードを開きます。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>現場ダッシュボード右上の「レポート」を選択します。</div>
          </HelpParagraph>
          <HelpParagraph icon={'③'}>
            <div>
              作成するレポートの種類で「個人デイリーレポート」を選択します。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'④'}>
            <div>
              レポート化したい日付を選択します。※データが集計できていない日はグレーアウトしています。
            </div>
          </HelpParagraph>
          <div className={contentClasses.image}>
            <div>レポート選択</div>
            <img
              className={contentClasses.imageImgM}
              src="/images/help/report-1.png"
              alt="レポート選択"
            />
          </div>
          <HelpParagraph icon={'⑤'}>
            <div>リストから確認したい装着者を見つけます。</div>
            <div className={contentClasses.textWithIcon}>
              <PointIcon />
              &nbsp;リスト右上の検索機能もご利用ください。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'⑤'}>
            <div>左端の「レポート作成」を選択してください。</div>
          </HelpParagraph>
          <div className={contentClasses.image}>
            <div></div>
            <img
              className={contentClasses.imageImgM}
              src="/images/help/report-2.png"
              alt=""
            />
          </div>
          <HelpParagraph icon={'⑥'}>
            <div>
              別ウィンドウで選択した装着者のデイリーレポートが表示されます。
            </div>
            <div className={contentClasses.textWithIcon}>
              <PointIcon />
              &nbsp;PDF化するアプリケーション等をご利用いただければ、ブラウザ上に表示されたレポートをPDFとして保存できます。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={<WarningIcon />}>
            <div>
              「レポートはありません」という表示は、ご指定された日に、選択した装着者がアプリを利用されておらず、システムにデータが無い場合に表示されます。
            </div>
            <div>
              利用があったにも関わらず、この表示が出る場合は下記をご確認ください。
            </div>
            <div>
              ・ログイン時に装着者が選択した現場名と、レポートを確認している現場ダッシュボード名が一致しているか。
            </div>
            <div>・選択したユーザー名が正しいか。</div>
          </HelpParagraph>
          <HelpSubtitle>個人マンスリーレポート</HelpSubtitle>
          <HelpParagraph icon={<PointIcon />}>
            <div>マンスリーレポートは月の途中でも閲覧が可能です。</div>
          </HelpParagraph>
          <HelpParagraph icon={'①'}>
            <div>
              レポート化したい装着者が登録された現場ダッシュボードを開きます。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>現場ダッシュボード右上の「レポート」を選択します。</div>
          </HelpParagraph>
          <HelpParagraph icon={'③'}>
            <div>
              作成するレポートの種類で「個人マンスリーレポート」を選択します。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'④'}>
            <div>
              レポート化したい月を選択します。※データが集計されていない月はグレーアウトしています。
            </div>
          </HelpParagraph>
          <div className={contentClasses.image}>
            <div></div>
            <img
              className={contentClasses.imageImgM}
              src="/images/help/report-3.png"
              alt=""
            />
          </div>
          <HelpParagraph icon={'⑤'}>
            <div>リストから確認したい装着者を見つけます。</div>
            <div className={contentClasses.textWithIcon}>
              <PointIcon />
              &nbsp;リスト右上の検索機能もご利用ください。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'⑥'}>
            <div>左端の「レポートの作成」を選択してください。</div>
          </HelpParagraph>
          <div className={contentClasses.image}>
            <div></div>
            <img
              className={contentClasses.imageImgM}
              src="/images/help/report-4.png"
              alt=""
            />
          </div>
          <HelpParagraph icon={'⑦'}>
            <div>
              別ウィンドウで選択した装着者のデイリーレポートが表示されます。
            </div>
            <div className={contentClasses.textWithIcon}>
              <PointIcon />
              &nbsp;PDF化するアプリケーション等をご利用いただければ、ブラウザ上に表示されたレポートをPDFとして保存できます。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={<WarningIcon />}>
            <div>
              「レポートはありません」という表示は、ご指定された日に、選択した装着者がアプリを利用されておらず、システムにデータが無い場合に表示されます。
            </div>
            <div>
              利用があったにも関わらず、この表示が出る場合は下記をご確認ください。
            </div>
            <div>
              ・ログイン時に装着者が選択した現場名と、レポートを確認している現場ダッシュボード名が一致しているか。
            </div>
            <div>・選択したユーザー名が正しいか。</div>
          </HelpParagraph>
        </div>
      </HelpSection>

      <HelpSection id="worksiteReport">
        <HelpTitle>現場レポートを作成する</HelpTitle>
        <HelpParagraph>
          <div>現場レポートは「マンスリーレポート」の1種類のみです。</div>
          <div>
            レポートの集計は毎朝5時に前日分までのデータに対して行われるため、今日のデータを反映したレポートは翌日確認することができます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>レポート化したい現場ダッシュボードを開きます。</div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>現場ダッシュボード右上の「レポート」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            作成するレポートの種類で「現場マンスリーレポート」を選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            レポート化したい月を選択します。※データが集計されていない月はグレーアウトしています。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'⑤'}>
          <div>「レポートを作成する」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'⑥'}>
          <div>別ウィンドウで現場のマンスリーレポートが表示されます。</div>
          <div className={contentClasses.textWithIcon}>
            <PointIcon />
            &nbsp;PDF化するアプリケーション等をご利用いただければ、ブラウザ上に表示されたレポートをPDFとして保存できます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={<WarningIcon />}>
          <div>
            「レポートはありません」という表示は、ご指定された月に、選択した現場で誰もアプリでログインしておらず、システムにデータが無い場合に表示されます。
          </div>
          <div>
            利用があったにも関わらず、この表示が出る場合は、ログイン時に装着者が選択した現場名と、レポートを確認している現場ダッシュボード名が一致しているかをご確認ください。
          </div>
        </HelpParagraph>
      </HelpSection>
    </Paper>
  );
};

export default HelpContentsReport;

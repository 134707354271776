import { PieChart, Pie, Cell } from 'recharts';
import { ChartsFreeText } from '../../../components';
import PieLabel from './PieLabel';
import {
  ReportWorkderMonthlyEnqueteDataGraphs,
  ReportWorkderMonthlyEnqueteDataForm,
} from '../../../types/report';
import { useEnqueteMonthlyStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: ReportWorkderMonthlyEnqueteDataGraphs[];
  detail: ReportWorkderMonthlyEnqueteDataForm;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyEnquetePie = (props: Props) => {
  const { data, detail } = props;
  const classes = useEnqueteMonthlyStyles();
  const totalCount = data.reduce((prev, current) => prev + current.count, 0);
  const warningCount = data.reduce(
    (prev, current) => (current.answer >= 4 ? prev + current.count : prev),
    0,
  );

  data.sort(
    (
      a: ReportWorkderMonthlyEnqueteDataGraphs,
      b: ReportWorkderMonthlyEnqueteDataGraphs,
    ) => (a.answer < b.answer ? 1 : -1),
  );

  return (
    <PieChart className={classes.pie} width={160} height={100}>
      <Pie
        data={data}
        dataKey="count"
        nameKey="answer"
        innerRadius={30}
        outerRadius={46}
        startAngle={90}
        label={
          <PieLabel
            label={detail.isReverse ? detail.leftComment : detail.rightComment}
          />
        }
        labelLine={false}
        endAngle={-270}
      >
        {data.map((e, index) => (
          <Cell key={index} fill={e.answer >= 4 ? '#5C5B5B' : '#E9E8E8'} />
        ))}
      </Pie>

      <g>
        <ChartsFreeText
          x={'50%'}
          y={'50%'}
          text={`${((warningCount / totalCount) * 100).toFixed(0)}`}
          textUnit="%"
          textAnchor={'middle'}
          fontSize={22}
          color="#7A7A7A"
        />
      </g>
    </PieChart>
  );
};

export default ReportMonthlyEnquetePie;

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from '../../redux/alertSlice';
import { AlertState } from '../../redux/store';
import useStyles from '../../assets/styles/Snackbar.css';

const UISnackbar = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state: AlertState) => state.alert);
  const classes = useStyles();

  const handleClose = () => {
    dispatch(removeAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={alert.isOpen}
      onClose={handleClose}
      message={alert.text}
      autoHideDuration={600000}
      ContentProps={{
        classes: {
          root: classes[alert.severity],
        },
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};
export default UISnackbar;

import { Paper, Box } from '@material-ui/core';
import HelpSection from './parts/HelpSection';
import HelpTitle from './parts/HelpTitle';
import HelpSubtitle from './parts/HelpSubtitle';
import HelpParagraph from './parts/HelpParagraph';
import PointIcon from './parts/PointIcon';
import WarningIcon from './parts/WarningIcon';
import HelpImage from './parts/HelpImage';
import useStyles, { useContentsStyles } from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpContentsNotice = () => {
  const classes = useStyles();
  const contentClasses = useContentsStyles();

  return (
    <Paper className={classes.contentsPaper}>
      <HelpSection id="notice-1" first={true}>
        <div style={{ backgroundColor: 'white' }}>
          <HelpTitle>お知らせを見る</HelpTitle>
          <HelpParagraph icon={<PointIcon />}>
            <div>お知らせには3種類あります。</div>
            <Box display="flex">
              <Box className={contentClasses.paragraphList}>A</Box>
              <Box flexGrow={1}>
                <div>配信元　：スターライト工業株式会社</div>
                <div>
                  表示場所：管理者用ログイン画面、管理者ログイン後お知らせ通知画面
                </div>
              </Box>
            </Box>
            <div>
              　　　　　　　
              スマホアプリログイン画面、スマホアプリメニュー内お知らせ画面
            </div>
            <div>　</div>
            <Box display="flex">
              <Box className={contentClasses.paragraphList}>B</Box>
              <Box flexGrow={1}>
                <div>配信元　：プロジェクト最上位組織のadmin権限者</div>
                <div>表示場所：管理者ログイン後お知らせ通知画面</div>
              </Box>
            </Box>
            <div>　</div>
            <Box display="flex">
              <Box className={contentClasses.paragraphList}>C</Box>
              <Box flexGrow={1}>
                <div>配信元　：現場が紐づく組織のadminまたは現場監督者</div>
                <div>
                  表示場所：現場に紐づく装着者のスマホアプリメニュー内お知らせ画面
                </div>
              </Box>
            </Box>
          </HelpParagraph>
          <HelpSubtitle>ログイン前に見る</HelpSubtitle>
          <HelpParagraph>
            <div className={contentClasses.textWithIcon}>
              <WarningIcon />
              &nbsp;ログイン前に見ることができるのはAのスターライトから全ユーザーへのお知らせのみです。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'①'}>
            <div>eメットログインページを表示します。</div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>
              ログイン情報入力欄下部のお知らせ表示欄から、閲覧したいお知らせを選択します。
            </div>
          </HelpParagraph>
          <HelpImage title="" src="/images/help/notice-1.png" />
          <HelpParagraph icon={'③'}>
            <div>モーダルで表示されるお知らせを確認します。</div>
          </HelpParagraph>
          <HelpImage title="" src="/images/help/notice-2.png" />
          <HelpParagraph>
            <div className={contentClasses.textWithIcon}>
              <PointIcon />
              &nbsp;お知らせモーダルの下部の閉じるを選択またはモーダル以外のエリアをクリックすると元の画面に戻ります。
            </div>
          </HelpParagraph>
          <HelpSubtitle>ログイン後に見る</HelpSubtitle>
          <HelpParagraph>
            <div className={contentClasses.textWithIcon}>
              <WarningIcon />
              &nbsp;ログイン後に見ることができるのはAとBの管理ユーザー向けのお知らせのみです。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'①'}>
            <div>eメット管理システムにログインします。</div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>上部メニューバーのお知らせを選択します。</div>
          </HelpParagraph>
          <div className={contentClasses.image}>
            <img
              className={contentClasses.imageImgL}
              src="/images/help/notice-3.png"
              alt=""
            />
          </div>
          <HelpParagraph icon={'③'}>
            <div>閲覧したいお知らせを選択します。</div>
            <div className={contentClasses.textWithIcon}>
              <PointIcon />
              &nbsp;リストの上でスクロールすると、表示より以前のお知らせを閲覧できます。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'④'}>
            <div>モーダルで表示されるお知らせを確認します。</div>
          </HelpParagraph>
        </div>
      </HelpSection>
      <HelpSection id="notice-2">
        <HelpTitle>お知らせを投稿する</HelpTitle>
        <HelpSubtitle>全ての管理ユーザーにお知らせを投稿する</HelpSubtitle>
        <HelpParagraph color={true} icon={<WarningIcon />}>
          <div>
            管理ユーザーに向けたお知らせ投稿は、プロジェクト内の最上位組織でadmin権限をもつユーザーのみ使用できます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>eメット管理システムにログインします。</div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>TOPページで最上位組織のダッシュボードを選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>メニューバーの「お知らせ管理」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>メニューバーの「お知らせ追加」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'⑤'}>
          <div>
            投稿に必要な情報「投稿日」「投稿者名」「タイトル」「本文」を入力します。
          </div>
          <div>※文字数制限があるのでご注意ください。</div>
          <div>　投稿者名：255字</div>
          <div>　タイトル：255字</div>
          <div>　本文　　：65000字</div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/notice-4.png" />
        <HelpParagraph icon={'⑥'}>
          <div>
            入力ができたら「確認」を選択し、確認画面にて内容に問題がなければ「確定」ボタンを選択すると投稿が完了します。
          </div>
        </HelpParagraph>
        <HelpSubtitle>アプリユーザーにお知らせを投稿する</HelpSubtitle>
        <HelpParagraph color={true} icon={<WarningIcon />}>
          <div>
            アプリユーザーに向けたお知らせ投稿は、現場単位での投稿となります。プロジェクトに登録された全アプリユーザーへの一括投稿や個人を指定した投稿はできません。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>お知らせを投稿したい現場ダッシュボードを開きます。</div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>
            現場ダッシュボード右上メニューバーの「お知らせ管理」を選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>メニューバーの「お知らせ追加」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            投稿に必要な情報「投稿日」「投稿者名」「タイトル」「本文」を入力します。
          </div>
          <div>※文字数制限があるのでご注意ください。</div>
          <div>　投稿者名：255字</div>
          <div>　タイトル：255字</div>
          <div>　本文　　：65000字</div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/notice-4.png" />
        <HelpParagraph icon={'⑤'}>
          <div>
            入力ができたら「確認」を選択し、確認画面にて内容に問題がなければ「確定」ボタンを選択すると投稿が完了します。
          </div>
        </HelpParagraph>
      </HelpSection>
    </Paper>
  );
};

export default HelpContentsNotice;

export const LABEL = [
  { name: '新人', value: '新人' },
  { name: '高齢', value: '高齢' },
  { name: '疾患', value: '疾患' },

  { name: '発症歴者', value: '発症歴者' },
  { name: '喫煙者', value: '喫煙者' },
  { name: '習慣飲酒', value: '習慣飲酒' },

  { name: '肥満', value: '肥満' },
  { name: '特殊作業', value: '特殊作業' },
  { name: '要注意', value: '要注意' },
];

import { makeStyles, Theme } from '@material-ui/core/styles';

export const settingTwoFactorStyle = makeStyles(
  (theme: Theme) => ({
    table: {
        minWidth: 600,
        border: '.5px solid rgba(0, 0, 0, .08)',
        width: 'auto',
        tableLayout: 'auto',
      },
    row: {
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    },

    onText: {
        color: '#458e95',
        fontWeight: 600,
    },
    offText: {
        color: '#ff3c6d',
        fontWeight: 600,
    },    
    twoFactorSetting: {
      padding: '24px',
      backgroundColor: '#F9F9F9',
      boxShadow: '0px 3px 15px #458E95',
    },
    twoFactorSettingTableHead: {
      backgroundColor: '#458E95',
      color: '#fff',
    },
    twoFactorSettingTableHeadCenter: {
      textAlign: 'center',
    },
  }),
);

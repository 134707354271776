import { FC, ChangeEvent, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  name: string;
  value: string;
  error?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  handleChange: (e: ChangeEvent) => void;
};

/**
 * パスワードフィールド
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UIPasswordField: FC<Props> = (props: Props) => {
  const isError = !!props.error;

  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      name={props.name}
      label={props.label}
      value={props.value}
      onChange={props.handleChange}
      placeholder={props.placeholder}
      required={props.required ?? false}
      color="primary"
      margin="dense"
      variant="outlined"
      error={isError}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton
            size="small"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
        style: { color: "#666666" },
      }}
    />
  );
};

export default UIPasswordField;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignedIn: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    signIn: (state) => {
      state.isSignedIn = true;
    },
    signOut: (state) => {
      state.isSignedIn = false;
      localStorage.removeItem("authToken");
    },
  },
});

export const { signIn, signOut } = loginSlice.actions;
export default loginSlice.reducer;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';
import {
  ReportContainer,
  ReportSection,
  ReportSectionHeader,
  ReportSubHeaderWorkerDaily,
  ReportParamsTable,
  ReportDailyBodyHeatIndex,
  ReportDailyEnqueteTable,
} from '../../components';
import { fetch, useAlert } from '../../functions';
import { ApiGetReportWorkderDaily } from '../../types/report';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
  date: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkersDaily = () => {
  const { id, date } = useParams<Params>();
  const [report, setReport] = useState<ApiGetReportWorkderDaily>();
  const [isNodata, setIsNodata] = useState(false);
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();

  //  ダッシュボードのデータ取得
  useEffect(() => {
    (async () => {
      const displayDateTime = date.match(/^[0-9]{4}[0-9]{2}[0-9]{2}$/)
        ? format(
            new Date(
              Number(date.substr(0, 4)),
              Number(date.substr(4, 2)) - 1,
              Number(date.substr(6, 2)),
            ),
            'yyyy/MM/dd 00:00:00',
          )
        : format(new Date(), 'yyyy/MM/dd 00:00:00');

      const result = await fetch.get(`/worker/${id}/daily_report`, {
        displayDateTime,
      });
      if (result.status === 200) {
        setReport(result.data);
      } else if (result.status === 401) {
        alertWithCode(result.status);
        dispatch(push('/'));
      } else {
        setIsNodata(true);
      }
    })();
  }, [id, date, alertWithCode, dispatch]);

  return (
    <ReportContainer title="デイリー個人レポート" isNodata={isNodata}>
      <ReportSubHeaderWorkerDaily
        startTime={report?.startTime ?? ''}
        endTime={report?.endTime ?? ''}
        name={report?.firstname + ' ' + report?.lastname}
        birthday={report?.birthday ?? ''}
        alertValue={report?.alertValue ?? 0}
        alertName={report?.alertName ?? ''}
        projectName={report?.projectName ?? ''}
        organizationName={report?.organizationName ?? ''}
        worksiteName={report?.workSiteName ?? ''}
        devices={report?.devices ?? ''}
      />

      <ReportSection>
        <ReportSectionHeader title="カラダ暑さ指標結果" />
        <ReportDailyBodyHeatIndex
          alertValue={report?.alertValue ?? 0}
          bodyHeatIndexData={JSON.parse(report?.bodyHeatIndexData ?? '[]')}
          lastBodyHeatIndexData={JSON.parse(
            report?.lastBodyHeatIndexData ?? '[]',
          )}
          alertValueData={JSON.parse(report?.alertValueData ?? '[]')}
          environmentData={JSON.parse(report?.environmentData ?? '[]')}
        />
        <ReportParamsTable
          left={[
            {
              name: 'WBGT 最大値',
              value: `${report?.wbgtMax}℃`,
            },
            {
              name: 'WBGT 平均値',
              value: `${Number(report?.wbgtAverage).toFixed(1)}℃`,
            },
            {
              name: 'センサーデバイス最高気温・湿度',
              value: `${report?.temperatureMax}℃ / ${report?.humidityMax}%`,
            },
            {
              name: 'センサーデバイス平均気温・湿度',
              value: `${Number(report?.temperatureAverage).toFixed(
                1,
              )}℃ / ${Number(report?.humidityAverage).toFixed(1)}%`,
            },
          ]}
          right={[
            {
              name: '最大カラダ暑さ指標値',
              value: `${report?.bodyHeatIndexMax}`,
            },
            {
              name: '最大値の対設定値の最大割合',
              value: `${(Number(report?.bodyHeatIndexMaxRate) * 100).toFixed(
                1,
              )}%`,
            },
            {
              name: '傾きの平均最大値（10分間）',
              value: `${report?.bodyHeatIndexMaxDifference}/分`,
            },
            {
              name: '凸凹度（傾きが＋から-になった回数）',
              value: `${report?.bodyHeatIndexNegativeCount}回/日`,
            },
            {
              name: '閾値越え回数',
              value: `${report?.bodyHeatIndexAlertCount}回`,
            },
            {
              name: '平均カラダ暑さ指標値',
              value: `${report?.bodyHeatIndexAverageCount}`,
            },
          ]}
        />
      </ReportSection>

      <ReportSection>
        <ReportSectionHeader title="アンケート結果" />
        <ReportDailyEnqueteTable
          enqueteData={JSON.parse(report?.enqueteData ?? '[]')}
        />
      </ReportSection>
    </ReportContainer>
  );
};

export default WorkersDaily;

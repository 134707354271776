import { push } from 'connected-react-router';
import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useAlert } from '../../../functions';
import useStyles from '../../../assets/styles/Header.css';

type Menu = {
  icon: any;
  name: string;
  link: string;
  component?: any;
  allow?: boolean;
  denyMessage?: string;
};

interface Props {
  menus?: Menu[];
}
const MasterMenu: FC<Props> = (props) => {
  const { menus } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alertWithCode, alertWithText } = useAlert();

  const handleClick = (link: string, allow?: boolean, denyMessage?: string) => {
    if (allow !== undefined && !allow) {
      if (denyMessage !== undefined) {
        alertWithText(denyMessage, 'danger');
      } else {
        alertWithCode(401);
      }
    } else {
      dispatch(push(link));
    }
  };

  return (
    <>
      {menus &&
        menus.map((menu: Menu, index: number) => (
          <Fragment key={index}>
            {menu.component !== undefined ? (
              menu.component
            ) : (
              <span
                onClick={() =>
                  handleClick(menu.link, menu.allow, menu.denyMessage)
                }
                className={clsx(
                  classes.subHeaderRightMenu,
                  menu.allow !== undefined &&
                    !menu.allow &&
                    classes.subHeaderRightMenuDeny,
                )}
              >
                {menu.icon}
                {menu.name}
              </span>
            )}
          </Fragment>
        ))}
    </>
  );
};

export default MasterMenu;

import useStyles from '../../assets/styles/Sub.css';

const TermOfUse = () => {
  const classes = useStyles();

  const Title = ({ children }: any) => (
    <div className={classes.termOfUseTitle}>{children}</div>
  );

  const SubTitle = ({ children }: any) => (
    <div className={classes.termOfUseSubTitle}>{children}</div>
  );

  const Content = ({ children }: any) => (
    <div className={classes.termOfUseContent}>
      <pre>{children}</pre>
    </div>
  );

  const Footer = ({ children }: any) => (
    <div className={classes.termOfUseFooter}>{children}</div>
  );

  return (
    <div className={classes.termOfUseContainer}>
      <Title>
        <p style={{ color: '#FFF' }}>ｅメットシステムユーザー企業利用規約</p>
      </Title>
      <SubTitle>第１条（本規約の目的）</SubTitle>
      <Content>{`
      　この利用規約（以下「本規約」といいます。）は、当社が提供する熱中症見守りシステム「e メット®IoT モデル」を利用したサービス及びこれに関連する各種サービス（以下「本サービス」といいます）向けアプリケーションである「e メットアプリ」（以下「本アプリ」といいます。）を使用していただく前提となる条件を示すものです。本アプリの利用にあたっては、まず本規約をよくお読みください。
      `}</Content>

      <SubTitle>第２条（定義）</SubTitle>
      <Content>{`
      　本規約上で使用する用語の定義は、次に掲げるとおりとします。
      （１）「eメットセンサーデバイス」：対象者の生体情報、環境情報、時刻等に関する情報を測定する機能を備えた、ヘルメット型ウエアラブル測定器をいいます。
      （２）「本アプリ」： eメットセンサーデバイスで測定された情報及び対象者等の情報をサーバーへ送信し又はサーバーからデータを受信して表示するための専用のアプリケーションをいいます。
      （３）「測定情報」：eメットセンサーデバイスで測定した情報、本アプリを通じて提供された情報（アンケート回答等、本アプリに入力された情報を含みます。）並びに測定した情報を特定の計算式又はアルゴリズムに基づき算定し得られた情報をいいます。      
      `}</Content>

      <SubTitle>第２条（利用許諾等）</SubTitle>
      <Content>{`
      １　当社は、本規約並びに当社が委託する外部クラウドサーバーの管理者またはアプリストア提供者（例えば Apple Store 及び Google Play ストア等を指しますが、これらに限定されません。以下「アプリストア提供者」といいます。）が定めるプライボシーポリシー、セキュリティポリシー及び規約（以下「第三者規約」といいます。）を遵守することを条件として、本アプリの利用者（以下「利用者」といいます。）に対し、利用者の端末において、本アプリを使用する譲渡不能かつ非独占的な権利を許諾します。弊社は、本アプリに関する著作権その他の知的財産権を利用者に移転するものではありません。
      ２　利用者は、本規約、e メットシステム・プライバシーポリシー及び第三者規約に同意することを条件として、本アプリを使用できるものとします。本アプリの初回起動時に表示される「同意する」ボタンを押していただくことによって、利用者が本規約の内容に同意いただいたものとみなされますので、本規約の内容をお読みいただき、ご理解いただいた上で「同意する」ボタンを押してください。
      `}</Content>

      <SubTitle>第３条（使用条件）</SubTitle>
      <Content>{`
      １　利用者は、本アプリを使用するにあたり必要な通信環境等を自己の費用と責任で用意し、インターネットを利用することのセキュリティ上のリスクを十分理解した上、自身の責任と判断に基づいて適切なセキュリティ対策を講じることとします。
      ２　当社は、本アプリに関し、事実上または法律上の瑕疵(安全性、信頼性、正確性、完全性、有効性、セキュリティ上の欠陥・脆弱性、エラー、バグ、権利侵害を含みます。)の不存在、本アプリの特定目的への適合性および第三者の権利の非侵害を保証しません。当社は、利用者に対して、かかる瑕疵等を除去して本アプリを提供する義務を負いません。また、当社は、利用者によるオンラインサーバの継続使用を保証しません。
      ３　当社は、利用者が本アプリの使用により取得したデータの漏洩もしくは消失その他本アプリの瑕疵に起因して利用者または第三者に発生した損害について一切の責任を負いません。
      `}</Content>

      <SubTitle>第４条（未成年者による利用）</SubTitle>
      <Content>{`
      １　未成年者が本アプリを利用する場合、本アプリの一切の利用（前条第２項の同意も含みます）について、法定代理人（親権者等）の承諾を要するものとします。
      ２　利用者が未成年である場合は、当該利用者の法定代理人は、本アプリの使用に際して当該利用者を管理・監督するものとし、当該利用者が本アプリを利用して行った一切の行為及びその結果について責任を負うものとします。
      ３　未成年者の利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは自らを成年と偽って本アプリを利用した場合、その他自らが行為能力者であることを信じさせるために詐術を用いた場合、当該利用者は、本アプリに関する一切の法律行為を取り消すことは出来ません。
      ４　本規約の同意時に未成年であった利用者が成年に達した後に本アプリを利用した場合、当該利用者は本アプリに関する一切の法律行為を追認したものとみなします。
      `}</Content>

      <SubTitle>第５条（利用規約の修正、変更及び更新）</SubTitle>
      <Content>{`
      １　当社は、利用者の承諾を得ることなく、いつでも本規約の内容を変更することができるものとし、本規約が変更された後の本アプリの利用条件は、変更後の利用規約によるものとします。本規約の変更後の内容は、変更後に利用者が初めて本アプリを立ち上げた際に、本アプリの画面上に表示される事で利用者に通知され、本規約の変更の効力は、当該通知の時点から生じるものとします。なお、利用者が、変更後の利用規約を確認した後に本アプリを利用した場合は、変更後の利用規約の全ての記載内容に同意したものとみなします。
      ２　本アプリは、当社または当社以外のアプリストア提供者を通じて随時更新します。ただし、当社は本アプリを更新する義務を負うものではありません。利用者が更新後の本アプリの使用を希望する場合、利用者は、本アプリの最新版をアプリストアよりダウンロードし、新しい規約を確認する必要があります。
      `}</Content>

      <SubTitle>第６条（登録情報の管理）</SubTitle>
      <Content>{`
      １　利用者は、自己の責任の下、本アプリの利用に際して必要となる情報について本アプリを通じて任意に登録し、管理するものとします。当社は、登録された情報が不正確または虚偽であったことによって利用者が被った一切の損害に関して責任を負わないものとします。
      ２　当社は、利用者の端末における本アプリの利用をもって、利用者本人が本アプリを利用したものとみなします。また、当該利用によって生じた結果及びそれに伴う一切の責任については、利用者に帰属するものとします。
      ３　利用者は、登録情報（本アプリ内で利用者が登録する自己の氏名、性別、年齢、所属企業、所属部署、電話番号、メールアドレス等の情報）の不正な登録または管理によって、当社または第三者に損害が生じた場合、当該損害を賠償するものとします。
      `}</Content>

      <SubTitle>第７条（知的財産権）</SubTitle>
      <Content>{`
      １　利用者は本アプリに関する全ての知的所有権が当社または当社のライセンサーに帰属するものであることに同意するものとします。また、利用者は、利用者が本規約の内容に従い本アプリを利用する権利以外に本アプリについていかなる権利も有しないものとします。
      ２　利用者は、利用者がソースコードの形で本アプリにアクセスするいかなる権利も有しないものとします。
      `}</Content>

      <SubTitle>第８条（個人情報等の取り扱い）</SubTitle>
      <Content>{`
      １　当社は、利用者の個人情報につき、本規約に定めるほか、当社の定める「e メットシステム・プライバシーポリシー」に則り、適正に取り扱います。
      ２　利用者は、本アプリを利用する場合、本サービスの提供に必要な範囲内において、本サービスを通じて、利用者の所属する事業者等の組織から本サービスの契約者に対し、利用者の個人情報が提供されることに同意したものとみなされます。
      ３　当社は、当社における e メットセンサーデバイス及び本アプリの機能改善その他当社の提供する製品またはサービスの開発もしくは改善のため、本アプリの利用履歴及び測定情報等または特定の個人が識別できないように加工した情報を利用または第三者に提供することができるものとし、利用者はこれに同意するものとします。
      `}</Content>

      <SubTitle>第９条（本アプリの利用上の注意）</SubTitle>
      <Content>{`
      １　本アプリが提供する、ＷＢＧＴ近似値、カラダ暑さ指標等の予想などの情報は、利用者が本測定器で測定した測定情報を元に一定の計算式及びアルゴリズムより導き出すものです。そのため、本アプリが提供する全ての情報は、それぞれ異なる環境、事情、状態の利用者に対し、常に正しい情報が提供されているとは限らず、当社はその提供する情報の正確性について保証するものではありません。
      ２　本アプリは、疾病等（熱中症を含みます）の治療・予防等を目的としたものではなく、当社は、いかなる意味でも、疾病等の診察・診断・治療等の医療行為を行うものではありません。また、当社は、利用者に対し、医学的情報を提供するものではありません。当社が本アプリを通じて利用者に提供する情報は、医学的有用性や正確性をうたうものではなく、当社はそれらを保証しません。本アプリの利用に伴って利用者及び利用者であった者に健康上の被害を含む何らかの損害や不利益が生じたとしても、当社は一切の責任を負いません。
      ３　利用者は、前項の趣旨を理解し、自己の判断及び責任で本アプリを利用し、利用の終了または登録解除後を含め、本アプリの利用に伴って健康上の被害を含む何らかの損害や不利益が生じたとしても当社に対して何らの請求をしないことにあらかじめ同意した上で本アプリを利用するものとし、本規約第３条２項の同意の方法により、当該同意がなされたものとみなします。また、利用者は、本アプリの利用中及び利用後においても、自らの判断により、必要に応じて、適切な医療機関の受診等を行うものとします。
      `}</Content>

      <SubTitle>第１０条（禁止行為）</SubTitle>
      <Content>{`
      　本アプリの利用に際し、当社は、利用者に対し、次に掲げる行為を禁止します。当社において、利用者が禁止事項のいずれかに違反したと認めた場合、利用者への本アプリの使用許諾の停止または終了その他当社が必要と判断した措置を取ることができます。
       （１）本アプリを複製する行為（本アプリの通常の範囲での利用に伴う場合、バックアップまたは操作上の安全のために必要な場合を除きます。）
       （２）本アプリを貸与、翻訳、統合、改変、変更または修正する行為
       （３）本アプリの逆アセンブル、逆コンパイルもしくはリバースエンジニアリングその他本アプリのソースコード、構造、アイデア等を解析する行為
       （４）本アプリの全部または一部を他のプログラムへ統合し、組み込む行為
       （５）本アプリに影響を与える外部ツールの利用・作成・頒布等の行為
       （６）当社または第三者（他の利用者を含む。以下同じ。）の知的財産権を侵害する行為
       （７）当社または第三者の名誉・信用を毀損または不当に差別若しくは誹謗中傷する行為、及び人権を侵害する行為
       （８）当社または第三者のサーバー、ネットワーク及び所有物を含む財産を侵害する行為、または侵害する恐れのある行為
       （９）当社または第三者に経済的損害を与える行為
       （１０）犯罪行為に関する行為、及び法令または公序良俗に反する行為
       （１１）当社または第三者に対する脅迫的な行為
       （１２）当社または第三者の個人情報及び営業秘密等を収集または蓄積する行為
       （１３）コンピューターウィルス、有害なプログラムを使用しまたはそれを誘発する行為
       （１４）本アプリが接続するインフラ設備に対して過度な負担となる負荷をかける行為
       （１５）本アプリが接続するサーバーやシステム、セキュリティへの攻撃
       （１６）本アプリに組み込まれているセキュリティデバイスまたはセキュリティコードを無効化する行為
       （１７）他の利用者に成りすます行為
       （１８）上記の他、当社が不適切と判断する行為
       `}</Content>

      <SubTitle>第１１条（本アプリの変更または提供停止若しくは終了）</SubTitle>
      <Content>{`
      １　当社は、当社の判断により、本アプリの全部若しくは一部の仕様を変更し、または本アプリの全部若しくは一部の提供を停止若しくは終了することができるものとします。
      ２　前項の本アプリの変更または提供の停止若しくは終了により、利用者または第三者に損害が生じたとしても、当社は一切責任を負わないものとします。
      ３　第１項に基づき当社が本アプリの提供を停止または終了した場合、利用者は、本アプリに入力したデータ及び提供されたコンテンツデータを利用することができなくなるものとします。
      `}</Content>

      <SubTitle>第１２条（処分）</SubTitle>
      <Content>{`
      １　利用者が以下の事由のいずれかに該当した場合、当社は、当該利用者につき、一方的に、アプリ利用の制限または提供の停止若しくは終了その他当社が適当と認める処分を行うことができるものとします。
      （１）本規約等の規定に違反した場合、または違反のおそれがある場合
      （２）法令等に違反した場合、または違反のおそれがある場合
      （３）本アプリを利用して権利侵害を行う等、その他本アプリを悪用した場合
      （４）なりすまし行為を行った場合
      （５）本アプリ提供に支障が生じうると当社が判断する場合
      （６）第１７条に定める表明保証に反する事実があったとき
      （７）その他、利用者として不適格であると当社が判断する場合
      ２　利用者は、前項によって当社が行う処分によって利用者に生じる損害または不利益について、当社が一切の責任を負わないことにあらかじめ同意するものとします。
      `}</Content>

      <SubTitle>第１３条（免責及び損害の賠償）</SubTitle>
      <Content>{`
      １　当社は、本アプリの内容変更、提供の停止または終了によって利用者または第三者に生じたいかなる損害についても、一切責任を負いません。
      ２　当社は、本アプリが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本アプリの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。
      ３　当社は、本アプリが全ての情報端末に対応していることを保証するものではなく、本アプリの利用に供する端末のＯＳの更新等に伴い、本アプリの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ同意するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
      ４　利用者は、当社以外のアプリストア提供者の利用規約または運用方針の変更等に伴い、本アプリの一部または全部の利用が制限される可能性があることにあらかじめ同意するものとします。
      ５　当社は、利用者が本アプリを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償する責任を負いません。
      ６　当社は、利用者その他の第三者に発生した機会逸失、業務の中断等による損害、本アプリに関連して生じた損害または本アプリ及び本アプリに送られたデータ等の使用、使用不能若しくは使用の結果に関連して生じた損害その他いかなる損害（間接損害や逸失利益を含みます）についても、その発生原因を問わず、また、損害発生につき予見し、または予見することができたかを問わず、一切その責任を負いません。
      ７　利用者と他の利用者または第三者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者の間でトラブルが生じた場合でも、利用者は、自らの費用と責任においてこれを解決するものとし、当社には一切の迷惑や損害を与えないものとします。
      ８　利用者の行為により第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用を含む）と責任で、これを解決するものとします。当社が、利用者に代わり当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。
      ９　利用者が本アプリの利用に関連して当社に損害を与えた場合、利用者は、当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。
      `}</Content>

      <SubTitle>第１４条（不可抗力）</SubTitle>
      <Content>{`
      　当社は、天災、戦争、法令・規則の制定・改廃その他当社の合理的な制御範囲を超える行為または事由（電気通信網の障害を含みます。）により本アプリの利用が妨げられた場合、それにより生じた利用者の損害等について、一切責任を負いません。
      `}</Content>

      <SubTitle>第１５条（権利譲渡の禁止）</SubTitle>
      <Content>{`
      １　利用者は、予め当社の書面による承諾がない限り、本規約上の地位及び本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。
      ２　当社は、本規約上の地位及び本規約に基づく権利または義務の全部または一部を当社の裁量により第三者に譲渡することができるものとします。
      `}</Content>

      <SubTitle>第１６条（分離可能性）</SubTitle>
      <Content>{`
      　本規約のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても残りの部分は、継続して完全に効力を有するものとします。      
      `}</Content>

      <SubTitle>第１７条（反社会的勢力等の排除）</SubTitle>
      <Content>{`
      　利用者及び当社は、本規約に基づく契約の締結にあたり、自らまたはその役員及び従業員が、反社会的勢力等（暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者）に該当せず今後も該当しないこと及び反社会的勢力等との関係を持っておらず今後も持たないことを表明し、保証します。
      `}</Content>

      <SubTitle>第１８条（当社への連絡方法）</SubTitle>
      <Content>{`
      　本アプリに関するアプリ利用者の当社へのご連絡・お問い合わせは、当社が運営するweb サイト内の適宜の場所に設置するお問い合わせフォームからの送信または当社が別途指定する方法により行うものとします。      
      `}</Content>

      <SubTitle>第１９条（準拠法、管轄裁判所）</SubTitle>
      <Content>{`
      １　本規約の有効性、解釈及び履行については、日本法に準拠し、日本法に従って解釈されるものとします。
      ２　当社と利用者等との間での論議・訴訟その他一切の紛争については、大阪地方裁判所を専属的合意管轄裁判所とします｡
      `}</Content>

      <Footer>
        <div>以上</div>
        <div>
          <pre>2022年6月27日　制定</pre>
        </div>
      </Footer>
    </div>
  );
};

export default TermOfUse;

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PointIcon = () => {
  return <img src="/images/help/point.svg" alt="注目" />;
};

export default PointIcon;

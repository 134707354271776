import _ from 'lodash';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { ApiDefaultAlert } from '../types/default_alert';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Alert = {
  alertNumber: number | string | null;
  alertValue: number | null;
};

/**
 * 複数のアラート情報から適切な値を抜き出す
 *
 *
 * @method Function
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const getAlertParam = (
  alerts: Alert[],
  default_alerts: ApiDefaultAlert[],
): any => {
  const alert = alerts.shift();

  //  該当のアラートが見つからない場合再帰させる
  if (
    (alert?.alertNumber === null || alert?.alertNumber === '') &&
    alerts.length !== 0
  ) {
    return getAlertParam(alerts, default_alerts);
  }

  //  デフォルトアラート設定と適合させる
  const _alert: any = _.find(default_alerts, {
    number: alert?.alertNumber,
  });
  return {
    label: _alert ? _alert.name : alert?.alertNumber,
    value: alert?.alertNumber === 0 ? alert?.alertValue : _alert?.value,
  };
};

/**
 * 複数のアラート情報から適切な値を抜き出す
 *
 *
 * @method Function
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const getAge = (birth: string, datepoint: string): number => {
  return differenceInCalendarYears(new Date(datepoint), new Date(birth));
};

/**
 * カレンダー用のデータを取得する
 *
 *
 * @method Function
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const getCalendar = (date: Date) => {
  const weeks = eachWeekOfInterval({
    start: startOfMonth(date),
    end: endOfMonth(date),
  });

  return weeks.map((week) =>
    eachDayOfInterval({ start: week, end: endOfWeek(week) }),
  );
};

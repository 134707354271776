import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import {
  DeviceCommit,
  DeviceCreateConfirm,
  DeviceCreateForm,
  SubHeader,
} from '../../components';
import { fetch, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  name: yup
    .string()
    .matches(/^[A-Z]{3}[0-9]{4}$/, {
      message: '半角大文字英語3桁＋半角数字4桁で入力してください。',
    })
    .required('センサーデバイス名を入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DeviceCreate: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [workSite, setWorkSite] = useState<any>();
  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.post('/device', {
          name: values.name,
          workSiteId: id,
        });

        if (result.status === 200) {
          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  //サブヘッダー用の現場情報を取得。
  useEffect(() => {
    (async () => {
      const workSite = await fetch.get(`/work_site/${id}`);
      if (workSite.status === 200) {
        setWorkSite(workSite.data);
      } else if (workSite.status === 500) {
        alertWithCode(workSite.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={workSite?.name}
        subTitle={`センサーデバイス追加${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${id}`}
      />
      <div className={classes.root}>
        {mode === 'editing' && <DeviceCreateForm {...formik} />}
        {mode === 'confirm' && <DeviceCreateConfirm {...formik} />}
        {mode === 'commit' && (
          <DeviceCommit
            message={`センサーデバイスを新規作成しました。\nセンサーデバイス一覧ページから確認してください。`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(DeviceCreate);

import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubHeader } from '../../components';
import { fetch, useAlert } from '../../functions';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  Box,
  Button,
  Dialog,
} from '@material-ui/core';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { settingTwoFactorStyle } from '../../assets/styles/SettingTwoFactor.css';
import { UIReturn, UISubmit } from '../../components/index';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

type Params = {
  id: string;
};

// const header = ['2段階認証', '設定'];
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const SettingTwoFactor: FC = () => {
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [value, setValue] = useState<number>(0);
  const classes = settingTwoFactorStyle();
  const [isOpen, setIsOpen] = useState(false);
  const [showModalFlg, setShowModalFlg] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const project = await fetch.get(`/organization/${id}/project`);
      if (project.status === 200) {
        setValue(project.data.twoFactorAuth);
      } else if (project.status === 500) {
        alertWithCode(project.status);
      }
    })();
  }, [alertWithCode, id]);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number((event.target as HTMLInputElement).value);
    setValue(value);

    value !== 0 ? setShowModalFlg(true) : setShowModalFlg(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const result = await fetch.put(
        `/organization/${id}/project_two_factor_auth`,
        {
          twoFactorAuth: value,
        },
      );

      if (result.status === 200) {
        dispatch(push('/'));
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
    }
  };

  return (
    <div>
      <SubHeader title="2段階認証設定" link={'/'} />

      <form
        onSubmit={handleSubmit}
        style={{ padding: '2rem' }}
        id="two-factor-setting"
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.row}>
              <TableCell
                className={clsx(
                  classes.twoFactorSettingTableHead,
                  classes.twoFactorSettingTableHeadCenter,
                )}
              >
                2段階認証
              </TableCell>
              <TableCell
                colSpan={2}
                className={clsx(
                  classes.twoFactorSettingTableHead,
                  classes.twoFactorSettingTableHeadCenter,
                )}
              >
                設定
              </TableCell>

              <TableCell
                colSpan={2}
                className={clsx(
                  classes.twoFactorSettingTableHead,
                  classes.twoFactorSettingTableHeadCenter,
                )}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={classes.row}>
              <TableCell
                className={clsx(
                  classes.twoFactorSettingTableHeadCenter,
                  classes.onText,
                )}
                rowSpan={2}
              >
                ON
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                <FormControl
                  component="fieldset"
                  className={'classes.formControl'}
                >
                  <RadioGroup
                    aria-label="setting_type"
                    name="setting_type"
                    value={value}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio color="default" />}
                      label=""
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                毎回
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                ログインごとに2段階認証を行う
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                <FormControl
                  component="fieldset"
                  className={'classes.formControl'}
                >
                  <RadioGroup
                    aria-label="setting_type"
                    name="setting_type"
                    value={value}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={720}
                      control={<Radio color="default" />}
                      label=""
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                12時間ごと
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                ログイン状態を12時間保持する
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell
                className={clsx(
                  classes.twoFactorSettingTableHeadCenter,
                  classes.offText,
                )}
              >
                OFF
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                <FormControl
                  component="fieldset"
                  className={'classes.formControl'}
                >
                  <RadioGroup
                    aria-label="setting_type"
                    name="setting_type"
                    value={value}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={-1}
                      control={<Radio color="default" />}
                      label=""
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                OFF
              </TableCell>

              <TableCell
                className={clsx(classes.twoFactorSettingTableHeadCenter)}
              >
                2段階認証をOFFにする
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className="module-spacer--small" />

        <FormGroup row>
          <UIReturn title="戻る" />
          <div className="module-spacer--button" />
          {showModalFlg ? (
            <Button
              color="primary"
              type="button"
              onClick={handleClickOpen}
              variant="contained"
              disableElevation
            >
              設定
            </Button>
          ) : (
            <UISubmit title="設定" />
          )}
        </FormGroup>
        <Dialog
          classes={{ paper: classes.twoFactorSetting }}
          open={isOpen}
          maxWidth="lg"
          onClose={handleClose}
        >
          <Box textAlign="center">
            <div>
              二段階認証を利用することで、第三者による不正アクセスを防ぐことができます。
              <br />
              設定を解除しますか？
            </div>
          </Box>
          <div className="module-spacer--small" />
          <Box display="flex" justifyContent="center">
            <Button
              color="primary"
              type="button"
              onClick={handleClose}
              variant="outlined"
            >
              いいえ
            </Button>
            <div className="module-spacer--button" />
            <Button
              color="primary"
              type="submit"
              onClick={handleClose}
              variant="contained"
              disableElevation
              form="two-factor-setting"
            >
              はい
            </Button>
          </Box>
        </Dialog>
      </form>
    </div>
  );
};

export default SettingTwoFactor;

import Accordion from '@material-ui/core/Accordion';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

type Expanded = { category?: string; q?: string };

interface Props {
  children: any;
  expanded: Expanded;
  setExpanded: Dispatch<SetStateAction<Expanded>>;
}

const Category: FC<Props> = (props) => {
  const { children, expanded, setExpanded } = props;
  const category: string = children[0].props.children;

  const handleChange =
    (panel: string) =>
    (event: ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(
        { ...expanded, 'category': newExpanded ? panel : undefined }
      );
    };

  return (
    <Accordion
      expanded={category === expanded.category}
      onChange={handleChange(category)}
    >
      {children}
    </Accordion>
  );
};

export default Category;

import { Box } from '@material-ui/core';
import format from 'date-fns/format';
import { useSubheaderStyles } from '../../../assets/styles/Report.css';
import lastDayOfMonth from "date-fns/lastDayOfMonth";

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  timestamp: string;

  alertValue: number;
  alertName: string;

  projectName: string;
  organizationName: string;
  workSiteName: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportSubHeaderWorksiteMonthly = (props: Props) => {
  const {
    timestamp,
    alertValue,
    alertName,
    projectName,
    organizationName,
    workSiteName,
  } = props;
  const classes = useSubheaderStyles();

  return (
      <div className={classes.container}>
        <Box display="flex" alignItems="center">
          <Box className={classes.cellLarge}>
            {timestamp && format(new Date(timestamp), 'yyyy年MM月dd日')}
            <span> ~ </span>
            {timestamp && format(lastDayOfMonth(new Date(timestamp)), 'MM月dd日')}
          </Box>
          <Box className={classes.cellJoin}>の</Box>
          <Box className={classes.cellLarge}>{workSiteName}</Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box className={classes.cellJoin}>ー</Box>
          <Box className={classes.cellSmall}>
            アラート設定値 {alertValue} : {alertName}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box className={classes.cellImage}>
            <img src="/images/report/pin.svg" alt={projectName} />
          </Box>
          <Box className={classes.cellSmall}>
            {projectName} / {organizationName}
          </Box>
        </Box>
      </div>
  );
};

export default ReportSubHeaderWorksiteMonthly;

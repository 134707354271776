import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../assets/styles/Master.css';
import {
  PrimaryContainedButton,
  PrimaryDialog,
  PrimaryReadOnlyField,
  SubHeader,
} from '../../components';
import { UserState } from '../../redux/store';

const MyPage: FC = () => {
  const classes = useStyles();
  const name = useSelector((state: UserState) => state.user.name);
  const email = useSelector((state: UserState) => state.user.email);
  const belongingName = useSelector(
    (state: UserState) => state.user.belongingName,
  );
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
  const handleDeleteConfirmModal = () => {
    setDeleteConfirmIsOpen(!deleteConfirmIsOpen);
  };

  return (
    <>
      <SubHeader title="マイページ" link="" />
      <div className={classes.root}>
        <FormGroup>
          <PrimaryReadOnlyField label="ユーザー名" value={name} />
          <PrimaryReadOnlyField label="メールアドレス" value={email} />
          <PrimaryReadOnlyField label="所属名" value={belongingName ?? ''} />
        </FormGroup>
        <div className="module-spacer--medium" />
        <FormGroup row>
          <PrimaryContainedButton
            label="アカウント情報編集"
            linkTo="/my/edit"
          />
          <div className="module-spacer--button" />
          <PrimaryContainedButton
            label="パスワード変更"
            linkTo="/my/password/edit"
          />
          <div className="module-spacer--button" />
          <PrimaryDialog
            buttonLabel="削除"
            handleOpenClose={handleDeleteConfirmModal}
            dialogOpen={deleteConfirmIsOpen}
          >
            <DialogContent>
              <DialogContentText>
                <p>
                  <span style={{ color: '#FF0000' }}>{name}</span>
                  を削除します。
                </p>
                <p>この操作は取り消しできません。</p>
                <p>本当に削除しますか？</p>
              </DialogContentText>
            </DialogContent>
          </PrimaryDialog>
        </FormGroup>
      </div>
    </>
  );
};

export default MyPage;

import { Box, Container, Tab, Tabs } from '@material-ui/core';
import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  commonStyles,
  organizationStyles,
} from '../../assets/styles/Dashboard.css';
import { SubHeader } from '../../components';
import DatePicker from '../../components/dashboard/DatePicker';
import {
  DangerStatus,
  OrganizationStatus,
  TransitionStatus,
  WorksiteStatus,
} from '../../components/dashboard/organization';
import TimePicker from '../../components/dashboard/TimePicker';
import { fetch, useAlert, useAuth } from '../../functions';
import { isCheckBeforeTargetDate } from '../../utils/DateUtil';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationDashboard: FC = () => {
  const classes = commonStyles();
  const orgClasses = organizationStyles();
  const { id } = useParams<Params>();
  const [data, setData] = useState<any>();
  const [worksite, setWorksite] = useState<any>();
  const [organization, setOrganization] = useState<any>();
  const [anOrganization, setAnOrganization] = useState<any>();
  const [project, setProject] = useState<any>();
  const [tab, setTab] = useState('default');
  const [displayDateTime, setDisplayDateTime] = useState<string>('');
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();
  const { isAllowOrganizationWithAuth, isAllowPlanId } = useAuth();

  const subMenus = [
    {
      icon: '',
      name: 'アンケート設定',
      link: `/organization/${id}/enquete`,
      allow: isAllowOrganizationWithAuth(id, ['SYSTEM_ADMIN']),
    },
    {
      icon: '',
      name: '管理者一覧',
      link: `/organization/${id}/users`,
    },
    {
      icon: '',
      name: '会社・部署一覧',
      link: `/organization/${id}/organizations`,
      allow: isAllowOrganizationWithAuth(id, ['SYSTEM_ADMIN']),
    },
    {
      icon: '',
      name: '現場一覧',
      link: `/organization/${id}/workSites`,
      allow: isAllowOrganizationWithAuth(id, [
        'SYSTEM_ADMIN',
        'WORK_SITE_ADMIN',
      ]),
    },
  ] as any[];

  //  表示データの取得
  useEffect(() => {
    (async () => {
      //  現場の取得
      const resultWorksite = await fetch.get(`/organization/${id}/work_sites`);
      if (resultWorksite.status === 200) {
        setWorksite(resultWorksite.data);
      } else if (resultWorksite.status === 500) {
        alertWithCode(resultWorksite.status);
      }

      //  組織の取得
      const resultOrganization = await fetch.get(
        `/organization/${id}/organizations`,
      );
      if (resultOrganization.status === 200) {
        setOrganization(resultOrganization.data);
      } else if (resultOrganization.status === 500) {
        alertWithCode(resultOrganization.status);
      }

      //サブヘッダー用の(id指定個別)組織情報取得
      const anOrganization = await fetch.get(`/organization/${id}`);
      if (anOrganization.status === 200) {
        setAnOrganization(anOrganization.data);
      } else if (anOrganization.status === 500) {
        alertWithCode(anOrganization.status);
      }

      //プロジェクト情報取得
      const projectResult = await fetch.get(`/organization/${id}/project`);
      if (projectResult.status === 200) {
        setProject(projectResult.data);
      }
    })();
  }, [id, alertWithCode]);

  //  データの取得
  useEffect(() => {
    (async () => {
      const result =
        displayDateTime !== ''
          ? await fetch.get(`/organization/${id}/dashboard`, {
              displayDateTime: `${displayDateTime}`,
            })
          : await fetch.get(`/organization/${id}/dashboard`);

      if (result.status === 200) {
        setData(result.data);
      } else {
        alertWithCode(result.status);
        dispatch(push('/'));
      }
    })();
  }, [id, displayDateTime, alertWithCode, dispatch]);

  const isBeforeTargetDate = useMemo(() => {
    return isCheckBeforeTargetDate(displayDateTime);
  }, [displayDateTime]);

  return (
    <>
      <SubHeader
        title={anOrganization?.name}
        subTitle={anOrganization?.companyName}
        link={`/dashboard/organization/${id}`}
        menus={
          anOrganization?.parentId === null
            ? [
                {
                  icon: '',
                  name: 'お知らせ管理',
                  link: `/organization/${id}/notifications`,
                  allow:
                    isAllowPlanId(project?.planId) &&
                    isAllowOrganizationWithAuth(id, ['SYSTEM_ADMIN']),
                  denyMessage: isAllowPlanId(project?.planId)
                    ? undefined
                    : 'ご利用のプランでは利用できません。',
                },
                ...subMenus,
              ]
            : subMenus
        }
      />

      {data && (
        <Container maxWidth="xl">
          <Box display="flex" className={classes.datePickerArea}>
            <Box>
              <DatePicker
                value={
                  displayDateTime === '' ? data.log.timestamp : displayDateTime
                }
                setValue={setDisplayDateTime}
              />
            </Box>
            <Box>
              <TimePicker
                value={
                  displayDateTime === '' ? data.log.timestamp : displayDateTime
                }
                isBeforeTargetDate={isBeforeTargetDate}
                setValue={setDisplayDateTime}
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box className={orgClasses.main}>
              <DangerStatus
                alertLevel1={data.log.alertLevel1}
                alertLevel2={data.log.alertLevel2}
                alertLevel3={data.log.alertLevel3}
                alertLevel4={data.log.alertLevel4}
                totalCount={data.log.totalWorkerCount}
              />
            </Box>

            <Box className={orgClasses.sub} flexGrow={1}>
              <TransitionStatus
                data={JSON.parse(data.log.bodyHeatIndexAverageData)}
                lastData={JSON.parse(data.log.bodyHeatIndexAverageLastData)}
              />
            </Box>
          </Box>

          <div className={orgClasses.blockWithTab}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, val) => setTab(val)}
              aria-label="disabled tabs example"
              centered
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="配下の会社・部署"
                value="default"
                classes={{ root: classes.tabRoot, selected: classes.tabSlect }}
              />
              <Tab
                label="現場"
                value="worksite"
                classes={{ root: classes.tabRoot, selected: classes.tabSlect }}
              />
            </Tabs>

            <div className={orgClasses.blockWithTabGraph}>
              {tab === 'default' && (
                <OrganizationStatus
                  data={data.childLogs}
                  organizations={organization}
                />
              )}
              {tab === 'worksite' && (
                <WorksiteStatus
                  data={data.workSiteLogs}
                  worksites={worksite}
                  displayDateTime={displayDateTime}
                />
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default OrganizationDashboard;

import { push } from 'connected-react-router';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import logo from '../../assets/images/logo.svg';
import useStyles from '../../assets/styles/Master.css';
import { PasswordPasswordCommit, PasswordPasswordForm } from '../../components';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  password: yup
    .string()
    .required('パスワードを入力してください')
    .test('regex', '半角英数字記号（!%@.?/-）のみ入力できます', (value) =>
      /^[a-zA-Z0-9!%@.?/-]+$/.test(value ?? ''),
    ),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PasswordResetPassword: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        const result = await fetch.put(`/login/password_reset?token=${token}`, {
          password: values.password,
        });

        if (result.status === 200) {
          changeMode('commit');
        } else if(result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (token) {
        const result = await fetch.get(
          `/login/token_check/password?token=${token}`,
        );
        if (result.status !== 200) {
          dispatch(push('/signin'));
        }
      }
    })();
  }, [search, token, dispatch]);

  return (
    <>
      <div className={classes.centerRoot}>
        <img src={logo} alt="eメットシステム" />
        {mode === 'editing' && <PasswordPasswordForm {...formik} />}
        {mode === 'commit' && (
          <PasswordPasswordCommit
            message="パスワードを更新しました"
            link={`/signin`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(PasswordResetPassword);

import axios, { AxiosRequestConfig } from 'axios';

//
type FetchResponseObject = {
  status: number;
  data: any;
};
const API_URL = process.env.REACT_APP_API_URL;

/**
 *
 *
 *
 * @method RequestInterceptors
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  try {
    const token: string = JSON.parse(localStorage['authToken'] as string);

    if (token) {
      config.headers.Authorization = token;
    }
  } catch (e) {
    //
  }
  return config;
});
export default axios;

/**
 *
 *
 *
 * @method Request
 * @version 1.0.0
 * ------------------------------------------------------------------------ */
export const fetch = {
  /**
   *
   *
   *
   * @method GET
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  get: async (url: string, params = {}, config = {}): Promise<FetchResponseObject> => {
    const response = await axios
      .get(API_URL + url, { params, ...config })
      .then((response) => response)
      .catch(({ response }) => response);

    return response;
  },

  /**
   *
   *
   *
   * @method POST
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  post: async (url: string, params = {}, config = {}): Promise<FetchResponseObject> => {
    const response = await axios
      .post(API_URL + url, params, config)
      .then((response) => response)
      .catch(({ response }) => response);

    return response;
  },

  /**
   *
   *
   *
   * @method PUT
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  put: async (url: string, params = {}, config = {}): Promise<FetchResponseObject> => {
    const response = await axios
      .put(API_URL + url, params, config)
      .then((response) => response)
      .catch(({ response }) => response);

    return response;
  },

  /**
   *
   *
   *
   * @method PATCH
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  patch: async (url: string, params = {}, config = {}): Promise<FetchResponseObject> => {
    const response = await axios
      .patch(API_URL + url, params, config)
      .then((response) => response)
      .catch(({ response }) => response);

    return response;
  },

  /**
   *
   *
   *
   * @method DELETE
   * @version 1.0.0
   * ------------------------------------------------------------------------ */
  delete: async (url: string, config = {}): Promise<FetchResponseObject> => {
    const response = await axios
      .delete(API_URL + url, config)
      .then((response) => response)
      .catch(({ response }) => response);

    return response;
  },
};

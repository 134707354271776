import { useAlertRankingMonthlyStyles } from '../../../assets/styles/Report.css';
import { ReportWorkSiteMonthlyAlertRankingData } from '../../../types/report';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  rankingData: ReportWorkSiteMonthlyAlertRankingData[];
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyAlertRanking = (props: Props) => {
  const classes = useAlertRankingMonthlyStyles();

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tableThead}>
          <tr>
            <th>順位</th>
            <th>ユーザー名</th>
            <th>設定値超え累積時間(時間)</th>
            <th>月総数(回)</th>
            <th>月平均(回/稼働日)</th>
          </tr>
        </thead>
        <tbody>
          {props.rankingData.map((ranking, vi: number) => (
            <tr className={classes.tableTr} key={vi}>
              <td>{vi + 1}</td>
              <td>
                {ranking.firstname} {ranking.lastname}
              </td>
              <td>{Number(ranking.bodyHeatIndexAlertTime).toFixed(1)}</td>
              <td>{ranking.bodyHeatIndexAlertCount}</td>
              <td>
                {Number(ranking.bodyHeatIndexAlertCountAverage).toFixed(1)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportMonthlyAlertRanking;

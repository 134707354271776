import { FormikProps } from 'formik';
import { FC } from 'react';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import {
  PrimaryOutlinedButton,
  UIError,
  UIPasswordField,
  UISubmit,
} from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  password: string;
  passwordConfirm: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, handleChange, values, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={masterClasses.text}>新しいパスワードを入力してください</p>
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <UIPasswordField
          name="password"
          label="新しいパスワード"
          value={values.password}
          error={errors.password}
          required
          handleChange={handleChange}
        />
        <UIPasswordField
          name="passwordConfirm"
          label="新しいパスワード(確認)"
          value={values.passwordConfirm}
          error={errors.passwordConfirm}
          required
          handleChange={handleChange}
        />
      </div>

      <div className="module-spacer--medium" />

      <div
        className={masterClasses.submitButton}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <PrimaryOutlinedButton label="ログインページへ" linkTo="/signIn" />
        <div className="module-spacer--button" />
        <UISubmit title="送信" />
      </div>
    </form>
  );
};

export default Form;

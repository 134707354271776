import { FC } from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import { PieChart, Pie, Cell } from 'recharts';
import FaceIndicator from '../../FaceIndicator';
import WorkerDialog from './WorkersDialog';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';
import { ApiGetWorker } from '../../../../types/worker';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  alertLevel1: number;
  alertLevel2: number;
  alertLevel3: number;
  alertLevel4: number;
  totalCount: number;
  organization: any;
  groupName: string;
  worker: ApiGetWorker[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DangerGraph: FC<Props> = (props: Props) => {
  const { organization, groupName, totalCount, worker } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = commonStyles();
  const data = [
    { name: '危険', value: props.alertLevel4 },
    { name: '警戒', value: props.alertLevel3 },
    { name: '注意', value: props.alertLevel2 },
    { name: '用心', value: totalCount === 0 ? 1 : props.alertLevel1 },
  ];
  const COLORS = ['#FF3C6D', '#FFAFB3', '#FFDABC', '#A9D7D0'];
  const pieRadius = isSmall ? 154 : 120;

  return (
    <>
      <Box className={classes.dangerGraph} display="flex" alignItems="center">
        <Box className={classes.dangerGraphArea} flexGrow={1}>
          <PieChart
            className={classes.dangerGraphPie}
            width={pieRadius}
            height={pieRadius}
          >
            <Pie
              data={data}
              innerRadius={pieRadius / 2 - 20}
              outerRadius={pieRadius / 2}
              startAngle={90}
              endAngle={-270}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>

          <WorkerDialog
            worker={worker}
            organization={organization}
            groupName={groupName}
          />
        </Box>

        <Box className={classes.dangerGraphFace}>
          <FaceIndicator
            alertLevel1={props.alertLevel1}
            alertLevel2={props.alertLevel2}
            alertLevel3={props.alertLevel3}
            alertLevel4={props.alertLevel4}
            totalCount={props.totalCount}
          />
        </Box>
      </Box>
    </>
  );
};

export default DangerGraph;

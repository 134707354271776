import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FC, ReactNode } from 'react';
import useStyles from '../../assets/styles/List.css';

interface Props {
  deletable: boolean;
  editable: boolean;
  logout: boolean;
  unlock: boolean;
  children: ReactNode;
}
const Head: FC<Props> = (props) => {
  const { deletable, editable, logout, unlock, children } = props;
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow className={classes.row}>
        {editable && <TableCell className={classes.head}></TableCell>}
        {logout && <TableCell className={classes.head}></TableCell>}
        {unlock && <TableCell className={classes.head}></TableCell>}
        {deletable && <TableCell className={classes.head}></TableCell>}
        {children}
      </TableRow>
    </TableHead>
  );
};

export default Head;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import useStyles from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  sub?: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSummaryEditing = (props: Props) => {
  const { sub = false } = props;
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(location.hash.replace('#', ''));
    if (element !== null) {
      element.scrollIntoView(true);
    }
  }, [location.hash]);

  return (
    <Paper className={classes.summaryBody}>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-1">自分のアカウント情報</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>アカウント情報変更</li>
            <li>閲覧権限の変更</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-2">
          TOPページの表示ダッシュボード
        </Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-3">組織情報</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-4">現場情報</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-5">センサーデバイス情報</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-6">装着者情報</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-7">アラート情報</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/editing#editing-8">管理者情報</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>管理者の権限を変更</li>
            <li>管理者を削除</li>
          </ul>
        )}
      </div>
    </Paper>
  );
};

export default HelpSummaryEditing;

import { Box, Grid } from '@material-ui/core';
import ReportMonthlyEnquetePie from './EnquetePie';
import ReportMonthlyEnqueteTickC2 from './EnqueteTickC2';
import ReportMonthlyEnqueteTickC5 from './EnqueteTickC5';
import { ReportWorkSiteMonthlyEnqueteData } from '../../../types/report';
import { useEnqueteMonthlyPieStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  enqueteData: ReportWorkSiteMonthlyEnqueteData[];
  timestamp: Date;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportWorkSiteMonthlyEnqueteGraphList = (props: Props) => {
  const classes = useEnqueteMonthlyPieStyles();

  return (
    <Grid className={classes.container} container spacing={1}>
      {props.enqueteData.map((enquete, index) => (
        <Grid item xs={6} key={index}>
          <h4 className={classes.title}>
            {index + 1}. {enquete.enqueteForm.title}
          </h4>

          <Box className={classes.block} display="flex" alignItems="center">
            <Box className={classes.blockColumn}>
              {enquete.enqueteForm.type === 'select_two_string' ? (
                <ReportMonthlyEnqueteTickC2 enqueteForm={enquete.enqueteForm} />
              ) : (
                <ReportMonthlyEnqueteTickC5 enqueteForm={enquete.enqueteForm} />
              )}
            </Box>
            <Box className={classes.blockPie}>
              <ReportMonthlyEnquetePie data={enquete.enqueteGraphs} />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportWorkSiteMonthlyEnqueteGraphList;

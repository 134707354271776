//  認証コード有効期限
export const AUTH_CODE_EXPIRATION = 5 * 60 * 1000;

// 設定できるアンケートの個数
export const EnqueteMaxNum = 8;

//  性別
export const SEX_TYPE = [
  { name: '男性', value: 'male' },
  { name: '女性', value: 'female' },
  { name: 'その他', value: 'other' },
];

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../assets/styles/Header.css';
import WorkSiteMenu from './WorkSiteMenu';
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type WorkSite = {
  id: string;
  name: string;
};

type Organization = {
  id: string;
  name: string;
  workSites: WorkSite[];
};

interface Props {
  menus: Organization[];
  handleOpen: () => void;
}
/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationMenu: FC<Props> = (props) => {
  const { menus, handleOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const clickOrganization = (e: any, id: string) => {
    e.stopPropagation();
    dispatch(push(`/dashboard/organization/${id}`));
    handleOpen();
  };

  return (
    <div className='vertical_scroll' style={{height: 'calc(100vh - 106px)'}}>
      {menus &&
        menus.map((organization: any) => (
          <Accordion key={organization.id} square={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={`${classes.accordionSummary} transparent_text`}
            >
              <div
                onClick={(e) => clickOrganization(e, organization.id)}
                className="full-width"
              >
                <PeopleAltOutlinedIcon style={{ marginRight: '1rem' }} />
                <Typography>{organization.name}</Typography>
              </div>
            </AccordionSummary>
            {organization.workSites.length > 0 && (
              <AccordionDetails className={classes.accordionDetails}>
                <WorkSiteMenu workSites={organization.workSites} handleOpen={handleOpen}/>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
    </div>
  );
};

export default OrganizationMenu;

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import {
  commonStyles,
  worksiteStyles,
} from '../../../assets/styles/Dashboard.css';
import { fetch, useAlert } from '../../../functions';
import Empty from '../Empty';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: any[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const BatteryReplacers: FC<Props> = (props) => {
  const { data } = props;
  const classes = commonStyles();
  const wsClasses = worksiteStyles();
  const { alertWithCode } = useAlert();

  const [data2, setData2] = useState([
    { id: '', workerName: '', groupName: '', deviceName: '' },
  ]);

  useEffect(() => {
    setData2(_.sortBy(data, ['battery']));
  }, [data]);

  const deleteBatteryAlert = async (id: string) => {
    const result = await fetch.delete(`/device_battery_alert/${id}`);
    if (result.status === 200) {
      setData2(
        data.filter((d) => {
          return d.id !== id;
        }),
      );
      if (result.status === 200) {
      }
    } else if (result.status === 500) {
      alertWithCode(result.status);
    }
  };

  return (
    <div className={classes.block}>
      <h3 className={classes.blockTitle}>電池交換者リスト</h3>

      <Empty isEmpty={data.length === 0} label="該当者なし" size="small">
        <table className={wsClasses.battery}>
          <thead className={wsClasses.batteryThead}>
            <tr>
              <th></th>
              <th>名前 グループ</th>
              <th>センサーデバイス名</th>
            </tr>
          </thead>

          <tbody className={wsClasses.batteryTbody}>
            {data2.map((d: any) => (
              <tr key={d.id}>
                <td>
                  <IconButton onClick={() => deleteBatteryAlert(d.id)}>
                    <CloseIcon />
                  </IconButton>
                </td>
                <td>
                  <div>{d.workerName}</div>
                  <div>{d.groupName}</div>
                </td>
                <td>{d.deviceName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Empty>
    </div>
  );
};

export default BatteryReplacers;

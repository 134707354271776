import { makeStyles } from '@material-ui/core/styles';

/**
 *  共通のスタイル
 *
 *
 * -------------------------------------------------------------------------- */
export const commonStyles = makeStyles(
  (theme) => ({
    //  ブロック
    block: {
      margin: '0 0 16px 0',
      padding: '16px',
      boxSizing: 'content-box',
      backgroundColor: '#fff',
      borderRadius: '4px',
      filter: 'drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.08))',
    },
    blockTitle: {
      margin: '0',
      fontSize: '20px',
      fontWeight: 'normal',
      color: '#666',
    },

    // 日付フォーム
    datePickerArea: {
      height: '50px',
      padding: '16px 0 0 0',
    },
    datePicker: {
      height: '34px',
      margin: '0 8px 0 0',
      padding: '0 8px',
      backgroundColor: 'white',
      filter: 'drop-shadow(0px 1px 1px rgba(21, 34, 50, 0.08))',
      color: '#444',
    },
    // 時刻00固定時
    datePicker00: {
      height: '34px',
      minWidth: '54px',
      backgroundColor: 'white',
      filter: 'drop-shadow(0px 1px 1px rgba(21, 34, 50, 0.08))',
      borderRadius: '0',
      cursor: 'default',
      pointerEvents: 'none',
      color: '#666666',
    },
    timePickerBlock: {
      display: 'inline-block',
    },
    timePicker: {
      '& select': {
        height: '24px',
        padding: '5px 14px',
        backgroundColor: 'white',
        filter: 'drop-shadow(0px 1px 1px rgba(21, 34, 50, 0.08))',
      },
      fontSize: '14px',
      color: '#444',
    },
    timePickerSep: {
      padding: '0 8px',
    },

    //  アラート発報者と経過時間
    alert: {
      width: '20%',
      padding: '16px',
      boxSizing: 'border-box',

      [theme.breakpoints.down('lg')]: {
        width: '25%',
      },

      [theme.breakpoints.down('md')]: {
        width: '33.3%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '33.3%',
        margin: '0',
        padding: '16px',
      },
    },

    //  炎アイコン
    fire: {
      position: 'relative',
    },
    fireStatus: {
      position: 'absolute',
      margin: 'auto',
      bottom: '10px',
      left: '0',
      right: '0',
    },
    fireStatusTime: {
      textAlign: 'center',
      lineHeight: '1em',
      fontWeight: 'bold',
      fontSize: '25px',
      color: '#fff',
    },
    fireStatusText: {
      textAlign: 'center',
      fontSize: '12px',
      color: '#fff',
    },
    fireStatusAlertText: {
      position: 'absolute',
      textAlign: 'center',
      fontSize: '12px',
      bottom: '14px',
      width: '100%',
      color: '#fff',
    },

    //  作業者
    worker: {
      textAlign: 'center',
      color: '#666',
    },
    workerCompany: {
      fontSize: '13px',
    },
    workerName: {
      fontSize: '20px',
    },

    //  ラベル
    toggleLabelChips: {
      width: '100%',
    },
    toggleLabelChipsLabel: {
      paddingLeft: '0px',
      paddingRight: '0px',
      textOverflow: 'unset',
    },

    //  グループ
    groupTitle: {
      padding: '0 10px',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'normal',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: '#666',

      '& a': {
        textDecoration: 'none',
        color: '#666',

        '&:hover': {
          color: '#00A3F1',
        },
      },
    },
    group: {
      width: '25%',
      padding: '16px',
      boxSizing: 'border-box',

      [theme.breakpoints.down('lg')]: {
        width: '33%',
      },

      [theme.breakpoints.down('md')]: {
        width: '50%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        margin: '0',
        padding: '8px',
      },
    },

    //  危険度グラフ
    dangerGraph: {
      width: '100%',
    },
    dangerGraphPie: {
      margin: 'auto',
    },
    dangerGraphFace: {
      width: '160px',
      padding: '8px',

      [theme.breakpoints.down('lg')]: {
        width: '50%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '45%',
      },
    },
    dangerGraphArea: {
      textAlign: 'center',
    },
    dangerGraphWorkerButton: {
      width: '120px',
      margin: '16px 0 0',
      padding: '0 1px',
      backgroundColor: '#E4EEEF',
      color: '#666',
    },

    //  作業者一覧ダイアログ
    workersDialog: {
      padding: '24px',
      backgroundColor: '#F9F9F9',
      boxShadow: '0px 3px 15px #458E95',
      maxWidth: '800px',
    },
    workersDialogForm: {
      textAlign: 'center',
    },
    workersDialogFormLabel: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '500px',
      },
    },
    workersDialogFormLabelItem: {
      textAlign: 'left',
    },
    workersDialogFormHelp: {
      margin: '16px 0 0 0',
      textAlign: 'center',
      color: '#666',
    },
    workersDialogGroupName: {
      display: 'inline-block',
      padding: '7px 17px',
      boxSizing: 'content-box',
      backgroundColor: '#458E95',
      borderRadius: '4px',
      textAlign: 'center',
      fontSize: '14px',
      color: '#fff',
    },
    workersDialogClose: {
      color: '#FA6980',
    },
    workersDialogTable: {
      margin: '24px 0 0 0',
      display: 'block',
      overflow: 'auto',
    },
    workersDialogTableHead: {
      backgroundColor: '#C2DADC',
      color: '#000',
      minWidth: '180px',
      position: 'sticky',
      top: '0',
    },
    workersDialogTableHeadCenter: {
      textAlign: 'center',
    },
    workersDialogTableBodyRow: {
      backgroundColor: '#fff',

      '&:td': {
        borderBottom: 'none',
      },
      '&:nth-child(even)': {
        backgroundColor: '#F9F9F9',
      },
    },
    workerLogout: {
      color: '#CBD0D3',
      textAlign: 'center',
    },
    workersDialogTableCellName: {
      textAlign: 'center',
    },
    workersDialogTableCellAlertValue: {
      textAlign: 'center',
    },
    workersDialogTableCellFace: {
      textAlign: 'center',

      '& img': {
        width: '38px',
        height: 'auto',
        verticalAlign: 'middle',
      },
    },
    workersDialogTableCellTime: {
      textAlign: 'center',

      '& svg': {
        width: '18px',
        height: '18px',
        margin: '0 4px 1px 0',
        verticalAlign: 'middle',
      },
    },
    workersDialogTableCellLabel: {
      margin: '1px',
      border: '1px solid #707070',
      backgroundColor: '#F2F2F2',
      color: '#666666',
    },
    workersDialogTableCellOrg: {
      textAlign: 'center',
    },
    workersDialogTableCellBodyHeat: {
      textAlign: 'center',
    },
    workersDialogTableCellWbgt: {
      textAlign: 'center',
    },
    workersDialogTableCellHeadTemperature: {
      textAlign: 'center',
    },
    workersDialogTableLabel: {
      textAlign: 'center',
    },

    //  顔指標
    face: {
      width: '100%',
      color: '#666',

      '& img': {
        height: 'auto',
        verticalAlign: 'bottom',
      },
    },
    faceIconValue: {
      padding: '4px 7px',
      textAlign: 'right',
    },
    faceIconValuePercent: {
      fontSize: '0.5em',

      '& small': {
        margin: '0 0 0 2px',
        fontSize: '0.75em',
      },
    },
    faceIconValueValue: {
      fontSize: '0.7em',
      lineHeight: '1.35',

      '& small': {
        margin: '0 0 0 2px',
        fontSize: '0.65em',
      },
    },

    //  各顔アイコン専用
    face4: {
      position: 'relative',
      fontSize: '35px',
      backgroundColor: '#FF3C6D',
      borderRadius: '10px',
      color: '#fff',

      '& img': {
        position: 'absolute',
        top: '-10px',
        left: '-10px',
        width: '1em',
        height: 'auto',
        border: '6px solid #FF3C6D',
        borderRadius: '50%',
        backgroundColor: '#FF3C6D',
        boxSizing: 'content-box',
      },
    },
    face3: {
      fontSize: '30px',

      '& img': {
        width: '1em',
      },
    },
    face2: {
      fontSize: '20px',

      '& img': {
        width: '1em',
      },
    },
    face1: {
      fontSize: '20px',

      '& img': {
        width: '1em',
      },
    },

    face4Large: {
      fontSize: '60px',
    },
    face3Large: {
      fontSize: '51px',
    },
    face2Large: {
      fontSize: '33px',
    },
    face1Large: {
      fontSize: '33px',
    },

    //  カラダ暑さ指標遷移グラフ
    bodyTransitionTitle: {
      margin: '16px 0 0',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#666',
    },

    tabsRoot: {
      background: 'white',
    },

    tabsIndicator: {
      opacity: '0',
    },

    tabRoot: {
      borderRadius: '10px 10px 0 0',
      background: '#458E95',
      color: 'white',
      maxWidth: '50%',
      width: '50%',
    },

    tabSlect: {
      border: '3px solid',
      borderBottom: 'transparent',
      borderRadius: '10px 10px 0 0',
      background: 'white',
    },
  }),
  { index: 1 },
);

/**
 *  組織のスタイル
 *
 *
 * -------------------------------------------------------------------------- */
export const organizationStyles = makeStyles(
  (theme) => ({
    //  コンテンツ幅
    main: {
      width: '550px',
      minWidth: '550px',
      padding: '16px 16px 16px 0',

      [theme.breakpoints.down('lg')]: {
        width: '500px',
        minWidth: '500px',
        padding: '16px 16px 16px 0',
      },

      [theme.breakpoints.down('md')]: {
        width: '430px',
        minWidth: '430px',
      },

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        minWidth: '50%',
        padding: '16px 8px 16px 0',
      },
    },
    sub: {
      padding: '16px 0 16px 16px',

      [theme.breakpoints.down('lg')]: {
        width: '722px',
        padding: '16px 0 16px 16px',
      },

      [theme.breakpoints.down('md')]: {
        width: '465px',
      },

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        padding: '16px 0 16px 8px',
      },
    },

    blockWithTab: {
      margin: '0 0 16px 0',
      boxSizing: 'content-box',
      backgroundColor: '#fff',
      borderRadius: '4px',
      filter: 'drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.08))',
    },

    blockWithTabGraph: {
      padding: '0 16px 16px',
    },

    group: {
      width: '25%',
      padding: '16px',
      boxSizing: 'border-box',

      [theme.breakpoints.down('md')]: {
        width: '33.3%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        margin: '0',
        padding: '8px',
      },
    },
  }),
  { index: 1 },
);

/**
 *  現場のスタイル
 *
 *
 * -------------------------------------------------------------------------- */
export const worksiteStyles = makeStyles(
  (theme) => ({
    //  flex のルート
    root: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },

    // 日付選択フォーム
    datePickerArea: {
      margin: '0 0 0 16px',
      padding: '1px 4px',
      backgroundColor: '#fafafa',
    },

    //  コンテンツ幅
    main: {
      padding: '16px 16px 16px 0',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '16px 16px 0',
      },
    },
    sub: {
      width: '344px',
      minWidth: '344px',
      padding: '16px 0 16px 16px',

      [theme.breakpoints.down('lg')]: {
        width: '328px',
        minWidth: '328px',
      },

      [theme.breakpoints.down('md')]: {
        width: '281px',
        minWidth: '281px',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 16px 16px',
      },
    },

    //  専用ブロック
    block: {
      position: 'relative',
      margin: '0 0 16px 0',
      padding: '16px',
      boxSizing: 'content-box',
      backgroundColor: '#fff',
      borderRadius: '4px',
      filter: 'drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.08))',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '50%',
        alignItems: 'center',
        margin: '0 8px 16px 0',
        padding: '0',

        '&:nth-child(even)': {
          margin: '0 0 16px 8px',
        },
      },
    },
    blockTitle: {
      margin: '0',
      fontSize: '20px',
      fontWeight: 'normal',
      color: '#666',

      [theme.breakpoints.down('sm')]: {
        width: '42%',
        textAlign: 'center',
      },
    },

    //  フィルター
    filterButton: {
      margin: '0 0 0 16px',
      padding: '1px 9px',
      border: '1px solid #1E9097',
      color: '#1E9097',
    },
    filterChip: {
      margin: '0 0 0 4px',
      padding: '2px 6px',
      backgroundColor: '#f3f3f3',
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#666',
    },

    //  アラート　もっと見るボタン
    moreBg: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: '32px',
      background: 'linear-gradient(#FFFFFF, #F4F8F9 75%, #E4EEEF);',
    },
    moreIcon: {
      cursor: 'pointer',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '-8px',
      width: '32px',
      height: 'auto',
      margin: 'auto',
    },
    moreIconClose: {
      transition: '0.5s',
      transform: 'scale(1, -1)',
    },

    //  WBGT近似値
    wbgt: {
      margin: '16px 0 0 0',
      alignItems: 'baseline',
      color: '#666',

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        margin: '0',
        padding: '0',
      },
    },
    wbgtText: {
      width: '50%',
      fontSize: '20px',
      textAlign: 'right',
    },
    wbgtValue: {
      padding: '0 0 0 16px',
      textAlign: 'left',
      fontSize: '30px',

      '& b': {
        fontSize: '50px',
      },
    },

    //  稼働台数
    operatoinunits: {
      margin: '16px 0 0 0',
      alignItems: 'baseline',
      color: '#666',

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        margin: '0',
        padding: '0',
      },
    },
    operatoinunitsValue: {
      flexGrow: 1,
      fontSize: '30px',
      textAlign: 'right',

      '& b': {
        fontSize: '50px',
      },
    },
    operatoinunitsTotal: {
      width: '20%',
      padding: '0 0 0 8px',
      fontSize: '20px',

      [theme.breakpoints.down('md')]: {
        width: '30%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '35%',
        fontSize: '18px',
      },
    },

    //  電池交換者リスト
    battery: {
      width: '100%',
      margin: '32px 0 0 0',
      color: '#666',
    },
    batteryThead: {
      '& th': {
        fontSize: '14px',
        fontWeight: 'normal',
      },
    },
    batteryTbody: {
      '& td': {
        padding: '14px 8px',
        fontSize: '14px',
        textAlign: 'center',
      },
    },
  }),
  { index: 1 },
);

/**
 *  現場のスタイル
 *
 *
 * -------------------------------------------------------------------------- */
export const emptyStyles = makeStyles(
  (theme) => ({
    empty: {
      margin: '40px 0',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#666',
    },

    label: {
      fontSize: '60px',
    },

    overlay: {
      position: 'relative',
    },

    overlayLabel: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      width: '100%',
      zIndex: 100,
      fontSize: '60px',
    },

    content: {
      pointerEvents: 'none',
      opacity: '0.3',
    },

    normal: {},

    small: {
      fontSize: '40px',
    },
  }),
  { index: 1 },
);

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../assets/styles/Header.css';
import { signOut } from '../../../redux/loginSlice';

const STAR_LITE_URL = 'https://starlite.co.jp/';
const STAR_LITE_URL_PRIVACY = 'https://starlite.co.jp/privacy/';

const menus = [
  { name: 'アカウント設定', link: '/my', icon: <PermIdentityIcon /> },
  {
    name: '個人情報保護規定',
    link: STAR_LITE_URL_PRIVACY,
    icon: <DescriptionIcon />,
  },
  { name: '会社概要', link: STAR_LITE_URL, icon: <DescriptionIcon /> },
  { name: 'アプリ利用規約', link: '/term_of_use', icon: <DescriptionIcon /> },
  { name: 'ログアウト', link: '/signin', icon: <ExitToAppIcon /> },
];

interface Props {
  handleClose: () => void;
}
/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HeaderMenuList: FC<Props> = (props) => {
  const { handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (link: string) => {
    switch (link) {
      case '/signin':
        dispatch(signOut());
        break;
      case STAR_LITE_URL:
        window.open(link, '_blank');
        break;
      case STAR_LITE_URL_PRIVACY:
        window.open(link, '_blank');
        break;
      default:
        dispatch(push(link));
    }
    handleClose();
  };

  return (
    <List>
      {menus.map((menu, index) => (
        <ListItem
          button
          onClick={() => handleClick(menu.link)}
          key={index}
          className={classes.headerMainButtonListItem}
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.name} style={{ color: '#666666' }} />
        </ListItem>
      ))}
    </List>
  );
};

export default HeaderMenuList;

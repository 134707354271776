import { FC } from 'react';
import { FormikProps } from 'formik';
import Button from '@material-ui/core/Button';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { UITextField, UIError } from '../index';
import { fetch, useAlert } from '../../functions';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  email: string;
  password: string;
  oneTimePassword: string;
}

type Props = {
  btnDisabled: boolean;
} 

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>&Props> = (props) => {
  const { handleSubmit, handleChange, values, errors, btnDisabled } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  const { alertWithCode, alertWithText } = useAlert();

  const reSend = async () => {
    const result = await fetch.post('/login', {
      email: values.email,
      password: values.password,
    });

    if (result.status === 200) {
      alertWithText('２段階認証コードを再送しました');
    } else {
      alertWithCode(result.status);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="oneTimePassword"
          label="ワンタイムパスワード"
          value={values.oneTimePassword}
          error={errors.oneTimePassword}
          required
          handleChange={handleChange}
        />
      </div>

      <div className="module-spacer--medium" />

      <div className={masterClasses.submitButton}>
        <Button disabled={btnDisabled} color='primary' type='submit' variant='contained' disableElevation>
          ログイン
        </Button>
      </div>

      <Button color="primary" onClick={() => reSend()}>
        2段階認証コードを再送する
      </Button>

      <Button color="primary" href="/signin">
        ログイン入力フォームに戻る
      </Button>
    </form>
  );
};

export default Form;

import { FC } from 'react';
import useStyles from '../../assets/styles/Master.css';
import { PrimaryContainedButton } from '../../components';
import Button from '@material-ui/core/Button';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  link: string;
  token: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const EmailCommit: FC<Props> = (props: Props) => {
  const { message, link, token } = props;
  const classes = useStyles();

  return (
    <div>
      <p className={classes.text}>{message}</p>
      <div className="module-spacer--medium" />
      <PrimaryContainedButton label="戻る" linkTo={link} />
      {token && (
        <>
          <p>【開発用】</p>
          <Button href={`/password/reset?token=${token}`} variant="outlined">
            メール確認後
          </Button>
        </>
      )}
    </div>
  );
};

export default EmailCommit;

import { ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import {
  FormGroup,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import useStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { UISelect, UISubmit, UIReturn, UIError } from '../index';
import { EnqueteOnOff } from '../../templates/enquete/EnqueteEdit';
import clsx from 'clsx';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  [key: string]: number;
  isHidden: number;
  enqueteFormId1: number;
  enqueteFormId2: number;
  enqueteFormId3: number;
  enqueteFormId4: number;
  enqueteFormId5: number;
  enqueteFormId6: number;
  enqueteFormId7: number;
  enqueteFormId8: number;
}

type Props = FormikProps<FormValues> & {
  enqueteForm: {
    name: string;
    value: number;
  }[];
  enqueteCount: 4 | 8;
  handleChangeEnqueteCount: (e: ChangeEvent<HTMLInputElement>) => void;
};

type EnqueteCountSelectType = {
  name: string;
  value: number;
};

const enqueteCountSelect: EnqueteCountSelectType[] = [
  { name: '4', value: 4 },
  { name: '8', value: 8 },
];

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form = (props: Props) => {
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    enqueteForm,
    enqueteCount,
    handleChangeEnqueteCount,
  } = props;
  const classes = useStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className={classes.errorMessage}>
        <UIError errors={errors} />
      </div>
      {values && (
        <div className={uikitClasses.root}>
          <FormControl className={uikitClasses.formControl}>
            <FormLabel>アンケート機能ON/OFF</FormLabel>
            <RadioGroup
              row
              name="isHidden"
              value={values['isHidden']}
              onChange={(e) =>
                setFieldValue('isHidden', Number(e.target.value))
              }
            >
              <FormControlLabel
                color="primary"
                className={clsx(
                  values.isHidden === EnqueteOnOff.ON &&
                    uikitClasses.radioLabelActive,
                )}
                value={EnqueteOnOff.ON}
                control={
                  <Radio
                    color={
                      values.isHidden === EnqueteOnOff.ON
                        ? 'primary'
                        : 'default'
                    }
                  />
                }
                label={<span style={{ fontWeight: 700 }}>ON</span>}
              />
              <FormControlLabel
                className={clsx(
                  values.isHidden === EnqueteOnOff.OFF &&
                    uikitClasses.radioLabelActive,
                )}
                value={EnqueteOnOff.OFF}
                control={
                  <Radio
                    color={
                      values.isHidden === EnqueteOnOff.OFF
                        ? 'primary'
                        : 'default'
                    }
                  />
                }
                label={<span style={{ fontWeight: 'bold' }}>OFF</span>}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}

      {values && (
        <div className={uikitClasses.root}>
          <UISelect
            empty={values['isHidden'] === EnqueteOnOff.OFF ? '-' : undefined}
            name="enqueteCount"
            label="アンケート項目数"
            value={enqueteCount}
            menus={enqueteCountSelect}
            isDisabled={values['isHidden'] === EnqueteOnOff.OFF}
            handleChange={handleChangeEnqueteCount}
          />
        </div>
      )}

      {values &&
        Object.keys(values).map((key, index: number) => {
          if (key === 'isHidden') {
            return <></>;
          }
          let disabled = false;
          if (values['isHidden'] === EnqueteOnOff.OFF) {
            disabled = true;
          } else {
            if (enqueteCount === 4 && index > 4) {
              disabled = true;
            }
          }
          return (
            <div className={uikitClasses.root} key={index}>
              <UISelect
                empty={
                  (enqueteCount === 4 && index > 4) ||
                  values['isHidden'] === EnqueteOnOff.OFF
                    ? '-'
                    : undefined
                }
                name={key}
                label={`アンケート設定 ${index}`}
                value={
                  (enqueteCount === 4 && index > 4) ||
                  values['isHidden'] === EnqueteOnOff.OFF
                    ? ''
                    : values[key]
                }
                menus={enqueteForm}
                isDisabled={disabled}
                handleChange={handleChange}
              />
            </div>
          );
        })}

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

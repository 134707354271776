import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    footer: {
      padding: theme.spacing(2),
      position: 'sticky',
      bottom: 0,
      width: '100%',
      backgroundColor: '#FFF',
      borderTop: '.5px solid rgba(0, 0, 0, 0.12)',
      color: '#666666',
      zIndex: 99,
      height: '70px',
    },
    footerBackGroundColorNone: {
      padding: theme.spacing(2),
      position: 'sticky',
      bottom: 0,
      width: '100%',
      color: '#666666',
      zIndex: 99,
      height: '70px',
    },
    footerBackButton: {
      backgroundColor: '#FFF',
    },
  }),
  { index: 1 },
);

import { useParams } from 'react-router-dom';
import { PrimaryOutlinedButton } from '..';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
};

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Commit = (props: Props) => {
  const { message } = props;
  const { id } = useParams<Params>();

  return (
    <>
      <pre>{message}</pre>

      <div className="module-spacer--small" />
      <PrimaryOutlinedButton
        label="アンケート設定へ"
        linkTo={`/organization/${id}/enquete`}
      />
    </>
  );
};

export default Commit;

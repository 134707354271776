import { makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '2rem',
      width: '100%',
      maxWidth: '30rem',
      '&>*': {
        color: '#666666',
      },
    },
    message: {
      whiteSpace: 'nowrap',
    },
    errorMessage: {
      color: '#FF0000',
      whiteSpace: 'nowrap',
    },
    submitButton: {
      margin: '0 auto 16px',
    },
    marginLeftButton: {
      marginLeft: '.5rem',
      color: '#FFF',
    },
    centerRoot: {
      padding: '2rem',
      width: '100%',
      maxWidth: '30rem',
      margin: '0 auto',
      textAlign: 'center',
    },
    text: {
      color: '#666666',
    },
    errorMessageNoBottom: {
      color: '#FF0000',
      whiteSpace: 'nowrap',
      marginBottom: 0,
    },
    buttons: {
      textAlign: 'left',
    },
    newsDiv: {
      marginTop: '1rem',
      width: '35rem',
      margin: '0 auto',
    },
    alertRoot: {
      paddingTop: '1rem',
    },
    subtext: {
      fontSize: '.8rem',
      paddingLeft: '.5rem',
    },
    grids: {
      marginTop: '2rem',
      marginLeft: '0.5rem',
      textAlign: 'center',
    },
    gridLeft: {
      padding: '.2rem .3rem',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
    },
    imageDiv: {
      width: '30%',
    },
    image: {
      width: '100%',
      marginTop: '.3rem',
    },
    label: {
      width: '70%',
      fontSize: '.85rem',
      paddingLeft: '2px',
    },
    workSiteAlertDiv: {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      padding: '1rem',
      borderRadius: '4px',
    },
    workSiteEditAlert: {
      border: '1px solid rgba(0, 0, 0, .2)',
      padding: '0 1rem 1rem 1rem',
      borderRadius: '4px',
      marginTop: '1rem',
    },
    workSiteEditGroupTitle: {
      width: '100px',
      fontSize: '1rem',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      transform: 'translateY(-10px)',
    },
    workerEditRoot: {
      padding: '2rem',
      width: '100%',
      maxWidth: '80rem',
      '&>*': {
        color: '#666666',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
    },
    header: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none',
      height: '44px',
    },
    headerLogo: {
      cursor: 'pointer',
      width: '118px',
      height: '32px',
      marginLeft: '15px',
    },
    headerMenus: {
      marginLeft: 'auto',
    },
    headerButton: {
      cursor: 'pointer',
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      padding: '0 17px',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
    headerMainButtonLabel: {
      marginLeft: '5px',
      '& *': {
        color: '#FFF',
        fontSize: '10px',
        textAlign: 'left',
      },
      '& span': {
        fontSize: '14px',
      },
    },
    headerMainButtonListItem: {
      paddingRight: '2rem',
      borderBottom: '.5px  solid rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
    subHeader: {
      display: 'flex',
      height: '62px',
      width: '100%',
      backgroundColor: '#FFF',
      borderBottom: '.5px solid rgba(0, 0, 0, 0.12)',
      color: '#666666',
      position: 'fixed',
      zIndex: 99,
    },
    subHeaderLeftContainer: {
      paddingLeft: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
    subHeaderIconButton: {
      width: '60px',
      height: '60px',
      '& svg': {
        width: '41px',
        height: '41px',
      },
    },
    subHeaderTitle: {
      cursor: 'pointer',
      fontSize: '22px',
      paddingLeft: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    subHeaderSubTitle: { fontSize: '14px' },
    subHeaderModal: {
      outline: 'none',
      zIndex: 0,
    },
    subHeaderModalCustom: {
      zIndex: -1,
      position: 'fixed',
      inset: '105px 0 0 0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    subHeaderModalContent: {
      top: '61px',
      left: 0,
      position: 'absolute',
      maxWidth: '50%',
      outline: 'none',
      '&>*': {
        color: '#666666',
      },
      border: '1px solid rgba(0,0,0,0.01)',
    },
    accordion: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      zIndex: 99,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
    },
    accordionSummary: {
      borderBottom: 'none',
      '&>*': {
        fontSize: '14px',
      },
      marginBottom: -1,
      '& div': {
        display: 'flex',
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    accordionDetails: {
      padding: 0,
      borderTop: '.1px solid rgba(0,0,0,.1)',
      borderBottom: '.1px solid rgba(0,0,0,.1)',
    },
    subHeaderRight: {
      '&>*': {
        cursor: 'pointer',
        padding: '0 12px',
        color: '#666666',
      },
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: '1rem',
    },
    subHeaderRightMenu: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      height: '100%',
      '&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
    subHeaderRightMenuDeny: {
      opacity: 0.4,
    },
    subHeaderWorkSiteName: {
      paddingLeft: '22px',
    },
    subHeaderWorkSiteItem: {
      '&:hover': {
        backgroundColor: '#E4EEEF',
        borderLeft: '4px solid #458E95',
        boxShadow: '0px 3px 3px 0px rgba(0,0,0,0.16)',
      },
      '&>*': {
        color: '#458E95',
      },
      borderBottom: '.1px solid rgba(0,0,0,.1)',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    appHeaderDiv: {
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      '&>div': {
        margin: '0 auto',
        fontSize: '20px',
      },
    },

    //  ポップオーバーメニュー
    popoverlink: {
      display: 'block',
      borderBottom: '1px solid #e5e5e5',
      textDecoration: 'none',
      color: '#707070',

      '&:last-child': {
        borderBottom: 0,
      },

      '&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
    popoverlinkIcon: {
      padding: '12px 6px 12px 16px',

      '& img': {
        verticalAlign: 'bottom',
      },
    },
    popoverlinkText: {
      padding: '12px 24px 12px 6px',
    },
  }),
  { index: 1 },
);
export default useStyles;

import { ReactNode } from 'react';
import { useCommonStyles } from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface Props {
  children: ReactNode;
}

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportSection = (props: Props) => {
  const classes = useCommonStyles();

  return <div className={classes.section}>{props.children}</div>;
};

export default ReportSection;

import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import {
  SubHeader,
  ReportSelectorModeButton,
  ReportSelectorFormButton,
  ReportSelectorCreateReportWorker,
  ReportSelectorCreateReportWorksite,
} from '../../components';
import { fetch, useAlert } from '../../functions';
import { ApiGetWorker } from '../../types/worker';
import {
  useCommonStyles,
  useSelectStyles,
} from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
  kind: string;
  type: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportSelect = () => {
  const { id, kind, type } = useParams<Params>();
  const [organization, setOrganization] = useState<any>();
  const [workSite, setWorkSite] = useState<any>();
  const [workers, setWorkers] = useState<ApiGetWorker[]>([]);
  const [activeKind, setActiveKind] = useState<string>(kind);
  const [activeType, setActiveType] = useState<string>(type);
  const [date, setDate] = useState<Date>(sub(new Date(), { days: 1 }));
  const classes = useCommonStyles();
  const selectClasses = useSelectStyles();
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();

  //  ページの初期化
  useEffect(() => {
    (async () => {
      //  現場情報を取得
      const resultWorkSite = await fetch.get(`/work_site/${id}`);
      if (resultWorkSite.status === 200) {
        setWorkSite(resultWorkSite.data);

        //  組織の取得
        const resultOrganization = await fetch.get(
          `/organization/${resultWorkSite.data.organizationId}`,
        );
        if (resultOrganization.status === 200) {
          setOrganization(resultOrganization.data);
        }
      } else if (resultWorkSite.status === 401) {
        alertWithCode(resultWorkSite.status);
        dispatch(push('/'));
      }

      //  作業者一覧の取得
      const resultWorkers = await fetch.get(`/work_site/${id}/workers`);
      if (resultWorkers.status === 200) {
        setWorkers(resultWorkers.data);
      }
    })();
  }, [id, alertWithCode, dispatch]);

  return (
    <>
      <SubHeader
        title="レポート作成"
        subTitle={workSite?.name}
        link={`/dashboard/workSite/${id}`}
      />

      <Container className={classes.container}>
        <section className={selectClasses.section}>
          <h3 className={selectClasses.title}>レポートの種類</h3>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <ReportSelectorModeButton
                title="個人デイリーレポート"
                subtitle="装着者個人の1日分の結果を表示します。"
                active={activeKind === 'worker' && activeType === 'daily'}
                handleClick={() => {
                  setActiveKind('worker');
                  setActiveType('daily');
                }}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <ReportSelectorModeButton
                title="個人マンスリーレポート"
                subtitle="装着者個人の1ヵ月の結果を表示します。"
                active={activeKind === 'worker' && activeType === 'monthly'}
                handleClick={() => {
                  setActiveKind('worker');
                  setActiveType('monthly');
                }}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <ReportSelectorModeButton
                title="現場マンスリーレポート"
                subtitle="現場の1ヵ月分の結果を表示します。"
                active={activeKind === 'worksite' && activeType === 'monthly'}
                handleClick={() => {
                  setActiveKind('worksite');
                  setActiveType('monthly');
                }}
              />
            </Grid>
          </Grid>
        </section>

        <section className={selectClasses.section}>
          <h3 className={selectClasses.title}>作成範囲</h3>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              {activeType === 'monthly' ? (
                <ReportSelectorFormButton
                  type="monthly"
                  date={date}
                  setDate={setDate}
                />
              ) : (
                <ReportSelectorFormButton
                  type="daily"
                  date={date}
                  setDate={setDate}
                />
              )}
            </Grid>
          </Grid>
        </section>

        {activeKind === 'worker' ? (
          <ReportSelectorCreateReportWorker
            kind={activeKind}
            type={activeType}
            date={
              activeType === 'monthly'
                ? format(date, 'yyyyMM')
                : format(date, 'yyyyMMdd')
            }
            workers={workers}
            organizationName={organization?.name}
          />
        ) : (
          <ReportSelectorCreateReportWorksite
            kind={activeKind}
            type={activeType}
            worksite={id}
            date={format(date, 'yyyyMM')}
          />
        )}
      </Container>

      <div className={selectClasses.help}>
        <Link
          to="/help/usage/report"
          className={selectClasses.helpButton}
          target="_blank"
        >
          <img src="/images/report/help.svg" alt="レポート作成方法" />
          レポート作成方法
        </Link>
      </div>
    </>
  );
};

export default ReportSelect;

import { useContentsStyles } from '../../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  src: string;
  title: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpImage = (props: Props) => {
  const classes = useContentsStyles();

  return (
    <div className={classes.image}>
      <div>{props.title}</div>
      <img className={classes.imageImg} src={props.src} alt={props.title} />
    </div>
  );
};

export default HelpImage;

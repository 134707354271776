import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import face4 from '../../assets/images/face1.png';
import face3 from '../../assets/images/face2.png';
import face2 from '../../assets/images/face3.png';
import face1 from '../../assets/images/face4.png';
import {
  List,
  SubHeader,
  WorkerCsvDownloadButton,
  WorkerCsvSampleDownloadButton,
} from '../../components';
import { SEX_TYPE } from '../../constants/common';
import { fetch } from '../../functions';
import { ApiDefaultAlert } from '../../types/default_alert';
import { getAlertParam, useAlert } from '../../functions';
type Params = {
  id: string;
};

const header = [
  '危険度レベル',
  'ログイン名',
  'グループ名',
  '管理番号',
  'ユーザー名',
  'ユーザー名（サブ）',
  'ユーザー名　フリガナ',
  'ユーザー名　フリガナ（サブ）',
  '生年月日',
  '性別',
  'メールアドレス',
  '電話番号',
  '郵便番号',
  '管理用住所',
  '所属',
  'アラート設定名',
  'アラート設定値',
  '装着者ID',
];

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Worker = {
  id: number;
  loginName: string;
  workSiteId: number;
  groupNumber: number;
  controlNumber: string;
  lastname: string;
  firstname: string;
  lastnameKana: string;
  firstnameKana: string;
  birthday: Date;
  sex: 'male' | 'female';
  email: string;
  phone: string;
  post: string;
  address: string;
  affiliation: string;
  alertNumber: number | null;
  alertValue: number | null;
  heatIndex: number;
  alertLevel: number;
  alertStartTime: Date | null;
};

type OrganizationRow = {
  id: string;
  cells: string[];
  name: string;
};

type Group = {
  id: number;
  workSiteId: number;
  number: number;
  name: string;
  emai: string;
  alertNumber: number | null;
  alertValue: number | null;
};
/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkersList: FC = () => {
  const { alertWithCode } = useAlert();
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>([]);
  const [data, setData] = useState<OrganizationRow[]>([]);
  const [workSite, setWorkSite] = useState({
    name: '',
    alertNumber: null as number | null,
    alertValue: null as number | null,
    groups: [] as Group[],
  });
  const { id } = useParams<Params>();

  const subMenus = useMemo(() => {
    return [
      {
        icon: <AddIcon />,
        name: '装着者追加',
        link: `/workSite/${id}/worker/create`,
      },
      {
        icon: <EditIcon />,
        name: '装着者一括更新',
        link: `/workSite/${id}/worker/multipleCreate`,
      },
      data.length > 0
        ? {
            icon: <GetAppIcon />,
            name: '装着者一覧ダウンロード',
            link: '',
            component: (
              <WorkerCsvDownloadButton worksiteName={workSite?.name} />
            ),
          }
        : {
            icon: <span />,
            name: 'CSVサンプルダウンロード',
            link: '',
            component: <WorkerCsvSampleDownloadButton />,
          },
    ];
  }, [data, id, workSite]);

  const alertFace = (alertLevel: number | null) => {
    switch (alertLevel) {
      case 2:
        return <img src={face2} alt="注意" className="face_in_table" />;
      case 3:
        return <img src={face3} alt="警告" className="face_in_table" />;
      case 4:
        return <img src={face4} alt="危険" className="face_in_table" />;
      default:
        return <img src={face1} alt="用心" className="face_in_table" />;
    }
  };
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/work_site/${id}/workers`);
      const data: any[] = [];
      if (result.status === 200) {
        result.data.forEach((worker: Worker) => {
          const sex = _.find(SEX_TYPE, { value: worker.sex });
          const group: any = _.find(workSite.groups, {
            number: worker.groupNumber,
          });
          const alettParam = getAlertParam(
            [worker, group, workSite],
            default_alerts,
          );

          data.push({
            id: worker.id,
            cells: [
              alertFace(worker.alertLevel),
              worker.loginName,
              group?.isHidden ? '' : group?.name,
              worker.controlNumber,
              worker.firstname,
              worker.lastname,
              worker.firstnameKana,
              worker.lastnameKana,
              String(worker.birthday).split('00:00:00')[0],
              sex?.name,
              worker.email,
              worker.phone,
              worker.post,
              worker.address,
              worker.affiliation,
              alettParam?.label,
              String(alettParam?.value),
              worker.id,
            ],
            name: `${worker.firstname} ${worker.lastname}さん`,
          });
        });
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }

      setData(data);
    })();
  }, [setData, alertWithCode, id, default_alerts, workSite]);

  useEffect(() => {
    (async () => {
      //  現場情報の取得
      const result = await fetch.get(`/work_site/${id}`);
      if (result.status === 200) {
        setWorkSite({
          name: result.data.name,
          alertNumber: result.data.alertNumber,
          alertValue: result.data.alertValue,
          groups: result.data.groups,
        });

        //  現場情報をもとにデフォルトアラートを取得
        const daResult = await fetch.get(
          `/default_alert_v2?organizationId=${result.data.organizationId}`,
        );
        if (daResult.status === 200) {
          setDefaltAlerts([
            ...daResult.data,
            { number: 0, name: 'カスタム', value: 0 },
          ]);
        } else if (daResult.status === 500) {
          alertWithCode(result.status);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  const deleteWorker = async (id: string) => {
    const result = await fetch.delete(`/worker/${id}`);
    if (result.status === 200) {
      setData(
        data.filter((row) => {
          return row.id !== id;
        }),
      );
    } else if (result.status === 500) {
      alertWithCode(result.status);
    }
  };

  const logoutWorker = async (workerId: string) => {
    const result = await fetch.post(
      `/work_site/${id}/force_logout?workerId=${workerId}`,
    );
    if (result.status === 200) {
      alert('強制ログアウトを行いました');
    } else if (result.status === 500) {
      alertWithCode(result.status);
    }
  };

  return (
    <div>
      <SubHeader
        title={workSite?.name}
        subTitle="装着者編集"
        link={`/dashboard/workSite/${id}`}
        menus={subMenus}
      />
      <List
        tableHeadCells={header}
        tableBodyCells={data}
        deleteItem={deleteWorker}
        logoutItem={logoutWorker}
        edit={`/workSite/${id}/worker`}
      />
    </div>
  );
};

export default WorkersList;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { isOpen: false, text: '', severity: 'danger' };

export const loginSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<any>) => {
      state.isOpen = true;
      state.text = action.payload.text;
      state.severity = action.payload.severity;
    },
    removeAlert: (state) => {
      state.isOpen = false;
    },
  },
});

export const { showAlert, removeAlert } = loginSlice.actions;
export default loginSlice.reducer;

import Slide from '@material-ui/core/Slide';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import useStyles from '../../../assets/styles/Header.css';
import { CommonState } from '../../../redux/store';
import MasterMenu from './MasterMenu';
import MenuButton from './MenuButton';
import OrganizationList from './OrganizationMenu';
import Title from './Title';
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Menu = {
  icon: any;
  name: string;
  link: string;
  component?: any;
  auth?: boolean;
};
interface Props {
  title: string;
  subTitle?: string;
  link: string;
  menus?: Menu[];
}

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const SubHeaderLeft: FC<Props> = (props) => {
  const { title, subTitle, link, menus } = props;
  const organizations = useSelector((state: CommonState) => state.common);
  const classes = useStyles();

  //モーダル
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.subHeader}>
        <div className={classes.subHeaderLeftContainer}>
          <MenuButton onClick={() => setOpen((prev) => !prev)} />
          <Title title={title} subTitle={subTitle} link={link} />

          <div
            aria-hidden="true"
            onClick={() => setOpen(!open)}
            className={open ? classes.subHeaderModalCustom : ''}
          />
          <Slide direction="right" in={open} mountOnEnter>
            <div className={classes.subHeaderModalContent}>
              <OrganizationList
                menus={organizations}
                handleOpen={() => setOpen(!open)}
              />
            </div>
          </Slide>
        </div>
        <div className={classes.subHeaderRight}>
          <MasterMenu menus={menus} />
        </div>
      </div>
      <div className="module-spacer--bottom" />
    </>
  );
};

export default SubHeaderLeft;

import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../assets/styles/Header.css';
interface Props {
  title: string;
  link: string;
  subTitle?: string;
}

const Title: FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title, subTitle, link } = props;

  return (
    <div
      className={classes.subHeaderTitle}
      onClick={() => dispatch(push(link ?? '/'))}
    >
      <div className={classes.subHeaderSubTitle}>{title ? subTitle : ''}</div>
      <span className="shorten_title">{title??''}</span>
    </div>
  );
};

export default Title;

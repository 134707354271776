import clsx from 'clsx';
import { ReportWorkSiteMonthlyEnqueteData } from '../../../types/report';
import { useReportWorkSiteMonthlyEnqueteListStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  enqueteData: ReportWorkSiteMonthlyEnqueteData[];
  timestamp: Date;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportWorkSiteMonthlyEnqueteList = (props: Props) => {
  const classes = useReportWorkSiteMonthlyEnqueteListStyles();
  const dayCount = new Date(
    props.timestamp.getFullYear(),
    props.timestamp.getMonth() + 1,
    0,
  ).getDate();

  return (
    <div className={classes.container}>
      {props.enqueteData.map((enquete, vi: number) => (
        <div className={classes.containerInside} key={vi}>
          <h4 className={classes.title}>
            {vi + 1}. {enquete.enqueteForm.title}
          </h4>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.tableTh}></th>
                {[...Array(dayCount)].map((_, i) => {
                  const d = new Date(props.timestamp);
                  d.setDate(i + 1);
                  return (
                    <th className={classes.tableThDay} key={i}>
                      <span
                        className={clsx(d.getDay() === 0 && classes.tableDay0)}
                      >
                        {i + 1}
                      </span>
                    </th>
                  );
                })}
                <th className={classes.tableTh}>頻度</th>
              </tr>
            </thead>
            <tbody>
              {enquete.enqueteWorkerCalendars.map(
                (enqueteWorkerCalendars, index) => {
                  return (
                    <tr className={classes.tableTr} key={index}>
                      <>
                        <td>
                          {enqueteWorkerCalendars.firstname}
                          {enqueteWorkerCalendars.lastname}
                        </td>
                        {enqueteWorkerCalendars.enqueteDataDetailCalendar.map(
                          (calender, ci) => {
                            return (
                              <td key={ci}>
                                <span>
                                  {calender.answer === null
                                    ? ''
                                    : calender.answer > 3
                                    ? '●'
                                    : '-'}
                                </span>
                              </td>
                            );
                          },
                        )}
                        <td>
                          {(enqueteWorkerCalendars.frequency * 100).toFixed(0)}%
                        </td>
                      </>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default ReportWorkSiteMonthlyEnqueteList;

import { useState, useRef, useEffect } from 'react';
import { LineChart, Line, ReferenceLine, XAxis, YAxis, Label } from 'recharts';
import format from 'date-fns/format';
import YAxisValueTick from './YAxisValueTick';
import AlertReferenceLabel from './AlertReferenceLabel';
import Legend from './Legend';
import {
  ReportWorkderDailyBodyHeatIndexData,
  ReportWorkderDailyAlertValueData,
  ReportWorkderDailyEnvironmentData,
} from '../../../types/report';
import { useLineGraphStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  alertValue: number;
  bodyHeatIndexData: ReportWorkderDailyBodyHeatIndexData[];
  lastBodyHeatIndexData: ReportWorkderDailyBodyHeatIndexData[];
  alertValueData: ReportWorkderDailyAlertValueData[];
  environmentData: ReportWorkderDailyEnvironmentData[];
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportDailyBodyHeatIndex = (props: Props) => {
  const {
    alertValue,
    bodyHeatIndexData,
    lastBodyHeatIndexData,
    alertValueData,
    environmentData,
  } = props;
  const classes = useLineGraphStyles();
  const [points, setPoints] = useState<any>([]);
  const [max, setMax] = useState<number>(0);
  const line = useRef(null);
  const [minMax, setMinMax] = useState<{ min: number; max: number }>({
    min: 0,
    max: -1,
  });

  // 初期化
  useEffect(() => {
    const _data: any[] = [];
    let _max: number = 0;
    let _alertMax: number = 0;

    //  データの整形
    bodyHeatIndexData.forEach(
      (bhid: ReportWorkderDailyBodyHeatIndexData, index: number) => {
        const lbhid = lastBodyHeatIndexData[index];
        const avd = alertValueData[index];
        const ed = environmentData[index];
        const isLast = index + 1 === bodyHeatIndexData.length;
        let temperature: number | string = Number(ed.temperature).toFixed(1);
        let humidity: number | string = Number(ed.humidity).toFixed(1);

        if (bhid.bodyHeatIndex > _max) {
          _max = bhid.bodyHeatIndex;
        }
        if (avd.alertValue > _alertMax) {
          _alertMax = avd.alertValue;
        }

        // 3時間毎の温度湿度が null の場合は１時間前までを探索
        if (index % 36 === 0 && ed.temperature === null) {
          temperature = '-';
          humidity = '-';

          //  １時間前まででデータがある場合採用
          for (let i = 1; i < 12; i++) {
            const before = environmentData[index - i];
            if (before !== undefined && before.temperature !== null) {
              temperature = Number(before.temperature).toFixed(1);
              humidity = Number(before.humidity).toFixed(1);
            }
          }
        }
        _data.push({
          timestamp: isLast
            ? '24:00'
            : format(new Date(bhid.timestamp), 'HH:mm'),
          bodyHeatIndex: bhid.bodyHeatIndex,
          lastBodyHeatIndex: lbhid.bodyHeatIndex,
          alertValue: avd.alertValue,
          environment: `${temperature}℃/${humidity}%`,
        });
      },
    );
    // 通常データのMax値とアラートデータのMax値を比較
    if (_max < _alertMax) {
      const max = Math.round(_alertMax * 1.1);
      setMinMax({ min: 0, max: max });
    }
    setPoints(_data);
    setMax(_max);
  }, [
    bodyHeatIndexData,
    lastBodyHeatIndexData,
    alertValueData,
    environmentData,
  ]);

  return (
    <div className={classes.container}>
      <LineChart
        width={700}
        height={242}
        data={points}
        margin={{ top: 5, right: 25, bottom: 5, left: -5 }}
      >
        <YAxis
          dataKey="bodyHeatIndex"
          interval={0}
          tickLine={false}
          domain={[minMax.min, minMax.max === -1 ? 'auto' : minMax.max]}
          tick={<YAxisValueTick maxValue={max} />}
        />
        <ReferenceLine
          y={alertValue}
          style={{ opacity: '0' }}
          label={<AlertReferenceLabel value={alertValue} />}
        />
        <XAxis
          dataKey="timestamp"
          interval={35}
          xAxisId={0}
          tickLine={false}
          height={20}
          padding={{ left: 20, right: 20 }}
        />
        <XAxis
          dataKey="environment"
          interval={35}
          xAxisId={1}
          axisLine={false}
          tick={{ fontSize: 8 }}
          tickLine={false}
          padding={{ left: 20, right: 20 }}
          height={24}
          offset={-3}
        >
          <Label
            value="センサーデバイス内 気温/湿度"
            offset={13}
            width={40}
            style={{ fontSize: '8px', color: '#707070' }}
            position="left"
          />
        </XAxis>
        <Line
          ref={line}
          type="monotone"
          dataKey="alertValue"
          stroke="#7A7A7A"
          strokeWidth={1}
          strokeDasharray="10 4"
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="lastBodyHeatIndex"
          stroke="#686868"
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="bodyHeatIndex"
          stroke="#FE104B"
          dot={false}
          strokeWidth={3}
        />

        <g transform="translate(500, 0)">
          <Legend />
        </g>
      </LineChart>
    </div>
  );
};

export default ReportDailyBodyHeatIndex;

import AccordionDetails from '@material-ui/core/AccordionDetails';
import useStyles from '../../assets/styles/Sub.css';

const Answer = ({ children }: any) => {
  const classes = useStyles();

  return (
    <AccordionDetails className={classes.appHelpAnswer}>
      {children}
    </AccordionDetails>
  );
};

export default Answer;

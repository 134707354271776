import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import loginReducer from './loginSlice';
import userReducer from './userSlice';
import commonReducer from './commonSlice';
import alertReducer from './alertSlice';

export type UserState = {
  user: {
    id: string;
    name: string;
    email: string;
    password: string;
    belongingName: string | null;
    isAdmin: boolean;
    authorities: [
      {
        id: string;
        userId: string;
        organizationId: number;
        type: number | string;
      },
    ];
  };
};

export type LoginState = {
  login: {
    isSignedIn: boolean;
  };
};

type WorkSite = {
  id: string;
  name: string;
};

type Organization = {
  id: string;
  name: string;
  workSites: WorkSite[];
};

export type CommonState = {
  common: Organization[];
};

export type AlertState = {
  alert: {
    isOpen: boolean;
    text: string;
    severity: 'danger' | 'warning' | 'info' | 'success';
  };
};
export default function createStore(history: any) {
  return reduxCreateStore(
    combineReducers({
      router: connectRouter(history),
      login: loginReducer,
      user: userReducer,
      common: commonReducer,
      alert: alertReducer,
    }),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)),
  );
}

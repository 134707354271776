import { FC } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import {
  SubHeader,
  HelpFooter,
  HelpSummaryBasic,
  HelpSummaryEditing,
  HelpSummaryNotice,
  HelpSummaryReport,
  HelpContentsBasic,
  HelpContentsEditing,
  HelpContentsReport,
  HelpContentsNotice,
  HelpGotoTopButton,
} from '../../../components';
import useStyles from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  page: 'basic' | 'editing' | 'report' | 'notice';
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpContents: FC = () => {
  const classes = useStyles();
  const { page } = useParams<Params>();
  const pageName = {
    basic: '各種設定',
    editing: '登録情報の変更',
    notice: 'お知らせ',
    report: 'レポート'
  };

  return (
    <div className={classes.container}>
      <SubHeader title={"ヘルプ（操作方法） ＞　" + pageName[page]} link="/help/usage" />

      <Container className={classes.containerMain}>
        <Grid container spacing={4}>
          <Grid item md={5} xs={12}>
            <Link to={'/help/usage'} className={classes.summaryHeadLink}>
              <Paper className={classes.summaryHead}>
                {page === 'basic' && <>目次：各種設定</>}
                {page === 'editing' && <>目次：登録情報の変更</>}
                {page === 'notice' && <>目次：お知らせ</>}
                {page === 'report' && <>目次：レポート</>}
              </Paper>
            </Link>
            {page === 'basic' && <HelpSummaryBasic sub />}
            {page === 'editing' && <HelpSummaryEditing sub />}
            {page === 'notice' && <HelpSummaryNotice sub />}
            {page === 'report' && <HelpSummaryReport sub />}
          </Grid>
        </Grid>

        {page === 'basic' && <HelpContentsBasic />}
        {page === 'editing' && <HelpContentsEditing />}
        {page === 'notice' && <HelpContentsNotice />}
        {page === 'report' && <HelpContentsReport />}
      </Container>

      <HelpFooter
        left={{ title: 'よくある質問を見る', url: '/help' }}
        right={{ title: 'アプリのヘルプを見る', url: '/help/app' }}
      />

      <HelpGotoTopButton />
    </div>
  );
};

export default HelpContents;

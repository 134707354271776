import { ReactNode } from 'react';
import { Header, UISnackbar } from '..';
import useStyles from '../../assets/styles/App.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
};

/**
 *
 *
 *
 * @method LayoutComponents
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CommonLayout = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <UISnackbar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

export default CommonLayout;

import { FC, useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Button,
  Dialog,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from '@material-ui/core';
import { AccessTime, Close } from '@material-ui/icons';
import clsx from 'clsx';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import face1 from '../../../../assets/images/face/1.svg';
import face2 from '../../../../assets/images/face/2.svg';
import face3 from '../../../../assets/images/face/3.svg';
import face4 from '../../../../assets/images/face/4.svg';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';
import { ApiGetWorker } from '../../../../types/worker';
import { useParams } from 'react-router-dom';
import { getAlertParam, useAlert } from '../../../../functions';
import { ApiDefaultAlert } from '../../../../types/default_alert';
import { fetch } from '../../../../functions';
import _ from 'lodash';
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  organization: any;
  groupName: string;
  worker: ApiGetWorker[];
};

type Group = {
  id: number;
  workSiteId: number;
  number: number;
  name: string;
  emai: string;
  alertNumber: number | null;
  alertValue: number | null;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkerDialog: FC<Props> = (props: Props) => {
  const { organization, groupName, worker } = props;
  const classes = commonStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams<Params>();
  const { alertWithCode } = useAlert();
  const [data, setData] = useState<any>([]);
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>([]);
  const [workSite, setWorkSite] = useState({
    name: '',
    alertNumber: null as number | null,
    alertValue: null as number | null,
    groups: [] as Group[],
  });

  //  ダイアログオープン
  const handleClickOpen = () => {
    setIsOpen(true);
  };

  //  閉じる
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const data: any[] = [];
    worker.forEach((d) => {
      const group: any = _.find(workSite.groups, {
        number: d.groupNumber,
      });
      const alettParam = getAlertParam([d, group, workSite], default_alerts);
      alettParam.id = d.id;
      data.push(alettParam);
    });

    setData(data);
  }, [setData, alertWithCode, id, default_alerts, workSite, worker]);

  useEffect(() => {
    (async () => {
      //  現場情報の取得
      const result = await fetch.get(`/work_site/${id}`);
      if (result.status === 200) {
        setWorkSite({
          name: result.data.name,
          alertNumber: result.data.alertNumber,
          alertValue: result.data.alertValue,
          groups: result.data.groups,
        });

        //  現場情報をもとにデフォルトアラートを取得
        const daResult = await fetch.get(
          `/default_alert_v2?organizationId=${result.data.organizationId}`,
        );
        if (daResult.status === 200) {
          setDefaltAlerts([
            ...daResult.data,
            { number: 0, name: 'カスタム', value: 0 },
          ]);
        } else if (daResult.status === 500) {
          alertWithCode(result.status);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);
  return (
    <>
      <Button
        className={classes.dangerGraphWorkerButton}
        onClick={handleClickOpen}
      >
        装着者一覧
      </Button>

      <Dialog
        classes={{ paper: classes.workersDialog }}
        open={isOpen}
        maxWidth="lg"
        onClose={handleClose}
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <div className={classes.workersDialogGroupName}>{groupName}</div>
          </Box>
          <Box>
            <IconButton size="small" onClick={handleClose}>
              <Close className={classes.workersDialogClose} />
            </IconButton>
          </Box>
        </Box>

        <Table className={classes.workersDialogTable}>
          <TableHead>
            <TableRow>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                ユーザー名
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                アラート設定値
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                危険度レベル
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                経過時間
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                カラダ暑さ指標
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                WBGT
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                ひたい温度
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                所属
              </TableCell>
              <TableCell
                className={clsx(
                  classes.workersDialogTableHead,
                  classes.workersDialogTableHeadCenter,
                )}
              >
                属性
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {worker.map((d: ApiGetWorker) => {
              const isLogin =
                new Date(d.lastLoginTime).getTime() >
                new Date().getTime() - 60 * 60 * 1000;
              let alert = 0;
              data.forEach((a: any) => {
                if (a.id === d.id) {
                  alert = a.value;
                }
              });

              return (
                <TableRow
                  className={classes.workersDialogTableBodyRow}
                  key={d.id}
                >
                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellName
                        : classes.workerLogout
                    }
                  >
                    {d.firstname}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellAlertValue
                        : classes.workerLogout
                    }
                  >
                    {isLogin ? alert : '-'}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellFace
                        : classes.workerLogout
                    }
                  >
                    {isLogin ? (
                      <>
                        {(d.alertLevel === 1 || d.alertLevel === null) && (
                          <img src={face1} alt="用心" />
                        )}
                        {d.alertLevel === 2 && <img src={face2} alt="注意" />}
                        {d.alertLevel === 3 && <img src={face3} alt="警戒" />}
                        {d.alertLevel === 4 && <img src={face4} alt="危険" />}
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellTime
                        : classes.workerLogout
                    }
                  >
                    {isLogin ? (
                      <>
                        <AccessTime />
                        {d.alertStartTime
                          ? differenceInMinutes(
                              new Date(),
                              new Date(d.alertStartTime),
                            )
                          : 0}
                        分
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellBodyHeat
                        : classes.workerLogout
                    }
                  >
                    {isLogin ? d.bodyHeatIndex : '-'}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellWbgt
                        : classes.workerLogout
                    }
                  >
                    {isLogin ? d.wbgt : '-'}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellHeadTemperature
                        : classes.workerLogout
                    }
                  >
                    {isLogin ? d.headTemperature : '-'}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableCellOrg
                        : classes.workerLogout
                    }
                  >
                    {organization.name}
                  </TableCell>

                  <TableCell
                    className={
                      isLogin
                        ? classes.workersDialogTableLabel
                        : classes.workerLogout
                    }
                  >
                    {isLogin
                      ? d.labels.map((label: any) => (
                          <Chip
                            className={classes.workersDialogTableCellLabel}
                            label={label.name}
                            size="small"
                            variant="outlined"
                            key={label.id}
                          />
                        ))
                      : '-'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Dialog>
    </>
  );
};

export default WorkerDialog;

import { FormGroup } from '@material-ui/core';
import format from 'date-fns/format';
import ja from 'date-fns/locale/ja';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { FC } from 'react';
import useStyles from '../../assets/styles/Master.css';
import { SEX_TYPE } from '../../constants/common';
import { ApiDefaultAlert } from '../../types/default_alert';
import {
  PrimaryReadOnlyField,
  UIError,
  UIReturn,
  UISubmit,
  UIToggleLabel,
} from '../index';
import WorkSiteAlertData from './WorkSiteAlertData';
import { getAlertParam } from '../../functions';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  //  基本情報
  firstname: string;
  lastname: string;
  firstnameKana: string;
  lastnameKana: string;
  sex: string;
  birthday: string;
  phone: string;
  email: string;
  post: string;
  address: string;
  labels: string[];

  //  管理情報
  loginName: string;
  groupNumber: string;
  controlNumber: string;
  affiliation: string;

  //  アラート情報
  alertNumber: number | null;
  alertValue: number | null;
}
type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
  workSite: {
    name: string;
    alertNumber: number | null;
    alertValue: number | null;
    groups: any[];
  };
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const EditConfirm: FC<Props> = (props) => {
  const { handleSubmit, values, errors, default_alerts, workSite } = props;
  const classes = useStyles();
  const alert: any = (alertNumber: number) =>
    _.find(default_alerts, {
      number: alertNumber,
    });

  const sex = _.find(SEX_TYPE, { value: values.sex });
  const group = _.find(workSite.groups, { number: Number(values.groupNumber) });
  const groupAlert = alert(group.alertNumber);
  const customAlert = alert(values.alertNumber);
  const alettParam = getAlertParam([values, group, workSite], default_alerts);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className={classes.errorMessage}>
        <UIError errors={errors} />
      </div>
      <PrimaryReadOnlyField label="装着者ログイン名" value={values.loginName} />
      <PrimaryReadOnlyField label="ユーザー名" value={values.firstname} />
      <PrimaryReadOnlyField
        label="ユーザー名（サブ）"
        value={values.lastname}
      />
      <PrimaryReadOnlyField
        label="ユーザー名　フリガナ"
        value={values.firstnameKana}
      />
      <PrimaryReadOnlyField
        label="ユーザー名　フリガナ（サブ）"
        value={values.lastnameKana}
      />
      <PrimaryReadOnlyField label="性別" value={sex ? sex.name : ''} />
      <PrimaryReadOnlyField
        label="生年月日"
        value={
          values.birthday
            ? format(new Date(values.birthday), 'yyyy年MM月dd日', {
                locale: ja,
              })
            : ''
        }
      />
      <PrimaryReadOnlyField label="電話番号" value={values.phone} />
      <PrimaryReadOnlyField label="メールアドレス" value={values.email} />
      <PrimaryReadOnlyField label="郵便番号" value={values.post} />
      <PrimaryReadOnlyField label="管理用住所" value={values.address} />
      <PrimaryReadOnlyField label="所属" value={values.affiliation} />
      <PrimaryReadOnlyField label="管理番号" value={values.controlNumber} />
      <PrimaryReadOnlyField label="グループ番号" value={values.groupNumber} />

      <PrimaryReadOnlyField
        label="アラート設定"
        value={customAlert?.name ?? '下記の設定を反映'}
      />
      {customAlert ? (
        <PrimaryReadOnlyField
          label="アラート設定値"
          value={
            values.alertNumber === 0
              ? String(values.alertValue)
              : String(customAlert.value)
          }
        />
      ) : (
        <WorkSiteAlertData
          workSiteName={
            groupAlert ? `グループ: ${group?.name}` : `現場: ${workSite?.name}`
          }
          alertNumber={alettParam?.label}
          alertValue={String(alettParam?.value)}
        />
      )}
      <div className="module-spacer--small" />

      <UIToggleLabel name="labels" value={values.labels} disabled />

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確定" />
      </FormGroup>
    </form>
  );
};

export default EditConfirm;

import { FormGroup } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import {
  SubHeader,
  UIError,
  UIPasswordField,
  UIReturn,
  UISubmit,
} from '../../components';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  password: yup.string().required('パスワードを入力してください'),
  newPassword: yup
    .string()
    .required('パスワードを入力してください!')
    .min(8, 'パスワードは8文字以上を入力してください')
    .test('regex', '半角英数字記号（!%@.?/-）のみ入力できます', (value) =>
      /^[a-zA-Z0-9!%@.?/-]+$/.test(value ?? ''),
    ),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'パスワードが一致しません'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PasswordResetEmail: FC = () => {
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  const dispatch = useDispatch();
  const { alertWithCode } = useAlert();

  const Formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      const result = await fetch.put('/user/password', {
        password: values.password,
        newPassword: values.newPassword,
      });
      if (result.status === 200) {
        dispatch(push('/my/password/commit'));
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    },
  });
  const { handleSubmit, handleChange, values, errors } = Formik;

  return (
    <>
      <SubHeader title="パスワード編集" link="/my" />
      <div className={masterClasses.root}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <p className={masterClasses.text}>
            現在のパスワードと新しいパスワードを入力してください
            <br />
            <br />
            パスワードは半角英字（大文字小文字）/半角数字/記号（!%@.?/-)を組み合わせた8文字以上16文字以内で設定してください
          </p>
          <div className={masterClasses.errorMessage}>
            <UIError errors={errors} />
          </div>

          <div className={uikitClasses.root}>
            <UIPasswordField
              name="password"
              label="現在のパスワード"
              value={values.password}
              error={errors.password}
              required
              handleChange={handleChange}
            />
            <UIPasswordField
              name="newPassword"
              label="新しいパスワード"
              value={values.newPassword}
              error={errors.newPassword}
              required
              handleChange={handleChange}
            />
            <UIPasswordField
              name="newPasswordConfirm"
              label="新しいパスワード(確認)"
              value={values.newPasswordConfirm}
              error={errors.newPasswordConfirm}
              required
              handleChange={handleChange}
            />
          </div>

          <div className="module-spacer--medium" />

          <FormGroup row>
            <UIReturn title="戻る" />
            <div className="module-spacer--button" />
            <UISubmit title="変更" />
          </FormGroup>
        </form>
      </div>
    </>
  );
};

export default PasswordResetEmail;

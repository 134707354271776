import AccordionDetails from '@material-ui/core/AccordionDetails';

const QuestionsList = ({ children }: any) => {
  return (
    <AccordionDetails style={{ display: 'block' }}>{children}</AccordionDetails>
  
  )
}

export default QuestionsList

import { FC, ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  type?: 'text' | 'number' | 'email';
  name: string;
  className?: string;
  value: string;
  error?: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  handleChange: (e: ChangeEvent) => void;
};

/**
 * テキストフィールド
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UITextField: FC<Props> = (props: Props) => {
  const isError = !!props.error;

  return (
    <TextField
      type={props.type ?? 'text'}
      name={props.name}
      className={props.className}
      label={props.label}
      value={props.value}
      onChange={props.handleChange}
      placeholder={props.placeholder}
      required={props.required ?? false}
      helperText={props.helperText}
      color="primary"
      margin="dense"
      variant="outlined"
      error={isError}
      fullWidth
      multiline={props.multiline ?? false}
      rows={props.rows ?? 1}
      InputProps={{
        style: { color: '#666666' },
      }}
      autoComplete="none"
    />
  );
};

export default UITextField;

import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import { useStyles } from '../../assets/styles/FormFooter.css';
import { FC } from 'react';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  word: string;
  onSubmit: any;
  isBackGroundColor?: boolean;
};
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const FormFooter: FC<Props> = ({
  word,
  onSubmit,
  isBackGroundColor = false,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <footer
      className={
        isBackGroundColor ? classes.footerBackGroundColorNone : classes.footer
      }
    >
      <Box display="flex" justifyContent="center">
        <Button
          className={isBackGroundColor ? classes.footerBackButton : ''}
          color="primary"
          onClick={() => history.goBack()}
          variant="outlined"
          fullWidth
        >
          戻る
        </Button>
        <div className="module-spacer--button" />
        <Button
          color="primary"
          onClick={onSubmit}
          type="submit"
          variant="contained"
          disableElevation
          fullWidth
        >
          {word}
        </Button>
      </Box>
    </footer>
  );
};

export default FormFooter;

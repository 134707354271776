import { FC } from 'react';
import jaLocale from 'date-fns/locale/ja';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

/**
 *
 *
 *
 * @method Class
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: any) {
    return format(date, 'yyyy MMM', { locale: this.locale });
  }
  getDatePickerHeaderText(date: any) {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  name: string;
  value: string;
  error?: string;
  label: string;
  setFieldValue?: (name: string, value: any) => void;
};

/**
 * 日付ピッカー
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UIDatePicker: FC<Props> = (props: Props) => {
  const handleChange = (date: any, value: any) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, value);
    }
  };

  const dateErrorMsg = <div>YYYY/MM/DD形式で入力してください</div>;

  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDatePicker
        name={props.name}
        autoOk
        fullWidth={true}
        margin="dense"
        variant="inline"
        inputVariant="outlined"
        label={props.label}
        format="yyyy/MM/dd"
        value={props.value ? new Date(props.value) : null}
        InputAdornmentProps={{ position: 'end' }}
        onChange={handleChange}
        invalidDateMessage={dateErrorMsg}
      />
    </MuiPickersUtilsProvider>
  );
};

export default UIDatePicker;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import {
  ReportContainer,
  ReportMonthlyBodyHeatIndex,
  ReportMonthlyOperationStatus,
  ReportParamsTable,
  ReportSection,
  ReportSectionHeader,
  ReportSubHeaderWorksiteMonthly,
} from '../../components';
import ReportMonthlyAlertRanking from '../../components/report/ReportMonthlyAlertRanking';
import format from 'date-fns/format';
import { fetch, useAlert } from '../../functions';
import { ApiGetReportWorkSiteMonthly } from '../../types/report';
import ReportWorkSiteMonthlyEnqueteGraphList from '../../components/report/ReportWorkSiteMonthlyEnqueteGraphList';
import ReportWorkSiteMonthlyEnqueteList from '../../components/report/ReportWorkSiteMonthlyEnqueteList';
import { useCommonStyles } from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
  date: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorksiteMonthly = () => {
  const { id, date } = useParams<Params>();
  const [report, setReport] = useState<ApiGetReportWorkSiteMonthly>();
  const [isNodata, setIsNodata] = useState(false);
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();
  const classes = useCommonStyles();

  //  レポートのデータ取得
  useEffect(() => {
    (async () => {
      const displayDateTime = date.match(/^[0-9]{4}[0-9]{2}$/)
        ? format(
            new Date(Number(date.substr(0, 4)), Number(date.substr(4, 2)) - 1),
            'yyyy/MM/01 00:00:00',
          )
        : format(new Date(), 'yyyy/MM/01 00:00:00');

      const result = await fetch.get(`/work_site/${id}/monthly_report`, {
        displayDateTime,
      });
      if (result.status === 200) {
        setReport(result.data);
      } else if (result.status === 401) {
        alertWithCode(result.status);
        dispatch(push('/'));
      } else {
        setIsNodata(true);
      }
    })();
  }, [id, date, alertWithCode, dispatch]);
  return (
    <>
      <ReportContainer title="マンスリー現場レポート" isNodata={isNodata}>
        <ReportSubHeaderWorksiteMonthly
          timestamp={report?.timestamp ?? ''}
          alertValue={report?.alertValue ?? 0}
          alertName={report?.alertName ?? ''}
          projectName={report?.projectName ?? ''}
          organizationName={report?.organizationName ?? ''}
          workSiteName={report?.workSiteName ?? ''}
        />

        <ReportSection>
          <ReportSectionHeader
            title="カラダ暑さ指標最大値の平均推移結果"
            subTitle="※前期間と比較して､休憩のタイミングや時間の運用改善の参考にしてください。"
            flexGrow={1}
          />
          <ReportMonthlyBodyHeatIndex
            monthlyName="worksite"
            alertValue={report?.alertValue ?? 0}
            bodyHeatIndexMaxData={JSON.parse(
              report?.bodyHeatIndexMaxData ?? '[]',
            )}
            lastBodyHeatIndexMaxData={JSON.parse(
              report?.lastBodyHeatIndexMaxData ?? '[]',
            )}
            alertValueData={JSON.parse(report?.alertValueData ?? '[]')}
          />
          <ReportParamsTable
            left={[
              {
                name: 'WBGT 最大値',
                value: `${report?.wbgtMax}℃`,
              },
              {
                name: 'WBGT 平均値',
                value: `${Number(report?.wbgtAverage).toFixed(1)}℃`,
              },
              {
                name: 'センサーデバイス最高気温・湿度',
                value: `${report?.temperatureMax}℃ / ${report?.humidityMax}%`,
              },
              {
                name: 'センサーデバイス平均気温・湿度',
                value: `${Number(report?.temperatureAverage).toFixed(
                  1,
                )}℃ / ${Number(report?.humidityAverage).toFixed(1)}%`,
              },
            ]}
            right={[
              {
                name: '最大カラダ暑さ指標値',
                value: `${report?.bodyHeatIndexMax}`,
              },
              {
                name: '最大値の対設定値の最大割合',
                value: `${(Number(report?.bodyHeatIndexMaxRate) * 100).toFixed(
                  1,
                )}%`,
              },
              {
                name: '平均カラダ暑さ指標値',
                value: `${report?.bodyHeatIndexAverageCount}`,
              },
            ]}
          />
        </ReportSection>

        <ReportSection>
          <ReportSectionHeader title="eメット稼働状況" />
          <ReportMonthlyOperationStatus
            deviceUniqueCount={report?.deviceUniqueCount}
            deviceCount={report?.deviceCount}
            deviceSettingCount={report?.deviceSettingCount}
            workerUniqueCount={report?.workerUniqueCount}
            workerCount={report?.workerCount}
            workerSettingCount={report?.workerSettingCount}
          />
        </ReportSection>
        <ReportSection>
          <ReportSectionHeader title="閾値超え累計時間ランキング" />
          <ReportMonthlyAlertRanking
            rankingData={JSON.parse(report?.rankingData ?? '[]')}
          />
        </ReportSection>
        <div className={classes.containerPage}>1/3</div>
      </ReportContainer>

      <div className="print_page_break" />
      <ReportContainer title="マンスリー現場レポート" isNodata={isNodata}>
        <ReportSubHeaderWorksiteMonthly
          timestamp={report?.timestamp ?? ''}
          alertValue={report?.alertValue ?? 0}
          alertName={report?.alertName ?? ''}
          projectName={report?.projectName ?? ''}
          organizationName={report?.organizationName ?? ''}
          workSiteName={report?.workSiteName ?? ''}
        />
        <ReportSection>
          <ReportSectionHeader title="アンケート結果" />
          <ReportWorkSiteMonthlyEnqueteGraphList
            enqueteData={JSON.parse(report?.enqueteData ?? '[]')}
            timestamp={
              report?.timestamp ? new Date(report.timestamp) : new Date()
            }
          />
        </ReportSection>
        <div className={classes.containerPage}>2/3</div>
      </ReportContainer>

      <div className="print_page_break" />
      <ReportContainer title="マンスリー現場レポート" isNodata={isNodata}>
        <ReportSubHeaderWorksiteMonthly
          timestamp={report?.timestamp ?? ''}
          alertValue={report?.alertValue ?? 0}
          alertName={report?.alertName ?? ''}
          projectName={report?.projectName ?? ''}
          organizationName={report?.organizationName ?? ''}
          workSiteName={report?.workSiteName ?? ''}
        />
        <ReportSection>
          <ReportSectionHeader
            title="アンケート結果"
            subTitle="● 要注意回答　- 非該当回答"
          />
          <ReportWorkSiteMonthlyEnqueteList
            enqueteData={JSON.parse(report?.enqueteData ?? '[]')}
            timestamp={
              report?.timestamp ? new Date(report.timestamp) : new Date()
            }
          />
        </ReportSection>
        <div className={classes.containerPage}>3/3</div>
      </ReportContainer>
    </>
  );
};

export default WorksiteMonthly;

import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  title: string;
};

/**
 * 戻るボタン
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UIReturn: FC<Props> = (props: Props) => {
  const history = useHistory();

  return (
    <Button color="primary" onClick={() => history.goBack()} variant="outlined">
      {props.title}
    </Button>
  );
};

export default UIReturn;

import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useStyles from '../../assets/styles/Master.css';
import { SubHeader, WorkSiteCommit, WorkSiteEditForm } from '../../components';
import { fetch, yup, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { getOrganizations } from '../../redux/commonSlice';
import { ApiDefaultAlert } from '../../types/default_alert';
import FormFooter from '../../components/form/Footer';

type Params = {
  id: string;
};

type Group = {
  id: string;
  workSiteId: string;
  number: number | null;
  name: string;
  emails: string[];
  alertNumber: number | null;
  alertValue: number | null;
  isHidden: boolean;
};
type WorkSite = {
  id: string;
  name: string;
  organizationId: string;
  post: string;
  address: string;
  alertNumber: number | null;
  alertValue: number | null;
  groups: Group[];
};

type GroupEmails = {
  [key: string]: string[];
  group1: string[];
  group2: string[];
  group3: string[];
  group4: string[];
  group5: string[];
  group6: string[];
  group7: string[];
  group8: string[];
  group9: string[];
  group10: string[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorksiteCreate: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const dispatch = useDispatch();
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>([]);
  const [formEmails, setFormEmails] = useState<GroupEmails>({
    group1: [''],
    group2: [''],
    group3: [''],
    group4: [''],
    group5: [''],
    group6: [''],
    group7: [''],
    group8: [''],
    group9: [''],
    group10: [''],
  });
  const [workSites, setWorkSites] = useState<any>([{ name: '' }]);
  const [worksite, setWorksite] = useState<WorkSite>({
    id: '',
    name: '',
    organizationId: '',
    post: '',
    address: '',
    alertNumber: null as number | null,
    alertValue: null as number | null,
    groups: [],
  });

  const schema = yup.object({
    name: yup
      .string()
      .specialCharacter('現場名に機種依存文字が含まれています')
      .max(255, '現場名は255文字まで入力できます')
      .required('現場名を入力してください')
      .test('duplex-name', 'この現場名は登録されています', (value) =>
        duplexTest(value),
      ),
    post: yup
      .string()
      .matches(/^^[0-9]{7}$/, '郵便番号が正しくありません')
      .required('郵便番号は数字7桁（ハイフンはなし）で入力してください'),
    address: yup
      .string()
      .specialCharacter('住所に機種依存文字が含まれています')
      .max(255, '住所は255文字まで入力できます')
      .required('住所名を入力してください'),
    groups: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        emails: yup
          .array()
          .of(
            yup
              .string()
              .email('アラート時の通知先メールアドレスが不正です')
              .nullable(),
          ),
        alertNumber: yup.number().nullable(),
        alertValue: yup
          .number()
          .nullable()
          .when('alertNumber', {
            is: (value: any) => value === 0,
            then: yup
              .number()
              .min(100, 'アラート設定値は100以上を指定してください')
              .max(65000, 'アラート設定値は65000以下を指定してください')
              .required('アラート設定値を入力してください'),
          }),
      }),
    ),
  });

  const duplexTest = (name?: string) => {
    let test = true;
    workSites.forEach((ws: any) => {
      if (ws.name === name && worksite.name !== name) {
        test = false;
      }
    });
    return test;
  };

  //  フォームの定義
  const formik = useFormik({
    initialValues: worksite,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const result = await fetch.put(`/work_site/${id}`, values);
      if (result.status === 200) {
        const result2 = await fetch.get('/user/organizations');
        dispatch(getOrganizations(result2.data));
        changeMode('commit');
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    },
  });

  // グループを使用するボタン
  const handleAddGroup = (index: number) => {
    formik.setFieldValue(`groups.${index}.isHidden`, false);
  };

  // グループを使用しないボタン
  const handleDeleteGroup = (index: number) => {
    let hiddenCount = 0;
    formik.values.groups.forEach((group: Group) => {
      group.isHidden && hiddenCount++;
    });
    if (hiddenCount === 9) return;
    formik.setFieldValue(`groups.${index}.isHidden`, true);
  };

  // Email追加ボタン
  const handleAddEmail = (index: number) => {
    if (formEmails[`group${index + 1}`].length < 5) {
      const newEmails = formEmails[`group${index + 1}`].concat('');
      setFormEmails({ ...formEmails, [`group${index + 1}`]: newEmails });
      const newFormEmails = formik.values.groups[index].emails.concat('');
      formik.setFieldValue(`groups.${index}.emails`, newFormEmails);
    }
  };

  // Email削除ボタン
  const handleDeleteEmail = (index: number, mailIndex: number) => {
    if (formEmails[`group${index + 1}`].length > 0) {
      const deleteForms = formEmails[`group${index + 1}`].filter(
        (_, i) => i !== mailIndex,
      );
      let deleteEmails: string[] = [];
      formik.values.groups[index].emails.forEach((email: string, i: number) => {
        if (i !== mailIndex) {
          deleteEmails.push(email);
        }
      });
      setFormEmails({ ...formEmails, [`group${index + 1}`]: deleteForms });
      formik.setFieldValue(`groups.${index}.emails`, deleteEmails);
    }
  };

  //  データの取得
  //  --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/work_site/${id}`);

      if (result.status === 200) {
        setWorksite(result.data);
        if ('groups' in result.data) {
          const groupEmails: GroupEmails = {
            group1: [],
            group2: [],
            group3: [],
            group4: [],
            group5: [],
            group6: [],
            group7: [],
            group8: [],
            group9: [],
            group10: [],
          };
          result.data.groups.forEach((group: Group, index: number) => {
            groupEmails[`group${index + 1}`] = group.emails;
          });
          setFormEmails(groupEmails);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      } else {
        alert(result.status);
      }

      //
      const result2 = await fetch.get(
        `/default_alert_v2?organizationId=${result.data.organizationId}`,
      );
      if (result2.status === 200) {
        setDefaltAlerts([
          ...result2.data,
          { number: 0, name: 'カスタム', value: 100 },
        ]);
      } else if (result2.status === 500) {
        alertWithCode(result2.status);
      }
      const workSite = await fetch.get(
        `/organization/${result.data.organizationId}/work_sites`,
      );
      if (workSite.status === 200) {
        setWorkSites(workSite.data);
      } else if (workSite.status === 500) {
        alertWithCode(workSite.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={worksite?.name}
        subTitle={`現場編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${worksite?.id}`}
      />

      <div className={classes.root}>
        {mode === 'editing' && (
          <WorkSiteEditForm
            {...formik}
            default_alerts={default_alerts}
            onAddGroup={handleAddGroup}
            onAddEmail={(index: number) => handleAddEmail(index)}
            onDeleteEmail={(index: number, mailIndex: number) =>
              handleDeleteEmail(index, mailIndex)
            }
            onDeleteGroup={(index: number) => handleDeleteGroup(index)}
            formEmails={formEmails}
          />
        )}
        {mode === 'commit' && (
          <WorkSiteCommit
            message={`現場を更新しました。\n現場一覧ページから確認してください。`}
            linkToList={`/organization/${worksite.organizationId}/workSites`}
            linkToDashboard={`/dashboard/workSite/${id}`}
          />
        )}
      </div>
      {mode === 'editing' && (
        <FormFooter word="確定" onSubmit={formik.handleSubmit} />
      )}
    </>
  );
};

export default withFormStatus(WorksiteCreate);

import { EnqueteModel } from '../../types/enquete';
import useStyles from '../../assets/styles/Enquete.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  model: EnqueteModel;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Choices5 = (props: Props) => {
  const { model } = props;
  const classes = useStyles();

  return (
    <table className={classes.choices5}>
      <tbody>
        <tr>
          <td className={classes.choices5Comment}>{model.leftComment}</td>
          <td></td>
          <td></td>
          <td></td>
          <td className={classes.choices5Comment}>{model.rightComment}</td>
        </tr>

        <tr>
          <td className={classes.choices5Value}>1</td>
          <td className={classes.choices5Value}>2</td>
          <td className={classes.choices5Value}>3</td>
          <td className={classes.choices5Value}>4</td>
          <td className={classes.choices5Value}>5</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Choices5;

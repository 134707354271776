import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import VideocamIcon from '@material-ui/icons/Videocam';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useStyles from '../../../assets/styles/Sub.css';
import {
  Answer,
  Category,
  CategoryTitle,
  QandA,
  Question,
  QuestionsList,
} from '../../../components';
import qaData from './qaData.json';

type Expanded = { category?: string; q?: string };
type Q = { q: string; a: string; video: string };

const AppHelp: FC = () => {
  const classes = useStyles();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramCategory = params.get('category') ?? undefined;
  const paramQ = params.get('q') ?? undefined;
  const [expanded, setExpanded] = useState<Expanded>({});

  useEffect(() => {
    setExpanded({ category: paramCategory, q: paramQ });
  }, [paramCategory, paramQ]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.appHelpTitle}>
          <span>よくあるご質問</span>
        </div>
        {qaData.map((category, ci) => (
          <Category key={ci} expanded={expanded} setExpanded={setExpanded}>
            <CategoryTitle>{category.title}</CategoryTitle>
            <QuestionsList>
              {category.questions.map((question: Q, qi) => (
                <QandA key={`${ci}_${qi}`} expanded={expanded} setExpanded={setExpanded}>
                  <Question>
                    <span>Q:</span> {question.q}
                    {question.video && <VideocamIcon />}
                  </Question>
                  <Answer>
                    <span>A:</span>
                    <pre>{question.a}</pre>
                    {question.video && (
                      <a target="_blank" rel="noreferrer" href={question.video}>
                        <span>動画を見る</span> <PlayCircleFilledIcon />
                      </a>
                    )}
                  </Answer>
                </QandA>
              ))}
            </QuestionsList>
          </Category>
        ))}
      </div>
    </>
  );
};

export default AppHelp;

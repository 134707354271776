import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: '38px 52px',
    },

    header: {
      fontWeight: 'normal',
      fontSize: '20px',
      color: '#666',
    },

    section: {
      margin: '36px auto 24px',
      padding: '0',
    },

    sectionCaption: {
      padding: '0 20px',
    },

    sectionIcon: {
      '& svg': {
        verticalAlign: 'bottom',
        fontSize: '38px',
        color: '#F29600',
      },
    },

    sectionTitle: {
      marginLeft: '23px',
      fontSize: '20px',
      color: '#666',
    },

    panels: {
      padding: '4px 0',
    },

    panel: {
      width: '250px',
      padding: '16px',
      boxSizing: 'content-box',
    },

    block: {
      display: 'block',
      minHeight: '3em',
      lineHeight: '23px',
      padding: '11px 13px',
      boxSizing: 'content-box',
      backgroundColor: '#fff',
      borderRadius: '6px',
      textDecoration: 'none',
      fontSize: '14px',
      color: '#646464',
      transition: '0.3s',
      filter: 'drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.08))',
      '&:hover': {
        filter: 'drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.20))',
      },
      '&>div:first-child': {
        color: '#999',
        fontSize: '12px',
      },
      '&>div:last-child': {
        fontSize: '16px',
      },
    },
  }),
  { index: 1 },
);
export default useStyles;

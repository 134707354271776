import { fade, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) => ({
    table: {
      minWidth: 600,
      border: '.5px solid rgba(0, 0, 0, .08)',
      width: 'auto',
      tableLayout: 'auto',
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    head: {
      backgroundColor: '#458E95',
      color: theme.palette.common.white,
      whiteSpace: 'nowrap',
    },
    iconCell: {
      width: '3rem',
      '& > span': {
        width: '20rem',
      },
    },
    iconCellDeny: {
      opacity: 0.4,
    },
    tableCell: {
      whiteSpace: 'nowrap',
      color: '#666666',

      maxWidth: '500px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    cursor: {
      cursor: 'pointer',
    },
    delete: {
      color: '#FF0000',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 1),
      marginLeft: 0,
      width: '20rem',
      border: '1px solid rgba(194, 218, 220, 1)',
      marginBottom: '1rem',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      transition: theme.transitions.create('width'),
      width: '100%',
    },
  }),
  { index: 1 },
);
export default useStyles;

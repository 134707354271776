import { ReactNode } from 'react';
import '../../assets/print.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
};

/**
 *
 *
 *
 * @method LayoutComponents
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PrintLayout = (props: Props) => {
  return <div>{props.children}</div>;
};

export default PrintLayout;

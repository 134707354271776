/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  x: number | string;
  y: number | string;
  text: string;
  textUnit?: string;
  textAnchor?: 'start' | 'middle' | 'end' | 'inherit';
  dominantBaseline?: 'auto' | 'central';
  fontSize: number;
  color: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const FreeText = (props: Props) => {
  const {
    text,
    textUnit = '',
    x,
    y,
    textAnchor = 'start',
    dominantBaseline = 'central',
    color,
    fontSize,
  } = props;

  return (
    <text
      x={x}
      y={y}
      textAnchor={textAnchor}
      dominantBaseline={dominantBaseline}
      style={{ color, fontSize }}
    >
      <tspan style={{ fontWeight: 'bold' }}>{text}</tspan>
      {textUnit !== '' && (
        <tspan style={{ fontSize: fontSize / 2 }} dominantBaseline="hanging">
          {textUnit}
        </tspan>
      )}
    </text>
  );
};

export default FreeText;

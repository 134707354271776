import { Box } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { useEnqueteDailyStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  answer: string | number;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const EnqueteAnswerResultC2 = (props: Props) => {
  const { answer } = props;
  const classes = useEnqueteDailyStyles();

  return (
    <Box
      className={classes.answerResult}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <div className={classes.answerResultItem}>
          {answer === 1 && <CircleIcon />}
        </div>
      </Box>

      {[...Array(3)].map((val, index) => (
        <Box key={index}>
          <div
            className={clsx(
              classes.answerResultItem,
              classes.answerResultItemMini,
            )}
          >
            <CircleIcon />
          </div>
        </Box>
      ))}

      <Box>
        <div className={classes.answerResultItem}>
          {answer === 5 && <CircleIcon />}
        </div>
      </Box>
    </Box>
  );
};

export default EnqueteAnswerResultC2;

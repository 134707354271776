import { FC } from 'react';
import useStyles from '../../assets/styles/Master.css';
import { PrimaryOutlinedButton, SubHeader } from '../../components';

const PasswordCommit: FC = () => {
  const classes = useStyles();

  return (
    <>
      <SubHeader title="パスワード変更(完了)" link="/my" />
      <div className={classes.root}>
        <p>パスワードを更新しました。</p>
        <div className="module-spacer--small" />

        <PrimaryOutlinedButton
          label="マイページへ"
          linkTo={`/my`}
        />
      </div>
    </>
  );
};

export default PasswordCommit;

import { Rectangle } from 'recharts';
import { ChartsFreeText } from '../../../components';

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Legend = () => {
  return (
    <>
      <Rectangle x={0} y={2} width={8} height={4} fill="#FE104B" />
      <Rectangle x={40} y={3} width={8} height={2} fill="#686868" />
      <Rectangle x={90} y={3} width={3} height={1} fill="#7A7A7A" />
      <Rectangle x={96} y={3} width={3} height={1} fill="#7A7A7A" />
      <ChartsFreeText
        x={20}
        y={4}
        text="今日"
        textAnchor="middle"
        fontSize={8}
        color="#7A7A7A"
      />
      <ChartsFreeText
        x={66}
        y={4}
        text="昨日"
        textAnchor="middle"
        fontSize={8}
        color="#7A7A7A"
      />
      <ChartsFreeText
        x={115}
        y={4}
        text="設定値"
        textAnchor="middle"
        fontSize={8}
        color="#7A7A7A"
      />
    </>
  );
};

export default Legend;

import _ from 'lodash';
import { useMemo } from 'react';
import { Box } from '@material-ui/core';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import clsx from 'clsx';
import { getCalendar } from '../../../functions';
import { ReportWorkderMonthlyEnqueteDataCalendars } from '../../../types/report';
import { useEnqueteMonthlyStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: ReportWorkderMonthlyEnqueteDataCalendars[];
  timestamp: Date;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyEnqueteCalendar = (props: Props) => {
  const { data, timestamp } = props;
  const classes = useEnqueteMonthlyStyles();
  const calendars = useMemo(() => getCalendar(timestamp), [timestamp]);
  const thisMonth = getMonth(timestamp);

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}></Box>
        <Box className={classes.calendarCaption}>
          <span className={classes.calendarMiniFont}>{thisMonth + 1}月</span>
        </Box>
      </Box>
      <table className={classes.calendar}>
        <thead className={classes.calendarThead}>
          <tr>
            <th>
              <span className={classes.calendarMiniFont}>日</span>
            </th>
            <th>
              <span className={classes.calendarMiniFont}>月</span>
            </th>
            <th>
              <span className={classes.calendarMiniFont}>火</span>
            </th>
            <th>
              <span className={classes.calendarMiniFont}>水</span>
            </th>
            <th>
              <span className={classes.calendarMiniFont}>木</span>
            </th>
            <th>
              <span className={classes.calendarMiniFont}>金</span>
            </th>
            <th>
              <span className={classes.calendarMiniFont}>土</span>
            </th>
          </tr>
        </thead>

        <tbody className={classes.calendarTbody}>
          {calendars.map((week, weekid) => (
            <tr key={weekid}>
              {week.map((day, dayid) => {
                const _day = getDate(day);
                const _month = getMonth(day);
                const answer = _.find(data, {
                  timestamp: format(day, 'yyyy/MM/dd HH:mm:ss'),
                });
                const isWarning = answer !== undefined && answer.answer >= 4;
                const isAnswered =
                  answer !== undefined && answer.answer === null;

                return (
                  <td
                    className={clsx(
                      thisMonth !== _month && classes.calendarTbodyEmpty,
                      isWarning && classes.calendarTbodyWarning,
                      isAnswered && classes.calendarTbodyNoAnswered,
                    )}
                    key={dayid}
                  >
                    <span className={classes.calendarMiniFont}>{_day}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ReportMonthlyEnqueteCalendar;

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
 const UsersIcon = () => {
    return <img src="/images/help/users.svg" alt="ユーザー" />;
  };
  
  export default UsersIcon;
import EnqueteAnswerResultC2 from './EnqueteAnswerResultC2';
import EnqueteAnswerResultC5 from './EnqueteAnswerResultC5';
import { ReportWorkderDailyEnqueteData } from '../../../types/report';
import { useEnqueteDailyStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  enqueteData: ReportWorkderDailyEnqueteData[];
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportDailyEnqueteTable = (props: Props) => {
  const classes = useEnqueteDailyStyles();

  return (
    <table className={classes.table}>
      {props.enqueteData.map((version, vi: number) => (
        <tbody key={vi}>
          {version.data.map((data) => (
            <tr className={classes.tableTr} key={data.number}>
              <td className={classes.tableNumber}>{data.number}</td>
              <td className={classes.tableQuestion}>
                {data.enqueteForm.title}
              </td>
              <td className={classes.tableAnswerLeft}>
                {data.enqueteForm.leftComment}
              </td>
              <td className={classes.tableAnswerResult}>
                {data.enqueteForm.type === 'select_two_string' ? (
                  <EnqueteAnswerResultC2 answer={data.answer} />
                ) : (
                  <EnqueteAnswerResultC5 answer={data.answer} />
                )}
              </td>
              <td className={classes.tableAnswerRight}>
                {data.enqueteForm.rightComment}
              </td>
            </tr>
          ))}
        </tbody>
      ))}
    </table>
  );
};

export default ReportDailyEnqueteTable;

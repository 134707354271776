import { makeStyles } from '@material-ui/core/styles';

/**
 * 共通部分
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useCommonStyles = makeStyles(
  (theme) => ({
    container: {
      margin: '0',
      display: 'flex',
      flexFlow: 'column',
      minHeight: 'calc(100vh - (44px + 64px + 62px))',
    },

    //  ヘッダ
    header: {},
    headerImage: {
      '& img': {
        width: '32px',
        height: 'auto',
        verticalAlign: 'bottom',
        borderRadius: '6px',
      },
    },
    headerTitle: {
      padding: '0 0 0 8px',
      fontSize: '20px',
    },
    headerDate: {
      fontSize: '8px',
    },

    //  セクション
    section: {
      margin: '16px 0 16px',
    },
    sectionHeader: {
      padding: '10px 0',
      margin: '0',
      fontWeight: 'normal',
      fontSize: '16px',
    },
    sectionHeaderSub: {
      marginLeft: '20px',
      fontWeight: 'normal',
      fontSize: '10px',
      color: '#000',
    },

    // ページ表示
    containerPage: {
      position: 'absolute',
      width: 'calc(100% - 32px)',
      textAlign: 'center',
    }
  }),
  { index: 1 },
);

/**
 * レポート選択画面
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useSelectStyles = makeStyles(
  (theme) => ({
    section: {
      marginBottom: '64px',
    },

    //  タイトル
    title: {
      fontWeight: 'normal',
      fontSize: '26px',
      color: '#707070',
    },

    //  ボタン
    button: {
      cursor: 'pointer',
      backgroundColor: '#fff',
      borderRadius: '10px',
      color: '#707070',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
      transition: '0.3s',
    },
    buttonActive: {
      backgroundColor: '#D3EAED',
    },
    buttonRotate: {
      transform: 'rotate(180deg)',
    },
    buttonIcon: {
      padding: '24px',
      transition: '0.3s',

      '& img': {
        width: '32px',
        height: 'auto',
        verticalAlign: 'bottom',
      },
    },
    buttonText: {
      padding: '24px 24px 24px 0',
    },
    buttonTitle: {
      fontSize: '22px',
    },
    buttonSubtitle: {
      fontSize: '12px',
    },

    //  送信ボタン
    submit: {
      padding: '100px 0 0 0',
      textAlign: 'center',
    },
    submitButton: {
      cursor: 'pointer',
      padding: '16px 32px',
      textAlign: 'center',
      fontSize: '14px',
      borderRadius: '8px',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
      backgroundColor: '#499197',
      color: '#fff',
      transition: '0.3s',

      '&:hover': {
        opacity: '0.90',
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16))',
      },
    },

    //  ヘルプボタン
    help: {
      margin: '0 24px 24px 0',
      textAlign: 'right',
    },
    helpButton: {
      cursor: 'pointer',
      padding: '12px 24px 12px 12px',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: '14px',
      borderRadius: '30px',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
      backgroundColor: '#499197',
      color: '#fff',
      transition: '0.3s',

      '& img': {
        margin: '0 6px 0 0',
        verticalAlign: 'bottom',
      },

      '&:hover': {
        opacity: '0.90',
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16))',
      },
    },

    //  検索ボックス
    search: {
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: '30px',
      marginLeft: 0,
      width: '20rem',
      border: '1px solid rgba(194, 218, 220, 1)',
    },
    searchIcon: {
      padding: '0 2px 0 12px',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchInputRoot: {
      color: 'inherit',
    },
    searchInputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      transition: theme.transitions.create('width'),
      width: '100%',
    },

    //  作業者一覧
    workers: {},
    workersHead: {
      textAlign: 'center',
      backgroundColor: '#499197',
      color: '#fff',
    },
    workersBodyRow: {
      backgroundColor: '#fff',

      '&:td': {
        borderBottom: 'none',
      },
      '&:nth-child(even)': {
        backgroundColor: '#F9F9F9',
      },
    },
    workersCell: {
      textAlign: 'center',
    },
    workersButton: {
      cursor: 'pointer',
      padding: '6px 8px',
      textAlign: 'center',
      fontSize: '12px',
      borderRadius: '30px',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
      backgroundColor: '#499197',
      color: '#fff',
      transition: '0.3s',
      whiteSpace: 'nowrap',

      '&:hover': {
        opacity: '0.90',
        filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16))',
      },
    },
  }),
  { index: 1 },
);

/**
 * サブヘッダー
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useSubheaderStyles = makeStyles(
  (theme) => ({
    //  全体
    container: {
      margin: '16px 0',
    },
    cellLarge: {
      fontSize: '12px',
    },
    cellSmall: {
      fontSize: '8px',
    },
    cellJoin: {
      padding: '0 8px',
      fontSize: '8px',
    },
    cellImage: {
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        width: '8px',
        height: 'auto',
        verticalAlign: 'text-bottom',
      },
    },
  }),
  { index: 1 },
);

/**
 * パラメータ表示部分
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useParamsStyles = makeStyles(
  (theme) => ({
    //  全体
    container: {
      width: '95%',
      margin: '10px auto',
    },
    table: {
      width: '100%',
    },
  }),
  { index: 1 },
);

/**
 * パラメータ表示部分
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useLineGraphStyles = makeStyles(
  (theme) => ({
    container: {
      width: '100%',
    },
  }),
  { index: 1 },
);

/**
 * アンケートテーブル
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useEnqueteDailyStyles = makeStyles(
  (theme) => ({
    //  全体
    table: {
      width: '100%',
      margin: '10px auto',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      pageBreakInside: 'auto',
    },
    tableTr: {
      fontSize: '12px',
      pageBreakInside: 'avoid',
      pageBreakAfter: 'auto',

      '& td': {
        height: '3.75em;',
        paddingTop: '4px',
        paddingBottom: '4px',
      },

      '&:nth-child(even)': {
        backgroundColor: '#E9E8E8',
      },
    },
    tableNumber: {
      paddingLeft: '4px',
      paddingRight: '4px',
      textAlign: 'center',
    },
    tableQuestion: {},
    tableAnswerLeft: {
      textAlign: 'right',
      width: '10%',
    },
    tableAnswerRight: {
      textAlign: 'left',
      width: '10%',
    },

    //  結果表示
    tableAnswerResult: {
      position: 'relative',
      width: '280px',
      paddingLeft: '4px',
      paddingRight: '4px',

      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '10px',
        right: '10px',
        height: '1px',
        margin: 'auto',
        content: '""',
        backgroundImage:
          'linear-gradient(to right, #000, #000 1px, transparent 1px, transparent 3px)',
        backgroundSize: '3px 1px',
        backgroundRepeat: 'repeat-x',
      },
    },
    answerResult: {
      position: 'relative',
      textAlign: 'center',
      zIndex: 100,
    },
    answerResultItem: {
      position: 'relative',
      width: '18px',
      height: '18px',
      border: '1px solid #707070',
      borderRadius: '50%',
      backgroundColor: '#fff',

      '& svg': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',

        fontSize: '16px',
        verticalAlign: 'bottom',
        color: '#707070',
      },
    },
    answerResultItemMini: {
      border: 'none',
      backgroundColor: 'unset',

      '& svg': {
        fontSize: '8px',
        borderRadius: '50%',
        backgroundColor: '#707070',
        color: '#ccc',
      },
    },
  }),
  { index: 1 },
);

/**
 * アンケートリスト表示
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useEnqueteMonthlyStyles = makeStyles(
  (theme) => ({
    container: {},

    title: {
      margin: '0 0 8px',
      fontSize: '11px',
      fontWeight: 'normal',
    },

    block: {
      width: '100%',
    },
    blockColumn: {
      width: '50%',
    },
    blockPie: {
      width: '50%',
    },

    //  グラフ
    pie: {
      margin: '0 auto',
    },

    //  カレンダー
    calendar: {
      width: '100%',
      textAlign: 'center',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      fontSize: '10px',
    },
    calendarMiniFont: {
      display: 'block',
      transform: 'scale(0.6)',
    },
    calendarCaption: {
      textAlign: 'right',
      color: '#707070',
    },
    calendarThead: {
      '& th': {
        borderTop: '1px solid #eee',
        borderBottom: '1px solid #eee',
        color: '#707070',
        lineHeight: 1,
      },
    },
    calendarTbody: {
      '& td': {
        padding: '0px 2px',
      },
    },
    calendarTbodyWarning: {
      backgroundColor: '#686868',
      color: '#fff',
    },
    calendarTbodyNoAnswered: {
      background:
        'linear-gradient(-30deg,transparent 49%,#ddd 49%,#ddd 51%,transparent 51%,transparent)',
    },
    calendarTbodyEmpty: {
      color: '#fff',
    },
  }),
  { index: 1 },
);

/**
 * アンケートリスト表示
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useEnqueteMonthlyPieStyles = makeStyles(
  (theme) => ({
    container: {},

    title: {
      margin: '0 0 8px',
      fontSize: '11px',
      fontWeight: 'normal',
    },

    block: {
      width: '100%',
    },
    blockColumn: {
      width: '35%',
    },
    columnC2: {
      display: 'flex',
      margin: '3px 0',
      '& div': {
        marginLeft: '8px',
      },

      '&:first-child': {
        marginBottom: '20px',
      },
    },
    columnC5: {
      '& div': {
        margin: '6px 0',
      },
    },
    columnC5Column: {
      margin: '6px 6px 6px 8px',
    },
    columnC5ColumnArrow: {
      '& img': {
        width: 'auto',
        height: '70px',
        margin: '0 0 0 0',
        padding: '6px',
        boxSizing: 'border-box',
        verticalAlign: 'bottom',
      },
    },
    columnC5ColumnArrowUp: {
      position: 'absolute',
      color: '#707070',
      left: '4px',
      top: '0px',
      zIndex: 1,
    },
    columnC5ColumnArrowDown: {
      position: 'absolute',
      color: '#707070',
      left: '4px',
      bottom: '0px',
      zIndex: 1,
    },
    answerColorBox: {
      width: '8px',
      height: '16px',
      backgroundColor: '#E9E8E8',
    },
    answerColorBoxError: {
      width: '8px',
      height: '16px',
      backgroundColor: '#5C5B5B',
    },
    blockPie: {
      width: '65%',
    },

    //  グラフ
    pie: {},
  }),
  { index: 1 },
);

/**
 * アンケートリスト表示
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useNodataStyles = makeStyles(
  (theme) => ({
    container: {
      padding: '120px 0',
      textAlign: 'center',
      fontSize: '30px',
    },
  }),
  { index: 1 },
);

/**
 * eメット稼働状況表示
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useOperationStatusMonthlyStyles = makeStyles(
  (theme) => ({
    container: {},
    operationStatus: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px',
    },
    operationStatusImage: {
      width: '50px',
      height: '30px',
      '& img': {
        height: '30px',
      },
    },
    operationStatusTitle: {
      width: '100px',
      height: '30px',
      lineHeight: '30px',
    },
    operationStatusCount: {
      width: '100px',
      height: '30px',
      '& span': {
        fontSize: '30px',
      },
    },
  }),
  { index: 1 },
);

/**
 * eメット稼働状況表示
 *
 *
 * @method Style
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const useAlertRankingMonthlyStyles = makeStyles(
  (theme) => ({
    container: {
      borderTop: 'solid 1px #D6D6D6',
    },
    table: {
      width: '100%',
      margin: '2px auto',
      borderCollapse: 'collapse',
      borderSpacing: 0,
    },
    tableThead: {
      '& th': {
        padding: '4px',
        fontWeight: 'normal',
      },
    },
    tableTr: {
      fontSize: '12px',

      '& td': {
        padding: '5px',
        textAlign: 'center',
      },

      '&:nth-child(odd)': {
        backgroundColor: '#E9E8E8',
      },
    },
  }),
  { index: 1 },
);

export const useReportWorkSiteMonthlyEnqueteListStyles = makeStyles(
  (theme) => ({
    container: {},
    containerInside: {
      pageBreakInside: 'avoid',
    },
    title: {
      margin: '8px 0',
      fontSize: '11px',
      fontWeight: 'normal',
    },
    table: {
      width: '100%',
      margin: '2px auto',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      marginBottom: '24px',
    },
    tableTh: {
      fontWeight: 'normal',
      fontSize: '10px',
    },
    tableThDay: {
      fontWeight: 'normal',
      fontSize: '10px',
      textAlign: 'center',

      '& span': {
        display: 'inline-block',
        width: '1.6em',
        transform: 'scale(0.7)',
      },
    },
    tableDay0: {
      border: '1px solid #ccc',
    },
    tableTr: {
      fontSize: '10px',

      '& td': {
        padding: '6px 1px',
        textAlign: 'center',
        color: '#000',

        '& span': {
          display: 'inline-block',
          fontSize: '10px',
          transform: 'scale(0.9)',
        },
      },

      '&:nth-child(odd)': {
        backgroundColor: '#E9E8E8',
      },
    },
  }),
  { index: 1 },
);

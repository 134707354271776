import _ from 'lodash';
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import format from 'date-fns/format';
import {
  SubHeader,
  NotificationCommit,
  NotificationEditConfirm,
  NotificationEditForm,
} from '../../components';
import { fetch, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import useStyles from '../../assets/styles/Master.css';
import { ApiGetNotification } from '../../types/notifications';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
  nid: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  from: yup.string().required('投稿者名を入力してください'),
  title: yup.string().required('タイトルを入力してください'),
  description: yup.string().required('本文を入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const NotificationsEdit: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id, nid } = useParams<Params>();
  const [notice, setNotice] = useState<ApiGetNotification>();
  const [organization, setOrganization] = useState<any>();

  //  フォームの定義
  const formik = useFormik({
    initialValues: notice ?? {
      postDate: new Date().toString(),
      title: '',
      description: '',
      from: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.put(`/organization/${id}/notification`, {
          id: nid,
          postDate: format(new Date(values.postDate), 'yyyy/MM/dd 00:00:00'),
          title: values.title,
          description: values.description,
          from: values.from,
        });

        if (result.status === 200) {
          changeMode('commit');
        }
      }
    },
  });

  //  データの取得
  //  --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}/notifications`);

      if (result.status === 200) {
        const _notice = _.find(result.data, { id: Number(nid) });

        if (_notice !== undefined) {
          setNotice(_notice);
        } else {
          alertWithCode(404);
        }
      } else {
        alertWithCode(result.status);
      }
    })();
  }, [id, nid, alertWithCode]);

  //サブヘッダー用の組織情報を取得。
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}`);
      if (result.status === 200) {
        setOrganization(result.data);
      }
    })();
  }, [id]);

  return (
    <>
      <SubHeader
        title={organization?.name}
        subTitle={`お知らせ編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${id}`}
      />

      <div className={classes.root}>
        {mode === 'editing' && <NotificationEditForm {...formik} />}
        {mode === 'confirm' && <NotificationEditConfirm {...formik} />}
        {mode === 'commit' && (
          <NotificationCommit
            message={`お知らせ内容を変更しました。\nお知らせ一覧ページから確認してください。`}
            link={`/organization/${id}/notifications`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(NotificationsEdit);

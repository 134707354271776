import format from 'date-fns/format';
import ja from 'date-fns/locale/ja';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from '../../assets/styles/Master.css';
import {
  SubHeader,
  WorkerCommit,
  WorkerEditConfirm,
  WorkerEditForm,
} from '../../components';
import { fetch, yup, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { ApiDefaultAlert } from '../../types/default_alert';
import FormFooter from '../../components/form/Footer';

type Params = {
  id: string;
  workerId: string;
};
/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  loginName: yup
    .string()
    .max(255, '装着者ログイン名は255文字まで入力できます')
    .matches(/^(?!９９９９$).*$/, {
      message: '装着者ログイン名に９９９９は使用できません',
    })
    .required('装着者ログイン名を入力してください')
    .test(
      'regex',
      '装着者ログイン名は全角カタカナと全角数字のみ入力できます',
      (value) => !!/^[ァ-ヴー０-９]+$/.test(value ?? ''),
    ),
  firstname: yup
    .string()
    .specialCharacter('ユーザー名に機種依存文字が含まれています')
    .max(255, 'ユーザー名は255文字まで入力できます')
    .required('ユーザー名を入力してください'),
  lastname: yup
    .string()
    .specialCharacter('ユーザー名（サブ）に機種依存文字が含まれています')
    .max(255, 'ユーザー名（サブ）は255文字まで入力できます'),
  firstnameKana: yup
    .string()
    .max(255, 'ユーザー名　フリガナは255文字まで入力できます')
    .matches(/^[ァ-ヴー]*$/, {
      message: 'ユーザー名　フリガナは全角カタカナのみ使用できます',
    }),
  lastnameKana: yup
    .string()
    .max(255, 'ユーザー名　フリガナ（サブ）は255文字まで入力できます')
    .matches(/^[ァ-ヴー]*$/, {
      message: 'ユーザー名　フリガナ（サブ）は全角カタカナのみ使用できます',
    }),
  sex: yup.string().required('性別を選択してください'),
  birthday: yup.string().nullable(),
  phone: yup.string().max(255, '電話番号は255文字まで入力できます'),
  email: yup
    .string()
    .max(255, 'メールアドレスは255文字まで入力できます')
    .email('メールアドレスが不正です'),
  post: yup
    .string()
    .matches(
      /^^[0-9]{7}$/,
      '郵便番号は数字7桁（ハイフンはなし）で入力してください',
    )
    .required('郵便番号を入力してください'),
  address: yup
    .string()
    .specialCharacter('管理用住所に機種依存文字が含まれています')
    .max(255, '管理用住所は255文字まで入力できます'),
  affiliation: yup
    .string()
    .specialCharacter('所属に機種依存文字が含まれています')
    .max(255, '所属は255文字まで入力できます')
    .required('所属を入力してください'),

  controlNumber: yup.string().max(255, '管理番号は255文字まで入力できます'),
  groupNumber: yup.number().min(1).max(10),
  alertNumber: yup.number().nullable(),
  alertValue: yup
    .number()
    .nullable()
    .when('alertNumber', {
      is: (value: any) => value === 0,
      then: yup
        .number()
        .min(100, 'アラート設定値は100以上を指定してください')
        .max(65000, 'アラート設定値は65000以下を指定してください')
        .required('アラート設定値を入力してください'),
    }),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkerEdit: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const { alertWithCode } = useAlert();
  const classes = useStyles();
  const { id, workerId } = useParams<Params>();
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>([]);
  const [workSite, setWorkSite] = useState({
    name: '',
    alertNumber: null as number | null,
    alertValue: null as number | null,
    groups: [],
  });
  const [worker, setWorker] = useState<any>({
    firstname: '',
    lastname: '',
    firstnameKana: '',
    lastnameKana: '',
    sex: 'male',
    birthday: new Date().toString(),
    phone: '',
    email: '',
    post: '',
    address: '',
    labels: [] as string[],
    loginName: '',
    groupNumber: '1',
    controlNumber: '',
    affiliation: '',
    alertNumber: null as number | null,
    alertValue: null as number | null,
  });

  //  フォームの定義
  const formik = useFormik({
    initialValues: worker,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const result = await fetch.put(`/worker/${workerId}`, {
        ...values,
        workSiteId: id,
        birthday: values.birthday
          ? format(new Date(values.birthday), 'yyyy/MM/dd 00:00:00', {
              locale: ja,
            })
          : '1900/01/01 00:00:00',
      });
      if (result.status === 200) {
        changeMode('commit');
      } else {
        alertWithCode(result.status);
      }
    },
  });

  //  初期値取得
  useEffect(() => {
    (async () => {
      //  装着者取得
      const result = await fetch.get(`/worker/${workerId}`);
      if (result.status === 200) {
        const labelData: string[] = [];
        result.data.labels.forEach((label: any) => {
          labelData.push(label.name);
        });
        setWorker({ ...result.data, labels: labelData });
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }

      //  現場情報の取得
      const workSite = await fetch.get(`/work_site/${id}`);
      if (workSite.status === 200) {
        setWorkSite({
          name: workSite.data.name,
          alertNumber: workSite.data.alertNumber,
          alertValue: workSite.data.alertValue,
          groups: workSite.data.groups,
        });

        //  現場情報を元にデフォルトアラートを取得
        const daResult = await fetch.get(
          `/default_alert_v2?organizationId=${workSite.data.organizationId}`,
        );
        if (daResult.status === 200) {
          setDefaltAlerts([
            ...daResult.data,
            { number: 0, name: 'カスタム', value: 100 },
          ]);
        } else if (daResult.status === 500) {
          alertWithCode(daResult.status);
        }
      } else if (workSite.status === 500) {
        alertWithCode(workSite.status);
      }
    })();
  }, [id, workerId, alertWithCode]);

  return (
    <>
      <SubHeader
        title={workSite && workSite.name}
        subTitle={`装着者編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/workSite/${id}/workers`}
      />

      <div className={classes.workerEditRoot}>
        {mode === 'editing' && (
          <WorkerEditForm
            {...formik}
            default_alerts={default_alerts}
            workSite={workSite}
          />
        )}
        {mode === 'confirm' && (
          <WorkerEditConfirm
            {...formik}
            default_alerts={default_alerts}
            workSite={workSite}
          />
        )}
        {mode === 'commit' && (
          <WorkerCommit
            message={`装着者を更新しました。\n装着者一覧ページから確認してください。`}
            link={`/workSite/${id}/workers`}
          />
        )}
      </div>
      {mode === 'editing' && (
        <FormFooter
          isBackGroundColor={true}
          word="確定"
          onSubmit={formik.handleSubmit}
        />
      )}
    </>
  );
};

export default withFormStatus(WorkerEdit);

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { useSelectStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface Props {
  value: string;
  onChange: (e: any) => void;
  onKeyDown: (e: any) => void;
}

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const SearchBar = (props: Props) => {
  const classes = useSelectStyles();
  const { value, onChange, onKeyDown } = props;
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="キーワードを入力してください"
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInputInput,
        }}
        style={{ paddingLeft: 'calc(1em + 32px)' }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default SearchBar;

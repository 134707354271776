import { FC } from 'react';
import { TableCell } from '@material-ui/core';
import clsx from 'clsx';
import { confirmStyles } from '../../../assets/styles/CsvUpload.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  origin?: any;
  newdate?: any;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const StatusCell: FC<Props> = (props: Props) => {
  const confirmClasses = confirmStyles();

  return (
    <TableCell
      className={clsx(
        confirmClasses.status,
        props.newdate === undefined && confirmClasses.statusDanger,
      )}
    >
      <span>
        {props.newdate === undefined && '削除'}
        {props.origin === undefined && '追加'}
      </span>
    </TableCell>
  );
};

export default StatusCell;

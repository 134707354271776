import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#458E95',
      dark: '#666666',
    },
    secondary: {
      main: '#FFF',
    },
  },
  mixins: {
    toolbar: {
      minHeight: '44px',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: '#666666',
      },
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    MuiToolbar: {
      gutters: {
        height: '44px',
        '@media (min-width: 600px)': {
          paddingLeft: '14px',
          paddingRight: '8px',
        },
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
      },
      button: {
        height: '48px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '14px',
        fontWight: 'none',
      },
    },
    MuiTableCell: {
      root: {
        padding: '10px',
      },
    },
    MuiAvatar: {
      root: {
        width: '30px',
        height: '30px',
      },
    },
    MuiPopover: {
      paper: {
        overflowY: 'hidden',
      },
    },
    MuiPaper: {
      elevation8: {
        border: '.5px solid rgba(0, 0, 0, 0.1)',
        boxShadow: 'none',
      },
      elevation1: {
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiAccordion: {
      root: {
        '&$expanded': {
          margin: 0,
        },
        '&:before':{
          backgroundColor:'none'
        }
      },
    },
    MuiAccordionSummary: {
      root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        height: 'fit-content',
        '&$expanded': {
          height: 'fit-content',
          minHeight: 'none',
        },
        '&:hover': {
          backgroundColor: '#E4EEEF',
        },
      },
      content: {
        transition: 'none',
        overflowX: 'auto',
        alignItems: 'top',
        '&>*': { color: '#458E95' },
      },
      expandIcon: {
        color: '#458E95',
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      },
    },
    MuiSnackbarContent:{
      root:{
        backgroundColor: '#FF0000'
      }
    },
    MuiDialogTitle:{
      root:{
        backgroundColor: '#458E95',
        '&>*':{color: '#FFF'},
        marginBottom: '1rem'
      }
    }
  },
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Authority = {
  id: string;
  userId: string;
  organizationId: string;
  type: string;
};

type User = {
  id: string;
  name: string;
  email: string;
  password: string;
  belongingName: string;
  isAdmin: boolean;
  authorities: Authority[];
};

type UpdateUser = {
  name: string;
  belongingName: string;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {} as User,
  reducers: {
    getUser: (state, action: PayloadAction<User>) => (state = action.payload),
    updateUser: (state, { payload }: PayloadAction<UpdateUser>) => {
      state.name = payload.name;
      state.belongingName = payload.belongingName;
    },
  },
});

export const { getUser, updateUser } = userSlice.actions;
export default userSlice.reducer;

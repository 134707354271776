import _ from 'lodash';
import Papa from 'papaparse';
import iconv from 'iconv-lite';
import * as chardet from 'chardet';
import * as yup from 'yup';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  /** CSV ファイルオブジェクト */
  file: File;

  /** CSV 区切り文字 */
  delimiter: string;

  /** CSV のヘッダー情報 */
  csvHeader: string[];

  /** CSV データに適応するバリデーションスキーマ */
  validSchema: yup.AnyObjectSchema;
};
type Error = {
  key: string;
  message: string;
};
type ParseResult = {
  data: any[];
  error: Error[];
};

/**
 *
 *
 *
 * @method Function
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const csvParser = async (params: Params): Promise<ParseResult> => {
  const { file, csvHeader, validSchema, delimiter } = params;
  let data: any[] = [];
  let error: Error[] = [];

  await new Promise((resolve, reject) => {
    //  ファイルの読み込みオブジェクトを生成
    var fileReader = new FileReader();

    // 読み込み完了時のイベント
    fileReader.onload = function () {
      //  文字コードを判別して変換する
      const buffer = fileReader?.result! as ArrayBuffer;
      const analyse = chardet.analyse(new Uint8Array(buffer));
      const encode = _.filter(analyse, (o) => {
        return o.name === 'Shift_JIS' || o.name === 'UTF-8';
      });
      const decodedString = iconv.decode(
        Buffer.from(buffer),
        (encode.length > 0 ? encode[0].name : null)!.toString(),
      );

      //  CSV をパースする
      Papa.parse(decodedString, {
        delimiter,
        header: true,
        complete: (results) => {
          //  CSV のヘッダ情報からファイル整合性を調べる
          if (!_.isEqual(csvHeader, results.meta.fields)) {
            error.push({
              key: 'csv_file_error',
              message: 'CSVのファイル構造を確認してください',
            });
          } else {
            //  各行ごとのバリデーションを実行
            results.data.forEach((d: any, index) => {
              //  要素すうが合わない場合は無視する
              if (Object.keys(d).length === csvHeader.length) {
                let blank = 0;
                for (const key in d) {
                  if (d.hasOwnProperty(key)) {
                    if (d[key] === '') {
                      blank++;
                    } else {
                      // 前後のスペースを削除
                      d[key] = d[key].trim();
                    }
                  }
                }
                // 全項目が空(空行)の場合は無視する
                if (blank !== csvHeader.length) {
                  try {
                    if (csvHeader.length === 1) {
                      data.forEach((item, i) => {
                        if (item.センサーデバイス名 === d.センサーデバイス名) {
                          error.push({
                            key: `field_error_${index}`,
                            message: `${i + 2}列、${
                              index + 2
                            }列: センサーデバイス番号が重複しています。`,
                          });
                        }
                      });
                    }
                    data.push(d);
                    validSchema.validateSync(d);
                  } catch (err) {
                    error.push({
                      key: `field_error_${index}`,
                      message: `${index + 2}行目: ${err.errors}`,
                    });
                  }
                }
              }
            });
          }
          resolve(results);
        },
        error: (e) => {
          error.push({
            key: 'csv_parse_error',
            message: 'CSVのファイル形式が不正です',
          });
          resolve(e);
        },
      });
    };

    // 読み込みを実行
    fileReader.readAsArrayBuffer(file);
  });

  return { data, error };
};

import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
} from '@material-ui/core';
import StatusCell from '../common/UploadConfirmTable/StatusCell';
import ValueCell from '../common/UploadConfirmTable/ValueCell';
import { UISubmit, UIReturn } from '../index';
import { fetch, useAlert } from '../../functions';
import { ApiGetDevice } from '../../types/device';
import useStyles from '../../assets/styles/List.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};
type FormValues = {
  file: any;
};
type Props = FormikProps<FormValues> & {
  data: { センサーデバイス名: string }[];
};
type CsvRow = {
  origin?: any;
  newdata?: any;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DeviceConfirm: FC<Props> = (props) => {
  const { handleSubmit, data } = props;
  const [devices, setDevices] = useState<CsvRow[]>([]);
  const { id } = useParams<Params>();
  const classes = useStyles();
  const { alertWithCode } = useAlert();

  // 現在のデバイス情報と、CSVのデバイス情報を結合させる
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/work_site/${id}/devices`);
      if (result.status === 200) {
        let row: CsvRow[] = [];
        const inputDeviceNameList = data.map(
          (device) => device['センサーデバイス名'],
        );
        const resultDeviceNameList = result.data.map(
          (device: ApiGetDevice) => device.name,
        );

        // 追加
        inputDeviceNameList.forEach((inputDevice: string) => {
          const changeTarget = resultDeviceNameList.filter(
            (resultDevice: string) => inputDevice === resultDevice,
          );
          if (changeTarget.length === 0) {
            row.push({
              origin: undefined,
              newdata: { センサーデバイス名: inputDevice },
            });
          }
        });

        // 変更
        inputDeviceNameList.forEach((inputDevice: string) => {
          const changeTarget = resultDeviceNameList.filter(
            (resultDevice: string) => inputDevice === resultDevice,
          );
          if (changeTarget.length > 0) {
            row.push({
              origin: { name: inputDevice },
              newdata: { センサーデバイス名: inputDevice },
            });
          }
        });

        //  削除
        resultDeviceNameList.forEach((resultDevice: string) => {
          const changeTarget = inputDeviceNameList.filter(
            (inputDevice: string) => inputDevice === resultDevice,
          );
          if (changeTarget.length === 0) {
            row.push({
              origin: { name: resultDevice },
              newdata: undefined,
            });
          }
        });

        setDevices(row);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, data, alertWithCode]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Table className={classes.table}>
        <TableHead className={classes.row}>
          <TableRow>
            <TableCell className={classes.head}></TableCell>
            <TableCell className={classes.head}>センサーデバイス名</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {devices.map((d: CsvRow, index) => (
            <TableRow key={index}>
              <StatusCell origin={d.origin} newdate={d.newdata} />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="name"
                newdataKey="センサーデバイス名"
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確定" />
      </FormGroup>
    </form>
  );
};

export default DeviceConfirm;

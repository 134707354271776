import { EnqueteModel } from '../../types/enquete';
import useStyles from '../../assets/styles/Enquete.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  model: EnqueteModel;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Choices2 = (props: Props) => {
  const { model } = props;
  const classes = useStyles();

  return (
    <>
      <span className={classes.blockCommentText}>{model.leftComment}</span>
      <span className={classes.blockCommentText}>{model.rightComment}</span>
    </>
  );
};

export default Choices2;

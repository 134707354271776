import { Box, Grid } from '@material-ui/core';
import ReportMonthlyEnquetePie from './EnquetePie';
import ReportMonthlyEnqueteCalendar from './EnqueteCalendar';
import { ReportWorkderMonthlyEnqueteData } from '../../../types/report';
import { useEnqueteMonthlyStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  enqueteData: ReportWorkderMonthlyEnqueteData[];
  timestamp: Date;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyEnquete = (props: Props) => {
  const { enqueteData, timestamp } = props;
  const classes = useEnqueteMonthlyStyles();

  return (
    <Grid className={classes.container} container spacing={1}>
      {enqueteData.map((enquete, index) => (
        <Grid item xs={6} key={index}>
          <h4 className={classes.title}>
            {index + 1}. {enquete.enqueteForm.title}
          </h4>

          <Box className={classes.block} display="flex" alignItems="center">
            <Box className={classes.blockPie}>
              <ReportMonthlyEnquetePie
                data={enquete.enqueteGraphs}
                detail={enquete.enqueteForm}
              />
            </Box>
            <Box flexGrow={1}>
              <ReportMonthlyEnqueteCalendar
                data={enquete.enqueteCalendars}
                timestamp={timestamp}
              />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportMonthlyEnquete;

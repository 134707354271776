import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useSelectStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  active: boolean;
  title: string;
  subtitle: string;
  handleClick: () => void;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const SelectorModeButton = (props: Props) => {
  const { title, subtitle, active, handleClick } = props;
  const classes = useSelectStyles();

  return (
    <Box
      className={clsx(classes.button, active && classes.buttonActive)}
      display="flex"
      alignItems="center"
      onClick={() => handleClick()}
    >
      <Box className={classes.buttonIcon}>
        {active ? (
          <img src="/images/report/check.svg" alt={title} />
        ) : (
          <img src="/images/report/check-blank.svg" alt={title} />
        )}
      </Box>
      <Box className={classes.buttonText}>
        <div className={classes.buttonTitle}>{title}</div>
        <div className={classes.buttonSubtitle}>{subtitle}</div>
      </Box>
    </Box>
  );
};

export default SelectorModeButton;

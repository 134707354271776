import * as yup from 'yup';

//  省略文字
export const specialChar1 = [
  '№',
  '㏍',
  '℡',
  '㊤',
  '㊥',
  '㊦',
  '㊧',
  '㊨',
  '㈱',
  '㈲',
  '㈹',
  '㍾',
  '㍽',
  '㍼',
  '㍻',
];

//  単位
export const specialChar2 = [
  '㍉',
  '㎜',
  '㎝',
  '㎞',
  '㎎',
  '㎏',
  '㏄',
  '㍉',
  '㌔',
  '㌢',
  '㍍㌘',
  '㌧',
  '㌃',
  '㌶',
  '㍑',
  '㍗',
  '㌍',
  '㌦',
  '㌣',
  '㌫',
  '㍊',
  '㌻',
];

//  丸数字等
export const specialChar3 = [
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
  '⑩',
  '⑪',
  '⑫',
  '⑬',
  '⑭',
  '⑮',
  '⑯',
  '⑰',
  '⑱',
  '⑲',
  '⑳',
];

//  ローマ数字
export const specialChar4 = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ', 'Ⅵ', 'Ⅶ', 'Ⅷ', 'Ⅸ', 'Ⅹ'];

/**
 *
 *
 *
 * @method TypesExtends
 * @version 1.0.0
 * ------------------------------------------------------------------------ */
declare module 'yup' {
  interface StringSchema {
    specialCharacter(message?: string): StringSchema;
  }
}

/**
 *
 *
 *
 * @method AddMethod
 * @version 1.0.0
 * ------------------------------------------------------------------------ */
yup.addMethod(yup.string, 'specialCharacter', function (message?: string) {
  const sc =
    specialChar1.join('|') +
    '|' +
    specialChar2.join('|') +
    '|' +
    specialChar3.join('|') +
    '|' +
    specialChar4.join('|');

  return this.matches(new RegExp(`^(?!.*(${sc})).*`), {
    message: message ?? '機種依存文字が含まれています',
    excludeEmptyString: true,
  });
});

export default yup;

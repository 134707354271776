import { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FormModeCore } from '../types/forms';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export type FormStatusProps = {
  mode: FormModeCore;
  changeMode: (m: FormModeCore) => void;
};
type Params = {
  status: string;
};
type Props = {};

/**
 *
 *
 *
 * @method higherOrderComponents
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const withFormStatus = (WrappedComponent: any) => {
  return (props: Props) => {
    const { status } = useParams<Params>();
    const [mode, setMode] = useState<FormModeCore>('editing');
    const history = useHistory();
    const location = useLocation();

    //  モードを変更
    const changeMode = (m: FormModeCore) => {
      setMode(m);

      //  URL を書き換え
      if (m === 'confirm') {
        history.push(location.pathname + '/confirm');
      } else if (m === 'commit') {
        history.push(location.pathname.replace(/\/confirm$/g, '/commit'));
      } else {
        history.goBack();
      }
    };

    //  パスを変更
    useEffect(() => {
      if (status === undefined) {
        setMode('editing');
      }
    }, [status]);

    return (
      <WrappedComponent
        {...props}
        {...{
          mode,
          changeMode,
        }}
      />
    );
  };
};

export default withFormStatus;

import { Route } from 'react-router-dom';

/**
 *
 *
 *
 * @method Router
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PublicRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...rest} {...props} />
        </Layout>
      )}
    />
  );
};
export default PublicRoute;

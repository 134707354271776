import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import format from 'date-fns/format';
import { csvDownload } from '../../functions/CsvDownload';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import { CsvWorkerHeader } from '../../constants/csv';
import { ApiGetWorker } from '../../types/worker';
import useStyles from '../../assets/styles/Header.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};
type Props = {
  worksiteName: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CsvDownloadButton = (props: Props) => {
  const { id } = useParams<Params>();
  const classes = useStyles();
  const { alertWithCode } = useAlert();

  //  ダウンロードさせる
  const handleClick = async () => {
    const result = await fetch.get(`/work_site/${id}/workers`);
    if (result.status === 200) {
      const workers = result.data.map((worker: ApiGetWorker) => {
        return [
          worker.loginName,
          worker.groupNumber,
          worker.controlNumber,
          worker.firstname,
          worker.lastname,
          worker.firstnameKana,
          worker.lastnameKana,
          format(new Date(worker.birthday), 'yyyy/MM/dd'),
          worker.sex,
          worker.email,
          worker.phone,
          worker.post,
          worker.address,
          worker.affiliation,
          worker.alertNumber,
          worker.alertValue ?? '',
          worker.labels
            .filter((l) => l.id <= 9)
            .map((l) => l.name)
            .join(' '),
        ];
      });

      csvDownload({
        filename: `${props.worksiteName}-装着者一覧`,
        header: CsvWorkerHeader,
        body: workers,
      });
    } else if (result.status === 500) {
      alertWithCode(result.status);
    }
  };

  return (
    <span className={classes.subHeaderRightMenu} onClick={() => handleClick()}>
      <GetAppIcon />
      装着者一覧ダウンロード
    </span>
  );
};

export default CsvDownloadButton;

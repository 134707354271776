import { FC } from 'react';
import { Box } from '@material-ui/core';
import fire1 from '../../../../assets/images/fire1.svg';
import fire2 from '../../../../assets/images/fire2.svg';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  name: string;
  company: string;
  time: number;
};

/**
 * ラベル表示
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkerStatus: FC<Props> = (props: Props) => {
  const { name, company, time } = props;
  const classes = commonStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <div className={classes.fire}>
          {time >= 60 ? (
              <>
                <img src={fire1} alt={`${time}分経過`} />
                <div className={classes.fireStatus}>
                  <div className={classes.fireStatusTime}>
                    {time > 999 ? 999 : time}
                  </div>
                  <div className={classes.fireStatusText}>分経過</div>
                </div>
              </>
          ) : (
              <>
                  <img src={fire2} alt={`${time}分経過`} />
                  <div className={classes.fireStatus}>
                      <div className={classes.fireStatusAlertText}>アラート</div>
                  </div>
              </>

          )}
        </div>
      </Box>

      <Box flexGrow={1}>
        <div className={classes.worker}>
          <div className={classes.workerCompany}>{company}</div>
          <div className={classes.workerName}>{name}</div>
        </div>
      </Box>
    </Box>
  );
};

export default WorkerStatus;

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { push } from 'connected-react-router';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UIDialog, UILogoutDialog, UIUnlockDialog } from '..';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import useStyles from '../../assets/styles/List.css';
import ActionCell from './ActionCell';
import SearchBar from './SearchBar';
import TableHead from './TableHead';
/**
 *
 *
 *
 * @method Type
 * @version 1.0.0
 * -------------------------------------------------------------------------- */

export type Row = {
  id: string;
  cells: (string | number | null)[];
  name: string;
  authorityId?: string;
  isLocked?: boolean;
};

type TableBodyCell = {
  id: string;
  cells: (string | number | null)[];
  name: string;
  authorityId?: string;
  isLocked?: boolean;
};

type Props = {
  tableHeadCells: string[];
  tableBodyCells: TableBodyCell[];
  dashboard?: string;
  edit?: string;
  allow?: boolean;
  deleteItem?: (id: string) => Promise<void> | void;
  logoutItem?: (id: string) => Promise<void> | void;
  unlockItem?: (id: string) => Promise<void> | void;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const List: FC<Props> = (props) => {
  const {
    tableHeadCells,
    tableBodyCells,
    dashboard,
    edit,
    allow = true,
    deleteItem,
    logoutItem,
    unlockItem,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  //列(アイテム)削除
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState<boolean>(false);
  const [isUnlockOpen, setIsUnlockOpen] = useState<boolean>(false);
  const [remove, setRemove] = useState({ id: '', name: '', loginName: '' });
  const [logout, setLogout] = useState({ id: '', name: '' });
  const [unlock, setUnlock] = useState({ id: '', name: '' });
  const deleteRow = (row: any) => {
    setIsOpen(true);
    setRemove({ id: row.authorityId ?? row.id, name: row.name, loginName: row.cells[1] });
  };
  const logoutRow = (row: any) => {
    setIsLogoutOpen(true);
    setLogout({ id: row.id, name: row.name });
  };
  const unlockRow = (row: any) => {
    setIsUnlockOpen(true);
    setUnlock({ id: row.id, name: row.name });
  };

  //フィルター
  const [search, setSearch] = useState('');
  const [filteredCells, setFilteredCells] = useState<TableBodyCell[]>();
  useEffect(() => {
    setFilteredCells(tableBodyCells);
  }, [tableBodyCells]);

  const setTable = (e: any) => {
    if (e.type === 'keydown' && (e as React.KeyboardEvent).key === 'Enter') {
      if (search.length > 0) {
        setFilteredCells(
          tableBodyCells.filter((row) => row.name.includes(search)),
        );
      } else {
        setFilteredCells(tableBodyCells);
      }
    }
  };

  const handleEdit = (id: string) => dispatch(push(`${edit}/${id}/edit`));
  return (
    <>
      <div style={{ padding: '2rem' }}>
        <SearchBar
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={setTable}
        />

        <Table className={classes.table}>
          <TableHead
            editable={Boolean(edit)}
            logout={logoutItem !== undefined}
            unlock={unlockItem !== undefined}
            deletable={Boolean(deleteItem)}
          >
            {tableHeadCells.map((cell: string, index: number) => (
              <TableCell className={classes.head} key={index}>
                {cell}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {filteredCells?.map((row) => (
              <TableRow key={row.id} className={classes.row}>
                {edit && (
                  <ActionCell
                    title="編集"
                    onClick={() => handleEdit(row.id)}
                    allow={allow}
                  >
                    <CreateIcon />
                  </ActionCell>
                )}
                {logoutItem !== undefined && (
                  <ActionCell
                    title="強制ログアウト"
                    onClick={() => logoutRow(row)}
                    allow={allow}
                  >
                    <ExitToAppIcon />
                  </ActionCell>
                )}
                {deleteItem && (
                  <ActionCell
                    title="削除"
                    onClick={() => deleteRow(row)}
                    allow={allow}
                  >
                    <DeleteIcon />
                  </ActionCell>
                )}
                {unlockItem !== undefined && (
                  <>
                    {row.isLocked ?? false ? (
                      <ActionCell
                        title="ロックの解除"
                        onClick={() => unlockRow(row)}
                        allow={allow}
                      >
                        <LockIcon color="error"/>
                      </ActionCell>
                    ) : (
                      <TableCell>
                        <IconButton disabled>
                          <LockOpenIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </>
                )}

                {row.cells.map((cell, index) => (
                  <TableCell
                    scope="row"
                    className={`${classes.tableCell} ${
                      dashboard && classes.cursor
                    }`}
                    onClick={() =>
                      dashboard && dispatch(push(`${dashboard}/${row.id}`))
                    }
                    key={index}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <UIDialog
          dialogOpen={isOpen}
          handleOpen={() => setIsOpen(!isOpen)}
          deleteItem={deleteItem}
          deleteId={remove.id}
        >
          <>
            {logoutItem ? (
              <span className={`${classes.delete} shorten_text`}>
                ログイン名：{remove.loginName}<br/>
                ユーザー名：{remove.name}
              </span>
            ) : (
              <span className={`${classes.delete} shorten_text`}>
                {remove.name}
              </span>
            )}
            を削除します。
            <br />
            {/* 作業者一覧の時のみ表示 */}
            {logoutItem && (
              <span>削除すると同じログイン名が使用できなくなります。</span>
            )}
            　
          </>
        </UIDialog>

        <UILogoutDialog
          dialogOpen={isLogoutOpen}
          handleOpen={() => setIsLogoutOpen(!isLogoutOpen)}
          logoutItem={logoutItem}
          logoutId={logout.id}
        >
          <span className={`${classes.delete} shorten_text`}>
            {logout.name}
          </span>
          をログアウトします。
        </UILogoutDialog>

        <UIUnlockDialog
          dialogOpen={isUnlockOpen}
          handleOpen={() => setIsUnlockOpen(!isUnlockOpen)}
          unlockItem={unlockItem}
          unlockId={unlock.id}
        >
          <span className={`${classes.delete} shorten_text`}>
            {unlock.name}
          </span>
          のロックを解除します。
        </UIUnlockDialog>
      </div>
    </>
  );
};

export default List;

import { FC, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { Location, LocationDescriptor } from "history";
import Button from "@material-ui/core/Button";

interface Props {
  label: string;
  linkTo?:
    | LocationDescriptor<unknown>
    | ((location: Location<unknown>) => LocationDescriptor<unknown>);
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PrimaryOutlinedButton: FC<Props> = (props) => {
  return (
    <>
      {props.linkTo ? (
        <Button
          color="primary"
          component={Link}
          to={props.linkTo}
          variant="outlined"
        >
          {props.label}
        </Button>
      ) : (
        <Button color="primary" onClick={props.onClick} variant="outlined">
          {props.label}
        </Button>
      )}
    </>
  );
};

export default PrimaryOutlinedButton;

import { FC } from 'react';
import { Select, FormControl, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from '../../assets/styles/Uikit.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  name: string;
  value: number | string | null;
  error?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  handleChange: any;
  menus: Menu[];
  empty?: string;
  isDisabled?: boolean;
};
type Menu = {
  name: string;
  value: number | string;
};

/**
 * セレクトボックス
 *
 *
 * @method Components
 * @version 1.0.0
 * @todo ラベルの位置を改良すべし
 * -------------------------------------------------------------------------- */
const UISelect: FC<Props> = (props: Props) => {
  const isError = !!props.error;
  const disabled = props.isDisabled ?? false;
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel shrink className={classes.selectLabel}>
        {props.label}
      </InputLabel>
      <Select
        MenuProps={{ classes: { paper: classes.selectMenu } }}
        name={props.name}
        value={props.value ?? ''}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        required={props.required ?? false}
        color="primary"
        margin="dense"
        variant="outlined"
        error={isError}
        fullWidth
        displayEmpty={props.empty !== undefined}
        style={{ marginTop: '2px' }}
        disabled={disabled}
      >
        {props.empty && <MenuItem value="">{props.empty}</MenuItem>}
        {props.menus.map((state: any, index: number) => (
          <MenuItem
            key={index}
            value={state.value}
            style={{ color: '#666666' }}
          >
            {state.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UISelect;

import format from 'date-fns/format';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  filename: string;
  header: string[];
  body: any[];
};

/**
 *
 *
 *
 * @method Function
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const csvDownload = async (params: Params) => {
  const csvData: string[] = [];
  csvData.push('"' + params.header.join('","') + '"');

  //  Body の作成
  params.body.forEach((row) => {
    csvData.push('"' + row.join('","') + '"');
  });

  // BLOB生成してダウンロード実行 (UTF-8 BOM付き)
  let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvData.join('\r\n')], {
    type: 'text/csv',
  });
  const url = URL.createObjectURL(blob);

  //  リンクオブジェクトの作成
  const link = document.createElement('a');
  link.href = url;
  link.download = `${params.filename}-${format(
    new Date(),
    'yyyyMMdd-HHmmss',
  )}.csv`;

  //  ダウンロードさせる
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

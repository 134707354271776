import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import {
  SubHeader,
  EnqueteForm,
  EnqueteConfirm,
  EnqueteCommit,
} from '../../components';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { fetch, useAlert } from '../../functions';
import { EnqueteModel } from '../../types/enquete';
import useStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

export const EnqueteOnOff = {
  ON: 0,
  OFF: 1,
} as const;
export type OnOff = (typeof EnqueteOnOff)[keyof typeof EnqueteOnOff];

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const EnqueteEdit = (props: FormStatusProps) => {
  const { mode, changeMode } = props;
  const { id } = useParams<Params>();
  const classes = useStyles();
  const [enquete, setEnquete] = useState<any>();
  const [enqueteForm, setEnqueteForm] = useState<any>([]);
  const [organization, setOrganization] = useState<any>();
  const [enqueteCount, setEnqueteCount] = useState<4 | 8>(8);
  const { alertWithCode } = useAlert();

  //  フォームの定義
  const formik = useFormik({
    initialValues: enquete,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (values.isHidden === EnqueteOnOff.ON && enqueteCount === 8) {
        const item: any[] = Object.values(values).slice(1, 9);
        if (item.includes(0)) {
          actions.setFieldError('enqueteFormId1', `未選択の項目が存在します`);
          return;
        }
        if (new Set<number>(item).size !== 8) {
          actions.setFieldError('enqueteFormId1', `同じ項目が設定されています`);
          return;
        }
      } else if (values.isHidden === EnqueteOnOff.ON && enqueteCount === 4) {
        const item: any[] = Object.values(values).slice(1, 5);
        if (item.includes(0)) {
          actions.setFieldError('enqueteFormId1', `未選択の項目が存在します`);
          return;
        }
        if (new Set<number>(item).size !== 4) {
          actions.setFieldError('enqueteFormId1', `同じ項目が設定されています`);
          return;
        }
      }
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.put(`/organization/${id}/enquete`, {
          isHidden: values.isHidden === EnqueteOnOff.ON ? false : true,
          enqueteFormId1:
            values.isHidden === EnqueteOnOff.OFF ? 0 : values.enqueteFormId1,
          enqueteFormId2:
            values.isHidden === EnqueteOnOff.OFF ? 0 : values.enqueteFormId2,
          enqueteFormId3:
            values.isHidden === EnqueteOnOff.OFF ? 0 : values.enqueteFormId3,
          enqueteFormId4:
            values.isHidden === EnqueteOnOff.OFF ? 0 : values.enqueteFormId4,
          enqueteFormId5:
            values.isHidden === EnqueteOnOff.OFF
              ? 0
              : enqueteCount === 4
              ? 0
              : values.enqueteFormId5,
          enqueteFormId6:
            values.isHidden === EnqueteOnOff.OFF
              ? 0
              : enqueteCount === 4
              ? 0
              : values.enqueteFormId6,
          enqueteFormId7:
            values.isHidden === EnqueteOnOff.OFF
              ? 0
              : enqueteCount === 4
              ? 0
              : values.enqueteFormId7,
          enqueteFormId8:
            values.isHidden === EnqueteOnOff.OFF
              ? 0
              : enqueteCount === 4
              ? 0
              : values.enqueteFormId8,
        });

        if (result.status === 200) {
          changeMode('commit');
        }
      }
    },
  });

  const handleChangeEnqueteCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) === 4 || Number(e.target.value) === 8) {
      setEnqueteCount(Number(e.target.value) as 4 | 8);
    }
  };

  //  ページの初期化
  useEffect(() => {
    (async () => {
      //  アンケート情報の取得
      const result = await fetch.get(`/organization/${id}/enquete`);
      if (result.status === 200) {
        setEnquete({
          isHidden: result.data.isHidden ? EnqueteOnOff.OFF : EnqueteOnOff.ON,
          enqueteFormId1: result.data.enqueteFormId1,
          enqueteFormId2: result.data.enqueteFormId2,
          enqueteFormId3: result.data.enqueteFormId3,
          enqueteFormId4: result.data.enqueteFormId4,
          enqueteFormId5: result.data.enqueteFormId5,
          enqueteFormId6: result.data.enqueteFormId6,
          enqueteFormId7: result.data.enqueteFormId7,
          enqueteFormId8: result.data.enqueteFormId8,
        });
        if (
          result.data.enqueteFormId5 === 0 &&
          result.data.enqueteFormId6 === 0 &&
          result.data.enqueteFormId7 === 0 &&
          result.data.enqueteFormId8 === 0
        ) {
          setEnqueteCount(4);
        }
      } else {
        alertWithCode(result.status);
      }

      //  アンケート設定の取得
      const resultEnquete = await fetch.get(`/enquete_forms`);
      if (resultEnquete.status === 200) {
        const _enqueteForm = resultEnquete.data.map((enq: EnqueteModel) => {
          if (enq.id === 0) {
            return {
              name: 'アンケートを選択してください',
              value: enq.id,
            };
          }
          const typename = enq.type === 'select_two_string' ? '2択' : '5択';
          return {
            name: `No.${enq.id} ${enq.title} (${typename})`,
            value: enq.id,
          };
        });
        setEnqueteForm(_enqueteForm);
      } else {
        alertWithCode(resultEnquete.status);
      }

      //サブヘッダー用の組織情報取得
      const resultOrg = await fetch.get(`/organization/${id}`);
      if (resultOrg.status === 200) {
        setOrganization(resultOrg.data);
      } else {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title="アンケート設定の編集"
        subTitle={organization?.name}
        link={`/organization/${id}/enquete`}
      />

      <div className={classes.root}>
        {mode === 'editing' && (
          <EnqueteForm
            {...formik}
            enqueteForm={enqueteForm}
            enqueteCount={enqueteCount}
            handleChangeEnqueteCount={handleChangeEnqueteCount}
          />
        )}
        {mode === 'confirm' && (
          <EnqueteConfirm
            {...formik}
            enqueteForm={enqueteForm}
            enqueteCount={enqueteCount}
          />
        )}
        {mode === 'commit' && (
          <EnqueteCommit
            message={`アンケート設定を変更しました。\nアンケート設定ページから確認してください。`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(EnqueteEdit);

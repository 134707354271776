/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const AlertReferenceLabel = (props: any) => {
  return (
    <g>
      <text
        x={props.viewBox.x - 5}
        y={props.viewBox.y}
        textAnchor="end"
        dominantBaseline="central"
        fill="#666"
        fontSize={8}
      >
        設定値
      </text>
      <text
        x={props.viewBox.x - 5}
        y={props.viewBox.y + 10}
        textAnchor="end"
        dominantBaseline="central"
        fill="#666"
        fontSize={8}
      >
        {props.value}
      </text>
    </g>
  );
};

export default AlertReferenceLabel;

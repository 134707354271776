import AddIcon from '@material-ui/icons/Add';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { List, SubHeader } from '../../components';
import { Row } from '../../components/list/List';
import { fetch, useAuth, useAlert } from '../../functions';
import { getOrganizations } from '../../redux/commonSlice';

type Params = {
  id: string;
};

const header = ['会社名・部署名', '会社名'];
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface Organization {
  id: string;
  name: string;
  parentId: string;
  companyId: string;
  projectId: string;
  companyName: string;
}
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationsList: FC = () => {
  const [data, setData] = useState<Row[]>([]);
  const [organizationName, setOrganizationName] = useState<string>('');
  const dispatch = useDispatch();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const { isAllowOrganization } = useAuth();
  const isAllow = isAllowOrganization(id);

  const subMenus = [
    {
      icon: <AddIcon />,
      name: '会社・部署追加',
      link: `/organization/${id}/organization/create`,
      allow: isAllow,
    },
  ];

  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}/organizations`);
      if (result.status === 200) {
        const data: Row[] = [];
        result.data.forEach(async (organization: Organization) => {
          data.push({
            id: organization.id,
            cells: [organization.name, organization.companyName],
            name: organization.name,
          });
        });
        setData(data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
      const organization = await fetch.get(`/organization/${id}`);
      organization.status === 200 &&
        setOrganizationName(organization.data.name);
      if (organization.status === 500) {
        alertWithCode(organization.status);
      }
    })();
  }, [setData, alertWithCode, id]);

  const removeOrganization = async (id: string) => {
    await fetch.delete(`/organization/${id}`);
    const result = await fetch.get('/user/organizations');
    dispatch(getOrganizations(result.data));
    setData(
      data.filter((row: Row) => {
        return row.id !== id;
      }),
    );
  };

  return (
    <div>
      <SubHeader
        title={organizationName}
        subTitle="会社・部署一覧"
        link={`/dashboard/organization/${id}`}
        menus={subMenus}
      />
      <List
        tableHeadCells={header}
        tableBodyCells={data}
        deleteItem={removeOrganization}
        dashboard="/dashboard/organization"
        edit="/organization"
        allow={isAllow}
      />
    </div>
  );
};

export default OrganizationsList;

import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import { PasswordEmailCommit, PasswordEmailForm } from '../../components';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import logo from '../../assets/images/logo.svg';

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  email: yup
    .string()
    .max(255, 'メールアドレスは255文字まで入力できます')
    .email('メールアドレスが不正です')
    .required('メールアドレスを入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PasswordResetEmail: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const [token, setToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        const result = await fetch.post('/login/password_reset?', {
          email: values.email,
        });

        if (result.status === 200) {
          setToken(result.data);
          setErrorMessage('');
          changeMode('commit');
        } else if (result.status === 404) {
          setErrorMessage(
            'このアドレスは登録されていません。正しいメールアドレスか確認してください。',
          );
        } else if(result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  return (
    <>
      <div className={classes.centerRoot}>
        <img src={logo} alt="eメットシステム" />
        <pre className={classes.errorMessage}>{errorMessage}</pre>
        {mode === 'editing' && <PasswordEmailForm {...formik} />}
        {mode === 'commit' && (
          <PasswordEmailCommit
            message="送信完了。送信先のメールをご確認ください。"
            link={`/signin`}
            token={token}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(PasswordResetEmail);

import { FC } from 'react';
import { Box } from '@material-ui/core';
import { worksiteStyles } from '../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  value: number;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Wbgt: FC<Props> = (props) => {
  const { value } = props;
  const wsClasses = worksiteStyles();

  return (
    <div className={wsClasses.block}>
      <h3 className={wsClasses.blockTitle}>WBGT近似値</h3>

      <Box className={wsClasses.wbgt} display="flex">
        <Box className={wsClasses.wbgtText}>平均</Box>
        <Box className={wsClasses.wbgtValue}>
          <b>{value ?? '-'}</b>&#8451;
        </Box>
      </Box>
    </div>
  );
};

export default Wbgt;

import { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Search from './Search';
import { ApiGetWorker } from '../../../types/worker';
import { useSelectStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  kind: string;
  type: string;
  date: string;
  workers: ApiGetWorker[];
  organizationName: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CreateReportWorker = (props: Props) => {
  const { kind, type, date, workers, organizationName } = props;
  const [filteredCells, setFilteredCells] = useState<ApiGetWorker[]>([]);
  const [search, setSearch] = useState('');
  const classes = useSelectStyles();

  //  レポートを作成する
  const createReport = (worker: number) => {
    window.open(`/reports/${kind}/${worker}/${type}/${date}`);
  };

  //  テーブルの調整
  const setTable = (e: any) => {
    if (e.type === 'keydown' && (e as React.KeyboardEvent).key === 'Enter') {
      if (search.length > 0) {
        setFilteredCells(
          workers.filter(
            (row) =>
              row.firstname.includes(search) ||
              row.lastname.includes(search) ||
              row.firstnameKana.includes(search) ||
              row.lastnameKana.includes(search),
          ),
        );
      } else {
        setFilteredCells(workers);
      }
    }
  };

  useEffect(() => {
    setFilteredCells(workers);
  }, [workers]);

  return (
    <section className={classes.section}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <h3 className={classes.title}>装着者を選択</h3>
        </Box>
        <Box>
          <Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={setTable}
          />
        </Box>
      </Box>

      <Table className={classes.workers}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.workersHead}></TableCell>
            <TableCell className={classes.workersHead}>ユーザー名</TableCell>
            <TableCell className={classes.workersHead}>
              ユーザー名（サブ）
            </TableCell>
            <TableCell className={classes.workersHead}>
              ユーザー名　フリガナ
            </TableCell>
            <TableCell className={classes.workersHead}>
              ユーザー名　フリガナ（サブ）
            </TableCell>
            <TableCell className={classes.workersHead}>所属</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredCells.map((worker: ApiGetWorker) => (
            <TableRow className={classes.workersBodyRow} key={worker.id}>
              <TableCell className={classes.workersCell}>
                <span
                  className={classes.workersButton}
                  onClick={() => createReport(worker.id)}
                >
                  レポートの作成
                </span>
              </TableCell>

              <TableCell className={classes.workersCell}>
                {worker.firstname}
              </TableCell>

              <TableCell className={classes.workersCell}>
                {worker.lastname}
              </TableCell>

              <TableCell className={classes.workersCell}>
                {worker.firstnameKana}
              </TableCell>

              <TableCell className={classes.workersCell}>
                {worker.lastnameKana}
              </TableCell>

              <TableCell className={classes.workersCell}>
                {organizationName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
};

export default CreateReportWorker;

import { FC } from 'react';
import { PrimaryOutlinedButton } from '..';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  label: string;
  link: string;
};
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserCommit: FC<Props> = (props: Props) => {
  const { message, label, link } = props;

  return (
    <>
        <p>{message}</p>

        <div className="module-spacer--small" />
        <PrimaryOutlinedButton label={label} linkTo={link} />
    </>
  );
};

export default UserCommit;

import { useState, Dispatch, SetStateAction } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';
import { Box, Popover } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import clsx from 'clsx';
import { useSelectStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Class
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: any) {
    return format(date, 'yyyy MMM', { locale: this.locale });
  }
  getDatePickerHeaderText(date: any) {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  type: 'daily' | 'monthly';
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const SelectorFormButton = (props: Props) => {
  const { type, date, setDate } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useSelectStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className={classes.button}
        display="flex"
        alignItems="center"
        onClick={handleClick}
      >
        <Box className={classes.buttonIcon}>
          <img src="/images/report/calendar.svg" alt="期間を選択" />
        </Box>
        <Box className={classes.buttonText} flexGrow={1}>
          <div className={classes.buttonTitle}>
            {type === 'monthly'
              ? format(date, 'yyyy/MM')
              : format(date, 'yyyy/MM/dd')}
          </div>
        </Box>
        <Box
          className={clsx(
            classes.buttonIcon,
            Boolean(anchorEl) && classes.buttonRotate,
          )}
        >
          <ExpandLess />
        </Box>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
          <DatePicker
            autoOk
            format="yyyy/MM/dd"
            value={date}
            variant="static"
            onChange={(d: any) => setDate(new Date(d))}
            minDate={new Date('2021-01-01')}
            maxDate={new Date()}
            views={type === 'monthly' ? ['year', 'month'] : ['date']}
          />
        </MuiPickersUtilsProvider>
      </Popover>
    </>
  );
};

export default SelectorFormButton;

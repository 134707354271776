import Accordion from '@material-ui/core/Accordion';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

type Expanded = { category?: string; q?: string };

interface Props {
    children: any;
    expanded: Expanded;
    setExpanded: Dispatch<SetStateAction<Expanded>>;
  }

const QandA:FC<Props> = (props) => {
    const { children, expanded, setExpanded } = props;
  
    const q: string = children[0].props.children[2];

    const handleChange =
    (panel: string) =>
    (event: ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(
        { ...expanded, 'q': newExpanded ? panel : undefined }
      );
    };
    return (
        <Accordion expanded={q === expanded.q} onChange={handleChange(q)}>
        {children}
      </Accordion>
    )
}

export default QandA

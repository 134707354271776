import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import {
  AuthorityCommit,
  AuthorityCreateForm,
  SubHeader,
} from '../../components';
import { fetch, useAuth, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { getUser } from '../../redux/userSlice';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('メールアドレスが不正です'),
  authType: yup.string().required(),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserInvitation: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const [data, setData] = useState<string>('');
  const [organization, setOrganization] = useState<any>();
  const [isNewUser, setIsNewUser] = useState<boolean>(true);
  const { isAllowOrganizationWithAuth } = useAuth();

  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      email: '',
      authType: 'SYSTEM_ADMIN',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        const result = await fetch.post(`/organization/${id}/user`, {
          email: values.email,
          authType: values.authType,
        });
        if (result.status === 200) {
          if (result.data) {
            setIsNewUser(true);
            setData(result.data);
          } else {
            setIsNewUser(false);
          }

          //  ユーザー情報を更新（権限部分）
          const resultUser = await fetch.get('/user');
          if (resultUser.status === 200) {
            dispatch(getUser(resultUser.data));
          }

          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  //サブヘッダー用の組織情報取得
  useEffect(() => {
    (async () => {
      const organization = await fetch.get(`/organization/${id}`);
      if (organization.status === 200) {
        setOrganization(organization.data);
      } else if (organization.status === 500) {
        alertWithCode(organization.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={organization?.name}
        subTitle={`管理者追加${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/organization/${organization?.id}`}
      />
      <div className={classes.root}>
        {mode === 'editing' && (
          <AuthorityCreateForm
            {...formik}
            isAdmin={
              isAllowOrganizationWithAuth(id, ['SYSTEM_ADMIN']) ||
              isAllowOrganizationWithAuth(organization?.parentId, [
                'SYSTEM_ADMIN',
              ])
            }
          />
        )}
        {mode === 'commit' && (
          <AuthorityCommit
            message={
              isNewUser
                ? '招待メールを送信しました。\n招待メールのURLからアカウントを作成してください。'
                : '管理者を追加しました。\n管理者一覧からご確認ください。'
            }
            link={`/organization/${id}/users`}
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(UserInvitation);

import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import HeaderMenu from './HeaderMenu';
import useStyles from '../../../assets/styles/Header.css';
import logo from '../../../assets/images/header-logo.svg';

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Header: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.header}>
        <Toolbar>
          <img
            src={logo}
            alt="eメットシステム"
            onClick={() => history.push('/')}
            className={classes.headerLogo}
          />
          <HeaderMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default Header;

import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { List, SubHeader } from '../../components';
import { Row } from '../../components/list/List';
import { AUTH_TYPE } from '../../constants/authority';
import { fetch, useAuth, useAlert } from '../../functions';

const header = ['ユーザー名', '所属', 'メールアドレス', '権限名'];

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};
interface Authority {
  id: string;
  userId: string;
  organizationId: string;
  type: 'SYSTEM_ADMIN' | 'SECURITY_ADMIN' | 'WORK_SITE_MANAGER';
}
interface User {
  id: string;
  name: string;
  email: string;
  belongingName: string | null;
  authorities: Authority[];
  isLocked: boolean;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UsersList: FC = () => {
  const { id } = useParams<Params>();
  const [data, setData] = useState<Row[]>([]);
  const [organization, setOrganization] = useState<any>();
  const { alertWithText } = useAlert();
  const { isAllowOrganizationWithAuth } = useAuth();
  const { alertWithCode } = useAlert();

  //  該当組織の権限の有無
  const isAllow = isAllowOrganizationWithAuth(id, [
    'SYSTEM_ADMIN',
    'WORK_SITE_ADMIN',
  ]);

  //  親組織の権限の有無
  const isParentAllow = isAllowOrganizationWithAuth(organization?.parentId, [
    'SYSTEM_ADMIN',
    'WORK_SITE_ADMIN',
  ]);

  const subMenus = [
    {
      icon: <AddIcon />,
      name: '管理者追加',
      link: `/organization/${id}/user/add`,
      allow: isAllow || isParentAllow,
    },
  ];

  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}/users`);
      if (result.status === 200) {
        const data: Row[] = [];
        result.data.forEach((user: User) => {
          let authType = '';
          let authId: string = '';
          user.authorities.forEach((authority: Authority) => {
            if (authority.organizationId.toString() === id) {
              const auth = _.find(AUTH_TYPE, { value: authority.type });
              authType = auth ? auth.name : '';
              authId = authority.id;
            }
          });
          data.push({
            id: user.id,
            cells: [
              user.name,
              user.belongingName,
              user.email,
              authType,
            ],
            name: user.name,
            authorityId: authId,
            isLocked: user.isLocked,
          });
        });
        setData(data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [setData, alertWithCode, id]);

  useEffect(() => {
    (async () => {
      const organization = await fetch.get(`/organization/${id}`);
      if (organization.status === 200) {
        setOrganization(organization.data);
      } else if (organization.status === 500) {
        alertWithCode(organization.status);
      }
    })();
  }, [id, alertWithCode]);
  const deleteUserAuthority = async (authorityId: string) => {
    const result = await fetch.delete(`/authority/${authorityId}`);
    if (result.status === 200) {
      setData(
        data.filter((row: Row) => {
          return row.authorityId !== authorityId;
        }),
      );
    } else if (result.status === 500) {
      alertWithCode(result.status);
    }
  };

  const unlockUser = async (lockId: string) => {
    const index = _.findIndex(data, { id: lockId });
    if (index !== -1) {
      const result = await fetch.put(`/user/${lockId}/unlock`);
      if (result.status === 200) {
        data[index].isLocked = false;
        setData([...data]);
        alertWithText('ロックを解除しました', 'success');
      } else {
        alertWithText('権限がありません');
      }
    } else {
      alertWithText('ロック解除に失敗しました');
    }
  };

  return (
    <div>
      <SubHeader
        title={organization?.name}
        subTitle="管理者一覧"
        menus={subMenus}
        link={`/dashboard/organization/${id}`}
      />
      <List
        tableHeadCells={header}
        tableBodyCells={data}
        deleteItem={deleteUserAuthority}
        unlockItem={unlockUser}
        edit={`/organization/${id}/user`}
        allow={isAllow || isParentAllow}
      />
    </div>
  );
};

export default UsersList;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';
import {
  ReportContainer,
  ReportSection,
  ReportSectionHeader,
  ReportSubHeaderWorkerMonthly,
  ReportParamsTable,
  ReportMonthlyBodyHeatIndex,
  ReportMonthlyEnqueteList,
} from '../../components';
import { fetch, useAlert } from '../../functions';
import { ApiGetReportWorkderMonthly } from '../../types/report';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
  date: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkersMonthly = () => {
  const { id, date } = useParams<Params>();
  const [report, setReport] = useState<ApiGetReportWorkderMonthly>();
  const [isNodata, setIsNodata] = useState(false);
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();

  //  レポートのデータ取得
  useEffect(() => {
    (async () => {
      const displayDateTime = date.match(/^[0-9]{4}[0-9]{2}$/)
        ? format(
            new Date(Number(date.substr(0, 4)), Number(date.substr(4, 2)) - 1),
            'yyyy/MM/01 00:00:00',
          )
        : format(new Date(), 'yyyy/MM/01 00:00:00');

      const result = await fetch.get(`/worker/${id}/monthly_report`, {
        displayDateTime,
      });
      if (result.status === 200) {
        setReport(result.data);
      } else if (result.status === 401) {
        alertWithCode(result.status);
        dispatch(push('/'));
      } else {
        setIsNodata(true);
      }
    })();
  }, [id, date, alertWithCode, dispatch]);

  return (
    <ReportContainer title="マンスリー個人レポート" isNodata={isNodata}>
      <ReportSubHeaderWorkerMonthly
        timestamp={report?.timestamp ?? ''}
        name={report?.firstname + ' ' + report?.lastname}
        birthday={report?.birthday ?? ''}
        alertValue={report?.alertValue ?? 0}
        alertName={report?.alertName ?? ''}
        projectName={report?.projectName ?? ''}
        organizationName={report?.organizationName ?? ''}
        worksiteName={report?.workSiteName ?? ''}
      />

      <ReportSection>
        <ReportSectionHeader title="カラダ暑さ指標最大値の推移結果" />
        <ReportMonthlyBodyHeatIndex
          monthlyName="worker"
          alertValue={report?.alertValue ?? 0}
          bodyHeatIndexMaxData={JSON.parse(
            report?.bodyHeatIndexMaxData ?? '[]',
          )}
          lastBodyHeatIndexMaxData={JSON.parse(
            report?.lastBodyHeatIndexMaxData ?? '[]',
          )}
          alertValueData={JSON.parse(report?.alertValueData ?? '[]')}
        />
        <ReportParamsTable
          left={[
            {
              name: 'WBGT 最大値',
              value: `${report?.wbgtMax}℃`,
            },
            {
              name: 'WBGT 平均値',
              value: `${Number(report?.wbgtAverage).toFixed(1)}℃`,
            },
            {
              name: 'センサーデバイス最高気温・湿度',
              value: `${report?.temperatureMax}℃ / ${report?.humidityMax}%`,
            },
            {
              name: 'センサーデバイス平均気温・湿度',
              value: `${Number(report?.temperatureAverage).toFixed(
                1,
              )}℃ / ${Number(report?.humidityAverage).toFixed(1)}%`,
            },
          ]}
          right={[
            {
              name: '最大カラダ暑さ指標値',
              value: `${report?.bodyHeatIndexMax}`,
            },
            {
              name: '最大値の対設定値の最大割合',
              value: `${(Number(report?.bodyHeatIndexMaxRate) * 100).toFixed(
                1,
              )}%`,
            },
            {
              name: '閾値越え回数',
              value: `${report?.bodyHeatIndexAlertCount}回`,
            },
            {
              name: '平均カラダ暑さ指標値',
              value: `${report?.bodyHeatIndexAverageCount}`,
            },
          ]}
        />
      </ReportSection>

      <ReportSection>
        <ReportSectionHeader
          title="アンケート結果"
          subTitle="■ 要注意回答　□ 非該当回答　/ 未回答"
        />
        <ReportMonthlyEnqueteList
          enqueteData={JSON.parse(report?.enqueteData ?? '[]')}
          timestamp={
            report?.timestamp ? new Date(report.timestamp) : new Date()
          }
        />
      </ReportSection>
    </ReportContainer>
  );
};

export default WorkersMonthly;

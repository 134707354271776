import { FC } from 'react';
import { PrimaryOutlinedButton } from '..';
import FormGroup from '@material-ui/core/FormGroup';
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  linkToList: string;
  linkToDashboard: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationCommit: FC<Props> = (props: Props) => {
  const { message, linkToList, linkToDashboard } = props;

  return (
    <>
      <pre>{message}</pre>

      <div className="module-spacer--small" />
      <FormGroup row>
      <PrimaryOutlinedButton label="会社・部署一覧へ" linkTo={linkToList} />
      <div className="module-spacer--button" />
      <PrimaryOutlinedButton label="会社・部署ダッシュボードへ" linkTo={linkToDashboard} />
      </FormGroup>
    </>
  );
};

export default OrganizationCommit;

import { FC } from 'react';
import { Button } from '@material-ui/core';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  title: string;
};

/**
 * 送信ボタン
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UISubmit: FC<Props> = (props: Props) => {
  return (
    <Button color='primary' type='submit' variant='contained' disableElevation>
      {props.title}
    </Button>
  );
};

export default UISubmit;

import { FC } from 'react';
import { PrimaryOutlinedButton } from '../../components';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
};
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserCommit: FC<Props> = (props: Props) => {
  const { message } = props;

  return (
    <>
        <p style={{color: '#666666'}}>{message}</p>

        <div className="module-spacer--small" />
        <PrimaryOutlinedButton label="トップに戻る" linkTo="/" />
    </>
  );
};

export default UserCommit;

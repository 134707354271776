import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import {
  PrimaryReadOnlyField,
  UIError,
  UIReturn,
  UISubmit,
  UITextField,
  FormHowToUse,
} from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
  companyName: string;
  keepCompany: boolean;
}
type Props = FormikProps<FormValues> & {
  parentOrgName: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<Props> = (props) => {
  const { handleSubmit, handleChange, values, errors, parentOrgName } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormHowToUse />
      <p>新規に作成する会社・部署情報を入力してください</p>

      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <FormControlLabel
          control={
            <Switch
              name="keepCompany"
              checked={values.keepCompany}
              onChange={handleChange}
              color="primary"
            />
          }
          label="同じ会社名で作成する"
        />

        {values.keepCompany ? (
          <>
            <PrimaryReadOnlyField label="会社名" value={parentOrgName} />
            <div />
          </>
        ) : (
          <>
            <div />
            <UITextField
              type="text"
              name="companyName"
              label="会社名"
              value={values.companyName}
              error={errors.companyName}
              required
              handleChange={handleChange}
            />
          </>
        )}

        <UITextField
          type="text"
          name="name"
          label="会社名・部署名"
          value={values.name}
          error={errors.name}
          required
          handleChange={handleChange}
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

import { makeStyles } from '@material-ui/core/styles';

/**
 * 共通
 *
 *
 * -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    container: {
      margin: '0',
      display: 'flex',
      flexFlow: 'column',
      minHeight: 'calc(100vh - 44px)',
    },
    containerMain: {
      flex: 1,
    },

    /**
     * ヘルプのコンテンツエリア制御
     *
     */
    contents: {
      padding: '24px 16px',
    },
    contentsPaper: {
      margin: '24px 0',
    },
    summaryHeadLink: {
      textDecoration: 'none',
    },
    summaryHead: {
      margin: '8px 0 8px 0',
      padding: '16px',
      borderRadius: '8px',
      fontSize: '18px',
      color: '#707070',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
      transition: '0.4s',

      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    summaryBody: {
      padding: '8px',
      borderRadius: '8px',
      fontSize: '16px',
      color: '#707070',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
    },
    summaryBodyListItem: {
      '& a': {
        display: 'block',
        padding: '8px 8px',
        textDecoration: 'none',
        transition: '0.4s',
        color: '#707070',

        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
    summaryBodyListItemSub: {
      margin: '0',
      fontSize: '14px',
      lineHeight: '22px',
    },

    /**
     * フッター
     *
     */
    footer: {
      margin: '16px 0 0 0',
      padding: '16px',
      backgroundColor: '#458E95',
    },
    footerLink: {
      padding: '0 0 16px',
      fontSize: '16px',

      '& a': {
        transition: '0.3s',
        textDecoration: 'none',
        color: '#fff',

        '&:hover': {
          opacity: '0.7',
        },
      },
    },
    footerCopyright: {
      color: '#fff',
      textAlign: 'center',
    },

    /**
     * トップへ戻るボタン
     *
     */
    gotoTop: {
      cursor: 'pointer',
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      width: '70px',
      height: 'auth',
      verticalAlign: 'bottom',
      transition: '0.5s',
      opacity: 0,
      zIndex: 10000,
    },

    gotoTopActive: {
      opacity: 1,
    },

    gotoTopLast: {
      bottom: '100px',
    },
  }),
  { index: 1 },
);
export default useStyles;

/**
 * コンテンツ
 *
 *
 * -------------------------------------------------------------------------- */
export const useContentsStyles = makeStyles(
  (theme) => ({
    section: {
      paddingTop: '106px',
      marginTop: '-106px',
    },

    sectionTop: {
      backgroundColor: '#fafafa',
      paddingTop: '106px',
      marginTop: '-106px',
    },

    //  タイトル
    title: {
      position: 'relative',
      margin: '0 0 16px',
      fontSize: '36px',
      fontWeight: 'bold',
      color: '#707070',

      '& span': {
        position: 'relative',
        display: 'inline-block',
        padding: '0 0 0 10px',
        borderLeft: '13px solid #458E95',
        borderBottom: '2px solid #458E95',
        zIndex: 2,
      },

      '&::before': {
        position: 'absolute',
        display: 'block',
        content: "''",
        width: '100%',
        height: '2px',
        backgroundColor: '#F2F2F2',
        bottom: '0px',
        zIndex: 1,
      },
    },

    //  サブタイトル
    subtitle: {
      margin: 0,
      padding: '16px 16px',
      fontWeight: 'bold',
      fontSize: '20px',
      color: '#707070',
    },
    subtitleIcon: {
      padding: '0 8px 0 0',
      color: '#458E95',
    },

    //  段落
    paragraph: {
      padding: '8px 32px',
      lineHeight: '1.8em',
      fontSize: '16px',
      color: '#707070',
    },
    paragraphWarning: {
      padding: '8px 32px',
      lineHeight: '1.8em',
      fontSize: '16px',
      color: '#707070',
      backgroundColor: '#ffc8009c',
    },
    paragraphIcon: {
      padding: '0 8px 0 0',

      '& img': {
        width: '22px',
        height: 'auto',
        verticalAlign: 'text-bottom',
      },
    },
    paragraphList: {
      display: 'flex',
      padding: '0 15px',
      alignItems: 'center',
      justifyContent: 'center',
    },

    //  画像
    image: {
      margin: '16px auto',
      fontSize: '14px',
      textAlign: 'center',
      color: '#707070',

      [theme.breakpoints.up('md')]: {
        width: '800px',
      },
    },
    imageImg: {
      width: '400px',
      height: 'auto',
      margin: '8px 0 0 0',
      verticalAlign: 'bottom',
    },
    imageImgL: {
      width: '800px',
      height: 'auto',
      margin: '8px 0 0 0',
      verticalAlign: 'bottom',
    },
    imageImgM: {
      width: '750px',
      height: 'auto',
      margin: '8px 0 0 0',
      verticalAlign: 'bottom',
    },

    //  テキストアイコン付き
    textWithIcon: {
      '& img': {
        verticalAlign: 'text-bottom',
      },
    },

    //  優先度
    priorityTitle: {
      textAlign: 'center',
      writingMode: 'vertical-rl',
    },
    priorityGauge: {
      position: 'relative',
    },
    priorityGaugeUp: {
      position: 'absolute',
      top: '0px',
    },
    priorityGaugeLine: {
      position: 'relative',
      width: '1px',
      height: '6em',
      margin: 'auto',
      backgroundColor: '#707070',
    },
    priorityGaugeDown: {
      position: 'absolute',
      bottom: '0px',
    },

    // 権限表
    authTableTitle: {
      minWidth: '120px',
    },
  }),
  { index: 1 },
);

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Paper } from '@material-ui/core';
import { SubHeader, EnqueteChoices2, EnqueteChoices5 } from '../../components';
import { fetch, useAlert, useAuth } from '../../functions';
import { EnqueteMaxNum } from '../../constants/common';
import { ApiGetEnquete, EnqueteModel } from '../../types/enquete';
import useStyles from '../../assets/styles/Enquete.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const EnqueteDetail = () => {
  const { id } = useParams<Params>();
  const [enquete, setEnquete] = useState<ApiGetEnquete>();
  const [organization, setOrganization] = useState<any>();
  const [project, setProject] = useState<any>();
  const { alertWithCode } = useAlert();
  const { isAllowOrganizationWithAuth, isAllowPlanId } = useAuth();
  const classes = useStyles();

  //  サブメニューの設定
  const subMenus = [
    {
      icon: '',
      name: 'アンケート設定の編集',
      link: `/organization/${id}/enquete/edit`,
      allow:
        organization &&
        organization.parentId === null &&
        isAllowPlanId(project?.planId) &&
        isAllowOrganizationWithAuth(id, ['SYSTEM_ADMIN']),
      denyMessage: isAllowPlanId(project?.planId)
        ? undefined
        : 'ご利用のプランでは利用できません。',
    },
  ];

  //  ページの初期化
  useEffect(() => {
    (async () => {
      //  アンケート情報の取得
      const result = await fetch.get(`/organization/${id}/enquete`);
      if (result.status === 200) {
        setEnquete(result.data);
      } else {
        alertWithCode(result.status);
      }

      //サブヘッダー用の組織情報取得
      const resultOrg = await fetch.get(`/organization/${id}`);
      if (resultOrg.status === 200) {
        setOrganization(resultOrg.data);
      } else {
        alertWithCode(result.status);
      }

      //プロジェクト情報取得
      const projectResult = await fetch.get(`/organization/${id}/project`);
      if (projectResult.status === 200) {
        setProject(projectResult.data);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title="アンケート設定"
        subTitle={organization?.name}
        link={`/dashboard/organization/${id}`}
        menus={subMenus}
      />

      {enquete && (
        <Container>
          {[...Array(EnqueteMaxNum)].map((val, index: number) => {
            const enqueteKey: any = `enqueteFormModel${index + 1}`;
            const enqueteModel: EnqueteModel = enquete[enqueteKey];

            return (
              <Paper className={classes.block} key={index}>
                <Box display="flex" alignItems="center">
                  <Box className={classes.blockNo}>
                    アンケート設定 {index + 1}
                  </Box>

                  {enqueteModel !== undefined ? (
                    <>
                      <Box className={classes.blockTitle} flexGrow="1">
                        {enqueteModel.id !== 0
                          ? `No.${enqueteModel.id} ${enqueteModel.title}`
                          : '使用しない'}
                      </Box>
                      {enqueteModel.id !== 0 && (
                        <Box className={classes.blockComment}>
                          {enqueteModel.type === 'select_two_string' ? (
                            <EnqueteChoices2 model={enqueteModel} />
                          ) : (
                            <EnqueteChoices5 model={enqueteModel} />
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box flexGrow="1">設定なし</Box>
                  )}
                </Box>
              </Paper>
            );
          })}
        </Container>
      )}
    </>
  );
};

export default EnqueteDetail;

import { useSelectStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  kind: string;
  type: string;
  worksite: string;
  date: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const CreateReportWorksite = (props: Props) => {
  const { kind, type, worksite, date } = props;
  const selectClasses = useSelectStyles();

  //  レポートを作成する
  const createReport = () => {
    window.open(`/reports/${kind}/${worksite}/${type}/${date}`);
  };

  return (
    <section className={selectClasses.section}>
      <div className={selectClasses.submit}>
        <span
          className={selectClasses.submitButton}
          onClick={() => createReport()}
        >
          レポートを作成する
        </span>
      </div>
    </section>
  );
};

export default CreateReportWorksite;

import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from '../../assets/styles/Sub.css';

const Question = ({ children }: any) => {
  const classes = useStyles();
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      className={classes.appHelpQuestion}
    >
      {children}
    </AccordionSummary>
  );
};

export default Question;

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../assets/styles/Header.css';

const helpMenus = [
  { name: '操作方法', link: '/help/usage', icon: <ListAltIcon /> },
  { name: 'よくある質問', link: '/help', icon: <DescriptionIcon /> },
  { name: 'アプリのヘルプ', link: '/help/app', icon: <HelpOutlineIcon /> },
];

interface Props {
  handleClose: () => void;
}
/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HeaderHelpMenuList: FC<Props> = (props) => {
  const { handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (link: string) => {
    dispatch(push(link));
    handleClose();
  };

  return (
    <List>
      {helpMenus.map((menu, index) => (
        <ListItem
          button
          onClick={() => handleClick(menu.link)}
          key={index}
          className={classes.headerMainButtonListItem}
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.name} style={{ color: '#666666' }} />
        </ListItem>
      ))}
    </List>
  );
};

export default HeaderHelpMenuList;

import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { List, SubHeader } from '../../components';
import { fetch, useAuth, useAlert } from '../../functions';
import { getOrganizations } from '../../redux/commonSlice';
import { ApiDefaultAlert } from '../../types/default_alert';

const header = ['現場名', '郵便番号', '住所', 'アラート名', 'アラート設定値'];

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type WorkSite = {
  id: string;
  name: string;
  organizationId: string;
  post: string;
  address: string;
  alertNumber: 1 | 2 | 3;
  alertValue: number | null;
};

type OrganizationRow = {
  id: string;
  cells: string[];
  name: '';
};

type Params = {
  id: string;
};
/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkSitesList: FC = () => {
  const [data, setData] = useState<OrganizationRow[]>([]);
  const [organization, setOrganization] = useState();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const dispatch = useDispatch();
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>();
  const { isAllowOrganization } = useAuth();
  const isAllow = isAllowOrganization(id);

  const subMenus = [
    {
      icon: <AddIcon />,
      name: '現場追加',
      link: `/organization/${id}/workSite/create`,
      allow: isAllow,
    },
  ];

  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}/work_sites`);
      if (result.status === 200) {
        const data: any[] = [];
        result.data.forEach((workSite: WorkSite) => {
          const alert = _.find(default_alerts, {
            number: workSite.alertNumber,
          });

          data.push({
            id: workSite.id,
            cells: [
              workSite.name,
              workSite.post,
              workSite.address,
              alert === undefined ? 'カスタム' : alert.name,
              alert === undefined ? workSite.alertValue : alert.value,
            ],
            name: workSite.name,
          });
        });
        setData(data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
      const organization = await fetch.get(`/organization/${id}`);
      organization.status === 200 && setOrganization(organization.data.name);
      if (organization.status === 500) {
        alertWithCode(organization.status);
      }
    })();
  }, [setData, alertWithCode, id, default_alerts]);

  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/default_alert_v2?organizationId=${id}`);
      if (result.status === 200) {
        setDefaltAlerts(result.data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  const deleteWorkSite = async (id: string) => {
    await fetch.delete(`/work_site/${id}`);
    const result = await fetch.get('/user/organizations');
    dispatch(getOrganizations(result.data));
    setData(
      data.filter((row) => {
        return row.id !== id;
      }),
    );
  };

  return (
    <div>
      <SubHeader
        title={organization ?? ''}
        subTitle="現場一覧"
        menus={subMenus}
        link={`/dashboard/organization/${id}`}
      />
      <List
        tableHeadCells={header}
        tableBodyCells={data}
        deleteItem={deleteWorkSite}
        edit="/workSite"
        dashboard={`/dashboard/workSite`}
        allow={isAllow}
      />
    </div>
  );
};

export default WorkSitesList;

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { FC } from 'react';
import useStyles from '../../assets/styles/List.css';

interface Props {
  value: string;
  onChange: (e: any) => void;
  onKeyDown: (e: any) => void;
}

const SearchBar: FC<Props> = (props) => {
  const classes = useStyles();
  const { value, onChange, onKeyDown } = props;
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="キーワードを入力してください"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        style={{paddingLeft: 'calc(1em + 32px)'}}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default SearchBar;

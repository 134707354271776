import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    block: {
      margin: '8px 0',
      padding: '24px 16px',
    },
    blockNo: {
      padding: '0 16px',
    },
    blockTitle: {
      fontWeight: 'bold',
    },
    blockComment: {
      padding: '0 16px',
    },
    blockCommentText: {
      padding: '0 8px',
    },

    choices5: {},
    choices5Comment: {
      fontSize: '12px',
      textAlign: 'center',
    },
    choices5Value: {
      minWidth: '60px',
      textAlign: 'center',
    },
  }),
  { index: 1 },
);
export default useStyles;

import Toolbar from '@material-ui/core/Toolbar';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { FC, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { News } from '../..';
import useStyles from '../../../assets/styles/Header.css';
import { UserState } from '../../../redux/store';
import HeaderMenuButton from './HeaderMenuButton';
import HeaderMenuContent from './HeaderMenuContent';
import HeaderMenuList from './HeaderMenuList';
import HeaderHelpMenuList from './HeaderHelpMenuList';

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HeaderMenu: FC = (props) => {
  const classes = useStyles();
  const name = useSelector((state: UserState) => state.user.name);

  //メニューボタン開閉操作
  const [anchorEl, setAnchorEl] = useState<any>({ news: null, help: null, main: null });
  const handleClick = (event: MouseEvent<any>, name: string) => {
    setAnchorEl({ ...anchorEl, [name]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl({ news: null, help: null, main: null });
  };

  return (
    <div className={classes.headerMenus}>
      <Toolbar>
        <HeaderMenuButton
          onClick={(e) => handleClick(e, 'news')}
          icon={<NotificationsNoneIcon />}
        >
          <span className={classes.headerMainButtonLabel}>お知らせ</span>
        </HeaderMenuButton>
        <HeaderMenuContent anchorEl={anchorEl.news} onClose={handleClose}>
          <News apiurl={`/notifications`} />
        </HeaderMenuContent>

        <HeaderMenuButton
          icon={<HelpOutlineIcon />}
          onClick={(e) => handleClick(e, 'help')}
        >
          <span className={classes.headerMainButtonLabel}>
            ヘルプ
          </span>
        </HeaderMenuButton>
        <HeaderMenuContent anchorEl={anchorEl.help} onClose={handleClose}>
          <HeaderHelpMenuList handleClose={handleClose} />
        </HeaderMenuContent>

        <HeaderMenuButton
          onClick={(e) => handleClick(e, 'main')}
          icon={<PermIdentityIcon />}
        >
          <div className={classes.headerMainButtonLabel}>
            <div>こんにちは</div>
            <div>
              <span>{name}</span>さん
            </div>
          </div>
        </HeaderMenuButton>
        <HeaderMenuContent anchorEl={anchorEl.main} onClose={handleClose}>
          <HeaderMenuList handleClose={handleClose} />
        </HeaderMenuContent>
      </Toolbar>
    </div>
  );
};
export default HeaderMenu;

import { Box } from '@material-ui/core';
import { useContentsStyles } from '../../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  src: string;
  src2: string;
  title: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpImageFlex = (props: Props) => {
  const classes = useContentsStyles();

  return (
    <div className={classes.image}>
      <div>{props.title}</div>
      <Box display="flex">
        <Box pr={2}>
          <img className={classes.imageImg} src={props.src} alt={props.title} />
        </Box>
        <Box flexGrow={1}>
          <img className={classes.imageImg} src={props.src2} alt={props.title} />
        </Box>
      </Box>
    </div>
  );
};

export default HelpImageFlex;

import { FC, ReactNode } from 'react';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useAlert } from '../../functions';
import useStyles from '../../assets/styles/List.css';

interface Props {
  children: ReactNode;
  title: string;
  allow: boolean;
  onClick: () => void;
}

const ActionCell: FC<Props> = (props) => {
  const { children, title, allow, onClick } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();

  const handleClick = () => {
    if (!allow) {
      alertWithCode(401);
    } else {
      onClick();
    }
  };

  return (
    <TableCell className={classes.iconCell}>
      <Tooltip title={title}>
        <IconButton
          className={clsx(!allow && classes.iconCellDeny)}
          onClick={handleClick}
        >
          {children}
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export default ActionCell;

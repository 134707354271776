import { FC } from 'react';
import { Box } from '@material-ui/core';
import { worksiteStyles } from '../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  value: number;
  total: number;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OperationUnits: FC<Props> = (props) => {
  const { value, total } = props;
  const wsClasses = worksiteStyles();

  return (
    <div className={wsClasses.block}>
      <h3 className={wsClasses.blockTitle}>稼働台数</h3>

      <Box className={wsClasses.operatoinunits} display="flex">
        <Box className={wsClasses.operatoinunitsValue}>
          <b>{value ?? '- '}</b>台
        </Box>
        <Box className={wsClasses.operatoinunitsTotal}>/{total}台</Box>
      </Box>
    </div>
  );
};

export default OperationUnits;

import _ from 'lodash';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import {
  FaceIcon,
  PrimaryReadOnlyField,
  UIAlert,
  WorkerLabelList,
} from '../index';
import format from 'date-fns/format';
import ja from 'date-fns/locale/ja';
import { SEX_TYPE } from '../../constants/common';
import useStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  worker: any;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DetailView: FC<Props> = (props) => {
  const { worker } = props;
  const classes = useStyles();
  const sex = _.find(SEX_TYPE, { value: worker.sex });

  return (
    <div className={classes.root}>
      <PrimaryReadOnlyField label="ユーザー名" value={worker.firstname} />
      <PrimaryReadOnlyField
        label="ユーザー名（サブ）"
        value={worker.lastname}
      />
      <PrimaryReadOnlyField
        label="ユーザー名　フリガナ"
        value={worker.firstnameKana}
      />
      <PrimaryReadOnlyField
        label="ユーザー名　フリガナ（サブ）"
        value={worker.lastnameKana}
      />
      <PrimaryReadOnlyField label="性別" value={sex ? sex.name : ''} />
      <PrimaryReadOnlyField
        label="生年月日"
        value={
          worker.birthday
            ? format(new Date(worker.birthday), 'yyyy年MM月dd日', {
                locale: ja,
              })
            : ''
        }
      />
      <PrimaryReadOnlyField label="電話番号" value={worker.phone} />
      <PrimaryReadOnlyField label="メールアドレス" value={worker.email} />
      <PrimaryReadOnlyField label="郵便番号" value={worker.post} />
      <PrimaryReadOnlyField label="管理用住所" value={worker.address} />
      <PrimaryReadOnlyField label="所属" value={worker.affiliation} />
      <PrimaryReadOnlyField label="装着者ログイン名" value={worker.loginName} />
      <PrimaryReadOnlyField label="管理番号" value={worker.controlNumber} />
      <PrimaryReadOnlyField label="グループ番号" value={worker.groupNumber} />

      <PrimaryReadOnlyField
        label="アラート設定"
        value={alert ? alert.name : '未設定'}
      />

      <div className="module-spacer--large" />

      <Grid container spacing={3} className={classes.grids}>
        <Grid item xs={3}>
          <FaceIcon
            type="danger"
            label="危険"
            bgcolor="rgba(254, 16, 75, .2)"
          />
        </Grid>
        <Grid item xs={8}>
          <UIAlert
            name="alertParam1"
            value={worker.alertParam1 ?? 0}
            color="#fe104b"
            disabled
          />
        </Grid>
      </Grid>

      <div className="module-spacer--medium" />

      <Grid container spacing={3} className={classes.grids}>
        <Grid item xs={3}>
          <FaceIcon
            type="careful"
            label="要注意"
            bgcolor="rgba(250, 105, 128, .2)"
          />
        </Grid>
        <Grid item xs={8}>
          <UIAlert
            name="alertParam2"
            value={worker.alertParam2 ?? 0}
            color="#fa6980"
            disabled
          />
        </Grid>
      </Grid>

      <div className="module-spacer--medium" />

      <Grid container spacing={3} className={classes.grids}>
        <Grid item xs={3}>
          <FaceIcon
            type="warning"
            label="注意"
            bgcolor="rgba(242, 150, 0, .2)"
          />
        </Grid>
        <Grid item xs={8}>
          <UIAlert
            name="alertParam3"
            value={worker.alertParam3 ?? 0}
            color="#f29600"
            disabled
          />
        </Grid>
      </Grid>

      <div className="module-spacer--small" />

      <WorkerLabelList labels={worker.labels} />
    </div>
  );
};

export default DetailView;

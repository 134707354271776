import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    danger: {
      background: '#f44336',
    },
    warning: {
      background: '#ff9800',
    },
    info: {
      background: '#2196f3',
    },
    success: {
      background: '#4caf50',
    },
  }),
  { index: 1 },
);
export default useStyles;

import { FC, useState, MouseEvent, Dispatch, SetStateAction } from 'react';
import jaLocale from 'date-fns/locale/ja';
import { Button, Popover } from '@material-ui/core';
import { EventNote } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { commonStyles } from '../../assets/styles/Dashboard.css';
import { isCheckBeforeTargetDate } from '../../utils/DateUtil';

/**
 *
 *
 *
 * @method Class
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: any) {
    return format(date, 'yyyy MMM', { locale: this.locale });
  }
  getDatePickerHeaderText(date: any) {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

/**
 * 日付ピッカー
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DashboardDatePicker: FC<Props> = (props: Props) => {
  const classes = commonStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  //  値を変更する
  const handleChange = (date: any) => {
    if (isCheckBeforeTargetDate(date)) {
      props.setValue(format(new Date(date), 'yyyy/MM/dd HH:00:00'));
    } else {
      props.setValue(format(new Date(date), 'yyyy/MM/dd HH:mm:00'));
    }
    setAnchorEl(null);
  };

  //  ダイアログを開く
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  //  閉じる
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classes.datePicker}
        startIcon={<EventNote />}
        onClick={handleClick}
      >
        {format(
          props.value === null ? new Date() : new Date(props.value),
          'yyyy/MM/dd',
        )}
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
          <DatePicker
            autoOk
            format="yyyy/MM/dd"
            value={props.value === null ? new Date() : new Date(props.value)}
            variant="static"
            onChange={handleChange}
            maxDate={new Date()}
          />
        </MuiPickersUtilsProvider>
      </Popover>
    </>
  );
};

export default DashboardDatePicker;

import _ from 'lodash';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Empty from '../../Empty';
import DangerGraph from './DangerGraph';
import TransitionGraph from './TransitionGraph';
import {
  commonStyles,
  organizationStyles,
} from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: any[];
  worksites: any[];
  displayDateTime?: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ChildStatus: FC<Props> = (props: Props) => {
  const { data, worksites, displayDateTime } = props;
  const classes = commonStyles();
  const orgClasses = organizationStyles();

  return (
    <Empty
      isEmpty={worksites === undefined || worksites.length === 0}
      label={worksites === undefined ? '閲覧不可' : '未登録'}
    >
      <Box display="flex" flexWrap="wrap">
        {worksites &&
          worksites.map((work) => {
            const d = _.find(data, { workSiteId: work.id });

            return (
              <Box className={orgClasses.group} key={work.id}>
                <h4 className={classes.groupTitle}>
                  <Link
                    to={{
                      pathname: `/dashboard/worksite/${work.id}`,
                      state: { beforeDateTime: displayDateTime },
                    }}
                  >
                    {work.name}
                  </Link>
                </h4>
                <Empty isEmpty={d === undefined} label={'no data'} overlay>
                  <DangerGraph
                    alertLevel1={d ? d.alertLevel1 : 0}
                    alertLevel2={d ? d.alertLevel2 : 0}
                    alertLevel3={d ? d.alertLevel3 : 0}
                    alertLevel4={d ? d.alertLevel4 : 0}
                    totalCount={d ? d.totalWorkerCount : 0}
                  />
                </Empty>

                <Empty isEmpty={d === undefined} label={'no data'} overlay>
                  <TransitionGraph
                    data={d ? JSON.parse(d.bodyHeatIndexAverageData) : []}
                    lastData={
                      d ? JSON.parse(d.bodyHeatIndexAverageLastData) : []
                    }
                  />
                </Empty>
              </Box>
            );
          })}
      </Box>
    </Empty>
  );
};

export default ChildStatus;

import { Link } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import useStyle from '../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  left: {
    title: string;
    url: string;
  };
  right: {
    title: string;
    url: string;
  };
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpFooter = (props: Props) => {
  const classes = useStyle();

  return (
    <footer className={classes.footer}>
      <Container>
        <Box className={classes.footerLink} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Link to={props.left.url}>
              <span>&lt;&lt;</span> {props.left.title}
            </Link>
          </Box>
          <Box>
            <Link to={props.right.url}>
              {props.right.title} <span>&gt;&gt;</span>
            </Link>
          </Box>
        </Box>

        <div className={classes.footerCopyright}>
          &copy; STRALITE Co.,Ltd. All rights reserved.
        </div>
      </Container>
    </footer>
  );
};

export default HelpFooter;

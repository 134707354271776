import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useStyles from '../../assets/styles/Master.css';
import {
  OrganizationCommit,
  OrganizationCreateConfirm,
  OrganizationCreateForm,
  SubHeader,
} from '../../components';
import { fetch, yup, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { getOrganizations } from '../../redux/commonSlice';
import { getUser } from '../../redux/userSlice';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationCreate: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [organizationName, setOrganizationName] = useState<string>('');
  const [organizations, setOrganizations] = useState([{ name: '' }]);
  const [parentOrgName, setParentOrgName] = useState<string>('');
  const dispatch = useDispatch();
  const [newOrganizationId, setNewOrganizationId] = useState<string>('');

  const schema = yup.object({
    name: yup
      .string()
      .specialCharacter('会社・部署名に機種依存文字が含まれています')
      .max(255, '会社・部署名は255文字まで入力できます')
      .required('会社・部署名を入力してください')
      .test('duplex-name', 'この会社・部署名は登録済みです', (value) =>
        duplexTest(value),
      ),
    companyName: yup
      .string()
      .specialCharacter('会社名に機種依存文字が含まれています')
      .max(255, '会社名を255文字まで入力できます')
      .when('keepCompany', {
        is: false,
        then: yup.string().required('会社名を入力してください'),
      }),
    keepCompany: yup.boolean(),
  });

  const duplexTest = (name?: string) => {
    let test = true;
    organizations.forEach((organization) => {
      if (organization.name === name) {
        test = false;
      }
    });
    return test;
  };

  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      name: '',
      companyName: '',
      keepCompany: true,
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.post('/organization', {
          name: values.name,
          companyName: values.keepCompany ? null : values.companyName,
          parentId: id,
        });

        if (result.status === 200) {
          setNewOrganizationId(result.data.id);
          const result2 = await fetch.get('/user/organizations');
          dispatch(getOrganizations(result2.data));

          //  ユーザー情報を更新（権限部分）
          const resultUser = await fetch.get('/user');
          if (resultUser.status === 200) {
            dispatch(getUser(resultUser.data));
          } else if (resultUser.status === 500) {
            alertWithCode(resultUser.status);
          }

          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  //  組織情報の取得
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}`);
      if (result.status === 200) {
        setOrganizationName(result.data.name);
        setParentOrgName(result.data.companyName);

        //  同一名の組織を調べるためのリスト取得
        const resultOrgs = await fetch.get(`/organization/${id}/organizations`);
        if (resultOrgs.status === 200) {
          //  比較用のデータに親（自身）のデータを追加
          resultOrgs.data.push({
            id: result.data.id,
            name: result.data.name,
            companyName: result.data.companyName,
          });

          setOrganizations(resultOrgs.data);
        } else if (resultOrgs.status === 500) {
          alertWithCode(resultOrgs.status);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={organizationName}
        subTitle={`会社・部署追加${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/organization/${id}`}
      />
      <div className={classes.root}>
        {mode === 'editing' && (
          <OrganizationCreateForm {...formik} parentOrgName={parentOrgName} />
        )}
        {mode === 'confirm' && (
          <OrganizationCreateConfirm
            {...formik}
            parentOrgName={parentOrgName}
          />
        )}
        {mode === 'commit' && (
          <OrganizationCommit
            message={`会社・部署を新規作成しました。\n会社・部署一覧ページから確認してください。`}
            linkToList={`/organization/${id}/organizations`}
            linkToDashboard={`/dashboard/organization/${newOrganizationId}`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(OrganizationCreate);

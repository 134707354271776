// import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import {
  AuthorityEditCommit,
  AuthorityEditConfirm,
  AuthorityEditForm,
  SubHeader,
} from '../../components';
import { fetch, useAuth, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { getOrganizations } from '../../redux/commonSlice';
import { getUser } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';

type Params = {
  id: string;
  userId: string;
};
/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  authType: yup.string().required('権限を選択してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserAuthorityEdit: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alertWithCode } = useAlert();
  const { id, userId } = useParams<Params>();
  const [user, setUser] = useState({ name: '', authorityId: '', authType: '' });
  const [organization, setOrganization] = useState<any>();
  const { isAllowOrganizationWithAuth } = useAuth();

  //  フォームの定義
  const formik = useFormik({
    initialValues: user,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.put(`/authority/${values.authorityId}`, {
          authType: values.authType,
        });
        if (result.status === 200) {
          const result2 = await fetch.get('/user/organizations');
          dispatch(getOrganizations(result2.data));
          changeMode('commit');

          //  ユーザー情報を更新（権限部分）
          const resultUser = await fetch.get('/user');
          if (resultUser.status === 200) {
            dispatch(getUser(resultUser.data));
          } else if (resultUser.status === 500) {
            alertWithCode(resultUser.status);
          }
        } else if (result.status === 500) {
          alertWithCode(result.status);
        } else {
          alert(result.status);
        }
      }
    },
  });

  //  データの取得
  //  --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/user/${userId}`);
      if (result.status === 200) {
        result.data.authorities.forEach((authority: any) => {
          if (authority.organizationId === parseInt(id)) {
            setUser({
              name: result.data.name,
              authorityId: authority.id,
              authType: authority.type,
            });
          }
        });
      } else if (result.status === 500) {
        alertWithCode(result.status);
      } else {
        alert(result.status);
      }
    })();
  }, [setUser, alertWithCode, id, userId]);

  //  組織情報の取得
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/organization/${id}`);
      if (result.status === 200) {
        setOrganization(result.data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={organization?.name}
        subTitle={`権限編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link="/my"
      />
      <div className={classes.root}>
        {mode === 'editing' && (
          <AuthorityEditForm
            {...formik}
            isAdmin={
              isAllowOrganizationWithAuth(id, ['SYSTEM_ADMIN']) ||
              isAllowOrganizationWithAuth(organization?.parentId, [
                'SYSTEM_ADMIN',
              ])
            }
          />
        )}
        {mode === 'confirm' && <AuthorityEditConfirm {...formik} />}
        {mode === 'commit' && (
          <AuthorityEditCommit
            message="管理者権限を変更しました。"
            label="管理者一覧へ"
            link={`/organization/${id}/users`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(UserAuthorityEdit);

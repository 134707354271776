import {FC} from 'react';
import {TableCell} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../../../assets/styles/List.css';
import {confirmStyles} from '../../../assets/styles/CsvUpload.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  origin?: any;
  newdata?: any;
  originKey: string;
  newdataKey: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ValueCell: FC<Props> = (props: Props) => {
  const {origin, newdata, originKey, newdataKey} = props;
  const classes = useStyles();
  const confirmClasses = confirmStyles();

  //  削除されるセル
  if (newdata === undefined && origin !== undefined) {
    return (
      <TableCell className={clsx(classes.tableCell, confirmClasses.cellDelete)}>{origin[originKey]}</TableCell>
    );

    //  新規追加されるセル
  } else if (origin === undefined && newdata !== undefined) {
    return (
      <TableCell className={clsx(classes.tableCell, confirmClasses.cellUpdate)}>{newdata[newdataKey]}</TableCell>
    );
  }

  //  変更された可能性のあるセル
  else if (origin !== undefined && newdata !== undefined) {
    const oVal = origin[originKey];
    const nVal = newdata[newdataKey];

    return (
      <TableCell
        className={clsx(
          classes.tableCell,
          oVal !== nVal && confirmClasses.cellUpdate,
        )}
      >
        {nVal}
      </TableCell>
    );
  } else {
    return <TableCell className={classes.tableCell}>-</TableCell>;
  }
};

export default ValueCell;

import { ReactNode } from 'react';
import { useContentsStyles } from '../../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpTitle = (props: Props) => {
  const classes = useContentsStyles();

  return (
    <h2 className={classes.title}>
      <span>{props.children}</span>
    </h2>
  );
};

export default HelpTitle;

import { ReactNode } from 'react';
import ReportHeader from './ReportHeader';
import { useNodataStyles } from '../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
  title: string;
  isNodata: boolean;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportContainer = (props: Props) => {
  const classes = useNodataStyles();

  return (
    <div className="print_container">
      <ReportHeader title={props.title} />

      {props.isNodata ? (
        <section className={classes.container}>レポートはありません</section>
      ) : (
        <section>{props.children}</section>
      )}
    </div>
  );
};

export default ReportContainer;

import { Box, Paper } from '@material-ui/core';
import HelpSection from './parts/HelpSection';
import HelpTitle from './parts/HelpTitle';
import HelpSubtitle from './parts/HelpSubtitle';
import HelpParagraph from './parts/HelpParagraph';
import HelpImage from './parts/HelpImage';
import WarningIcon from './parts/WarningIcon';
import PointIcon from './parts/PointIcon';
import HelpSubTitle from './parts/HelpSubtitle';
import useStyles, { useContentsStyles } from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpContentsBasic = () => {
  const classes = useStyles();
  const contentClasses = useContentsStyles();

  return (
    <Paper className={classes.contentsPaper}>
      <HelpSection id="Step1" first={true}>
        <div style={{ backgroundColor: 'white' }}>
          <HelpTitle>STEP1　ログイン</HelpTitle>

          <HelpSubtitle>初回ログイン時のユーザー登録</HelpSubtitle>
          <HelpParagraph icon={'①'}>
            <div>
              eメット管理システムをご利用になる、インターネット接続が可能なタブレット端末、パソコンをご用意ください。
            </div>
            <div>※スマートフォンには対応しておりません。</div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>
              ご用意いただいた端末のブラウザから、招待メール記載のURLにアクセスしてください。
            </div>
            <div>
              ※ブラウザはGoogleChrome、Microsoft EdgeまたはSafari推奨です。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'③'}>
            <div>
              ユーザー情報登録画面が表示され、「ユーザー名」「パスワード」「所属」を設定いただきます。
            </div>
            <div>
              パスワードは半角英数字・記号（!%@.?/-）を用いて8文字以上16文字以下で設定することができます。
            </div>
            <div>所属には機種依存文字は使用できません。</div>
          </HelpParagraph>
          <HelpImage
            title="ユーザー情報登録画面"
            src="/images/help/step1.1.png"
          />
          <HelpParagraph icon={'④'}>
            ③の設定が完了するとログイン画面に遷移します。
          </HelpParagraph>

          <HelpSubtitle>2回目以降のログイン</HelpSubtitle>
          <HelpParagraph icon={'⑤'}>
            登録されたユーザー情報でログインをクリックすると、登録したアドレスにワンタイムパスワードがメールで届きます。
          </HelpParagraph>
          <HelpImage
            title="2回目以降のログイン情報入力画面"
            src="/images/help/step1.2.png"
          />
          <HelpParagraph icon={'⑥'}>
            メールに記載の4桁のワンタイムパスワードを入力後、再度ログインをクリックするとログインは成功です。
          </HelpParagraph>
          <HelpImage
            title="ワンタイムパスワード入力画面"
            src="/images/help/step1.3.png"
          />
          <HelpParagraph icon={'⑦'}>
            <div>ログインページは非公開です。</div>
            <div>
              次回以降はTOPページをブックマーク（お気に入り登録）してアクセスしてください。
            </div>
            <div>
              <a href="https://www.emet-system.jp/">
                https://www.emet-system.jp/
              </a>
            </div>
          </HelpParagraph>
          <HelpSubTitle>2段階認証設定</HelpSubTitle>
          <HelpParagraph>
            <div>
              2段階認証設定はプロジェクト内の最上位組織でadmin権限をもつユーザーのみ使用できます。
            </div>
            <div>設定内容は組織に属するすべてのアカウントに適用されます。</div>
            <div>
              ※2段階認証を利用することで第三者による不正アクセスを防ぐことができます。
            </div>
          </HelpParagraph>
          <HelpParagraph icon="①">
            管理者向けWEBページのトップページ右上「2段階認証設定」を選択します。
          </HelpParagraph>
          <HelpParagraph icon="②">3項目から設定をしてください。</HelpParagraph>
          <HelpImage
            title="2段階認証設定画面"
            src="/images/help/step1.4.png"
          ></HelpImage>
          <HelpParagraph icon="③">
            「設定」をクリックすると設定が完了です。
          </HelpParagraph>
          <HelpSubTitle>アカウントがロックされた場合</HelpSubTitle>
          <HelpParagraph>
            ログインに複数失敗された場合、アカウントがロックされます。
          </HelpParagraph>
          <HelpParagraph>
            <div>
              対処方法ⅰ　組織のadmin権限者へロックの解除を依頼してください。
            </div>
            <div>
              対処方法ⅱ　スターライト工業（株）サポート窓口へお問い合わせください。
            </div>
          </HelpParagraph>
        </div>
      </HelpSection>

      <HelpSection id="Step2">
        <HelpTitle>STEP2　組織を作る</HelpTitle>
        <HelpParagraph>
          <div>
            　ダッシュボードを見やすくする為、会社単位、部署単位、チーム単位など管理したい範囲に合わせ、組織として作成できます。
          </div>
          <div>
            事前にどんな範囲でダッシュボードを見たいかを想定してから階層組織イメージを作成されると作業がスムーズです。
          </div>
        </HelpParagraph>
        <HelpImage title="管理範囲のイメージ" src="/images/help/step2.1.svg" />
        <HelpParagraph>
          <div>上図の△△チームを作成したい場合を仮定します。</div>
          <div>チームの作成には先に上位になる部署の作成が必要です。</div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>TOPページにて部の1つ上の階層となる「会社名」を選択します。</div>
          <div>
            ※ご利用開始直後はご契約時にご記入いただいた、会社名または部署名の組織のみ表示されています。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          組織ダッシュボードの右上メニューバーの「会社・部署一覧」を選択します。
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          組織一覧画面右上のメニューバーから「＋会社・部署追加」を選択します。
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>どの管理範囲内に作成したいかを入力します。</div>
          <div>
            「同じ会社名で作成する」を選択すると、①で選択した部署と同じ管理範囲として作成されます。
          </div>
          <div>
            ※選択を外すと、会社名欄に別の管理範囲名を作成することができます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'⑤'}>
          「会社名・部署名」入力欄に作成したい部署名「〇〇製造部」を入力します。
        </HelpParagraph>
        <HelpImage title="④⑤の入力画面" src="/images/help/step2.2.png" />
        <HelpParagraph icon={'⑥'}>
          「確認」をクリックし内容確認、「確定」をクリックすると「〇〇製造部」の作成が完了です。　○○製造部のダッシュボードから②以降の操作をすると「△△チーム」の組織を作成することができます。
        </HelpParagraph>
      </HelpSection>

      <HelpSection id="Step3">
        <HelpTitle>STEP3　現場を作る</HelpTitle>
        <HelpParagraph>
          現場が属する組織をSTEP2の操作で作成してからこの操作を行ってください。
        </HelpParagraph>
        <HelpParagraph>
          <div>下図のように、現場は組織の配下に作成することができます。</div>
          <div className={contentClasses.textWithIcon}>
            <WarningIcon />
            &nbsp;独立して作成することはできません。
          </div>
          <div className={contentClasses.textWithIcon}>
            <WarningIcon />
            &nbsp;装着者の登録などシステムの運用にあたって、現場の作成は必須です。
          </div>
        </HelpParagraph>
        <HelpImage title="作成現場イメージ" src="/images/help/step3.1.svg" />
        <HelpParagraph>
          ここでは上図のAA現場を作成したい場合を仮定します。
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          作りたい現場を管理する部署「〇〇製造部」のダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          ダッシュボードの右上メニューバーから「現場一覧」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          現場一覧画面の右上メニューの「＋現場追加」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>「現場名」「郵便番号」「住所」を入力します。</div>
          <div>※同一組織内において、同じ現場名は登録できません。</div>
          <div>
            ※ここで登録された現場名は、装着者がeメットアプリで現場選択する際に表示されます。
          </div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/step3.2.png" />
        <HelpParagraph icon={'⑤'}>
          <div>現場に最大10グループ設定することができます。</div>
          <div>STEP5にて装着者をグルーピングしたい場合にご利用ください。</div>
          <div>
            「グループ名」「アラート通知先アドレス」「アラート設定」をそれぞれ入力します。
          </div>
          <div>※使用しないグループは設定しなくても問題ありません。</div>
        </HelpParagraph>
        <HelpParagraph icon={'⑥'}>
          入力が完了したら「確認」をクリックし、内容を確認後「確定」をクリックすると現場の登録が完了です。
        </HelpParagraph>
      </HelpSection>

      <HelpSection id="Step4">
        <HelpTitle>STEP4　センサーデバイスを登録する</HelpTitle>

        <HelpSubtitle>センサーデバイス登録の仕組み</HelpSubtitle>
        <HelpParagraph>
          センサーデバイスを登録したい現場をSTEP３の操作で作成してからこの操作を行ってください。
        </HelpParagraph>
        <HelpParagraph>
          図のように、センサーデバイスは現場ごとに登録を行います。
        </HelpParagraph>
        <HelpImage
          title="センサーデバイス登録イメージ"
          src="/images/help/step4.1.svg"
        />
        <HelpParagraph>
          ここでは図のAA現場にセンサーデバイスを登録すると仮定します。
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          センサーデバイスを登録したい「AA現場」のダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          ダッシュボードの右上メニューバーから「センサーデバイス一覧」をクリックします。
        </HelpParagraph>

        <HelpSubtitle>1台ずつ登録する</HelpSubtitle>
        <HelpParagraph icon={'③'}>
          センサーデバイス一覧画面の右上メニューの「＋センサーデバイス追加」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          eメットの裏面に記載のセンサーデバイス製造番号（3桁の半角大文字英語＋4桁の半角数字）を入力します。
        </HelpParagraph>
        <HelpParagraph icon={'⑤'}>
          入力が完了したら「確認」をクリックし、内容を確認後「確定」をクリックするとセンサーデバイスの登録が完了です。
        </HelpParagraph>

        <HelpSubtitle>複数台を一括で登録する</HelpSubtitle>
        <HelpParagraph icon={'③'}>
          センサーデバイス一覧画面の右上メニューの「＋センサーデバイス一括追加」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            登録用フォーマット内に1行ごとにeメットの裏面に記載のセンサーデバイス製造番号
          </div>
          <div>
            （3桁の半角大文字英語＋4桁の半角数字）を入力しcsv形式で保存します。
          </div>
          <div>
            ※フォーマットが無い場合はセンサーデバイス一括ダウンロードから入手してください。
          </div>
        </HelpParagraph>
        <HelpImage
          title="csvフォーマット入力例"
          src="/images/help/step4.2.png"
        />
        <HelpParagraph icon={'⑤'}>
          一括登録画面から「ファイルを選択」をクリックし、作成したcsvデータを選択してください。
        </HelpParagraph>
        <HelpParagraph icon={'⑥'}>
          「確認」をクリックしエラーや追加センサーデバイスの内容を確認後「確定」をクリックするとセンサーデバイスの一括登録が完了です。
        </HelpParagraph>
      </HelpSection>

      <HelpSection id="Step5">
        <HelpTitle>STEP5　装着者を登録する</HelpTitle>

        <HelpSubtitle>装着者登録の仕組み</HelpSubtitle>
        <HelpParagraph>
          装着者を登録したい現場をSTEP３の操作で作成してからこの操作を行ってください。
        </HelpParagraph>
        <HelpParagraph>
          図のように、装着者は現場ごとに登録を行います。
        </HelpParagraph>
        <HelpImage title="装着者登録イメージ" src="/images/help/step5.1.svg" />
        <HelpParagraph>
          ここでは図のAA現場に装着者を登録すると仮定します。
        </HelpParagraph>
        <HelpParagraph icon="①">
          装着者を登録したい「AA現場」のダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon="②">
          ダッシュボードの右上メニューバーから「装着者一覧」をクリックします。
        </HelpParagraph>

        <HelpSubtitle>一人ずつ登録する</HelpSubtitle>
        <HelpParagraph icon="③">
          センサーデバイス一覧画面の右上メニューの「＋装着者追加」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon="④">必要事項を入力します。</HelpParagraph>
        <HelpParagraph icon="⑤">
          <div>
            入力が完了し、内容を確認後「登録」をクリックすると装着者の登録が完了です。
          </div>
          <div>
            ※データ保護のため、1つの現場に同じログイン名を設定することはできません。
          </div>
          <div>
            ※削除されたログイン名であっても過去に登録実績があれば再登録ができません。
          </div>
          <div>
            ログイン者情報を変更したい場合は削除ではなく編集でログイン名以外の登録情報を変更して運用してください。
          </div>
        </HelpParagraph>

        <HelpSubtitle>複数人を一括登録する</HelpSubtitle>
        <HelpParagraph icon="③">
          装着者一覧画面の右上メニューの「＋装着者一括追加」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon="④">
          <div>
            登録用フォーマット内に装着者情報を入力しcsv形式で保存します。
          </div>
          <div>
            ※フォーマットが無い場合は装着者一覧ダウンロードから入手してください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon="⑤">
          一括登録画面から「ファイルを選択」をクリックし、④で作成したcsvデータを選択してください。
        </HelpParagraph>
        <HelpParagraph icon="⑥">
          「確認」をクリックしエラーや追加装着者の内容を確認後「確定」をクリックすると装着者の一括登録が完了です。
        </HelpParagraph>
        <HelpParagraph>
          <div>※ログイン名を基準にデータが上書きされます。</div>
          <div>
            &nbsp;&nbsp;&nbsp;装着者情報を空白で再登録した場合は未入力として再登録されてしまいます。
          </div>
          <div>
            ※前回登録時のデータを大切に保存いただくか、毎回装着者一覧ダウンロードから取得したデータを修正し登録する運用を推奨します。
          </div>
        </HelpParagraph>
      </HelpSection>

      <HelpSection id="Step6">
        <HelpTitle>STEP6　アラートを設定する</HelpTitle>
        <div id="ABC"></div>

        <HelpSubtitle>アラート設定の仕組み</HelpSubtitle>
        <HelpParagraph>アラートの設定方法は以下の4種類あります。</HelpParagraph>
        <HelpParagraph>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>低</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className={contentClasses.priorityTitle}>設定の優先度</td>
                <td className={contentClasses.priorityGauge}>
                  <div className={contentClasses.priorityGaugeUp}>▲</div>
                  <div className={contentClasses.priorityGaugeLine}></div>
                  <div className={contentClasses.priorityGaugeDown}>▼</div>
                </td>
                <td>
                  <div>
                    ⅰ．ご契約時に全員が共通でご利用いただける3つのデフォルト設定値
                  </div>
                  <div>ⅱ．現場単位で自由に設定いただける1つの設定値</div>
                  <div>
                    ⅲ．現場のグループ単位で自由に設定いただける1つの設定値
                  </div>
                  <div>
                    ⅳ．各現場の装着者一人ひとりに自由に設定いただける1つの設定値
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td></td>
                <td>高</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </HelpParagraph>
        <HelpParagraph>
          設定の優先度は後番にいくほど高くなります。
        </HelpParagraph>
        <HelpParagraph>
          例：現場設定値が1000のとき、装着者にカスタムで設定値1200を付与すると、付与された装着者は1200の設定値、付与されていない装着者は1000の設定値でアラートが鳴ります。
        </HelpParagraph>
        <HelpParagraph icon={<PointIcon />}>
          季節や天気、装着者の暑熱順化状態に合わせて、一人ひとりに最適な設定値での運用を行うことを推奨します。
        </HelpParagraph>

        <HelpSubtitle>ご契約時のアラート設定値を変更する</HelpSubtitle>
        <HelpParagraph>
          eメット管理システムのご契約担当者様より弊社へご連絡ください。
        </HelpParagraph>
        <HelpParagraph icon={<WarningIcon />}>
          <div>
            デフォルトの設定値を変更すると、このデフォルト設定値を利用されている方全員に反映されてしまいます。
          </div>
          <div>ⅱ～ⅳの設定値を利用されている方には影響がありません。</div>
        </HelpParagraph>

        <HelpSubTitle>現場単位でのアラート設定値を変更する</HelpSubTitle>
        <HelpParagraph icon="①">
          変更したい現場のダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon="②">
          右上メニューから「現場編集」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon="③">
          各グループのアラート設定欄にて、①の設定名またはカスタムを選択してください。カスタムの場合は任意の数字が設定可能です。
        </HelpParagraph>
        <HelpParagraph icon="④">
          「確認」をクリックしエラーや追加装着者の内容を確認後「確定」をクリックするとグループごとのアラート設定が完了です。
        </HelpParagraph>

        <HelpSubTitle>装着者一人ひとりのアラート設定値を変更する</HelpSubTitle>
        <HelpParagraph icon="①">
          設定したい装着者が所属する現場のダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon="②">
          右上メニューから「装着者一覧」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon="◆">一人ずつ設定値を変更する場合</HelpParagraph>
        <HelpParagraph icon="③">
          編集したい装着者の列左端の編集アイコンをクリックします。
        </HelpParagraph>
        <HelpParagraph icon="④">
          <div>
            アラート設定欄で「カスタム」を選択すると任意の設定値が登録可能です。
          </div>
          <div>
            「現場の設定を反映」を選択すると、所属するグループ③の設定値が登録されます。
          </div>
          <div>
            グループの設定が「現場の設定を反映」であれば②の設定値が登録されます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon="⑤">
          「確認」をクリックしエラーや追加装着者の内容を確認後「確定」をクリックすると装着者の設定が完了です。
        </HelpParagraph>
        <HelpParagraph icon="◆">まとめて設定値を変更する場合</HelpParagraph>
        <HelpParagraph icon="③">
          装着者一覧ダウンロードからcsvデータをダウンロードします。
        </HelpParagraph>
        <HelpParagraph icon="④">
          <div>csvデータのO列「アラート設定」に、</div>
          <ul>
            <li>
              現場またはグループで設定したアラート番号を利用する場合は未入力、
            </li>
            <li>
              契約時のデフォルトアラートを設定したい場合は該当するアラート番号１～３を入力、
            </li>
            <li>
              個別でアラート設定値の値を反映したい場合は「アラート設定」には0を入力し、「アラート設定値」に希望の設定値を入力
            </li>
          </ul>
        </HelpParagraph>
        <HelpParagraph icon="⑤">
          「確認」をクリックしエラーや追加装着者の内容を確認後「確定」をクリックすると装着者の設定が完了です。
        </HelpParagraph>
      </HelpSection>

      <HelpSection id="Step7">
        <HelpTitle>STEP7　アラート通知先を設定する</HelpTitle>
        <HelpParagraph>
          現場のグループごとにメール通知先を最大5件まで設定可能
        </HelpParagraph>
        <HelpParagraph icon="①">
          変更したい装着者が所属する現場のダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon="②">
          右上「アラート通知先設定」を選択します。
        </HelpParagraph>
        <HelpParagraph icon="③">
          <div>
            アラート通知先設定画面にてグループごとに最大5件のメールアドレスの設定が可能です。
          </div>
          <div>
            対象グループの「アラート通知先メールアドレス」欄に設定（通知）したいメールアドレスを入力してください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon="④">
          「確認」をクリックし内容を確認後、「確定」をクリックすると設定が完了です。
        </HelpParagraph>
        <HelpParagraph>
          ※ご利用のメーラーの受信設定で「info@emet-system.jp」からのメールを受信できるように登録してください。
        </HelpParagraph>
        <HelpImage
          title="メール通知内容（例）"
          src="/images/help/step7.1.png"
        ></HelpImage>
      </HelpSection>

      <HelpSection id="Step8">
        <HelpTitle>STEP8　管理者を増やす</HelpTitle>

        <HelpParagraph>
          管理者は組織のダッシュボードごとに登録する必要があります。
        </HelpParagraph>
        <HelpParagraph icon={<WarningIcon />}>
          上位階層組織の管理者であっても、配下の組織すべてのダッシュボードを閲覧できるわけではありません。
        </HelpParagraph>
        <HelpParagraph icon={<WarningIcon />}>
          現場のダッシュボードを見るには現場が紐づく組織に一般監視者以上の管理者権限をもつ必要があります。
        </HelpParagraph>
        <HelpImage
          title="閲覧可能ダッシュボード範囲のイメージ"
          src="/images/help/step8.1.svg"
        />
        <HelpParagraph icon="①">
          管理者を招待したいダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon="②">
          右上メニューの「管理者一覧」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon="③">
          管理者一覧画面の右上メニュー「＋管理者の追加」をクリックします。
        </HelpParagraph>
        <HelpParagraph icon="④">
          招待したい人のメールアドレスを入力します。
        </HelpParagraph>
        <HelpParagraph icon="⑤">
          招待したい人の権限レベルを設定します。
        </HelpParagraph>
        <HelpParagraph>
          <HelpParagraph>
            <Box display="flex">
              <Box className={contentClasses.authTableTitle}>・Admin権限：</Box>
              <Box>
                <div>
                  この権限を持つ組織内においてすべての機能を利用でき、直下の組織・現場の閲覧・編集権も持ちます。
                </div>
                <div>
                  ※最上位組織のadmin権限者のみ、アンケートのカスタムや組織全体へお知らせ投稿が可能です。
                </div>
              </Box>
            </Box>
          </HelpParagraph>

          <HelpParagraph>
            <Box display="flex">
              <Box className={contentClasses.authTableTitle}>
                ・現場監督者：
              </Box>
              <Box>
                <div>
                  この権限を持つ組織・現場内全ての機能を利用できますが、直下の組織に関する権限がありません。
                </div>
                <div>アンケートや組織全体へのお知らせ投稿はできません。</div>
              </Box>
            </Box>
          </HelpParagraph>

          <HelpParagraph>
            <Box display="flex">
              <Box className={contentClasses.authTableTitle}>
                ・一般監視者：
              </Box>
              <Box>
                <div>
                  この権限を持つ組織・現場のダッシュボードを閲覧できるのみの権限です。
                </div>
                <div>
                  人やセンサーデバイスなど登録情報の追加・編集する権限がありません。
                </div>
              </Box>
            </Box>
          </HelpParagraph>
        </HelpParagraph>
        <HelpParagraph icon="⑥">
          <div>
            「招待」をクリックすると招待者へ登録用のメールが届き、管理者の追加が完了です。
          </div>
          <div>
            「招待」をクリックした時点で、自動で同じアドレスのユーザーに管理者権限が付与されます。
          </div>
          <div>
            ※すでに本システムのいずれかの組織に管理者として登録済みの方にはメールは送信されません。
          </div>
        </HelpParagraph>
      </HelpSection>
      <HelpSection id="Step9">
        <HelpTitle>STEP9　アンケートを設定する</HelpTitle>
        <HelpParagraph>
          <div>
            アンケート設定はプロジェクト内の最上位組織でadmin権限を持つユーザーのみ使用できます。
          </div>
          <div>※アンケートはデフォルトで8項目設定されております。</div>
        </HelpParagraph>
        <HelpParagraph icon="①">
          最上位組織の組織ダッシュボードを開きます。
        </HelpParagraph>
        <HelpParagraph icon="②">
          右上メニューの「アンケート設定」を選択します。
        </HelpParagraph>
        <HelpParagraph icon="③">
          右上「アンケート設定の編集」を選択します。
        </HelpParagraph>
        <HelpParagraph icon="④">
          <div>アンケート機能ON/OFFを選択ください。</div>
          <div>ONの場合アンケート項目「４」または「８」を選択ください。</div>
          <div>
            ・アンケートは全29項目より8項目（または4項目）を選択ください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon="⑤">
          入力が完了しましたら「確認」をクリックし、内容を確認後「確定」をクリックすると登録完了です。
        </HelpParagraph>
        <HelpImage
          title="アンケート設定の編集画面"
          src="/images/help/step9.1.png"
        ></HelpImage>
      </HelpSection>
    </Paper>
  );
};

export default HelpContentsBasic;

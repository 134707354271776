import { makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles(
  (theme: Theme) => ({
    web: {
      '& span': {
        color: '#666666',
      },
    },
    root: {
      width: '100%',
      position: 'absolute',
      top: 0,
      '& span': {
        color: '#666666',
      },
      '& svg': {
        color: '#458E95',
      },
    },
    appHelpTitle: {
      height: '48px',
      padding: '16px',
      backgroundColor: '#FFF',
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        fontSize: '20px',
      },
    },
    appHelpAccordionTitle: {
      backgroundColor: '#F9F9F9',
      padding: '0 40px',
      '& span': {
        fontSize: '20px',
      },
      '&>*': {
        color: '#666666',
      },
    },
    appHelpQuestion: {
      backgroundColor: '#FFF',
      padding: '0px 26px 0px 40px',
      '&>*': { color: '#666666' },
      '&:hover': {
        backgroundColor: '#FFF',
      },
      '& svg': {
        width: '20px',
        height: '20px',
        color: '#458E95',
      },
      '& span': {
        marginRight: '5px',
      },
    },
    appHelpAnswer: {
      minHeight: '48px',
      display: 'flex',
      padding: '16px 26px 16px 40px',
      '&>pre': {
        margin: 0,
        color: '#666666',
        whiteSpace: 'pre-wrap',
        paddingRight: '10px',
      },
      '&>span': {
        marginRight: '5px',
      },
      '&>a': {
        minWidth: '80px',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& span': {
          textDecoration: 'underline #666666',
          marginRight: '5px',
          color: '#458E95',
          fontSize: '12px',
        },
        '& svg': {
          width: '14px',
          height: '14px',
        },
      },
    },
    termOfUseTitle: {
      backgroundColor: '#448E95',
      height: '50px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '20px',
    },
    termOfUseSubTitle: {
      borderBottom: '1px solid #448E95',
      width: '100%',
      marginTop: '40px',
      fontSize: '16px',
    },
    termOfUseContent: {
      padding: '30px 10px',
      width: '100%',
      '&>pre': {
        whiteSpace: 'pre-line',
      },
    },
    termOfUseContainer: {
      width: '80%',
      maxWidth: '1000px',
      margin: '0 auto',
      marginTop: '40px',
      '&>*': {
        color: '#666666',
      },
    },
    termOfUseFooter: {
      width: '100%',
      marginTop: '40px',
      '&>div:first-child': {
        marginLeft: 'auto',
        width: 'fit-content',
      },
      '&>div:last-child': {
        marginRight: 'auto',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;

import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { UITextField, UISelect, UISubmit, UIReturn, UIError } from '../index';
import { AUTH_TYPE } from '../../constants/authority';
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  email: string;
  authType: string;
}
type Props = FormikProps<FormValues> & {
  isAdmin: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form = (props: Props) => {
  const { handleSubmit, handleChange, values, errors, isAdmin } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p>組織に追加する管理者情報を入力してください</p>

      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="email"
          name="email"
          label="メールアドレス"
          value={values.email}
          error={errors.email}
          required
          handleChange={handleChange}
        />
        <UISelect
          name="authType"
          label="権限"
          value={values.authType}
          error={errors.authType}
          required
          handleChange={handleChange}
          menus={
            isAdmin
              ? AUTH_TYPE
              : AUTH_TYPE.filter((e) => e.value !== 'SYSTEM_ADMIN')
          }
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="招待" />
      </FormGroup>
    </form>
  );
};
export default Form;

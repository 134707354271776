import { FC } from 'react';
import face1 from '../../assets/images/face1.png';
import face2 from '../../assets/images/face2.png';
import face3 from '../../assets/images/face3.png';
import useStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  type: 'danger' | 'careful' | 'warning';
  label: string;
  bgcolor: string;
};

/**
 * 顔アイコン
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const FaceIcon: FC<Props> = (props: Props) => {
  const { type, label, bgcolor } = props;
  const classes = useStyles();

  return (
    <div className={classes.gridLeft} style={{ backgroundColor: bgcolor }}>
      <div className={classes.imageDiv}>
        {
          {
            danger: <img src={face1} alt={label} className={classes.image} />,
            careful: <img src={face2} alt={label} className={classes.image} />,
            warning: <img src={face3} alt={label} className={classes.image} />,
          }[type]
        }
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

export default FaceIcon;

import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import {
  DeviceCommit,
  DeviceEditConfirm,
  DeviceEditForm,
  SubHeader,
} from '../../components';
import { fetch, useAlert } from '../../functions';
import { FormModeCore } from '../../types/forms';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  name: yup
    .string()
    .max(255, 'センサーデバイス名は255文字まで入力できます')
    .required('センサーデバイス名を入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DeviceEdit: FC = () => {
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [device, setDevice] = useState({ name: '' });
  const [mode, setMode] = useState<FormModeCore>('editing');
  const [workSite, setWorkSite] = useState<any>();
  //  フォームの定義
  const formik = useFormik({
    initialValues: device,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        setMode('confirm');
      } else {
        const result = await fetch.put(`/device/${id}`, values);
        if (result.status === 200) {
          setMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        } else {
          alert('まだ編集できません:' + result.status);
        }
      }
    },
  });

  //  データの取得
  //  --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/device/${id}`);

      if (result.status === 200) {
        setDevice({
          name: result.data.name,
        });
        //サブヘッダー用の現場情報を取得。
        const workSite = await fetch.get(
          `/work_site/${result.data.workSiteId}`,
        );
        if (workSite.status === 200) {
          setWorkSite(workSite.data);
        } else if (workSite.status === 500) {
          alertWithCode(workSite.status);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      } else {
        alert('まだ編集できません:' + result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={workSite?.name}
        subTitle={`センサーデバイス編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${workSite?.id}`}
      />
      <div className={classes.root}>
        {mode === 'editing' && <DeviceEditForm {...formik} />}
        {mode === 'confirm' && <DeviceEditConfirm {...formik} />}
        {mode === 'commit' && (
          <DeviceCommit
            message={`センサーデバイスを更新しました。\nセンサーデバイス一覧ページから確認してください。`}
          />
        )}
      </div>
    </>
  );
};

export default DeviceEdit;

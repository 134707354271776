import { Switch } from 'react-router-dom';
import PublicRoute from './components/layout/PublicRoute';
import PrivateRoute from './components/layout/PrivateRoute';
import CommonLayout from './components/layout/CommonLayout';
import SigninLayout from './components/layout/SigninLayout';
import PrintLayout from './components/layout/PrintLayout';
import NoLayout from './components/layout/NoLayout';
import * as t from './templates';

const Router = () => {
  return (
    <Switch>
      <PublicRoute
        exact
        path={'/signin'}
        component={t.SignIn}
        layout={SigninLayout}
      />
      <PublicRoute
        exact
        path={'/passwordReset/:status(commit)?'}
        component={t.PasswordResetEmail}
        layout={SigninLayout}
      />
      <PublicRoute
        exact
        path={'/password/:token/:status(commit)?'}
        component={t.PasswordResetPassword}
        layout={CommonLayout}
      />
      <PrivateRoute exact path={'/'} component={t.Home} layout={CommonLayout} />
      {/* ユーザ関連 */}
      <PrivateRoute
        exact
        path={'/organization/:id/users'}
        component={t.UsersList}
        layout={CommonLayout}
      />
      <PublicRoute
        exact
        path={'/user/:token'}
        component={t.UserCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/user/add'}
        component={t.UserInvitation}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/my/edit/:status(confirm|commit)?'}
        component={t.UserEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/user/:userId/edit/:status(confirm|commit)?'}
        component={t.UserAuthorityEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/my/password/edit'}
        component={t.PasswordEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/my/password/commit'}
        component={t.PasswordCommit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/my'}
        component={t.MyPage}
        layout={CommonLayout}
      />
      {/* 組織関連 */}
      <PrivateRoute
        exact
        path={'/organization/:id/organization/create/:status(confirm|commit)?'}
        component={t.OrganizationCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/edit/:status(confirm|commit)?'}
        component={t.OrganizationEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/organizations'}
        component={t.OrganizationsList}
        layout={CommonLayout}
      />
      {/** アンケート設定 */}
      <PrivateRoute
        exact
        path={'/organization/:id/enquete'}
        component={t.EnqueteDetail}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/enquete/edit/:status(confirm|commit)?'}
        component={t.EnqueteEdit}
        layout={CommonLayout}
      />
      {/** プロジェクト お知らせ */}
      <PrivateRoute
        exact
        path={'/organization/:id/notifications'}
        component={t.OrganizationsNotificationsList}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/notifications/create/:status(confirm|commit)?'}
        component={t.OrganizationsNotificationsCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={
          '/organization/:id/notifications/:nid/edit/:status(confirm|commit)?'
        }
        component={t.OrganizationsNotificationsEdit}
        layout={CommonLayout}
      />
      {/* 現場関連 */}
      <PrivateRoute
        exact
        path={'/organization/:id/workSite/create/:status(confirm|commit)?'}
        component={t.WorkSiteCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/edit/:status(confirm|commit)?'}
        component={t.WorkSiteEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/alert/:status(confirm|commit)?'}
        component={t.WorkSiteAlert}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/organization/:id/workSites'}
        component={t.WorkSitesList}
        layout={CommonLayout}
      />
      {/** 現場 お知らせ */}
      <PrivateRoute
        exact
        path={'/workSite/:id/notifications'}
        component={t.WorkSitesNotificationsList}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/notifications/create/:status(confirm|commit)?'}
        component={t.WorkSitesNotificationsCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/notifications/:nid/edit/:status(confirm|commit)?'}
        component={t.WorkSitesNotificationsEdit}
        layout={CommonLayout}
      />
      {/* 作業員関連 */}
      <PrivateRoute
        exact
        path={'/workSite/:id/worker/create/:status(confirm|commit)?'}
        component={t.WorkerCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/worker/:workerId/edit/:status(confirm|commit)?'}
        component={t.WorkerEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/worker/detail/:id'}
        component={t.WorkerDetail}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/worker/multipleCreate/:status(confirm|commit)?'}
        component={t.WorkersCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/workers'}
        component={t.WorkersList}
        layout={CommonLayout}
      />
      {/* デバイス関連 */}
      <PrivateRoute
        exact
        path={'/workSite/:id/device/create/:status(confirm|commit)?'}
        component={t.DeviceCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/device/:id/edit'}
        component={t.DeviceEdit}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/device/multipleCreate/:status(confirm|commit)?'}
        component={t.DevicesCreate}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/workSite/:id/devices'}
        component={t.DevicesList}
        layout={CommonLayout}
      />
      {/* ダッシュボード */}
      <PrivateRoute
        exact
        path={'/dashboard/organization/:id'}
        component={t.OrganizationDashboard}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/dashboard/workSite/:id'}
        component={t.WorkSiteDashboard}
        layout={CommonLayout}
      />
      {/* サブコンテンツ */}
      <PrivateRoute
        exact
        path={'/introduction'}
        component={t.Introduction}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/licence'}
        component={t.Licence}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/term_of_use'}
        component={t.TermOfUse}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/help'}
        component={t.Help}
        layout={CommonLayout}
      />
      {/* レポート */}
      <PrivateRoute
        exact
        path={
          '/workSite/:id/report/:kind(worker|worksite)/:type(daily|monthly)'
        }
        component={t.ReportSelect}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/reports/worker/:id/daily/:date'}
        component={t.WorkersDailyReports}
        layout={PrintLayout}
      />
      <PrivateRoute
        exact
        path={'/reports/worker/:id/monthly/:date'}
        component={t.WorkersMonthlyReports}
        layout={PrintLayout}
      />
      <PrivateRoute
        exact
        path={'/reports/workSite/:id/monthly/:date'}
        component={t.WorksiteMonthlyReports}
        layout={PrintLayout}
      />

      {/* 2要素認証設定 */}
      <PrivateRoute
        exact
        path={'/organization/twoFactor/:id'}
        component={t.SettingTwoFactors}
        layout={CommonLayout}
      />

      {/* サブコンテンツ ヘルプ */}
      <PrivateRoute
        exact
        path={'/help'}
        component={t.Help}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/help/app'}
        component={t.HelpApp}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/help/usage'}
        component={t.HelpUsageSummary}
        layout={CommonLayout}
      />
      <PrivateRoute
        exact
        path={'/help/usage/:page(basic|editing|report|notice)'}
        component={t.HelpUsageContents}
        layout={CommonLayout}
      />

      {/* アプリからの閲覧 */}
      <PublicRoute
        exact
        path={'/app/help'}
        component={t.AppHelp}
        layout={NoLayout}
      />

      {/* Not Found */}
      <PrivateRoute component={t.NotFound} />
    </Switch>
  );
};
export default Router;

import {FC, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
} from '@material-ui/core';
import StatusCell from '../common/UploadConfirmTable/StatusCell';
import ValueCell from '../common/UploadConfirmTable/ValueCell';
import { UISubmit, UIReturn } from '../index';
import { fetch, useAlert } from '../../functions';
import { ApiGetWorker } from '../../types/worker';
import useStyles from '../../assets/styles/List.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};
type FormValues = {
  file: any;
};
type Props = FormikProps<FormValues> & {
  data: any;
};
type CsvRow = {
  origin?: any;
  newdata?: any;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkerConfirm: FC<Props> = (props) => {
  const { handleSubmit, data } = props;
  const [workers, setWorkers] = useState<CsvRow[]>([]);
  const { id } = useParams<Params>();
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const getOrigin = (loginName: string, resultDataList: ApiGetWorker[]) => {
    const resultData = resultDataList.find((d: ApiGetWorker) => d.loginName === loginName)
    if(resultData) {
      return {
        ...resultData,
        groupNumber: String(resultData.groupNumber),
        alertNumber: String(resultData.alertNumber ?? ''),
        alertValue: String(resultData.alertValue ?? ''),
        birthday: resultData.birthday.replace(' 00:00:00', ''),
        labels: resultData.labels.map((l: any) => l.name).join(' '),
      };
    }
    return void 0;
  };

  // 現在のデバイス情報と、CSVのデバイス情報を結合させる
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/work_site/${id}/workers`);
      if (result.status === 200) {
        let row: CsvRow[] = [];
        const inputLoginNameList = data.map((inputData: any) => inputData['ログイン名']);
        const resultLoginNameList = result.data.map((resultData: ApiGetWorker) => resultData.loginName);

        // 追加
        inputLoginNameList.forEach((inputLoginName: string) => {
          const changeTarget = resultLoginNameList.filter((resultLoginName: string) => inputLoginName === resultLoginName);
          if (changeTarget.length === 0) {
            row.push({
              origin: undefined,
              newdata: data.find((inputData: any) => inputData['ログイン名'] === inputLoginName),
            });
          }
        });

        // 変更
        inputLoginNameList.forEach((inputLoginName: string) => {
          const changeTarget = resultLoginNameList.filter((resultLoginName: string) => inputLoginName === resultLoginName);
          if (changeTarget.length > 0) {
            row.push({
              origin: getOrigin(inputLoginName, result.data),
              newdata: data.find((inputData: any) => inputData['ログイン名'] === inputLoginName),
            });
          }
        });

        //  削除
        resultLoginNameList.forEach((resultLoginName: string) => {
          const changeTarget = inputLoginNameList.filter((inputLoginName:  string) => inputLoginName === resultLoginName);
          if (changeTarget.length === 0) {
            row.push({
              origin: getOrigin(resultLoginName, result.data),
              newdata: undefined,
            });
          }
        });

        setWorkers(row);
      } else if(result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, data, alertWithCode]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Table className={classes.table}>
        <TableHead className={classes.row}>
          <TableRow>
            <TableCell className={classes.head}></TableCell>
            <TableCell className={classes.head}>ログイン名</TableCell>
            <TableCell className={classes.head}>グループ番号</TableCell>
            <TableCell className={classes.head}>管理番号</TableCell>
            <TableCell className={classes.head}>ユーザー名</TableCell>
            <TableCell className={classes.head}>ユーザー名（サブ）</TableCell>
            <TableCell className={classes.head}>ユーザー名　フリガナ</TableCell>
            <TableCell className={classes.head}>
              ユーザー名　フリガナ（サブ）
            </TableCell>
            <TableCell className={classes.head}>生年月日</TableCell>
            <TableCell className={classes.head}>性別</TableCell>
            <TableCell className={classes.head}>メールアドレス</TableCell>
            <TableCell className={classes.head}>電話番号</TableCell>
            <TableCell className={classes.head}>郵便番号</TableCell>
            <TableCell className={classes.head}>管理用住所</TableCell>
            <TableCell className={classes.head}>所属</TableCell>
            <TableCell className={classes.head}>アラート設定</TableCell>
            <TableCell className={classes.head}>アラート設定値</TableCell>
            <TableCell className={classes.head}>ラベル</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {workers.map((d: CsvRow, index) => (
            <TableRow key={index}>
              <StatusCell origin={d.origin} newdate={d.newdata} />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="loginName"
                newdataKey="ログイン名"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="groupNumber"
                newdataKey="グループ番号"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="controlNumber"
                newdataKey="管理番号"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="firstname"
                newdataKey="ユーザー名"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="lastname"
                newdataKey="ユーザー名（サブ）"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="firstnameKana"
                newdataKey="ユーザー名　フリガナ"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="lastnameKana"
                newdataKey="ユーザー名　フリガナ（サブ）"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="birthday"
                newdataKey="生年月日"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="sex"
                newdataKey="性別"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="email"
                newdataKey="メールアドレス"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="phone"
                newdataKey="電話番号"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="post"
                newdataKey="郵便番号"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="address"
                newdataKey="管理用住所"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="affiliation"
                newdataKey="所属"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="alertNumber"
                newdataKey="アラート設定"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="alertValue"
                newdataKey="アラート設定値"
              />
              <ValueCell
                origin={d.origin}
                newdata={d.newdata}
                originKey="labels"
                newdataKey="ラベル"
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確定" />
      </FormGroup>
    </form>
  );
};

export default WorkerConfirm;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    news: {
      backgroundColor: '#fff',
      borderRadius: '5px',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
      margin: '0 auto',
      padding: '15px',
      '&>*': {
        color: '#666666',
      },
      width: '35rem',
    },
    newsTitle: {
      fontSize: '1rem',
      marginBottom: '.5rem',
      textAlign: 'center',
      '&>*': {
        marginRight: '0.5rem',
        verticalAlign: 'middle',
      },
    },
    icon: {
      height: '20px',
      width: '20px',
    },
    newsItem: {
      height: 'auto',
      minHeight: '48px',
      '&:hover': {
        backgroundColor: '#F9F9F9',
      },
    },
    newsItemDate: {
      width: '78px',
      paddingRight: '16px',
      flex: 'none',
    },
    dialogWidth: {
      minWidth: '600px',
    },
    dialogStatus: {
      margin: '0 0 8px 0',
      padding: '4px',
      fontWeight: 'bold',
      fontSize: '12px',
      color: '#666',
    },
    dialogDescription: {
      padding: '8px',
      whiteSpace: 'pre-line',
    },
  }),
  { index: 1 },
);
export default useStyles;

import _ from 'lodash';
import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Empty from '../../Empty';
import DangerGraph from './DangerGraph';
import TransitionGraph from './TransitionGraph';
import DatePicker from '../../DatePicker';
import TimePicker from '../../TimePicker';
import {
  commonStyles,
  worksiteStyles,
} from '../../../../assets/styles/Dashboard.css';
import { fetch } from '../../../../functions/fetch';
import Button from '@material-ui/core/Button';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};
type Props = {
  data: any[];
  worksite: any;
  organization: any;
  isBeforeTargetDate: boolean;
  displayDateTime: string;
  setDisplayDateTime: Dispatch<SetStateAction<string>>;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const GroupStatus: FC<Props> = (props: Props) => {
  const {
    data,
    worksite,
    organization,
    isBeforeTargetDate,
    displayDateTime,
    setDisplayDateTime,
  } = props;
  const { id } = useParams<Params>();
  const [workers, setWorkers] = useState<any>([]);
  const classes = commonStyles();
  const wsClasses = worksiteStyles();

  //  作業者一覧の取得
  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/work_site/${id}/workers`);
      if (result.status === 200) {
        setWorkers(result.data);
      }
    })();
  }, [id]);

  //　日時更新ボタン
  const handleUpdateCurrentDate = () => {
    setDisplayDateTime('');
    //組織ダッシュボードで選択した日時のパラメーターをクリア
    window.history.replaceState({}, '');
  };

  return (
    <div className={classes.block}>
      <Box display="flex" alignItems="center">
        <Box>
          <h3 className={classes.blockTitle}>グループの状態</h3>
        </Box>
        <Box>
          <Box display="flex" className={wsClasses.datePickerArea}>
            <Box>
              <DatePicker
                value={displayDateTime}
                setValue={setDisplayDateTime}
              />
            </Box>
            <Box>
              <TimePicker
                value={displayDateTime}
                isBeforeTargetDate={isBeforeTargetDate}
                setValue={setDisplayDateTime}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateCurrentDate}
          >
            更新
          </Button>
        </Box>
      </Box>

      <Empty isEmpty={worksite.groups.length === 0} label="未登録">
        <Box display="flex" flexWrap="wrap">
          {worksite.groups.map((group: any) => {
            if (group.isHidden) return null;
            const d = _.find(data, { groupNumber: group.number });
            const worker = _.filter(workers, { groupNumber: group.number });
            if (d === undefined || worker.length === 0) {
              return null;
            }
            return (
              <Box className={classes.group} key={group.id}>
                <h4 className={classes.groupTitle}>
                  {group.number}. {group.name}
                </h4>
                <DangerGraph
                  alertLevel1={d ? d.alertLevel1 : 0}
                  alertLevel2={d ? d.alertLevel2 : 0}
                  alertLevel3={d ? d.alertLevel3 : 0}
                  alertLevel4={d ? d.alertLevel4 : 0}
                  totalCount={d ? d.totalWorkerCount : 0}
                  organization={organization}
                  groupName={group.name}
                  worker={worker}
                />
                <TransitionGraph
                  data={d ? JSON.parse(d.bodyHeatIndexAverageData) : []}
                  lastData={d ? JSON.parse(d.bodyHeatIndexAverageLastData) : []}
                />
              </Box>
            );
          })}
        </Box>
      </Empty>
    </div>
  );
};

export default GroupStatus;

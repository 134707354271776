import { FC, useState, useEffect } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Line,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import format from 'date-fns/format';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: any;
  lastData: any;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const TransitionGraph: FC<Props> = (props: Props) => {
  const { data, lastData } = props;
  const classes = commonStyles();
  const [points, setPoints] = useState<any>([]);

  // 初期化
  useEffect(() => {
    const _data: any[] = [];

    data.forEach((d: any, index: number) => {
      const lastBodyHeat = lastData[index] ?? null;

      _data.push({
        timestamp: format(new Date(d.timestamp), 'HH:mm'),
        bodyHeatIndex: Number(d.bodyHeatIndexAverage),
        lastBodyHeatIndex: lastBodyHeat
          ? Number(lastBodyHeat.bodyHeatIndexAverage)
          : 0,
      });
    });
    setPoints(_data);
  }, [data, lastData]);

  return (
    <>
      <h4 className={classes.bodyTransitionTitle}>カラダ暑さ指標推移</h4>
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart data={points}>
          <Legend verticalAlign="top" height={36} iconType="square" />
          <CartesianGrid stroke="#e5e5e5" vertical={false} />
          <XAxis dataKey="timestamp" fontSize={10} />
          <YAxis fontSize={10} />
          <Line
            name="前日"
            type="monotone"
            dataKey="lastBodyHeatIndex"
            stroke="#00A3F1"
            dot={false}
          />
          <Area
            name="今日"
            type="monotone"
            dataKey="bodyHeatIndex"
            stroke="#FFB9CB"
            fill="#FFDEE6"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default TransitionGraph;

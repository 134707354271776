import format from 'date-fns/format';

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const XAxisDateTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      {payload.value !== 'auto' && new Date(payload.value).getDay() === 0 && (
        <rect
          x={-8}
          y={6}
          width="16"
          height="13"
          fill="none"
          stroke="#666"
          strokeWidth="1"
        />
      )}
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize={9}>
        {payload.value !== 'auto' && format(new Date(payload.value), 'd')}
      </text>
    </g>
  );
};

export default XAxisDateTick;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import useStyles from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  sub?: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSummaryBasic = (props: Props) => {
  const { sub = false } = props;
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(location.hash.replace('#', ''));
    if (element !== null) {
      element.scrollIntoView(true);
    }
  }, [location.hash]);

  return (
    <Paper className={classes.summaryBody}>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step1">STEP1　ログイン</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>初回ログイン時のユーザー登録</li>
            <li>2回目以降のログイン</li>
            <li>2段階認証設定</li>
            <li>アカウントがロックされた場合</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step2">STEP2　組織を作る</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step3">STEP3　現場を作る</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step4">
          STEP4　センサーデバイスを登録する
        </Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>センサーデバイス登録の仕組み</li>
            <li>1台ずつ登録する</li>
            <li>複数台を一括で登録する</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step5">STEP5　装着者を登録する</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>装着者登録の仕組み</li>
            <li>1人ずつ登録する</li>
            <li>複数人を一括登録する</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step6">STEP6　アラートを設定する</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>アラート設定の仕組み</li>
            <li>ご契約時のアラート設定値を変更する</li>
            <li>現場単位でのアラート設定値を変更する</li>
            <li>現場のグループ単位でのアラート設定値を変更する</li>
            <li>装着者一人ひとりのアラート設定値を変更する　</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step7">
          STEP7　アラート通知先を設定する
        </Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step8">STEP8　管理者を増やす</Link>
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/basic#Step9">STEP9　アンケートを設定する</Link>
      </div>
    </Paper>
  );
};

export default HelpSummaryBasic;

import { memo, useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import format from 'date-fns/format';
import { fetch } from '../functions/fetch';
import { ApiGetNotification } from '../types/notifications';
import useStyles from '../assets/styles/News.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  apiurl: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const News = memo((props: Props) => {
  const { apiurl } = props;
  const [news, setNews] = useState<ApiGetNotification[]>([]);
  const [dialogData, setDialogData] = useState<ApiGetNotification>();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const listSelect = (node: ApiGetNotification) => {
    setDialogData(node);
    setIsOpen(true);
  };

  useEffect(() => {
    (async () => {
      const result = await fetch.get(apiurl);

      if (result.status === 200) {
        setNews(result.data);
      }
    })();
  }, [apiurl]);

  return (
    <div className={classes.news}>
      <div className={classes.newsTitle}>
        <NotificationsActiveIcon className={classes.icon} />
        <span>お知らせ一覧</span>
      </div>
      <Divider />
      <List
        component="nav"
        className="vertical_scroll"
        style={{ height: '200px' }}
      >
        {news.map((node) => (
          <ListItem
            key={node.id}
            button
            className={classes.newsItem}
            onClick={() => listSelect(node)}
          >
            <ListItemText
              className={classes.newsItemDate}
              primary={format(new Date(node.postDate), 'yy.MM.dd')}
            />
            <ListItemText primary={node.title} />
          </ListItem>
        ))}
      </List>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        {dialogData && (
          <>
            <DialogTitle>{dialogData.title}</DialogTitle>
            <DialogContent className={classes.dialogWidth}>
              <Box className={classes.dialogStatus} display="flex">
                <Box flexGrow={1}>
                  {format(new Date(dialogData.postDate), 'yy.MM.dd')}
                </Box>
                <Box>{dialogData.from}</Box>
              </Box>

              <DialogContentText className={classes.dialogDescription}>
                {dialogData.description}
              </DialogContentText>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary" autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default News;

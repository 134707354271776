import { FC } from 'react';
import { FormikProps } from 'formik';
import FormGroup from '@material-ui/core/FormGroup';
import { PrimaryReadOnlyField, UISubmit, UIReturn, UIError } from '../index';
import useStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationConfirm: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, values, errors } = props;
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={classes.errorMessage}>
        <UIError errors={errors} />
      </p>

      <PrimaryReadOnlyField label="センサーデバイス名" value={values.name} />

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確定" />
      </FormGroup>
    </form>
  );
};

export default OrganizationConfirm;

import { useEffect, useState } from 'react';
import clsx from 'clsx';
import useStyle from '../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const GotoTopButton = () => {
  const classes = useStyle();
  const [isActive, setIsActive] = useState(false);
  const [isLast, setIsLast] = useState(false);

  //  トップへ戻る
  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  //  スクロール時の処理
  const scrollWindow = () => {
    var obj = document.getElementById('root');

    if (100 <= window.scrollY) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    if (obj && obj.scrollHeight - 1000 < window.scrollY) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }
  };

  //  イベントの登録
  useEffect(() => {
    window.addEventListener('scroll', scrollWindow);
    return () => {
      window.removeEventListener('scroll', scrollWindow);
    };
  }, []);

  return (
    <img
      className={clsx(
        classes.gotoTop,
        isActive && classes.gotoTopActive,
        isLast && classes.gotoTopLast,
      )}
      src="/images/help/top.svg"
      alt="トップへ戻る"
      onClick={onClick}
    />
  );
};

export default GotoTopButton;

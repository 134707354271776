import _ from 'lodash';
import { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import {
  UITextField,
  UISelect,
  UIDatePicker,
  UIToggleLabel,
  FormHowToUse,
} from '../index';
import { SEX_TYPE } from '../../constants/common';
import { ApiDefaultAlert } from '../../types/default_alert';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import PrimaryReadOnlyField from '../uikit/PrimaryReadOnlyField';
import WorkSiteAlertData from './WorkSiteAlertData';
import { getAlertParam } from '../../functions';
import { Grid } from '@material-ui/core';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  //  基本情報
  firstname: string;
  lastname: string;
  firstnameKana: string;
  lastnameKana: string;
  sex: string;
  birthday: string;
  phone: string;
  email: string;
  post: string;
  address: string;
  labels: string[];

  //  管理情報
  loginName: string;
  groupNumber: string;
  controlNumber: string;
  affiliation: string;

  //  アラート情報
  alertNumber: number | null;
  alertValue: number | null;
}
type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
  workSite: {
    name: string;
    alertNumber: number | null;
    alertValue: number | null;
    groups: any[];
  };
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<Props> = (props) => {
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    default_alerts,
    workSite,
  } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  const [alert_list, setAlertList] = useState([]);

  //  Select 用の番号リストを生成
  const groupNumbers = useMemo(() => {
    const menuList = [];
    if (workSite) {
      workSite.groups.forEach((group, index) => {
        if (!group.isHidden) {
          menuList.push({ name: String(index + 1), value: String(index + 1) });
        }
      });
    }
    if (menuList.length === 0) {
      menuList.push({ name: '未設定', value: '' });
    }
    return menuList;
  }, [workSite]);

  const alert = (alertNumber: number | null) =>
    _.find(default_alerts, { number: alertNumber });
  const customAlert: any = alert(values.alertNumber);
  const group = _.find(workSite.groups, { number: Number(values.groupNumber) });
  const groupAlert: any = alert(group?.alertNumber);
  const alertParam = getAlertParam([values, group, workSite], default_alerts);

  //  Select Box の変化でアラート値を決定
  const uiSelectHandleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('alertValue', customAlert?.value);
    setFieldValue('alertNumber', e.target.value);
  };

  //  デフォルトアラート情報を取得
  useEffect(() => {
    const alert: any = [];
    default_alerts.forEach((o) => {
      alert.push({
        name: o.name,
        value: o.number,
      });
    });

    setAlertList(alert);
  }, [default_alerts]);

  useEffect(() => {
    errors && window.scrollTo(0, 0);
  }, [errors]);

  useEffect(() => {
    setFieldValue('groupNumber', groupNumbers[0].value);
  }, [groupNumbers, setFieldValue]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormHowToUse />
      <p>新規に作成する装着者情報を入力してください</p>
      <p style={{ color: '#FF0000', fontSize: '16px' }}>
        入力完了後、登録ボタンを押してください！
      </p>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={masterClasses.errorMessage}>
            {errors.loginName ? <div>{errors.loginName}</div> : null}
            {errors.firstname ? <div>{errors.firstname}</div> : null}
            {errors.lastname ? <div>{errors.lastname}</div> : null}
            {errors.firstnameKana ? <div>{errors.firstnameKana}</div> : null}
            {errors.lastnameKana ? <div>{errors.lastnameKana}</div> : null}
            {errors.sex ? <div>{errors.sex}</div> : null}
            {errors.birthday ? <div>{errors.birthday}</div> : null}
            {errors.phone ? <div>{errors.phone}</div> : null}
            {errors.email ? <div>{errors.email}</div> : null}
            {errors.post ? <div>{errors.post}</div> : null}
            {errors.address ? <div>{errors.address}</div> : null}
            {errors.affiliation ? <div>{errors.affiliation}</div> : null}
            {errors.controlNumber ? <div>{errors.controlNumber}</div> : null}
            {errors.groupNumber ? <div>{errors.groupNumber}</div> : null}
            {errors.alertValue ? <div>{errors.alertValue}</div> : null}
          </div>

          <div className={uikitClasses.root}>
            <UITextField
              name="loginName"
              label="装着者ログイン名"
              value={values.loginName}
              error={errors.loginName}
              required
              handleChange={handleChange}
              helperText="全角カタカナ・全角数字のみ ※「９９９９」は不可"
            />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={uikitClasses.root}>
                <UITextField
                  type="text"
                  name="firstname"
                  label="ユーザー名"
                  value={values.firstname}
                  error={errors.firstname}
                  required
                  handleChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={uikitClasses.root}>
                <UITextField
                  type="text"
                  name="firstnameKana"
                  label="ユーザー名　フリガナ"
                  value={values.firstnameKana}
                  error={errors.firstnameKana}
                  handleChange={handleChange}
                  helperText="全角カタカナのみ"
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className={uikitClasses.root}>
                <UISelect
                  name="sex"
                  label="性別"
                  value={values.sex}
                  error={errors.sex}
                  required
                  handleChange={handleChange}
                  menus={SEX_TYPE}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={uikitClasses.root}>
                <UIDatePicker
                  name="birthday"
                  label="生年月日"
                  value={values.birthday}
                  error={errors.birthday}
                  setFieldValue={setFieldValue}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={uikitClasses.root}>
                <UITextField
                  name="phone"
                  label="電話番号"
                  value={values.phone}
                  error={errors.phone}
                  handleChange={handleChange}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className={uikitClasses.root}>
                <UITextField
                  name="post"
                  label="郵便番号"
                  value={values.post}
                  error={errors.post}
                  required
                  handleChange={handleChange}
                  helperText="数字7桁（ハイフンなし）"
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={uikitClasses.root}>
                <UITextField
                  name="address"
                  label="管理用住所"
                  value={values.address}
                  error={errors.address}
                  handleChange={handleChange}
                />
              </div>
            </Grid>
          </Grid>

          <div className={uikitClasses.root}>
            <UITextField
              name="affiliation"
              label="所属"
              value={values.affiliation}
              error={errors.affiliation}
              required
              handleChange={handleChange}
            />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div className={uikitClasses.root}>
                <UITextField
                  name="controlNumber"
                  label="管理番号"
                  value={values.controlNumber}
                  error={errors.controlNumber}
                  handleChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={uikitClasses.root}>
                <UISelect
                  name="groupNumber"
                  label="グループ番号"
                  value={values.groupNumber}
                  error={errors.groupNumber}
                  handleChange={handleChange}
                  menus={groupNumbers}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={uikitClasses.root}>
            <UISelect
              name="alertNumber"
              label="アラート設定"
              value={String(values.alertNumber ?? '')}
              error={errors.alertNumber}
              handleChange={uiSelectHandleChange}
              menus={alert_list}
              empty="現場グループの設定を反映"
            />
            {values.alertNumber !== null &&
            typeof values.alertNumber !== 'string' ? (
              <>
                {values.alertNumber === 0 ? (
                  <UITextField
                    type="text"
                    name="alertValue"
                    label="アラート設定値"
                    value={String(values.alertValue ?? 0)}
                    error={errors.alertValue}
                    required
                    handleChange={handleChange}
                  />
                ) : (
                  <PrimaryReadOnlyField
                    label="アラート設定値"
                    value={String(customAlert ? customAlert.value : '未設定')}
                  />
                )}
              </>
            ) : (
              <WorkSiteAlertData
                workSiteName={
                  groupAlert
                    ? `グループ: ${group.name}`
                    : `現場: ${workSite.name}`
                }
                alertNumber={alertParam?.label}
                alertValue={String(alertParam?.value)}
              />
            )}
          </div>

          <div className="module-spacer--extra-small" />

          <div className={uikitClasses.root}>
            <UIToggleLabel
              name="labels"
              value={values.labels}
              setFieldValue={setFieldValue}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;

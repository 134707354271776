//  CSVヘッダー 作業者用
export const CsvWorkerHeader = [
  'ログイン名',
  'グループ番号',
  '管理番号',
  'ユーザー名',
  'ユーザー名（サブ）',
  'ユーザー名　フリガナ',
  'ユーザー名　フリガナ（サブ）',
  '生年月日',
  '性別',
  'メールアドレス',
  '電話番号',
  '郵便番号',
  '管理用住所',
  '所属',
  'アラート設定',
  'アラート設定値',
  'ラベル',
];

//  CSVヘッダー デバイス用
export const CsvDeviceHeader = ['センサーデバイス名'];

import _ from 'lodash';
import { FC, useState, useEffect } from 'react';
import { Box, Collapse, useTheme, useMediaQuery } from '@material-ui/core';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import clsx from 'clsx';
import LabelList from './LabelList';
import WorkerStatus from './WorkerStatus';
import FilterDialog from './FilterDialog';
import Empty from '../../Empty';
import arrow from '../../../../assets/images/arrow.svg';
import {
  commonStyles,
  worksiteStyles,
} from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  data: any[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const AlertWorkers: FC<Props> = (props: Props) => {
  const classes = commonStyles();
  const wsClasses = worksiteStyles();
  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.up('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const [beforeData, setBeforeDate] = useState<any>([]);
  const [afterData, setAfterDate] = useState<any>([]);
  const [preCount, setPreCount] = useState(0);
  const [isMore, setIsMore] = useState<boolean>(false);
  const [accessTime, setAccessTime] = useState('');

  useEffect(() => {
    const now = new Date();
    const hh = `0${now.getHours()}`.slice(-2);
    const mm = `0${now.getMinutes()}`.slice(-2);
    setAccessTime(`${hh}:${mm}`);
  }, []);

  //  絞り込み
  const setFilter = (labels: string[]) => {
    let data = props.data;

    if (labels.length > 0) {
      data = _.filter(props.data, (o) => {
        let isMatch = false;
        o.labels.forEach((label: any) => {
          isMatch = isMatch || labels.indexOf(label.name) !== -1;
        });
        return isMatch;
      });
    }

    setBeforeDate(data.slice(0, preCount));
    setAfterDate(data.slice(preCount));
  };

  //  初期表示件数を絞る
  useEffect(() => {
    const _preCount = isXLarge ? 5 : isLarge ? 4 : 3;
    setBeforeDate(props.data.slice(0, _preCount));
    setAfterDate(props.data.slice(_preCount));
    setPreCount(_preCount);
  }, [props.data, isLarge, isXLarge]);

  return (
    <div className={classes.block}>
      <Box display="flex" alignItems="center">
        <Box>
          <h3
            className={classes.blockTitle}
          >{`アラート発報者と経過時間(${accessTime}時点)`}</h3>
        </Box>
        <Box>
          <FilterDialog setFilter={setFilter} />
        </Box>
      </Box>

      <Empty isEmpty={beforeData.length === 0} label="該当者なし">
        <>
          <Box display="flex" flexWrap="wrap">
            {beforeData.map((d: any, index: any) => (
              <Box className={classes.alert} key={index}>
                <WorkerStatus
                  name={`${d.firstname} ${d.lastname}`}
                  company={d.affiliation}
                  time={
                    d.alertStartTime
                      ? differenceInMinutes(
                          new Date(),
                          new Date(d.alertStartTime),
                        )
                      : 0
                  }
                />
                <LabelList labels={d.labels} />
              </Box>
            ))}
          </Box>

          <Collapse in={isMore}>
            <Box display="flex" flexWrap="wrap">
              {afterData.map((d: any, index: any) => (
                <Box className={classes.alert} key={index}>
                  <WorkerStatus
                    name={`${d.firstname} ${d.lastname}`}
                    company={d.affiliation}
                    time={
                      d.alertStartTime
                        ? differenceInMinutes(
                            new Date(),
                            new Date(d.alertStartTime),
                          )
                        : 0
                    }
                  />
                  <LabelList labels={d.labels} />
                </Box>
              ))}
            </Box>
          </Collapse>

          {beforeData.length + afterData.length > preCount && (
            <>
              <div className={wsClasses.moreBg}></div>
              <img
                className={clsx(
                  wsClasses.moreIcon,
                  isMore && wsClasses.moreIconClose,
                )}
                src={arrow}
                alt="もっと見る"
                onClick={() => setIsMore(!isMore)}
              />
            </>
          )}
        </>
      </Empty>
    </div>
  );
};

export default AlertWorkers;

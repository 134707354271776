import { FC } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import {
  UITextField,
  UISubmit,
  UIReturn,
  UIError,
  FormHowToUse,
} from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
  companyName: string;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, handleChange, values, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormHowToUse />
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div>
        <UITextField
          type="text"
          name="companyName"
          label="会社名"
          value={values.companyName}
          error={errors.companyName}
          required
          handleChange={handleChange}
        />
      </div>

      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="name"
          label="会社名・部署名"
          value={values.name}
          error={errors.name}
          required
          handleChange={handleChange}
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

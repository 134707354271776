import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import useStyles from '../../assets/styles/Master.css';
import {
  SubHeader,
  WorkSiteAlertConfirm,
  WorkSiteAlertForm,
  WorkSiteCommit,
} from '../../components';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { ApiDefaultAlert } from '../../types/default_alert';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  alertNumber: yup.number().required('アラート設定を選択してください'),
  alertValue: yup
    .number()
    .nullable()
    .when('alertNumber', {
      is: (value: any) => value === 0,
      then: yup
        .number()
        .min(100, 'アラート設定値は100以上を指定してください')
        .max(65000, 'アラート設定値は65000以下を指定してください')
        .required('アラート設定値を入力してください'),
    }),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * @todo 一時的に groups を送信（専用API未対応のため汎用更新APIで代用）
 * -------------------------------------------------------------------------- */
const WorksiteAlert: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [organization, setOrganization] = useState<any>();
  const [worksite, setWorksite] = useState({
    organizationId: '',
    alertNumber: 1,
    alertValue: 0,
  });
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>();

  //  フォームの定義
  const formik = useFormik({
    initialValues: worksite,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.put(`/work_site/${id}`, values);

        if (result.status === 200) {
          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  //  初期値取得
  useEffect(() => {
    (async () => {
      //  デフォルトアラートリストと指定IDのアラート情報を取得
      const result = await fetch.get(`/work_site/${id}`);
      if (result.status === 200) {
        //  現場情報を元にデフォルトアラートを取得
        const daResult = await fetch.get(
          `/default_alert_v2?organizationId=${result.data.organizationId}`,
        );
        if (daResult.status === 200) {
          setDefaltAlerts([
            ...daResult.data,
            { number: 0, name: 'カスタム', value: 100 },
          ]);

          const alert: any = _.find(daResult.data, {
            number: result.data.alertNumber ?? 1,
          });
          result.data.alertValue = result.data.alertValue ?? alert.value;
        } else if (daResult.status === 500) {
          alertWithCode(result.status);
        }

        setWorksite(result.data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }

      //  組織情報の取得
      const orgResult = await fetch.get(
        `/organization/${result.data.organizationId}`,
      );
      if (orgResult.status === 200) {
        setOrganization(orgResult.data);
      } else if (orgResult.status === 500) {
        alertWithCode(orgResult.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={organization && `${organization.name}`}
        subTitle={`現場のアラート管理${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${id}`}
      />

      <div className={classes.root}>
        {mode === 'editing' && default_alerts && (
          <WorkSiteAlertForm {...formik} default_alerts={default_alerts} />
        )}
        {mode === 'confirm' && default_alerts && (
          <WorkSiteAlertConfirm {...formik} default_alerts={default_alerts} />
        )}
        {mode === 'commit' && (
          <WorkSiteCommit
            message="現場のアラート情報を編集しました。"
            linkToList={`/organization/${worksite.organizationId}/workSites`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(WorksiteAlert);

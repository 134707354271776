import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useStyles from '../../assets/styles/Master.css';
import {
  OrganizationCommit,
  OrganizationEditConfirm,
  OrganizationEditForm,
  SubHeader,
} from '../../components';
import { fetch, yup, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { getOrganizations } from '../../redux/commonSlice';

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const OrganizationEdit: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState({
    id: '',
    parentId: '',
    name: '',
    companyName: '',
  });
  const [organizations, setOrganizations] = useState([{ name: '' }]);

  const schema = yup.object({
    name: yup
      .string()
      .specialCharacter('会社・部署名に機種依存文字が含まれています')
      .max(255, '会社・部署名は255文字まで入力できます')
      .required('会社・部署名を入力してください')
      .test('duplex-name', 'この会社・部署名は登録済みです', (value) =>
        duplexTest(value),
      ),
    companyName: yup
      .string()
      .specialCharacter('会社名に機種依存文字が含まれています')
      .max(255, '会社名を255文字まで入力できます')
      .required('会社名を入力してください'),
  });

  const duplexTest = (name?: string) => {
    let test = true;
    organizations.forEach((sameLayerOrganization) => {
      if (sameLayerOrganization.name === name && organization.name !== name) {
        test = false;
      }
    });
    return test;
  };

  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      name: organization.name,
      companyName: organization.companyName,
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        changeMode('confirm');
      } else {
        const result = await fetch.put(`/organization/${id}`, values);
        if (result.status === 200) {
          const result2 = await fetch.get('/user/organizations');
          dispatch(getOrganizations(result2.data));
          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  //  データの取得
  //  --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      //  対象データの取得
      const result = await fetch.get(`/organization/${id}`); //対象の組織

      if (result.status === 200) {
        setOrganization({
          id: result.data.id,
          parentId: result.data.parentId,
          name: result.data.name,
          companyName: result.data.companyName,
        });

        //  同一名の組織を調べるためのリスト取得
        //  親組織がない場合は、自身の下層組織一覧を取得
        const resultOrgs = await fetch.get(
          `/organization/${
            result.data.parentId ?? result.data.id
          }/organizations`,
        );

        if (resultOrgs.status === 200) {
          // 親IDがある場合は、比較用に親のデータを追加
          if (result.data.parentId) {
            const resultParent = await fetch.get(
              `/organization/${result.data.parentId}`,
            );
            if (resultParent.status === 200) {
              resultOrgs.data.push({
                id: resultParent.data.id,
                name: resultParent.data.name,
                companyName: resultParent.data.companyName,
              });
            } else if (resultParent.status === 500) {
              alertWithCode(resultParent.status);
            }
          }

          setOrganizations(resultOrgs.data);
        } else if (resultOrgs.status === 500) {
          alertWithCode(resultOrgs.status);
        }
      } else if (result.status === 500) {
        alertWithCode(result.status);
      } else {
        alert(result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={organization?.name}
        subTitle={`会社・部署編集${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/organization/${id}`}
      />
      <div className={classes.root}>
        {mode === 'editing' && <OrganizationEditForm {...formik} />}
        {mode === 'confirm' && <OrganizationEditConfirm {...formik} />}
        {mode === 'commit' && (
          <OrganizationCommit
            message="会社・部署を編集しました。"
            linkToList={`/organization/${
              organization.parentId ?? organization.id
            }/organizations`}
            linkToDashboard={`/dashboard/organization/${id}`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(OrganizationEdit);

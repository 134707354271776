import {FC} from 'react';
import IconButton from '@material-ui/core/IconButton';
import useStyles from '../../../assets/styles/Header.css';
import ListIcon from '@material-ui/icons/List';

interface Props{
  onClick: () => void;
}
const MenuButton:FC<Props> = (props) => {
  const classes = useStyles();
  const {onClick} = props;
  return (
    <IconButton
    size="small"
    className={classes.subHeaderIconButton}
    onClick={onClick}
  >
    <ListIcon />
  </IconButton>
  )
}

export default MenuButton

import { FC, ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

interface Props {
  buttonLabel: string;
  children: ReactNode;
  dialogOpen: boolean;
  handleOpenClose: () => void;
}
const PrimaryDialog: FC<Props> = (props) => {
  return (
    <div>
      <Button
        className='delete_button'
        onClick={props.handleOpenClose}
        variant="outlined"
      >
        {props.buttonLabel}
      </Button>
      <Dialog open={props.dialogOpen} onClose={props.handleOpenClose}>
        {props.children}
        <DialogActions>
          <Button
            color="primary"
            onClick={props.handleOpenClose}
            variant="outlined"
          >
            戻る
          </Button>
          <Button autoFocus className='delete_button' variant="contained">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PrimaryDialog;

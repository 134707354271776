import { useFormik } from 'formik';
import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useStyles from '../../assets/styles/Master.css';
import {
  SubHeader,
  WorkSiteCommit,
  WorkSiteCreateConfirm,
  WorkSiteCreateForm,
} from '../../components';
import { fetch, yup, useAlert } from '../../functions';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import { getOrganizations } from '../../redux/commonSlice';
import { ApiDefaultAlert } from '../../types/default_alert';
import FormFooter from '../../components/form/Footer';

type Params = {
  id: string;
};

type GroupEmails = {
  [key: string]: string[];
  group1: string[];
  group2: string[];
  group3: string[];
  group4: string[];
  group5: string[];
  group6: string[];
  group7: string[];
  group8: string[];
  group9: string[];
  group10: string[];
};
type Group = {
  name: string;
  emails: string[];
  alertNumber: number | null;
  alertValue: number | null;
  isHidden: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorksiteCreate: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const dispatch = useDispatch();
  const [default_alerts, setDefaltAlerts] = useState<ApiDefaultAlert[]>([]);
  const [organization, setOrganization] = useState<any>();
  const [workSites, setWorkSites] = useState<any>([{ name: '' }]);
  const [workSiteId, setWorkSiteId] = useState<string>('');
  const [formEmails, setFormEmails] = useState<GroupEmails>({
    group1: [],
    group2: [],
    group3: [],
    group4: [],
    group5: [],
    group6: [],
    group7: [],
    group8: [],
    group9: [],
    group10: [],
  });
  const initialGroups = useMemo(() => {
    const data = [];
    for (let i = 0; i < 10; i++) {
      if (i === 0) {
        data.push({
          name: `グループ${i + 1}`,
          emails: [],
          alertNumber: 1,
          alertValue: null,
          isHidden: false,
        });
      } else {
        data.push({
          name: `グループ${i + 1}`,
          emails: [],
          alertNumber: 1,
          alertValue: null,
          isHidden: true,
        });
      }
    }
    return data;
  }, []);

  const schema = yup.object({
    name: yup
      .string()
      .specialCharacter('現場名に機種依存文字が含まれています')
      .max(255, '現場名は255文字まで入力できます')
      .required('現場名を入力してください')
      .test('duplex-name', 'この現場名は登録されています', (value) =>
        duplexTest(value),
      ),
    post: yup
      .string()
      .matches(/^^[0-9]{7}$/, '郵便番号が正しくありません')
      .required('郵便番号は数字7桁（ハイフンはなし）で入力してください'),
    address: yup
      .string()
      .specialCharacter('住所に機種依存文字が含まれています')
      .max(255, '住所は255文字まで入力できます')
      .required('住所名を入力してください'),
    groups: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        emails: yup
          .array()
          .of(
            yup
              .string()
              .email('アラート時の通知先メールアドレスが不正です')
              .nullable(),
          ),
        alertNumber: yup.number().nullable(),
        alertValue: yup
          .number()
          .nullable()
          .when('alertNumber', {
            is: (value: any) => value === 0,
            then: yup
              .number()
              .min(100, 'アラート設定値は100以上を指定してください')
              .max(65000, 'アラート設定値は65000以下を指定してください')
              .required('アラート設定値を入力してください'),
          }),
      }),
    ),
  });

  const duplexTest = (name?: string) => {
    let test = true;
    workSites.forEach((workSite: any) => {
      if (workSite.name === name) {
        test = false;
      }
    });
    return test;
  };

  //  フォームの定義
  const formik = useFormik({
    initialValues: {
      name: '',
      post: '',
      address: '',
      alertNumber: 1,
      groups: initialGroups,
    } as any,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      const result = await fetch.post('/work_site', {
        organizationId: id,
        name: values.name,
        post: values.post,
        address: values.address,
        groups: values.groups,
        alertNumber: values.alertNumber,
      });

      if (result.status === 200) {
        setWorkSiteId(result.data.id);
        const result2 = await fetch.get('/user/organizations');
        dispatch(getOrganizations(result2.data));
        changeMode('commit');
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    },
  });

  // グループを使用するボタン
  const handleAddGroup = (index: number) => {
    formik.setFieldValue(`groups.${index}.isHidden`, false);
  };

  // グループを使用しないボタン
  const handleDeleteGroup = (index: number) => {
    let hiddenCount = 0;
    formik.values.groups.forEach((group: Group) => {
      group.isHidden && hiddenCount++;
    });
    if (hiddenCount === 9) return;
    formik.setFieldValue(`groups.${index}.isHidden`, true);
  };

  // Email追加ボタン
  const handleAddEmail = (index: number) => {
    if (formEmails[`group${index + 1}`].length < 5) {
      const newEmails = formEmails[`group${index + 1}`].concat('');
      setFormEmails({ ...formEmails, [`group${index + 1}`]: newEmails });
      const newFormEmails = formik.values.groups[index].emails.concat('');
      formik.setFieldValue(`groups.${index}.emails`, newFormEmails);
    }
  };

  // Email削除ボタン
  const handleDeleteEmail = (index: number, mailIndex: number) => {
    if (formEmails[`group${index + 1}`].length > 0) {
      const deleteForms = formEmails[`group${index + 1}`].filter(
        (_, i) => i !== mailIndex,
      );
      let deleteEmails: string[] = [];
      formik.values.groups[index].emails.forEach((email: string, i: number) => {
        if (i !== mailIndex) {
          deleteEmails.push(email);
        }
      });
      setFormEmails({ ...formEmails, [`group${index + 1}`]: deleteForms });
      formik.setFieldValue(`groups.${index}.emails`, deleteEmails);
    }
  };

  //  サブヘッダー用の組織情報取得
  useEffect(() => {
    (async () => {
      const organization = await fetch.get(`/organization/${id}`);
      if (organization.status === 200) {
        setOrganization(organization.data);
      } else if (organization.status === 500) {
        alertWithCode(organization.status);
      }

      //  組織単位のデフォルトアラートを取得
      const daResult = await fetch.get(
        `/default_alert_v2?organizationId=${id}`,
      );
      if (daResult.status === 200) {
        setDefaltAlerts([
          ...daResult.data,
          { number: 0, name: 'カスタム', value: 100 },
        ]);
      } else if (daResult.status === 500) {
        alertWithCode(daResult.status);
      }

      const workSite = await fetch.get(`/organization/${id}/work_sites`);
      if (workSite.status === 200) {
        setWorkSites(workSite.data);
      } else if (workSite.status === 500) {
        alertWithCode(workSite.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={organization?.name}
        subTitle={`現場追加${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/organization/${organization?.id}`}
      />

      <div className={classes.root}>
        {mode === 'editing' && (
          <WorkSiteCreateForm
            {...formik}
            default_alerts={default_alerts}
            onAddGroup={handleAddGroup}
            onAddEmail={(index: number) => handleAddEmail(index)}
            onDeleteEmail={(index: number, mailIndex: number) =>
              handleDeleteEmail(index, mailIndex)
            }
            onDeleteGroup={(index: number) => handleDeleteGroup(index)}
            formEmails={formEmails}
          />
        )}
        {mode === 'confirm' && (
          <WorkSiteCreateConfirm {...formik} default_alerts={default_alerts} />
        )}
        {mode === 'commit' && (
          <WorkSiteCommit
            message={`現場を新規作成しました。\n現場一覧ページから確認してください。`}
            linkToList={`/organization/${id}/workSites`}
            linkToDashboard={`/dashboard/workSite/${workSiteId}`}
          />
        )}
      </div>
      {mode === 'editing' && (
        <FormFooter word="登録" onSubmit={formik.handleSubmit} />
      )}
    </>
  );
};

export default withFormStatus(WorksiteCreate);

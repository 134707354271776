import { FC } from 'react';
import { Grid, Chip } from '@material-ui/core';
import { commonStyles } from '../../../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Label = {
  id: number;
  name: string;
};
type Props = {
  labels: Label[];
};

/**
 * ラベル表示
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const LabelList: FC<Props> = (props: Props) => {
  const classes = commonStyles();

  return (
    <Grid container spacing={1}>
      {props.labels.map((label: Label) => (
        <Grid item md={4} xs={4} key={label.id}>
          <Chip
            classes={{
              root: classes.toggleLabelChips,
              label: classes.toggleLabelChipsLabel,
            }}
            label={label.name}
            size="small"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LabelList;

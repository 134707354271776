import _ from 'lodash';
import { FC, useState, useEffect, ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import {
  UITextField,
  UISelect,
  UIError,
  UIReturn,
  UISubmit,
  PrimaryReadOnlyField,
} from '../../components';
import { ApiDefaultAlert } from '../../types/default_alert';
import useMasterStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  organizationId: string;
  alertNumber: number;
  alertValue: number;
}
type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const AlertForm: FC<Props> = (props) => {
  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    values,
    errors,
    default_alerts,
  } = props;
  const classes = useMasterStyles();
  const [alert_list, setAlertList] = useState([]);

  //  Select Box の変化でアラート値を決定
  const uiSelectHandleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const alert: any = _.find(default_alerts, { number: e.target.value });
    if (alert) {
      setFieldValue('alertValue', alert.value);
    }
    setFieldValue('alertNumber', e.target.value);
  };

  //  デフォルトアラート情報を取得
  useEffect(() => {
    const alert: any = [];
    default_alerts.forEach((o) => {
      alert.push({
        name: o.name,
        value: o.number,
      });
    });

    setAlertList(alert);
  }, [default_alerts]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={classes.errorMessage}>
        <UIError errors={errors} />
      </p>

      <div className={classes.alertRoot}>
        <UISelect
          name="alertNumber"
          label="アラート設定"
          value={String(values.alertNumber)}
          error={errors.alertNumber}
          handleChange={uiSelectHandleChange}
          menus={alert_list}
        />

        {values.alertNumber === 0 ? (
          <>
            <div className="module-spacer--small" />
            <UITextField
              type="text"
              name="alertValue"
              label="アラート設定値"
              value={String(values.alertValue)}
              error={errors.alertValue}
              required
              handleChange={handleChange}
            />
          </>
        ) : (
          <PrimaryReadOnlyField
            label="アラート設定値"
            value={String(values.alertValue)}
          />
        )}
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default AlertForm;

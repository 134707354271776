import _ from 'lodash';
import { FC, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Box, NativeSelect, Button } from '@material-ui/core';
import format from 'date-fns/format';
import { commonStyles } from '../../assets/styles/Dashboard.css';
import { isCheckBeforeTargetDate } from '../../utils/DateUtil';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  value: string;
  isBeforeTargetDate: boolean;
  setValue: Dispatch<SetStateAction<string>>;
};

/**
 * 時間ピッカー
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DashboardTimePicker: FC<Props> = (props: Props) => {
  const classes = commonStyles();

  //  時間を変更する
  const handleChangeHour = (e: ChangeEvent<HTMLSelectElement>) => {
    if (
      isCheckBeforeTargetDate(
        format(
          props.value === null ? new Date() : new Date(props.value),
          `yyyy/MM/dd ${e.target.value}:mm:00`,
        ),
      )
    ) {
      props.setValue(
        format(
          props.value === null ? new Date() : new Date(props.value),
          `yyyy/MM/dd ${e.target.value}:00:00`,
        ),
      );
    } else {
      props.setValue(
        format(
          props.value === null ? new Date() : new Date(props.value),
          `yyyy/MM/dd ${e.target.value}:mm:00`,
        ),
      );
    }
  };

  //  分を変更する
  const handleChangeMinute = (e: ChangeEvent<HTMLSelectElement>) => {
    if (
      isCheckBeforeTargetDate(
        format(
          props.value === null ? new Date() : new Date(props.value),
          `yyyy/MM/dd hh:${e.target.value}:00`,
        ),
      )
    ) {
      props.setValue(
        format(
          props.value === null ? new Date() : new Date(props.value),
          `yyyy/MM/dd HH:00:00`,
        ),
      );
    } else {
      props.setValue(
        format(
          props.value === null ? new Date() : new Date(props.value),
          `yyyy/MM/dd HH:${e.target.value}:00`,
        ),
      );
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <NativeSelect
          className={classes.timePicker}
          value={format(
            props.value === null ? new Date() : new Date(props.value),
            'HH',
          )}
          onChange={handleChangeHour}
        >
          {_.times(24, Number).map((o) => (
            <option key={o}>{('00' + o).slice(-2)}</option>
          ))}
        </NativeSelect>
      </Box>

      <Box className={classes.timePickerSep}>:</Box>

      <Box>
        {props.isBeforeTargetDate ? (
          <Button className={classes.datePicker00}>00</Button>
        ) : (
          <NativeSelect
            className={classes.timePicker}
            value={format(
              props.value === null ? new Date() : new Date(props.value),
              'mm',
            )}
            onChange={handleChangeMinute}
          >
            {_.times(12, Number).map((o) => (
              <option key={o * 5}>{('00' + o * 5).slice(-2)}</option>
            ))}
          </NativeSelect>
        )}
      </Box>
    </Box>
  );
};

export default DashboardTimePicker;

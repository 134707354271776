import { FC, ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { usageStyles } from '../../assets/styles/CsvUpload.css';
import { UISubmit, UIReturn, UIError, FormHowToUse } from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  file: any;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, setFieldValue, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  const csvClasses = usageStyles();

  //  ファイルを選択
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFieldValue(e.target.name, e.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={masterClasses.message}>
        登録する装着者が記載されたCSVファイルをアップロードしてください
        <span className={csvClasses.warning}>
          登録したcsvの内容と同じ内容に装着者一覧の更新を行います
        </span>
      </p>

      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <input
          type="file"
          name="file"
          accept="text/csv"
          onChange={handleFileChange}
          onClick={(e) => {
            e.currentTarget.value = '';
          }}
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>

      <div className="module-spacer--medium" />

      <FormHowToUse />

      <table className={csvClasses.usage}>
        <tbody>
          <tr className={csvClasses.row}>
            <th>
              ログイン名<span>必須</span>
            </th>
            <td>全角カタカナまたは全角数字 ※「９９９９」は不可</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              グループ番号<span>必須</span>
            </th>
            <td>現場に登録したグループ番号1~10を半角で入力</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>管理番号</th>
            <td>社員番号など、お客様が任意に設定できる番号</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              ユーザー名<span>必須</span>
            </th>
            <td>装着者のユーザー名</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>ユーザー名（サブ）</th>
            <td>装着者のサブユーザー名</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              ユーザー名　フリガナ<span>必須</span>
            </th>
            <td>装着者のユーザー名の読み仮名を全角カタカナで登録</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>ユーザー名　フリガナ（サブ）</th>
            <td>装着者のサブユーザー名の読み仮名を全角カタカナで登録</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              生年月日<span>必須</span>
            </th>
            <td>西暦/月/日 (例 : 1980/01/01)</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              性別<span>必須</span>
            </th>
            <td>
              male,female,other のどれかで入力（male: 男性、female: 女性、
              other: その他）
            </td>
          </tr>

          <tr className={csvClasses.row}>
            <th>メールアドレス</th>
            <td>装着者個人の固有アドレスまたは共有アドレス</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>電話番号</th>
            <td>ハイフンなしで半角数字で登録</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              郵便番号<span>必須</span>
            </th>
            <td>ハイフンなしで半角数字で登録</td>
          </tr>

          <tr className={csvClasses.row}>
            <th>管理用住所</th>
            <td>
              全角/半角で漢字、英語、ひらがな、カタカナ、数字、ハイフンの使用が可能
            </td>
          </tr>

          <tr className={csvClasses.row}>
            <th>
              所属<span>必須</span>
            </th>
            <td>
              全角/半角で漢字、英語、ひらがな、カタカナ、数字、ハイフンの使用が可能
            </td>
          </tr>

          <tr className={csvClasses.row}>
            <th>アラート設定</th>
            <td>
              現場またはグループで設定したアラート番号を利用する場合は未入力、
              <br />
              個別で指定したい場合はアラート番号を入力、
              <br />
              個別でアラート設定値の値を反映したい場合は0を入力
            </td>
          </tr>

          <tr className={csvClasses.row}>
            <th>アラート設定値</th>
            <td>
              個別の設定値を付与したい際に半角数字で登録、アラート設定が0の場合のみ有効
            </td>
          </tr>

          <tr className={csvClasses.row}>
            <th>ラベル</th>
            <td>
              新人、高齢、疾患、発症歴者、喫煙者、習慣飲酒、肥満、特殊作業、要注意から任意に設定可能
              <br />
              複数設定する場合は、
              <span className={csvClasses.important}>
                ラベルの間を半角スペースで区切る必要がある
              </span>
              ので注意
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Form;

import { FC } from 'react';
import { PrimaryOutlinedButton } from '../../components';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  link: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkerCommit: FC<Props> = (props: Props) => {
  const { message, link } = props;

  return (
    <>
      <pre>{message}</pre>

      <div className="module-spacer--small" />
      <PrimaryOutlinedButton label="装着者一覧へ" linkTo={link} />
    </>
  );
};

export default WorkerCommit;

import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PrimaryOutlinedButton } from '..';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
};

type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DeviceCommit: FC<Props> = (props: Props) => {
  const { message } = props;

  const { id } = useParams<Params>();

  return (
    <>
      <pre>{message}</pre>

      <div className="module-spacer--small" />
      <PrimaryOutlinedButton
        label="センサーデバイス一覧へ"
        linkTo={`/workSite/${id}/devices`}
      />
    </>
  );
};

export default DeviceCommit;

import { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import useStyles from '../../assets/styles/Uikit.css';
interface Props {
  label: string;
  value: string;
  multiline?: boolean;
}
const PrimaryReadOnlyField: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        disabled
        label={props.label}
        value={props.value}
        multiline={props.multiline ?? false}
      />
    </div>
  );
};

export default PrimaryReadOnlyField;

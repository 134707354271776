import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  SubHeader,
  DeviceCsvSampleDownloadButton,
  DeviceCommit,
  DeviceUploadConfirm,
  DeviceUploadForm,
} from '../../components';
import { fetch, csvParser, useAlert } from '../../functions';
import { CsvDeviceHeader } from '../../constants/csv';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import useStyles from '../../assets/styles/Master.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const contentSchema = yup.object({
  file: yup.mixed().required('ファイルをアップロードしてください'),
});
export const deviceSchema = yup.object({
  センサーデバイス名: yup
    .string()
    .matches(/^[A-Z]{3}[0-9]{4}$/, {
      message: 'センサーデバイス名が正しくありません',
    })
    .required('センサーデバイス名が必須です'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const DevicesCreate: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [data, setData] = useState<any[]>([]);
  const [workSite, setWorkSite] = useState<any>();

  //  メニューの追加
  const subMenus = [
    {
      icon: <span />,
      name: 'CSVサンプルダウンロード',
      link: '',
      component: <DeviceCsvSampleDownloadButton />,
    },
  ];

  //  フォームの定義
  const formik = useFormik({
    initialValues: {} as { file: File },
    validationSchema: contentSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        // CSV をパースする
        // ※ delimiter を$¥（利用しないであろう記号）にすることで、1項目だけの際に起きるエラーを防止
        const result = await csvParser({
          file: values.file,
          csvHeader: CsvDeviceHeader,
          delimiter: '$¥',
          validSchema: deviceSchema,
        });

        //  エラーを処理する
        if (result.error.length > 0) {
          result.error.forEach((e) => {
            actions.setFieldError(e.key, e.message);
          });
        } else {
          setData(result.data);
          changeMode('confirm');
        }
      } else {
        //  ファイルを送信する
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('workSiteId', id);

        const result = await fetch.post(`/device/bulk_regist`, formData);
        if (result.status === 200) {
          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        } else {
          alert('エラーが発生しました');
        }
      }
    },
  });

  useEffect(() => {
    (async () => {
      //  現場情報の取得
      const result = await fetch.get(`/work_site/${id}`);
      if (result.status === 200) {
        setWorkSite(result.data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={workSite?.name}
        subTitle={`センサーデバイス一括更新${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${id}`}
        menus={subMenus}
      />

      <div className={classes.root}>
        {mode === 'editing' && <DeviceUploadForm {...formik} />}
        {mode === 'confirm' && <DeviceUploadConfirm {...formik} data={data} />}
        {mode === 'commit' && (
          <DeviceCommit
            message={`センサーデバイスを一括登録しました。\nセンサーデバイス一覧ページから確認してください。`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(DevicesCreate);

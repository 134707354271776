import { FC, ReactChild } from 'react';
import clsx from 'clsx';
import { emptyStyles } from '../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  isEmpty: boolean;
  children: ReactChild;
  label?: string;
  overlay?: boolean;
  size?: 'normal' | 'small';
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Empty: FC<Props> = (props: Props) => {
  const {
    isEmpty,
    children,
    label = '集計中',
    overlay = false,
    size = 'normal',
  } = props;
  const emptyClasses = emptyStyles();

  return (
    <>
      {isEmpty ? (
        <>
          {overlay ? (
            <div className={clsx(emptyClasses.empty, emptyClasses.overlay)}>
              <div className={emptyClasses.overlayLabel}>{label}</div>
              <div className={emptyClasses.content}>{children}</div>
            </div>
          ) : (
            <div className={emptyClasses.empty}>
              <div className={clsx(emptyClasses.label, emptyClasses[size])}>
                {label}
              </div>
            </div>
          )}
        </>
      ) : (
        children
      )}
    </>
  );
};

export default Empty;

import { FormGroup } from '@material-ui/core';
import { FC } from 'react';
import { PrimaryOutlinedButton } from '../../components';
/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  linkToList: string;
  linkToDashboard?: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkSiteCommit: FC<Props> = (props: Props) => {
  const { message, linkToList, linkToDashboard } = props;

  return (
    <>
      <pre>{message}</pre>

      <div className="module-spacer--small" />
      <FormGroup row>
        <PrimaryOutlinedButton label="現場一覧へ" linkTo={linkToList} />
       { linkToDashboard && <><div className="module-spacer--button" />
        <PrimaryOutlinedButton
          label="現場ダッシュボードへ"
          linkTo={linkToDashboard}
        /></>}
      </FormGroup>
    </>
  );
};

export default WorkSiteCommit;

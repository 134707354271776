import { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoginState } from '../../redux/store';
import { signIn, signOut } from '../../redux/loginSlice';
import { getUser } from '../../redux/userSlice';
import { getOrganizations } from '../../redux/commonSlice';
import { fetch } from '../../functions';

/**
 *
 *
 *
 * @method Router
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const PrivateRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: any) => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state: LoginState) => state.login.isSignedIn);
  const [isCheck, setIsCheck] = useState<boolean>(false);

  //  ユーザーデータを取得
  useEffect(() => {
    (async () => {
      if ('authToken' in localStorage) {
        const result = await fetch.get('/user');
        const result2 = await fetch.get('/user/organizations');
        if (result.status === 200 && result2.status === 200) {
          dispatch(getUser(result.data));
          dispatch(getOrganizations(result2.data));
          dispatch(signIn());
        } else {
          dispatch(signOut());
        }
      }

      setIsCheck(true);
    })();
  }, [dispatch]);

  return (
    <>
      {isCheck && (
        <Route
          {...rest}
          render={(props) =>
            isSignedIn ? (
              <Layout>
                <Component {...rest} {...props} />
              </Layout>
            ) : (
              <Redirect to="/signin" />
            )
          }
        />
      )}
    </>
  );
};
export default PrivateRoute;

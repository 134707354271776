import { FC } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import {
  UITextField,
  UIPasswordField,
  UISubmit,
  UIReturn,
  UIError,
  PrimaryReadOnlyField,
} from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
  password: string;
  passwordConfirm: string;
  belongingName: string;
}

type Props = FormikProps<FormValues> & {
  email: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<Props> = (props) => {
  const { handleSubmit, handleChange, values, errors, email } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p style={{ color: '#666666' }}>
        新規に作成するユーザー情報を入力してください
        <br />
        <br />
        パスワードは半角英字（大文字小文字）/半角数字/記号（!%@.?/-)を組み合わせた8文字以上16文字以内で設定してください
        <br />
        所属には環境依存文字を使用できません
      </p>

      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <PrimaryReadOnlyField label="メールアドレス" value={email} />
        <UITextField
          type="text"
          name="name"
          label="ユーザー名"
          value={values.name}
          error={errors.name}
          required
          handleChange={handleChange}
        />
        <UIPasswordField
          name="password"
          label="パスワード"
          value={values.password}
          error={errors.password}
          required
          handleChange={handleChange}
        />
        <UIPasswordField
          name="passwordConfirm"
          label="パスワード(確認)"
          value={values.passwordConfirm}
          error={errors.passwordConfirm}
          required
          handleChange={handleChange}
        />
        <UITextField
          type="text"
          name="belongingName"
          label="所属"
          value={values.belongingName}
          error={errors.belongingName}
          handleChange={handleChange}
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

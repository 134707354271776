import { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { useContentsStyles } from '../../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
  icon?: ReactNode | string;
  color?: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpParagraph = (props: Props) => {
  const {color = false} = props;
  const classes = useContentsStyles();

  return (
    <Box className={color ? classes.paragraphWarning : classes.paragraph} display="flex">
      {props.icon && <Box className={classes.paragraphIcon}>{props.icon}</Box>}
      <Box flexGrow={1}>{props.children}</Box>
    </Box>
  );
};

export default HelpParagraph;

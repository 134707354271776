import Button from '@material-ui/core/Button';
import { FC } from 'react';
import { PrimaryOutlinedButton } from '..';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  message: string;
  link: string;
  data: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const InvitationCommit: FC<Props> = (props: Props) => {
  const { message, link, data } = props;

  return (
    <>
      <pre>{message}</pre>

      <div className="module-spacer--small" />
      <PrimaryOutlinedButton label="管理者一覧へ" linkTo={link} />
      {data && (
        <>
          <p>【開発用】TODOトークンがある場合のみ表示</p>
          <Button href={`/user/regist?token=${data}`} variant="outlined">
            招待メールを受け取った後に表示されるページ
          </Button>
        </>
      )}
    </>
  );
};

export default InvitationCommit;

import { makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& > *': {
        marginTop: '1rem',
        width: '100%',
      },
      '& .MuiTextField-root': {
        marginTop: '1rem',
        width: '100%',
      },
    },
    formControl: {
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectLabel: {
      margin: '3px 0 0 -4px',
      padding: '0 4px',
      backgroundColor: '#fff',
    },
    selectMenu: {
      overflow: 'auto',
    },

    //IconButtonLabel.tsx
    iconButtonLabel: {
      '&>*': { color: 'rgba(0, 0, 0, 0.54)' },
      textAlign: 'center',
      width: '100%',
    },

    //  UIToggleLabel
    toggleLabelChips: {
      width: '100%',
    },
    toggleLabelChipsActive: {
      background: '#458E95 !important',
      color: 'white !important',
      '&:focus': {
        background: '#458E95',
        color: 'white',
      },
    },
    radioLabelActive: {
      color: '#458E95',
    },
  }),
  { index: 1 },
);
export default useStyles;

import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeviceCsvDownloadButton, List, SubHeader } from '../../components';
import { Row } from '../../components/list/List';
import { fetch, useAlert } from '../../functions';

type Params = {
  id: string;
};

const header = ['センサーデバイス名'];

const DevicesList = () => {
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [data, setData] = useState<Row[]>([]);
  const [workSite, setWorkSite] = useState<any>();
  const { alertWithText } = useAlert();
  const subMenus = [
    {
      icon: <AddIcon />,
      name: 'センサーデバイス追加',
      link: `/workSite/${id}/device/create`,
    },
    {
      icon: <EditIcon />,
      name: 'センサーデバイス一括更新',
      link: `/workSite/${id}/device/multipleCreate`,
    },
    {
      icon: <GetAppIcon />,
      name: 'センサーデバイス一覧ダウンロード',
      link: '',
      component: <DeviceCsvDownloadButton />,
    },
  ];

  //サブヘッダー用の現場情報を取得。
  useEffect(() => {
    (async () => {
      const workSite = await fetch.get(`/work_site/${id}`);
      if (workSite.status === 200) {
        setWorkSite(workSite.data);
      } else if (workSite.status === 500) {
        alertWithCode(workSite.status);
      }
    })();
  }, [id, alertWithCode]);

  useEffect(() => {
    (async () => {
      const result = await fetch.get(`/work_site/${id}/devices`);
      if (result.status === 200) {
        const data: Row[] = [];
        result.data.forEach(async (device: any) => {
          data.push({
            id: device.id,
            cells: [device.name],
            name: device.name,
            isLocked: device.isLocked,
          });
        });
        setData(data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [setData, id, alertWithCode]);

  const deleteDevice = async (id: string) => {
    await fetch.delete(`/device/${id}`);
    setData(
      data.filter((row: Row) => {
        return row.id !== id;
      }),
    );
  };

  const unlockDevice = async (lockId: string) => {
    const index = _.findIndex(data, { id: lockId });
    if (index !== -1) {
      const result = await fetch.put(`/device/${lockId}/unlock`);
      if (result.status === 200) {
        data[index].isLocked = false;
        setData([...data]);
        alertWithText('ロックを解除しました', 'success');
      } else {
        alertWithText('権限がありません');
      }
    } else {
      alertWithText('ロック解除に失敗しました');
    }
  };

  return (
    <div>
      <SubHeader
        title={workSite?.name}
        subTitle="センサーデバイス一覧"
        link={`/dashboard/workSite/${workSite?.id}`}
        menus={subMenus}
      />
      <List
        tableHeadCells={header}
        tableBodyCells={data}
        deleteItem={deleteDevice}
        unlockItem={unlockDevice}
      />
    </div>
  );
};

export default DevicesList;

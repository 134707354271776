import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';
import logo from '../../assets/images/logo.svg';
import useStyles from '../../assets/styles/Master.css';
import { UserCommit, UserConfirm, UserCreateForm } from '../../components';
import { fetch } from '../../functions/fetch';
import { useAlert } from '../../functions';
import { FormMode } from '../../types/forms';

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const schema = yup.object({
  name: yup.string().required('ユーザーネームを入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードは8文字以上を入力してください')
    .test('regex', '半角英数字記号（!%@.?/-）のみ入力できます', value=>/^[a-zA-Z0-9!%@.?/-]+$/.test(value??'')),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません'),
  belongingName: yup.string().nullable().required('所属を入力してください'),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const UserCreate: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alertWithCode } = useAlert();
  const [mode, setMode] = useState<FormMode>('editing');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const [email, setEmail] = useState<string>('');
  const formik = useFormik({
    initialValues: {name: '', password: '', passwordConfirm: '', belongingName: ''},
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        setMode('confirm');
      } else {
        const result = await fetch.post(`/login/user?token=${token}`, {
          name: values.name,
          password: values.password,
          belongingName: values.belongingName,
        });

        if (result.status === 200) {
          setMode('commit');
        } else if(result.status === 500) {
          alertWithCode(result.status);
        }
      }
    },
  });

  useEffect(() => {
    (async () => {
      const result = await fetch.get(
        `/login/token_check/invitation?token=${token}`,
      );
      if (result.status === 200) {
        setEmail(result.data.email);
      } else {
        if (result.status === 500) {
          alertWithCode(result.status);
        }
        dispatch(push('/signin'));
      }
    })();
  }, [search, token, email, dispatch, alertWithCode]);

  return (
    <>
      <div className={classes.centerRoot}>
        <img src={logo} alt="eメットシステム" />
        {mode === 'editing' && <UserCreateForm {...formik} email={email} />}
        {mode === 'confirm' && <UserConfirm {...formik} email={email} />}
        {mode === 'commit' && <UserCommit message="ユーザーを作成しました。" />}
      </div>
    </>
  );
};

export default UserCreate;

import { FC, useState, MouseEvent } from 'react';
import { Popover } from '@material-ui/core';
import {
  specialChar1,
  specialChar2,
  specialChar3,
  specialChar4,
} from '../../functions/yup';
import { useHowToUseStyles } from '../../assets/styles/Form.css';

/**
 * フォームの使い方
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const FormHowToUse: FC = () => {
  const classes = useHowToUseStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.message}>
        機種依存文字は使用できません。
        <span className={classes.messageLink} onClick={handleOpen}>
          詳細はこちら
        </span>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popover}>
          <div className={classes.popoverMessage}>
            下記に記載される文字は使用できません
          </div>

          <table className={classes.popoverTable}>
            <tbody>
              <tr>
                <td>省略文字</td>
                <td>{specialChar1.join(',')}</td>
              </tr>

              <tr>
                <td>単位</td>
                <td>{specialChar2.join(',')}</td>
              </tr>

              <tr>
                <td>丸数字等</td>
                <td>{specialChar3.join(',')}</td>
              </tr>

              <tr>
                <td>ローマ数字</td>
                <td>{specialChar4.join(',')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Popover>
    </>
  );
};

export default FormHowToUse;

import { FC, ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { usageStyles } from '../../assets/styles/CsvUpload.css';
import { UISubmit, UIReturn, UIError } from '../index';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  file: any;
}

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<FormikProps<FormValues>> = (props) => {
  const { handleSubmit, setFieldValue, errors } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  const csvClasses = usageStyles();

  //  ファイルを選択
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFieldValue(e.target.name, e.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <p className={masterClasses.message}>
        登録するセンサーデバイスが記載されたCSVファイルをアップロードしてください
        <span className={csvClasses.warning}>
          登録したcsvの内容と同じ内容にセンサーデバイス一覧の更新を行います
        </span>
      </p>

      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>

      <div className={uikitClasses.root}>
        <input
          type="file"
          name="file"
          accept="text/csv"
          onChange={handleFileChange}
          onClick={(e) => {
            e.currentTarget.value = '';
          }}
        />
      </div>

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

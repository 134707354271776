import _ from 'lodash';
import { FC, useEffect, useState, ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import { Button } from '@material-ui/core';
import useMasterStyles from '../../assets/styles/Master.css';
import useUikitStyles from '../../assets/styles/Uikit.css';
import { ApiDefaultAlert } from '../../types/default_alert';
import {
  PrimaryReadOnlyField,
  UITextField,
  UISelect,
  UIError,
  FormHowToUse,
} from '../index';
import { AddCircleOutline, Clear, HighlightOff } from '@material-ui/icons';
import { FormButtonStyles } from '../../assets/styles/Form.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  name: string;
  post: string;
  address: string;
  alertNumber: number | null;
  alertValue: number | null;
  groups: Group[];
}

type Group = {
  name: string;
  emails: string[];
  alertNumber: number | null;
  alertValue: number | null;
  isHidden: boolean;
};

type Props = FormikProps<FormValues> & {
  default_alerts: ApiDefaultAlert[];
  formEmails: any;
  onAddGroup: (index: number) => void;
  onAddEmail: (index: number) => void;
  onDeleteEmail: (index: number, mailIndex: number) => void;
  onDeleteGroup: (index: number) => void;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form: FC<Props> = (props) => {
  const classes = FormButtonStyles();
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    onAddGroup,
    onAddEmail,
    onDeleteEmail,
    onDeleteGroup,
    formEmails,
    values,
    errors,
    default_alerts,
  } = props;
  const masterClasses = useMasterStyles();
  const uikitClasses = useUikitStyles();
  // const [formGroups, setFormGroups] = useState<Group[]>(values.groups);

  const [alert_list, setAlertList] = useState([]);
  const alert = (alertNumber: number | null): any => {
    return _.find(default_alerts, {
      number: alertNumber === null ? values.alertNumber : alertNumber,
    });
  };

  //  Select Box の変化でアラート値を決定
  const uiSelectHandleChange = (
    e: ChangeEvent<HTMLSelectElement>,
    index: number,
  ) => {
    const customAlert: any = alert(Number(e.target.value));
    setFieldValue(`groups.${index}.alertValue`, customAlert?.value);
    setFieldValue(`groups.${index}.alertNumber`, e.target.value);
  };

  //  デフォルトアラート情報を取得
  useEffect(() => {
    const alert: any = [];
    default_alerts.forEach((o) => {
      alert.push({
        name: o.name,
        value: o.number,
      });
    });
    setAlertList(alert);
  }, [default_alerts]);

  useEffect(() => {
    errors && window.scrollTo(0, 0);
  }, [errors]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormHowToUse />
      <p>新規に作成する現場情報を入力してください</p>
      <p style={{ color: '#FF0000', fontSize: '16px' }}>
        入力完了後、登録ボタンを押してください！
      </p>
      <div className={masterClasses.errorMessage}>
        <UIError errors={errors} />
      </div>
      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="name"
          label="現場名"
          value={values.name}
          error={errors.name}
          required
          handleChange={handleChange}
        />
      </div>
      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="post"
          label="郵便番号"
          value={values.post}
          error={errors.post}
          required
          handleChange={handleChange}
          helperText="数字7桁（ハイフンなし）"
        />
      </div>
      <div className={uikitClasses.root}>
        <UITextField
          type="text"
          name="address"
          label="住所"
          value={values.address}
          error={errors.address}
          required
          handleChange={handleChange}
        />
      </div>
      {values.groups.map((group: Group, index: number) => {
        if (!group.isHidden) {
          return (
            <>
              <div className={masterClasses.workSiteEditAlert} key={index}>
                <div className={masterClasses.workSiteEditGroupTitle}>
                  グループ{index + 1}
                </div>
                <div>
                  <UITextField
                    type="text"
                    name={`groups.${index}.name`}
                    label="グループ名"
                    value={group.name}
                    error={
                      errors.groups &&
                      errors.groups[index] &&
                      (errors.groups[index] as any).name
                    }
                    handleChange={handleChange}
                  />
                  {formEmails[`group${index + 1}`]?.map(
                    (_: number, i: number) => (
                      <div className="emailField">
                        <UITextField
                          type="email"
                          name={`groups.${index}.emails.${i}`}
                          className="formUITextField"
                          label={`アラート時の通知先メールアドレス${i + 1}`}
                          key={index + i}
                          value={group.emails?.[i]}
                          error={
                            errors.groups &&
                            errors.groups[index] &&
                            (errors.groups[index] as any).email[i]
                          }
                          handleChange={handleChange}
                        />
                        <Button
                          type="button"
                          className={classes.emailDeleteBtn}
                          onClick={() => onDeleteEmail(index, i)}
                          startIcon={<Clear />}
                        ></Button>
                      </div>
                    ),
                  )}
                  <div className="module-spacer--extra-small" />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.emailAddBtn}
                    onClick={() => onAddEmail(index)}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    startIcon={<AddCircleOutline />}
                  >
                    メールアドレスを追加
                  </Button>
                  <div className="module-spacer--extra-small" />
                  <UISelect
                    name={`groups.${index}.alertNumber`}
                    label="アラート設定"
                    value={group.alertNumber}
                    error={
                      errors.groups &&
                      errors.groups[index] &&
                      (errors.groups[index] as any).alertNumber
                    }
                    handleChange={(e: any) => uiSelectHandleChange(e, index)}
                    menus={alert_list}
                    empty={undefined}
                  />
                  {group.alertNumber === 0 ? (
                    <>
                      <div className="module-spacer--small" />
                      <UITextField
                        type="text"
                        name={`groups.${index}.alertValue`}
                        label="アラート設定値"
                        value={String(group.alertValue)}
                        error={
                          errors.groups &&
                          errors.groups[index] &&
                          (errors.groups[index] as any).alertValue
                        }
                        required
                        handleChange={handleChange}
                      />
                    </>
                  ) : (
                    <PrimaryReadOnlyField
                      label="アラート設定値"
                      value={alert(group.alertNumber)?.value ?? '未設定'}
                    />
                  )}
                </div>
                <div className="module-spacer--extra-small" />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.groupDeleteBtn}
                  onClick={() => onDeleteGroup(index)}
                  startIcon={<HighlightOff />}
                >
                  {`グループ${index + 1}を使用しない`}
                </Button>
              </div>
            </>
          );
        } else {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classes.groupAddBtn}
              onClick={() => onAddGroup(index)}
            >
              {`グループ${index + 1}を使用する`}
            </Button>
          );
        }
      })}
      <div className="module-spacer--extra-small" />
    </form>
  );
};

export default Form;

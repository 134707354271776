import { FC, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import face1 from '../../assets/images/face/1.svg';
import face2 from '../../assets/images/face/2.svg';
import face3 from '../../assets/images/face/3.svg';
import face4 from '../../assets/images/face/4.svg';
import { commonStyles } from '../../assets/styles/Dashboard.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  alertLevel1: number;
  alertLevel2: number;
  alertLevel3: number;
  alertLevel4: number;
  totalCount: number;
  size?: 'regular' | 'large';
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const FaceIndicator: FC<Props> = (props: Props) => {
  const {
    alertLevel1,
    alertLevel2,
    alertLevel3,
    alertLevel4,
    totalCount,
    size = 'regular',
  } = props;
  const classes = commonStyles();
  const isLarge = size === 'large';

  //  割合の計算
  const calcRatio = useCallback(
    (val) => {
      return totalCount !== 0 && totalCount !== null
        ? ((val / totalCount) * 100).toFixed(0)
        : 0;
    },
    [totalCount],
  );

  return (
    <>
      <div
        className={clsx(
          classes.face,
          classes.face4,
          isLarge && classes.face4Large,
        )}
      >
        <img src={face4} alt="危険" />

        <div className={classes.faceIconValue}>
          <div className={classes.faceIconValuePercent}>
            <strong>{calcRatio(alertLevel4)}</strong>
            <small>%</small>
          </div>
          <div className={classes.faceIconValueValue}>
            <strong>{alertLevel4}</strong>
            <small>人</small>
          </div>
        </div>
      </div>

      <Box
        className={clsx(
          classes.face,
          classes.face3,
          isLarge && classes.face3Large,
        )}
        display="flex"
        alignItems="center"
      >
        <Box>
          <img src={face3} alt="警戒" />
        </Box>

        <Box className={classes.faceIconValue} flexGrow={1}>
          <div className={classes.faceIconValuePercent}>
            <strong>{calcRatio(alertLevel3)}</strong>
            <small>%</small>
          </div>
          <div className={classes.faceIconValueValue}>
            <span>{alertLevel3}</span>
            <small>人</small>
          </div>
        </Box>
      </Box>

      <Grid container>
        <Grid item xs={6}>
          <Box
            className={clsx(
              classes.face,
              classes.face2,
              isLarge && classes.face2Large,
            )}
            display="flex"
            alignItems="center"
          >
            <Box>
              <img src={face2} alt="注意" />
            </Box>

            <Box className={classes.faceIconValue} flexGrow={1}>
              <div className={classes.faceIconValuePercent}>
                <strong>{calcRatio(alertLevel2)}</strong>
                <small>%</small>
              </div>
              <div className={classes.faceIconValueValue}>
                <span>{alertLevel2}</span>
                <small>人</small>
              </div>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            className={clsx(
              classes.face,
              classes.face1,
              isLarge && classes.face1Large,
            )}
            display="flex"
            alignItems="center"
          >
            <Box>
              <img src={face1} alt="用心" />
            </Box>

            <Box className={classes.faceIconValue} flexGrow={1}>
              <div className={classes.faceIconValuePercent}>
                <strong>{calcRatio(alertLevel1)}</strong>
                <small>%</small>
              </div>
              <div className={classes.faceIconValueValue}>
                <span>{alertLevel1}</span>
                <small>人</small>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FaceIndicator;

import _ from 'lodash';
import { FormikProps } from 'formik';
import { FormGroup } from '@material-ui/core';
import { PrimaryReadOnlyField, UISubmit, UIReturn, UIError } from '../index';
import useStyles from '../../assets/styles/Master.css';
import { EnqueteOnOff } from '../../templates/enquete/EnqueteEdit';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
interface FormValues {
  [key: string]: number;
  enqueteOnOff: number;
  enqueteFormId1: number;
  enqueteFormId2: number;
  enqueteFormId3: number;
  enqueteFormId4: number;
  enqueteFormId5: number;
  enqueteFormId6: number;
  enqueteFormId7: number;
  enqueteFormId8: number;
}

type Props = FormikProps<FormValues> & {
  enqueteForm: {
    name: string;
    value: number;
  }[];
  enqueteCount: 4 | 8;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const Form = (props: Props) => {
  const { handleSubmit, values, errors, enqueteForm, enqueteCount } = props;
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <div className={classes.errorMessage}>
        <UIError errors={errors} />
      </div>

      <PrimaryReadOnlyField
        label={'アンケート機能ON/OFF'}
        value={values['isHidden'] === EnqueteOnOff.ON ? 'ON' : 'OFF'}
      />

      <PrimaryReadOnlyField
        label={'アンケート項目数'}
        value={String(enqueteCount) ?? ''}
      />

      {Object.keys(values).map((key, index: number) => {
        if (key === 'isHidden') {
          return <></>;
        }
        let isHidden = false;
        if (values['isHidden'] === EnqueteOnOff.OFF) {
          isHidden = true;
        } else {
          if (enqueteCount === 4 && index > 4) {
            isHidden = true;
          }
        }
        const enq = _.find(enqueteForm, { value: values[key] });

        return (
          <PrimaryReadOnlyField
            label={`アンケート設定 ${index}`}
            value={isHidden ? '-' : enq?.name ? enq.name : ''}
            key={key}
          />
        );
      })}

      <div className="module-spacer--medium" />

      <FormGroup row>
        <UIReturn title="戻る" />
        <div className="module-spacer--button" />
        <UISubmit title="確認" />
      </FormGroup>
    </form>
  );
};

export default Form;

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
 const TrashIcon = () => {
    return <img src="/images/help/trash.svg" alt="削除" />;
  };
  
  export default TrashIcon;
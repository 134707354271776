import { Box } from '@material-ui/core';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { getAge } from '../../../functions';
import { useSubheaderStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  timestamp: string;
  name: string;
  birthday: string;

  alertValue: number;
  alertName: string;

  projectName: string;
  organizationName: string;
  worksiteName: string;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportSubHeaderWorkerMonthly = (props: Props) => {
  const {
    timestamp,
    name,
    birthday,
    alertValue,
    alertName,
    projectName,
    organizationName,
    worksiteName,
  } = props;
  const classes = useSubheaderStyles();

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center">
        <Box className={classes.cellLarge}>
          {timestamp && format(new Date(timestamp), 'yyyy年MM月dd日')}
          <span> ~ </span>
          {timestamp && format(lastDayOfMonth(new Date(timestamp)), 'MM月dd日')}
        </Box>
        <Box className={classes.cellJoin}>の</Box>
        <Box className={classes.cellLarge}>{name}</Box>
        <Box>({birthday && timestamp && getAge(birthday, timestamp)}歳)</Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box className={classes.cellJoin}>ー</Box>
        <Box className={classes.cellSmall}>
          アラート設定値 {alertValue} : {alertName}
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box className={classes.cellImage}>
          <img src="/images/report/pin.svg" alt={projectName} />
        </Box>
        <Box className={classes.cellSmall}>
          {projectName} / {organizationName}
        </Box>
        <Box className={classes.cellImage}>
          <img src="/images/report/map.svg" alt={worksiteName} />
        </Box>
        <Box className={classes.cellSmall}>{worksiteName}</Box>
      </Box>
    </div>
  );
};

export default ReportSubHeaderWorkerMonthly;

import { ReportWorkSiteMonthlyEnqueteDataForm } from '../../../types/report';
import { useEnqueteMonthlyPieStyles } from '../../../assets/styles/Report.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  enqueteForm: ReportWorkSiteMonthlyEnqueteDataForm;
};

/**
 *
 *
 *
 * @method Component
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const ReportMonthlyEnqueteTickC2 = (props: Props) => {
  const { enqueteForm } = props;
  const classes = useEnqueteMonthlyPieStyles();

  return (
    <>
      <div className={classes.columnC2}>
        <div className={classes.answerColorBoxError} />
        <div>
          {enqueteForm.isReverse
            ? enqueteForm.leftComment
            : enqueteForm.rightComment}
        </div>
      </div>
      <div className={classes.columnC2}>
        <div className={classes.answerColorBox} />
        <div>
          {enqueteForm.isReverse
            ? enqueteForm.rightComment
            : enqueteForm.leftComment}
        </div>
      </div>
    </>
  );
};

export default ReportMonthlyEnqueteTickC2;

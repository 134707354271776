import { ReactNode } from 'react';
import { useContentsStyles } from '../../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  children: ReactNode;
  id: string;
  first?: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSection = (props: Props) => {
  const { children, id, first = false} = props;
  const contentClasses = useContentsStyles();

  return (
    <section className={first ? contentClasses.sectionTop : contentClasses.section} id={id}>
      {children}
    </section>
  );
};

export default HelpSection;
